import styled from "styled-components";
import { Box, Typography} from '@mui/material'

import { color } from "../../utils/Colors";

const dataInPx = (value) => {
return `${value * 8}px`
}

export const AboutSuperContainer = styled(Box)({
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center', 
    alignItems: 'center',
    width: '100%',
    marginBottom: props => (!props.isConnect && dataInPx(10)),
    padding: props => (props.isConnect && `${dataInPx(10)} 0px`),
})


export const ConnectMainContainer = styled(Box)({
    maxWidth: 1350, 
    display: "flex", 
    flexDirection: props => (props.matchesxss ? "row" : "column"),
    width: '90%',

})


export const ConnectContainer = styled(Box)({
    display: "flex", 
    flexDirection: "column", 
    width:  props => (props.matchesxss ? '65%' : '100%'),
    backgroundColor: "#97BED2",
    borderTopRightRadius: props => (props.matchesxss ? '8px' : 0),
    borderTopLeftRadius: 0,
    borderBottomRightRadius: '8px',
    borderBottomLeftRadius: props => (props.matchesxss ? dataInPx(0) : dataInPx(8)),
    padding: props => (props.matchesxss && `${dataInPx(4)} 0px`),
    paddingLeft: props => (props.matchesxss ? dataInPx(4) : dataInPx(2)), 
})


export const ConnectSubHeading = styled(Typography)({
    color: '#fff',
    fontWeight: 400,
    fontFamily: 'Phosphate !important',
    fontSize: props => (`${props.fontSize} !important`),
    letterSpacing: '0.2px !important',
    margin: "1rem 0px 0px !important"
})


export const ConnectDescription = styled(Typography)({
    marginTop: props => (props.isDescription1 ? '5% !important' : '1.5rem !important'),
    color: '#fff', 
    fontWeight: '500 !important', 
    fontFamily: 'Helvetica Neue !important',
    fontSize: props => (`${props.fontSize} !important`),
    letterSpacing: '0.2px !important', 
    width: '95%'
})


export const MainContainer = styled(Box)({
    width: '91%', 
    maxWidth: 1350, 
    position: 'relative'
})


export const Container = styled(Box)({
    position: props=> (props.matchesxss ? "absolute": ""), 
    top: '12%', 
    left: '33%',
    width: props=> (props.matchesxss ? '67%' : '100%'),
    backgroundColor: "#F8F9F9",
    boxShadow: '0px 8px 24px rgba(0, 0, 0, 0.08)'
})

export const Heading = styled(Typography)({
    color: props => (props.isConnect  ? '#3843AC' : color.themeColor),
    fontWeight: 400,
    fontFamily: 'Phosphate !important',
    fontSize: props => (`${props.fontSize} !important`),
    letterSpacing: props => (props.isConnect ? '4px !important' : '0.2px !important'),
})


export const SubHeading = styled(Typography)({
    color: '#111827',
    fontWeight: 400,
    fontFamily: 'Helvetica Neue !important',
    fontSize: props => (`${props.fontSize} !important`),
    marginTop: "1rem",
    textAlign: "left",
    lineHeight: '170% !important',
    marginTop: props => (`${dataInPx(props.mt)} !important`)
})


export const LearnMoreBox = styled(Typography)({
    textAlign: "left", 
    color: '#111827',
    fontWeight: 400, 
    fontFamily: 'Helvetica Neue !important',
    fontSize: "1rem !important",

})