import React, { useState } from 'react';
import {  useMediaQuery } from '@mui/material'
import { styled } from '@mui/material/styles';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import MuiAccordion from '@mui/material/Accordion';
import MuiAccordionSummary from '@mui/material/AccordionSummary';
import MuiAccordionDetails from '@mui/material/AccordionDetails';

import { AccordionContent, AccordionHeadding,WrapperAccordian } from './Accordian.styles';
import { color } from '../../utils/Colors';

const Accordion = styled((props) => (
    <MuiAccordion disableGutters elevation={0} {...props}
        sx={{
            '&:first-child': {
                borderTopLeftRadius: 8,
                borderTopRightRadius: 8,
                border: `1px solid #bdbdbd`
            },
            '&:last-child': {
                borderBottomLeftRadius: 8,
                borderBottomRightRadius: 8,
                border: `1px solid #bdbdbd`
            },
            border: `1px solid #bdbdbd`,
            '&:not(:last-child)': {
                borderBottom: 0,
            },
        }} />
))(({ theme }) => ({

}));

const AccordionSummary = styled((props) => (
    <MuiAccordionSummary  {...props}
        expandIcon={<ExpandMoreIcon sx={{ fontSize: '1.5rem', color: props.isExpanded ? '#fff' : '#999999' }} />}
        sx={{
            borderTopLeftRadius: props.id === 'panel1d-header' ? 8 : 0,
            borderTopRightRadius: props.id === 'panel1d-header' ? 8 : 0,
            borderBottomLeftRadius: props.id === 'panel5d-header' && !props.isExpanded ? 8 : 0,
            borderBottomRightRadius: props.id === 'panel5d-header' && !props.isExpanded ? 8 : 0,
            backgroundColor: props.isExpanded ? color.themeColor : '#fff'
        }}
    />
))(({ theme }) => ({
    '& .MuiAccordionSummary-content': {
        marginLeft: theme.spacing(1),
    },
}));

const AccordionDetails = styled((props) =>
    <MuiAccordionDetails {...props} />)(({ theme }) => ({
        padding: theme.spacing(3),
        borderTop: '1px solid rgba(0, 0, 0, .125)',

    }));

const Accordian = () => {
    const [expanded, setExpanded] = useState('panel1');
    const handleChange = (panel) => (event, newExpanded) => {
        setExpanded(newExpanded ? panel : false);
    };
    const matches = {
        lg: useMediaQuery('(min-width:1100px)'),
        md: useMediaQuery('(min-width:980px)'),
        sm: useMediaQuery('(min-width:880px)'),
        xs: useMediaQuery('(min-width:760px)'),
        xss: useMediaQuery('(min-width:600px)'),
    }
  return (
    <div>
           <WrapperAccordian matchessm={matches.sm}>
                <Accordion expanded={expanded === 'panel1'} onChange={handleChange('panel1')}>
                    <AccordionSummary aria-controls="panel1d-content" id="panel1d-header"
                        isExpanded={expanded === 'panel1'}>
                        <AccordionHeadding expanded={expanded} data={'panel1'}> What is MadConnect? </AccordionHeadding>
                    </AccordionSummary>
                    <AccordionDetails>
                        <AccordionContent>
                            MadConnect is the one-to-many connector hub for data delivery
                            that is built for the AdTech and MarTech category.
                        </AccordionContent>
                    </AccordionDetails>
                </Accordion>
                <Accordion expanded={expanded === 'panel2'} onChange={handleChange('panel2')}>
                    <AccordionSummary aria-controls="panel2d-content" id="panel2d-header"
                        isExpanded={expanded === 'panel2'}>
                        <AccordionHeadding expanded={expanded} data={'panel2'}>
                            What does MadConnect do?</AccordionHeadding>
                    </AccordionSummary>
                    <AccordionDetails>
                        <AccordionContent>
                            As a connector hub, MadConnect allows for the privacy-safe transfer and normalization of data between platforms.
                        </AccordionContent>
                    </AccordionDetails>
                </Accordion>
                <Accordion expanded={expanded === 'panel3'} onChange={handleChange('panel3')}>
                    <AccordionSummary aria-controls="panel3d-content" id="panel3d-header"
                        isExpanded={expanded === 'panel3'}>
                        <AccordionHeadding expanded={expanded} data={'panel3'}>
                            Who is MadConnect for?
                        </AccordionHeadding>
                    </AccordionSummary>
                    <AccordionDetails>
                        <AccordionContent>
                            Publishers, Brands, Agencies, Data Platforms - any company looking to transfer and normalize data to and from their licensed data platforms.
                        </AccordionContent>
                    </AccordionDetails>
                </Accordion>
                <Accordion expanded={expanded === 'panel4'} onChange={handleChange('panel4')}>
                    <AccordionSummary aria-controls="panel4d-content" id="panel4d-header"
                        isExpanded={expanded === 'panel4'}>
                        <AccordionHeadding expanded={expanded} data={'panel4'}>
                            Why is MadTech uniquely positioned to bring you MadConnect?</AccordionHeadding>
                    </AccordionSummary>
                    <AccordionDetails>
                        <AccordionContent>
                            MadTech is a product focused consultancy specializing in AdTech and MarTech. MadTech has deep expertise in identity solutions, first party data, and programmatic through our team of engineers, product managers and product marketers. We work with publishers, brands, agencies and platforms as an extension of their teams on the product and engineering side to solve hard problems and accelerate revenue growth. MadConnect is our answer to an industry-wide challenge around the lack of interoperability between data platforms based on our partners feedback.
                        </AccordionContent>
                    </AccordionDetails>
                </Accordion>
                <Accordion expanded={expanded === 'panel5'} onChange={handleChange('panel5')}>
                    <AccordionSummary aria-controls="panel5d-content" id="panel5d-header"
                        isExpanded={expanded === 'panel5'}>
                        <AccordionHeadding expanded={expanded} data={'panel5'}>
                            Who on my team is going to use MadConnect?</AccordionHeadding>
                    </AccordionSummary>
                    <AccordionDetails>
                        <AccordionContent>
                            Technical Operations or Data Engineering teams will most likely be the primary users of the MadConnect system.
                        </AccordionContent>
                    </AccordionDetails>
                </Accordion>
            </WrapperAccordian>
    </div>
  );
}

export default Accordian;
