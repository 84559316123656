import React, { useCallback, useEffect, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { Grid, useMediaQuery } from '@mui/material';

import whiteLogo from '../../assets/madconnect-white.svg'
import madConnectLogo from '../../assets/madconnectlight 2 (1).svg'
import '../SignIn/SignIn.css'
import { color } from '../../utils/Colors';
import { useForgotPasswordMutation } from '../../services/query';
import AuthLayout from '../../components/AuthLayout/AuthLayout';
import useDocumentTitle from '../../hook/useDocumentTitle';
import CommanForgotPass from '../../components/CommanOAuth/CommanForgotpass';
import { ColumnDiv, Logo, LogoSmall, Para } from './ForgetPassword.styles';


const ForgotPass = () => {
    const location = useLocation()
    useDocumentTitle('Forgot Password')
    const matchesxsss = useMediaQuery('(min-width:495px)');
    
    const [email, setEmail] = useState("");
    const [errorMsg, setErrormsg] = useState('')
    const [emailErr, setEmailErr] = useState(false);
    const [msg, setMsg] = useState('')

    const [forgotPassword, { data, error, isLoading }] = useForgotPasswordMutation()
    const navigate = useNavigate();
    
    const _validateForm = () => {
        return email.length > 0
    }

    const validateEmail = () => {
        if (!email.match(/^(([^<>()[\]\\.,;:\s@]+(\.[^<>()[\]\\.,;:\s@]+)*)|(.+))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/)) {
            setEmailErr(true);
            return true;
        } else {
            setEmailErr(false);
        }
        return false;
    };

    const sendEmail = useCallback(() => {
        if (validateEmail()) {
            return
        }
        forgotPassword({ email })
    }, [useForgotPasswordMutation, email])

    useEffect(() => {
        if (data !== undefined)
            if (data?.response) {
                setMsg('An email has been sent to your account.')
                setTimeout(() => {
                    navigate('/signin')
                }, 3000)
            } else {
                setErrormsg(data?.statusMessage)
            }
        if (error !== undefined) {
            console.log('error', error);
        }
    }, [data, error])

   
    return (
        <AuthLayout  OAuthType={false}>
            <Grid container>
                <Grid item md={7} lg={7} sm={12} xs={12} sx={{ position: 'relative' }}>
                    <Logo alt={'MADCONNECT'} src={whiteLogo}
                        onClick={() => navigate('/')}></Logo>
                    <div className='login-signin-container' style={{ background: color.themeColor }}>
                        <div className='left-container'>
                            <div className='row'>                       
                                <ColumnDiv>
                                    <div className='row'>
                                        <div className='col-12'>
                                            <Para>Welcome to</Para>
                                        </div>
                                    </div>
                                    <div className='row'>
                                        <div className='col-12'>
                                            <LogoSmall  alt={'MADCONNECT'} src={madConnectLogo}></LogoSmall>
                                        </div>
                                    </div>
                                </ColumnDiv>
                            </div>
                        </div>
                    </div>
                </Grid>
               <CommanForgotPass 
                  background="white"
                classname="login-signin-container"
                OAuthType={false}
                boxRadius="none"
                boxShadow="none"
                TitlefontSize="2rem"
                textforntSize="1rem"
               />
            </Grid>
         
        </AuthLayout>
    );
}

export default ForgotPass;
