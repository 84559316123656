import {
  Backdrop,
  Box,
  Button,
  Fade,
  Modal,
  OutlinedInput,
  Typography,
} from "@mui/material";
import React, { useEffect, useState } from "react";

import trash from "../../../assets/trash-2.svg";
import { useMyContext } from "../../../components/CommanToasterContext/toast";
import { color } from "../../../utils/Colors";
import { useDeleteSUserMutation } from "../../../services/query";
import cancel from "../../../assets/cancel.svg";
import {
  DeletText,
  DeleteEmailHeading,
  DeleteModalWrapper,
  DeleteNameHeading,
  InputField,
  InputLabelStyle,
  InputWrapper,
  Wrapper,
} from "./Delete.styles";

const Delete = ({
  selectedItem,
  isDeleteOpen,
  setSelectedItem,
  getSUser,
  setIsDeleteOpen,
  setIsUserEdit = () => {},
}) => {
  const { setOpen, setMessage, setOpen2, setMessage2 } = useMyContext();

  const [deleteSUser, { data: deleteSUserData, error: deleteSUserError }] =
    useDeleteSUserMutation();

  const [inputText, setInputText] = useState("");
  const [isDeleteButtonEnabled, setIsDeleteButtonEnabled] = useState(false);

  useEffect(() => {
    if (deleteSUserData !== undefined) {
      if (deleteSUserData.statusCode === 200) {
        setIsDeleteOpen(false);
        setIsUserEdit(false);
        setMessage(deleteSUserData?.statusMessage);
        setOpen(true);
        getSUser();
      } else {
        setOpen2(true);
        setMessage2(deleteSUserData?.statusMessage);
        setIsDeleteOpen(false);
      }
    }
    if (deleteSUserError !== undefined) {
      console.log("deleteSUserError", deleteSUserError);
    }
  }, [deleteSUserData, deleteSUserError]);

  useEffect(() => {
    if (inputText === "DELETE") {
      setIsDeleteButtonEnabled(true);
    } else {
      setIsDeleteButtonEnabled(false);
    }
  }, [inputText]);

  const _handleDeleteUser = (event) => {
    event.preventDefault();
    var payload = {
      userId: selectedItem.userId,
    };
    deleteSUser({ userData: payload });
  };

  const handleInputChange = (event) => {
    const inputValue = event.target.value.toUpperCase(); // Convert input to uppercase
    setInputText(inputValue);
  };

  const deleteUserClose = () => {
    setIsDeleteOpen(false);
    setSelectedItem(null);
    setInputText("");
  };

  return (
    <Modal
      aria-labelledby="transition-modal-title"
      aria-describedby="transition-modal-description"
      open={isDeleteOpen}
      onClose={deleteUserClose}
      closeAfterTransition
      slots={{ backdrop: Backdrop }}
      slotProps={{
        backdrop: {
          timeout: 500,
        },
      }}
    >
      <Fade in={isDeleteOpen}>
        <DeleteModalWrapper sx={{ boxShadow: 24 }}>
          <Typography
            style={{
              fontWeight: 700,
              fontFamily: "Helvetica Neue",
              fontSize: "1rem",
              textAlign: "center",
            }}
          >
            {selectedItem !== null && (
              <Wrapper mb={2}>
                <Wrapper width="100%">
                  <Box
                    sx={{
                      display: "flex",
                      flexDirection: "column",
                      justifyContent: "space-between",
                    }}
                  >
                    <DeleteNameHeading>{selectedItem.name}</DeleteNameHeading>
                    <DeleteEmailHeading>
                      {selectedItem.email}
                    </DeleteEmailHeading>
                  </Box>
                </Wrapper>
              </Wrapper>
            )}
          </Typography>
          <DeletText>Are you sure you want delete this user?</DeletText>
          <InputWrapper>
            <InputField size="small">
              <InputLabelStyle id="delete">
                Type DELETE to confirm
              </InputLabelStyle>
              <OutlinedInput
                placeholder={""}
                sx={{
                  width: "100%",
                  height: "2.75rem",
                  fontSize: "1rem",
                  color: "#666666",
                  backgroundColor: "#FFFFFF",
                  fontFamily: "Helvetica Neue",
                }}
                labelid="delete"
                id="delete"
                label="Type DELETE to confirm"
                onChange={handleInputChange}
                value={inputText}
              />
            </InputField>
          </InputWrapper>
          <Box
            sx={{
              mt: 3,
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              gap: "1rem",
            }}
          >
            <Button
              onClick={(event) => {
                _handleDeleteUser(event);
              }}
              disabled={!isDeleteButtonEnabled}
              style={{
                cursor: "pointer",
                backgroundColor: color.whiteColor,
                width: "6.625rem",
                height: "2.5rem",
                color: color.themeRed,
                textTransform: "none",
                border: "1.5px solid",
                borderColor: color.themeRed,
                cursor: isDeleteButtonEnabled ? "pointer" : "not-allowed",
              }}
              variant="contained"
            >
              <Box
                sx={{ display: "flex", gap: "0.5rem", alignItems: "center" }}
              >
                <img
                  alt=""
                  src={trash}
                  style={{
                    width: "1.3rem",
                    display: "flex",
                    alignItems: "center",
                  }}
                ></img>
                <Typography
                  style={{
                    fontSize: "1rem",
                    fontWeight: 500,
                    fontFamily: "Helvetica",
                    display: "flex",
                    alignItems: "center",
                    // marginTop: 2,
                  }}
                >
                  Delete
                </Typography>
              </Box>
            </Button>

            <Button
              onClick={deleteUserClose}
              style={{
                cursor: "pointer",
                backgroundColor: color.whiteColor,
                width: "6.625rem",
                height: "2.5rem",
                color: color.themeBlack,
                textTransform: "none",
              }}
              variant="contained"
            >
              <Box
                sx={{ display: "flex", gap: "0.5rem", alignItems: "center" }}
              >
                <img
                  alt=""
                  src={cancel}
                  style={{
                    width: "0.8rem",
                    display: "flex",
                    alignItems: "center",
                  }}
                ></img>
                <Typography
                  style={{
                    fontSize: "1rem",
                    fontWeight: 500,
                    fontFamily: "Helvetica",
                    display: "flex",
                    alignItems: "center",
                  }}
                >
                  Cancel
                </Typography>
              </Box>
            </Button>
          </Box>
        </DeleteModalWrapper>
      </Fade>
    </Modal>
  );
};

export default Delete;
