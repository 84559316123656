import { Box, Typography } from "@mui/material";
import styled from "styled-components";
import ArrowForwardIcon from "@mui/icons-material/ArrowForward";

export const BreadCrumWrapper = styled(Box)({
  display: "flex",
  marginBottom: "16px",
  alignItems: "center",
  height: "40px",
});

export const SettingText = styled(Typography)({
  fontSize: "1.25rem !important",
  fontWeight: "700 !important",
  fontFamily: "Helvetica Neue !important",
  cursor: "pointer",
  textDecoration: "underline",
});

export const PathName = styled(Typography)({
  fontSize: "1.25rem !important",
  fontFamily: "Helvetica Neue !important",
  fontWeight: "500 !important",
  letterSpacing: 0.2,
  marginLeft: "10px !important",
});

export const SPathName = styled(Typography)({
  fontSize: "1.25rem !important",
  fontFamily: "Helvetica Neue !important",
  fontWeight: "700 !important",
  letterSpacing: "0.2px !important",
  marginLeft: "10px !important",
});

export const ArrowForwardIconComp = styled(ArrowForwardIcon)({
  height: "1.25rem !important",
  width: "1.25rem !important",
  marginBottom: "3px !important",
});

export const LoaderWrapper = styled(Box)({
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
  width: "100%",
  height: "75vh",
});

export const GapSpan = styled("span")({
  fontSize: "1.25rem",
  fontFamily: "Helvetica Neue",
  fontWeight: 500,
  letterSpacing: 0.2,
  marginLeft: "10px",
  cursor: "default",
  textDecoration: "none",
});