import React, { useCallback } from "react";
import {
  Box,
  Checkbox,
  CircularProgress,
  FormControlLabel,
  Tab,
  Tabs,
  Typography,
  useMediaQuery,
} from "@mui/material";
import { color } from "../../utils/Colors";
import invoiceImg from "../../assets/InvoiceLogo.svg";
import downloadImg from "../../assets/download.svg";
import noConfigImg from "../../assets/Illustrations.svg";
import { BASE_URL } from "../../utils/Baseurl";
import {
  AmountColumn,
  AmountHeading,
  AuthorizationDataWrapper,
  ContactListHeading,
  ContractName,
  ContractNameColumn,
  ContractNameHeading,
  DateColumn,
  DateSubHeading,
  DownloadColumn,
  DownloadInner,
  ImageBox,
  InvoiceImg,
  InvoiceListContainer,
  InvoiceListHeading,
  InvoiceListSubHeading,
  InvoiceListWrapper,
  InvoiceName,
  InvoiceNameColumn,
  InvoiceNameHeading,
  InvoiceTabsContainer,
  InvoiceTabsWrapper,
  LastUpdatedColumn,
  LastUpdatedHeading,
  NoinvoiceHeading,
  NoinvoiceImgBox,
  NoinvoicehistoryWrapper,
  SerialNoColumn,
  StatusColumn,
  StatusHeading,
  StatusSubHeading,
} from "./Invoice.styles";
// import { contractList } from "./dummyData";

const RenderHtmlLabel = ({ html }) => {
  return <span dangerouslySetInnerHTML={{ __html: html }} />;
};

const TabsData = ({
  selectedTab,
  setSelectedTab,
  invoiceList,
  contract,
  date,
  authorizationLoading,
  authorizationData,
  contractLoading,
}) => {
  const handleTabChange = (event, newValue) => {
    setSelectedTab(newValue);
  };

  const isSmallForTableHeader = useMediaQuery("(max-width:1200px)");

  const handleDownloadInvoice = useCallback(
    (id) => {
      const link = document.createElement("a");
      link.href = `${BASE_URL}/invoice/download/${id}`;
      document.body.appendChild(link);
      link.click();
    },
    [BASE_URL, invoiceList]
  );

  const handleDownloadContract = useCallback(() => {
    const link = document.createElement("a");
    link.href = `${BASE_URL}/user/contract/download`;
    document.body.appendChild(link);
    link.click();
  }, [BASE_URL]);

  return (
    <>
      <InvoiceTabsWrapper>
        {/*Tab  */}
        <InvoiceTabsContainer>
          {/* <Tabs
            value={selectedTab}
            onChange={handleTabChange}
            variant="fullWidth"
            indicatorColor=""
            textColor="primary"
            sx={{ width: "40%" }}
          > */}
            {/* <Tab
              selectedTab={selectedTab}
              sx={{
                borderBottom: `1px solid ${selectedTab === 0 ? color.themeColor : "#E4E4E4"
                  }`,
                height: "2.5rem",
              }}
              label={
                <Typography
                  sx={{
                    fontFamily: "Helvetica Neue !important",
                    fontWeight:
                      selectedTab === 0 ? "700 !important" : "500 !important",
                    fontSize: "20px !important",
                    color: selectedTab === 0 ? color.themeColor : "#AEAEAE",
                    textTransform: "capitalize",
                    width: "150px",
                  }}
                  selected
                >
                  Invoice history
                </Typography>
              }
            /> */}
            {/* <Tab
              label={
                <Typography
                  sx={{
                    fontFamily: "Helvetica Neue !important",
                    fontWeight:
                      selectedTab === 1 ? "700 !important" : "500 !important",
                    fontSize: "1rem !important",
                    color: selectedTab === 1 ? color.themeColor : "#AEAEAE",
                    textTransform: "capitalize",
                    width: "150px",
                  }}
                >
                  Contract
                </Typography>
              }
              sx={{
                borderBottom: `1px solid ${
                  selectedTab === 1 ? color.themeColor : "#E4E4E4"
                }`,
                height: "2.5rem",
              }}
            />
            <Tab
              label={
                <Typography
                  sx={{
                    fontFamily: "Helvetica Neue !important",
                    fontWeight:
                      selectedTab === 2 ? "700 !important" : "500 !important",
                    fontSize: "1rem !important",
                    color: selectedTab === 2 ? color.themeColor : "#AEAEAE",
                    textTransform: "capitalize",
                    width: "150px",
                  }}
                >
                  Authorizations
                </Typography>
              }
              sx={{
                borderBottom: `1px solid ${
                  selectedTab === 2 ? color.themeColor : "#E4E4E4"
                }`,
                width: "1.75rem",
                height: "2.5rem",
              }}
            /> */}
          {/* </Tabs> */}

          {/* <Box sx={{ mt: 2 }}> */}
            {/* {selectedTab === 0 &&
              (contractLoading ? (
                <>
                  <Box
                    display="flex"
                    justifyContent="center"
                    alignItems="center"
                  >
                    <CircularProgress
                      size={45}
                      thickness={8}
                      sx={{ color: "#3646AC" }}
                    ></CircularProgress>
                  </Box>
                </>
              ) : invoiceList?.length === 0 ? (
                <Box
                  display={"flex"}
                  justifyContent={"center"}
                  alignItems={"center"}
                  height={400}
                >
                  <NoinvoicehistoryWrapper>
                    <NoinvoiceImgBox>
                      <img
                        src={noConfigImg}
                        style={{ maxWidth: "100%", maxHeight: "100%" }}
                      />
                    </NoinvoiceImgBox>
                    <NoinvoiceHeading>
                      No invoice history available!
                    </NoinvoiceHeading>
                  </NoinvoicehistoryWrapper>
                </Box>
              ) : (
                <InvoiceListWrapper>
                  {invoiceList?.map((item, idx) => (
                    <InvoiceListContainer key={idx}>
                      <SerialNoColumn>
                        <InvoiceListHeading
                          sx={{
                            fontFamily: "Helvetica Neue",
                            fontWeight: 400,
                            fontSize: "1rem",
                            ml: "0.5rem",
                          }}
                        >
                          {idx + 1}.
                        </InvoiceListHeading>
                        {idx === 0 && (
                          <InvoiceListSubHeading
                            isSmallForTableHeader={isSmallForTableHeader}
                          >
                            {" "}
                            Sr. No.{" "}
                          </InvoiceListSubHeading>
                        )}
                      </SerialNoColumn>

                      <InvoiceNameColumn
                        isSmallForTableHeader={isSmallForTableHeader}
                      >
                        <ImageBox>
                          <InvoiceImg
                            src={invoiceImg}
                            style={{ maxWidth: "100%", maxHeight: "100%" }}
                          />
                        </ImageBox>
                        <InvoiceName
                          isSmallForTableHeader={isSmallForTableHeader}
                        >
                          {item.name}
                        </InvoiceName>
                        {idx === 0 && (
                          <InvoiceNameHeading
                            isSmallForTableHeader={isSmallForTableHeader}
                          >
                            Invoice name
                          </InvoiceNameHeading>
                        )}
                      </InvoiceNameColumn>

                      <DateColumn isSmallForTableHeader={isSmallForTableHeader}>
                        <columnHeading
                          isSmallForTableHeader={isSmallForTableHeader}
                        >
                          {item.date}
                        </columnHeading>
                        {idx === 0 && (
                          <DateSubHeading
                            isSmallForTableHeader={isSmallForTableHeader}
                          >
                            Date
                          </DateSubHeading>
                        )}
                      </DateColumn>

                      <AmountColumn>
                        <columnHeading>${item.amount}</columnHeading>
                        {idx === 0 && (
                          <AmountHeading
                            isSmallForTableHeader={isSmallForTableHeader}
                          >
                            Amount
                          </AmountHeading>
                        )}
                      </AmountColumn>

                      <StatusColumn
                        isSmallForTableHeader={isSmallForTableHeader}
                      >
                        <StatusHeading
                          isSmallForTableHeader={isSmallForTableHeader}
                          item={item.status}
                        >
                          {item.status}
                        </StatusHeading>

                        {idx === 0 && (
                          <StatusSubHeading
                            isSmallForTableHeader={isSmallForTableHeader}
                          >
                            Status
                          </StatusSubHeading>
                        )}
                      </StatusColumn>

                      <DownloadColumn
                        sx={{ display: "flex", alignItems: "center" }}
                      >
                        <DownloadInner
                          onClick={() => {
                            handleDownloadInvoice(item.id);
                            // setInvoiceid(item.id)
                            // if (isInvoiceDownloadable) {
                            //     setIsInvoiceDownloadable(false)
                            // }
                            // else {
                            //     invoiceRefetch()
                            // }
                          }}
                        >
                          <img
                            src={downloadImg}
                            style={{ maxWidth: "100%", maxHeight: "100%" }}
                          />
                        </DownloadInner>
                      </DownloadColumn>
                    </InvoiceListContainer>
                  ))}
                </InvoiceListWrapper>
              ))} */}

            {/* {selectedTab === 1 &&
              (invoiceList?.length === 0 ? (
                <Box
                  display={"flex"}
                  justifyContent={"center"}
                  alignItems={"center"}
                  height={400}
                >
                  <NoinvoicehistoryWrapper>
                    <NoinvoiceImgBox>
                      <img
                        src={noConfigImg}
                        style={{ maxWidth: "100%", maxHeight: "100%" }}
                      />
                    </NoinvoiceImgBox>
                    <NoinvoiceHeading>
                      No Contract history available!
                    </NoinvoiceHeading>
                  </NoinvoicehistoryWrapper>
                </Box>
              ) : (
                <InvoiceListWrapper>
                  {contract?.map((item, idx) => (
                    <InvoiceListContainer key={idx}>
                      <SerialNoColumn>
                        <ContactListHeading
                          sx={{
                            fontFamily: "Helvetica Neue",
                            fontWeight: 400,
                            fontSize: "1rem",
                            ml: "0.5rem",
                          }}
                        >
                          {idx + 1}.
                        </ContactListHeading>
                        {idx === 0 && (
                          <InvoiceListSubHeading
                            isSmallForTableHeader={isSmallForTableHeader}
                          >
                            {" "}
                            Sr. No.{" "}
                          </InvoiceListSubHeading>
                        )}
                      </SerialNoColumn>

                      <ContractNameColumn
                        isSmallForTableHeader={isSmallForTableHeader}
                      >
                        <ImageBox>
                          <InvoiceImg
                            src={invoiceImg}
                            style={{ maxWidth: "100%", maxHeight: "100%" }}
                          />
                        </ImageBox>
                        <ContractName
                          isSmallForTableHeader={isSmallForTableHeader}
                        >
                          {item?.name}
                        </ContractName>
                        {idx === 0 && (
                          <ContractNameHeading
                            isSmallForTableHeader={isSmallForTableHeader}
                          >
                            Contract Name
                          </ContractNameHeading>
                        )}
                      </ContractNameColumn>

                      <DateColumn isSmallForTableHeader={isSmallForTableHeader}>
                        <columnHeading
                          isSmallForTableHeader={isSmallForTableHeader}
                        >
                          {item?.startDate}
                        </columnHeading>
                        {idx === 0 && (
                          <ContractNameHeading
                            isSmallForTableHeader={isSmallForTableHeader}
                          >
                            Start Date
                          </ContractNameHeading>
                        )}
                      </DateColumn>

                      <DateColumn isSmallForTableHeader={isSmallForTableHeader}>
                        <columnHeading
                          isSmallForTableHeader={isSmallForTableHeader}
                        >
                          {item?.validTill}
                        </columnHeading>
                        {idx === 0 && (
                          <DateSubHeading
                            isSmallForTableHeader={isSmallForTableHeader}
                          >
                            Valid till
                          </DateSubHeading>
                        )}
                      </DateColumn>

                      <LastUpdatedColumn>
                        <columnHeading>{item.lastUpdated}</columnHeading>
                        {idx === 0 && (
                          <LastUpdatedHeading
                            isSmallForTableHeader={isSmallForTableHeader}
                          >
                            Last updated
                          </LastUpdatedHeading>
                        )}
                      </LastUpdatedColumn>

                      <StatusColumn
                        isSmallForTableHeader={isSmallForTableHeader}
                      >
                        <StatusHeading
                          isSmallForTableHeader={isSmallForTableHeader}
                          item={item.status}
                        >
                          {item.status}
                        </StatusHeading>

                        {idx === 0 && (
                          <StatusSubHeading
                            isSmallForTableHeader={isSmallForTableHeader}
                          >
                            Status
                          </StatusSubHeading>
                        )}
                      </StatusColumn>

                      <DownloadColumn
                        sx={{ display: "flex", alignItems: "center" }}
                      >
                        <StatusSubHeading
                          isSmallForTableHeader={isSmallForTableHeader}
                        >
                          Status
                        </StatusSubHeading>
                        <DownloadInner
                          onClick={() => {
                            handleDownloadInvoice(item.id);
                            // setInvoiceid(item.id)
                            // if (isInvoiceDownloadable) {
                            //     setIsInvoiceDownloadable(false)
                            // }
                            // else {
                            //     invoiceRefetch()
                            // }
                          }}
                        >
                          <img
                            src={downloadImg}
                            style={{ maxWidth: "100%", maxHeight: "100%" }}
                          />
                        </DownloadInner>
                      </DownloadColumn>
                    </InvoiceListContainer>
                  ))}
                </InvoiceListWrapper>
              ))} */}

            {/* {selectedTab === 2 && (
              <AuthorizationDataWrapper>
                <Box display="block" flexDirection="column" alignItems="center">
                  {authorizationLoading || !authorizationData ? (
                    <>
                      <Box
                        display="flex"
                        justifyContent="center"
                        alignItems="center"
                      >
                        <CircularProgress
                          size={45}
                          thickness={8}
                          sx={{ color: "#3646AC" }}
                        ></CircularProgress>
                      </Box>
                    </>
                  ) : (
                    <FormControlLabel
                      value="start"
                      control={
                        <Checkbox
                          sx={{ ml: 5 }}
                          checked={authorizationData?.isSelected}
                          readOnly={authorizationData?.isReadonly}
                          disabled={authorizationData?.isReadonly}
                        />
                      }
                      label={
                        <RenderHtmlLabel html={authorizationData.message} />
                      }
                      //  <> <div ref={authorizationStatement}></div></>
                      labelPlacement="end"
                    />
                  )}
                </Box>
              </AuthorizationDataWrapper>
            )} */}

          {/* </Box> */}


          {/* new changes */}
          <Box sx={{
            borderBottom: `1px solid ${color.themeColor}`,
            height: "2.5rem",
            width: "420px",
            textAlign:'center'
          }}>
            <Typography
              sx={{
                fontFamily: "Helvetica Neue !important",
                fontWeight:"700 !important",
                fontSize: "1.300rem !important",
                color:  color.themeColor,
                textTransform: "capitalize",
                // width: "150px",
              }}
            >
              Invoice history
            </Typography>
          </Box>

          <Box sx={{ mt: 2 }}>
            {(contractLoading ? (
              <>
                <Box
                  display="flex"
                  justifyContent="center"
                  alignItems="center"
                >
                  <CircularProgress
                    size={45}
                    thickness={8}
                    sx={{ color: "#3646AC" }}
                  ></CircularProgress>
                </Box>
              </>
            ) : invoiceList?.length === 0 ? (
              <Box
                display={"flex"}
                justifyContent={"center"}
                alignItems={"center"}
                height={400}
              >
                <NoinvoicehistoryWrapper>
                  <NoinvoiceImgBox>
                    <img
                      src={noConfigImg}
                      style={{ maxWidth: "100%", maxHeight: "100%" }}
                    />
                  </NoinvoiceImgBox>
                  <NoinvoiceHeading>
                    No invoice history available!
                  </NoinvoiceHeading>
                </NoinvoicehistoryWrapper>
              </Box>
            ) : (
              <InvoiceListWrapper>
                {invoiceList?.map((item, idx) => (
                  <InvoiceListContainer key={idx}>
                    <SerialNoColumn>
                      <InvoiceListHeading
                        sx={{
                          fontFamily: "Helvetica Neue",
                          fontWeight: 400,
                          fontSize: "1rem",
                          ml: "0.5rem",
                        }}
                      >
                        {idx + 1}.
                      </InvoiceListHeading>
                      {idx === 0 && (
                        <InvoiceListSubHeading
                          isSmallForTableHeader={isSmallForTableHeader}
                        >
                          {" "}
                          Sr. No.{" "}
                        </InvoiceListSubHeading>
                      )}
                    </SerialNoColumn>

                    <InvoiceNameColumn
                      isSmallForTableHeader={isSmallForTableHeader}
                    >
                      <ImageBox>
                        <InvoiceImg
                          src={invoiceImg}
                          style={{ maxWidth: "100%", maxHeight: "100%" }}
                        />
                      </ImageBox>
                      <InvoiceName
                        isSmallForTableHeader={isSmallForTableHeader}
                      >
                        {item.name}
                      </InvoiceName>
                      {idx === 0 && (
                        <InvoiceNameHeading
                          isSmallForTableHeader={isSmallForTableHeader}
                        >
                          Invoice name
                        </InvoiceNameHeading>
                      )}
                    </InvoiceNameColumn>

                    <DateColumn isSmallForTableHeader={isSmallForTableHeader}>
                      <columnHeading
                        isSmallForTableHeader={isSmallForTableHeader}
                      >
                        {item.date}
                      </columnHeading>
                      {idx === 0 && (
                        <DateSubHeading
                          isSmallForTableHeader={isSmallForTableHeader}
                        >
                          Date
                        </DateSubHeading>
                      )}
                    </DateColumn>

                    <AmountColumn>
                      <columnHeading>${item.amount}</columnHeading>
                      {idx === 0 && (
                        <AmountHeading
                          isSmallForTableHeader={isSmallForTableHeader}
                        >
                          Amount
                        </AmountHeading>
                      )}
                    </AmountColumn>

                    <StatusColumn
                      isSmallForTableHeader={isSmallForTableHeader}
                    >
                      <StatusHeading
                        isSmallForTableHeader={isSmallForTableHeader}
                        item={item.status}
                      >
                        {item.status}
                      </StatusHeading>

                      {idx === 0 && (
                        <StatusSubHeading
                          isSmallForTableHeader={isSmallForTableHeader}
                        >
                          Status
                        </StatusSubHeading>
                      )}
                    </StatusColumn>

                    <DownloadColumn
                      sx={{ display: "flex", alignItems: "center" }}
                    >
                      <DownloadInner
                        onClick={() => {
                          handleDownloadInvoice(item.id);
                          // setInvoiceid(item.id)
                          // if (isInvoiceDownloadable) {
                          //     setIsInvoiceDownloadable(false)
                          // }
                          // else {
                          //     invoiceRefetch()
                          // }
                        }}
                      >
                        <img
                          src={downloadImg}
                          style={{ maxWidth: "100%", maxHeight: "100%" }}
                        />
                      </DownloadInner>
                    </DownloadColumn>
                  </InvoiceListContainer>
                ))}
              </InvoiceListWrapper>
            ))}
          </Box>


        </InvoiceTabsContainer>
      </InvoiceTabsWrapper>
    </>
  );
};

export default TabsData;

// {selectedTab === 1 && (<Box
//   sx={{
//     // height: 500,
//     display: "flex",
//     flexDirection: "column",
//     mt: "2rem",

//   }}
// >
//   <ContractDetailsWrapper>
//     <ContractDetailsHeading>
//       Contract details
//     </ContractDetailsHeading>
//     <UnsignedWrapper>
//       <UnsignedName>
//         {contract?.signed ? "Signed" : "Unsigned"}
//       </UnsignedName>
//     </UnsignedWrapper>
//   </ContractDetailsWrapper>

//   {contract?.description && (
//     <Typography
//       sx={{
//         fontFamily: "Helvetica Neue",
//         fontSize: "1.125rem",
//         fontWeight: 400,
//         color: "#000000",
//         width: "100%",
//         mt: "1.5rem",
//       }}
//     >
//       {contract?.description}

//     </Typography>
//   )}
//   <Box sx={{ mt: "1rem" }}>
//     <ul>
//       <li>
//         <ListHeading>
//           Start date: {contract?.startDate || "__"}
//         </ListHeading>
//       </li>
//       {/* 01/04/2023 */}
//       <li>
//         <ListHeading>
//           Valid till: {contract?.endDate || "__"}
//         </ListHeading>
//       </li>
//     </ul>
//   </Box>
//   <ContractFooterBox>
//     <Box
//       display={"flex"}
//       flexDirection={"row"}
//       justifyContent={"space-between"}
//       alignItems={"center"}
//     >
//       <CommonButton
//         width={130}
//         height={40}
//         textStyle={{
//           fontSize: "1rem",
//           fontWeight: 400,
//           fontFamily: "Helvetica Neue",
//           color: color.buttonColor,
//           marginRight: "4px"
//         }}
//         hasBorder={`1px solid ${color.buttonColor}`}
//         onClick={() => {
//           handleDownloadContract();
//           // if (isContractDownloadable) {
//           //     setIsContractDownloadable(false)
//           // } else {
//           //     refetch()
//           // }
//         }}
//         title={"Order Form"}
//         color={color.whiteColor}
//         disabled={contract ? !contract.download : true}
//       ></CommonButton>

//     </Box>
//     <LastUpdated>
//       Last updated: {date}
//     </LastUpdated>
//   </ContractFooterBox>
// </Box>)}
