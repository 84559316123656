import {
  Box,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableRow,
  Typography,
} from "@mui/material";
import React from "react";

const EndingAddendumTable = () => {
  return (
    <>
      <Typography variant="h6" gutterBottom sx={{ my: 4 }}>
        Table 4: Ending this Addendum when the Approved Addendum Changes
      </Typography>
      <TableContainer component={Paper}>
        <Table sx={{ minWidth: 650, border: "1px solid #f2c200" }}>
          <TableBody>
            <TableRow>
              <TableCell
                sx={{
                  width: "30%",
                  backgroundColor: "#fef7e4",
                  fontWeight: "bold",
                  border: "1px solid #f2c200",
                }}
              >
                Ending this Addendum when the Approved Addendum changes
              </TableCell>
              <TableCell sx={{ border: "1px solid #f2c200" }}>
                <Box>
                  Which Parties may end this Addendum as set out in Section 19
                  of the Approved Addendum:
                </Box>
                <Box sx={{ mt: 2 }}>☒ Importer</Box>
                <Box>☒ Exporter</Box>
                <Box>☐ neither Party</Box>
              </TableCell>
            </TableRow>
          </TableBody>
        </Table>
      </TableContainer>
    </>
  );
};

export default EndingAddendumTable;
