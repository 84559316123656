import styled from "styled-components";
import { Box, Typography } from "@mui/material";



export const WrapperAccordian = styled(Box)({
    width: props => (props.matchessm ? '80% !important' : '90% !important'),
    marginTop: "1.5rem !important",
    marginBottom: "1.5rem !important",
    borderRadius: "8px !important",
    marginLeft: "auto !important",
    marginRight: "auto  !important",
})

export const AccordionHeadding = styled(Typography)({
    fontFamily: props => (props.expanded === props.data) ? 'Inter !important' : 'Helvetica Neue !important',
    color: props => (props.expanded === props.data) ? '#fff !important' : '#999999 !important',
    fontWeight: props => (props.expanded === props.data) ? "600 !important" : "500 !important"
})

export const AccordionContent = styled(Typography)({
    fontSize: '1rem !important',
    fontFamily: 'Helvetica Neue !important',
    color: '#999999 !important'
})