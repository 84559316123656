import React, { useCallback, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import {
  useMediaQuery,
} from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import { useRef } from "react";


// import whiteLogo from '../../assets/madtechlight.svg'
// import madConnectLogo from '../../assets/MadConnectNewLogo1.svg'
// import lineLogo from '../../assets/line_2.svg'
// import bookOpenLogo from '../../assets/book-open.svg'
import "./SignUp.css";
import { useSignupUserMutation } from "../../services/query";
import {
  madTechState,
} from "../../features/madTechSlice";
import "react-phone-input-2/lib/bootstrap.css";
import useDocumentTitle from "../../hook/useDocumentTitle";
import { BASE_URL } from "../../utils/Baseurl";
import CommanSignUp from "../../components/CommanOAuth/CommanSignup";




function SignUp() {
  useDocumentTitle("Sign up");
  const matchesxsss = useMediaQuery("(min-width:495px)");

  const { userChoice } = useSelector(madTechState);
  const captchaRef = useRef(null);

  const [userType, setUserType] = useState(userChoice);
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [workEmail, setWorkEmail] = useState("");
  const [companyName, setCompanyName] = useState("");
  const [title, setTitle] = useState("");
  const [phoneNumber, setPhoneNumber] = useState("");
  const [emailErr, setEmailErr] = useState(false);
  const [phoneError, setPhoneError] = useState(false);
  const [agreement, setAgreement] = useState(false);
  const [completeSignup, setCompleteSignup] = useState(false);
  const [signupErrorMsg, setSignupErrorMsg] = useState("");
  const [captchaValue, setCaptchaValue] = useState(null);
  const [requiredCredentials, setrequiredCredentials] = useState(false);
  const [agreementError, setAgreementError] = useState(false);
  const [firstNameError, setFirstNameError] = useState(false);
  const [lastNameError, setLastNameError] = useState(false);

  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [signupUser, { isLoading, data, error }] = useSignupUserMutation();

  const validateAggrement = useCallback(() => {
    if (!agreement) {
      setAgreementError(true);
      return true;
    } else {
      setAgreementError(false);
    }
    return false;
  }, [agreement]);

  useEffect(() => {
    if (agreement) {
      setAgreementError(false);
    }
  }, [agreement]);

  const onCaptchaChange = (value) => {
    setSignupErrorMsg("");
    setCaptchaValue(value);
    // console.log("Captcha value:", value);
  };

  const captureCaptcha = async () => {
    try {
      // console.log("Captcha value:", captchaValue);
      setSignupErrorMsg("");
      if (
        validateRewquiredField() ||
        validateEmail() ||
        validatePhone() ||
        validateAggrement()
      ) {
        return;
      }
      if (captchaValue) {
        const recaptchaesponse = await fetch(`${BASE_URL}/recaptcha/verify`, {
          method: "POST",
          headers: { "Content-Type": "application/json" },
          body: JSON.stringify({ token: captchaValue }),
        });
        const resData = await recaptchaesponse.json();
        // console.log('recaptcha response', resData);
        if (resData?.statusCode === 200) {
          _signup();
        } else {
          setSignupErrorMsg(resData?.statusMessage);
        }
      } else {
        // console.log('fill captcha');
        setSignupErrorMsg("Please verify recaptcha");
      }
    } catch (error) {
      console.log("recaptcha error", error);
    }
  };

  const validateRewquiredField = useCallback(() => {
    if (firstName.trim() === "" && lastName.trim() === "") {
      setrequiredCredentials(true);
      return true;
    } else if (firstName.trim() === "") {
      setFirstNameError(true);
      setrequiredCredentials(false);
      return true;
    } else if (lastName.trim() === "") {
      setLastNameError(true);
      setrequiredCredentials(false);
      return true;
    }
    return false;
  }, [firstName, lastName]);

  const validateEmail = () => {
    if (
      !workEmail.match(
        /^(([^<>()[\]\\.,;:\s@]+(\.[^<>()[\]\\.,;:\s@]+)*)|(.+))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
      )
    ) {
      setEmailErr(true);
      return true;
    } else {
      setEmailErr(false);
    }
    return false;
  };

  const validatePhone = useCallback(() => {
    if (
      phoneNumber.trim() === "" ||
      6 > phoneNumber.length ||
      phoneNumber.length > 15 ||
      !/^[0-9]+$/.test(phoneNumber)
    ) {
      setPhoneError(true);
      return true;
    } else {
      setPhoneError(false);
    }
    return false;
  }, [phoneNumber]);

  const updateAgreement = (event) => {
    setAgreement(event.target.checked);
    setSignupErrorMsg("");
  };

  const _signup = useCallback(() => {
    // if (validateRewquiredField() || validateEmail() || validatePhone() || validateAggrement()) {
    //   return;
    // }
    signupUser({
      userType,
      firstName,
      lastName,
      email: workEmail,
      companyName,
      title,
      phoneNumber,
    });
  }, [
    useSignupUserMutation,
    userType,
    firstName,
    lastName,
    workEmail,
    companyName,
    title,
    phoneNumber,
    validateEmail,
    validatePhone,
    validateRewquiredField,
    validateAggrement,
  ]);

  useEffect(() => {
    if (data !== undefined) {
      if (data?.statusCode === 200) {
        // Strings.signup_success_msg
        setCompleteSignup(true);
        // dispatch(setLoggedin())
      } else {
        captchaRef.current.reset();
        setSignupErrorMsg(data?.statusMessage);
        setCaptchaValue(null);
      }
    }
    if (error !== undefined) {
      console.log("error", error);
    }
  }, [data, error]);

  return (
    <CommanSignUp
      textAlign=""
      classname="login-signup-container"
      Boxwidth="60%"
      TextWidth="80%"
      OAuthType={false}
      background="rgba(25, 50, 65, 0.08)"
    />
  );
}

export default SignUp;
