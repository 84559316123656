import React from 'react'
import { Box } from '@mui/material'

import mailBorder from '../../assets/MailBorder.svg'
import mailArrow from '../../assets/MailArrow.svg'
import mailDownArrow from '../../assets/MailDownArrow.svg'

const MailLogo = (props) => {

    const {Boxheight, Boxwidth,Arrowwidth,Arrowheight} = props
    
    return (
        <Box sx={{ position: "relative" }}>
            <img alt='' src={mailBorder} style={{ width: Boxwidth , height: Boxheight }}></img>
            <img alt='' src={mailDownArrow} style={{
                position: "absolute",
                left: "25%", top: "20%", width: 50, height: 15
            }}></img>
            <img alt='' src={mailArrow} style={{
                position: "absolute",
                right: 0, bottom: -5, width: Arrowwidth, height: Arrowheight
            }}></img>
        </Box>
    )
}

export default MailLogo