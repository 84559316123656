import { Box, Paper, useMediaQuery } from '@mui/material'
import React, { useEffect, useState } from 'react'
import { useSelector } from 'react-redux'
import { styled } from '@mui/material/styles';

import { madTechState } from '../../features/madTechSlice'
import { useFetchPartnerQuery } from '../../services/platformQuery'
import { Wrapper } from './Connectors.styles';
import ConnectoreFilter from './ConnectoreFilter';
import ConnectorData from './ConnectorData';
import { useNavigate, useParams } from 'react-router-dom';
import { HOST } from '../../utils/Baseurl';

export const Item = styled(Paper)(({ theme }) => ({
    border: "1px solid rgba(54, 70, 172, 0.25)",
    borderRadius: 10,
    position: "relative",
    cursor: "pointer",
    height: 120,
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    ...theme.typography.body2,
    padding: theme.spacing(2),
    textAlign: 'center',
    color: theme.palette.text.secondary,
    backgroundColor: "rgba(54, 70, 172, 0.02)",
}));


const ConnectorsList = () => {

    const { id } = useParams();
    const matchesxss = useMediaQuery('(min-width:600px)');
    const { platfomrs } = useSelector(madTechState)
    const navigate = useNavigate()
    const [searchValue, setSearchValue] = useState('')
    const [selectedPlatform, setSelectedPlatform] = useState([])
    const [selectedPlatformIds, setSelectePlatformIds] = useState([])
    const [allplatfomrs, setAllplatfomrs] = useState([])
    const [allCategories, setAllCategories] = useState([])
    const [selectedCategories, setSelectedCategories] = useState([])
    const [categoryName, setCategoryName] = useState('')
    const [showDoc, setShowDoc] = useState(id != null)
    const [selectedDoc, setSelectedDoc] = useState('')
    const [selectedItem, setSelectedItem] = useState({})
    const [loader, setLoader] = useState(false);


    const { data, error, isLoading } = useFetchPartnerQuery()
    const ref = React.useRef();

    useEffect(() => {
        if (id !== undefined && id !== null && allplatfomrs?.length > 0) {
            const selectedPlatform = allplatfomrs?.find(platform => {
                const overviewUrl = `${HOST}${platform.overviewUrl}`;
                const url = new URL(overviewUrl);
                const searchParams = new URLSearchParams(url.search);
                const path = searchParams.get("path");
                return path === id;
            })
            if (selectedPlatform) {
                setSelectedItem(selectedPlatform);
                setSelectedDoc(selectedPlatform.overviewUrl);
                setShowDoc(true);
                return
            } else {
                return navigate("/page-not-found")
            }
        } else {
            setShowDoc(false);
            setSelectedDoc('');
            setSelectedItem({});
            setLoader(false);
        }
    }, [id, allplatfomrs])



    useEffect(() => {
        if (searchValue === '') {
            setAllplatfomrs(platfomrs)
            setSelectedCategories([])
            setCategoryName('')
        }
    }, [searchValue])

    useEffect(() => {
        if (selectedPlatform?.length > 0) {
            setSelectePlatformIds(selectedPlatform?.map(item => item.id))
        } else {
            setSelectePlatformIds([])
        }
    }, [selectedPlatform])

    useEffect(() => {
        if (selectedCategories?.length > 0 && platfomrs?.length > 0) {
            const newAllPlatforms = []
            platfomrs?.forEach(ptItem => {
                ptItem?.categories?.forEach(catEl => {
                    if (selectedCategories?.includes(catEl)) {
                        if (!newAllPlatforms.includes(catEl)) {
                            newAllPlatforms.push(ptItem)
                        }
                    }
                });
            })
            const newSetArr = [...new Set(newAllPlatforms.map((data) => data))]
            setAllplatfomrs(newSetArr)

            let newselectedPlatform = [...selectedPlatform]
            let newArr = []
            newselectedPlatform?.forEach(ptItem => {
                ptItem?.categories?.forEach(catEl => {
                    if (selectedCategories?.includes(catEl)) {
                        if (!newArr.includes(catEl)) {
                            newArr.push(ptItem)
                        }
                    }
                });
            })
            const newSetArr2 = [...new Set(newArr?.map((data) => data))]
            setSelectedPlatform(newSetArr2)
        } else {
            setAllplatfomrs(platfomrs)
        }
    }, [selectedCategories, platfomrs])

    useEffect(() => {
        if (data !== undefined && data.response) {
            setAllplatfomrs(data?.response?.platforms);
            setAllCategories(data?.response?.categories)
        }
        if (error !== undefined) {
            console.log('error', error);
        }
    }, [data, error])

    useEffect(() => {
        window.scrollTo({ behavior: "auto", top: 0 });
    }, [showDoc])


    const handleBack = () => {
        setShowDoc(false);
        setSelectedDoc('');
        setSelectedItem({});
        setLoader(false);
        navigate("/connector")
    }

    const handleSearch = (e) => {
        setSearchValue(e.target.value)
        if (selectedCategories?.length === 0) {
            setAllplatfomrs(platfomrs?.filter(item =>
                item.name.toLowerCase().startsWith(e.target.value.toLowerCase())))
        } else {
            setAllplatfomrs(allplatfomrs?.filter(item =>
                item.name.toLowerCase().startsWith(e.target.value.toLowerCase())))
        }
    }

    const handleShowConnectoreData = (item) => {
        if (item.status === 1) {
            setSelectedItem(item);
            setShowDoc(true);
            setSelectedDoc(item.overviewUrl);
        }
        const overviewUrl = `${HOST}${item.overviewUrl}`;
        const url = new URL(overviewUrl);
        const searchParams = new URLSearchParams(url.search);
        const path = searchParams.get("path");
        if (path && path != '') {

            return navigate(`/connector/${path}`)
        }
        return item.overviewUrl;
    }
    return (
        <>
            <Wrapper matchesxss={matchesxss} >
                {matchesxss &&
                    <ConnectoreFilter
                        allCategories={allCategories}
                        categoryName={categoryName}
                        selectedCategories={selectedCategories}
                        setCategoryName={setCategoryName}
                        setSelectedCategories={setSelectedCategories}
                        setShowDoc={setShowDoc}
                        setLoader={setLoader}
                    />
                }
                <Box width='75%' ml={5} >
                    <ConnectorData
                        handleBack={handleBack}
                        matchesxss={matchesxss}
                        showDoc={showDoc}
                        handleSearch={handleSearch}
                        searchValue={searchValue}
                        loader={loader}
                        selectedItem={selectedItem}
                        selectedDoc={selectedDoc}
                        data={data}
                        handleShowConnectoreData={handleShowConnectoreData}
                        allplatfomrs={allplatfomrs}
                        ref={ref}
                        isLoading={isLoading}
                        id={id}
                    />

                </Box>
            </Wrapper>
        </>
    )
}

export default ConnectorsList