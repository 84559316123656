import React from 'react';
import {  Header, HeaderContainer, HeaderHeading, HeaderPara, } from './FAQ.styles';
import { useMediaQuery } from '@mui/material';

const HeaderPart = () => {
    const matcheslg = useMediaQuery('(min-width:1100px)');
    const matchesmd = useMediaQuery('(min-width:980px)');
    const matchessm = useMediaQuery('(min-width:880px)');
    const matchesxs = useMediaQuery('(min-width:760px)');
    const matchesxss = useMediaQuery('(min-width:600px)');
    return (
        <div>
            <Header>
                <HeaderContainer>
                    <HeaderHeading
                        matcheslg={matcheslg} matchesmd={matchesmd} matchessm={matchessm} matchesxs={matchesxs}>
                        frequently asked questions
                    </HeaderHeading>
                    <HeaderPara
                        matcheslg={matcheslg} matchesmd={matchesmd} matchessm={matchessm} matchesxs={matchesxs}>
                        Everything you need to know about MadConnect.
                    </HeaderPara>
                </HeaderContainer>
            </Header>
        </div>
    );
}

export default HeaderPart;
