import { Box, Typography } from "@mui/material";
import styled from "styled-components";
import { color } from "../../utils/Colors";

export const Wrapper = styled(Box)({
    marginTop: props => (props.matchemd ? 0 : "1.5rem"),
    paddingTop: "3rem",
    display: "flex",
    flexDirection: "column",
    // justifyContent: 'center',
    width: props => (props.matchemd ? "90%" : "97%"),
    maxWidth: props => (props.matchemd ? "41.87rem" : "62.5rem"),
    height: "100vh",
    // border: '1px solid white'
})


export const Heading = styled(Typography)({
    color: "#fff",
    fontWeight: "400 !important",
    fontFamily: "Phosphate !important",
    fontSize: "2.5rem !important",
    letterSpacing: "0.02em !important",
})


export const SubHeading = styled(Typography)({
    marginTop: "2rem !important",
    textAlign: "left",
    color: "#fff !important",
    fontWeight: "400 !important",
    fontFamily: "Helvetica Neue !important",
    fontSize: "1rem !important ",
})


export const SocialMediaIcons = styled(Box)({
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    width: "3rem",
    height: "3rem",
    background: "rgba(255, 255, 255, 0.24)",
    boxShadow: "0px 8px 16px rgba(0, 0, 0, 0.04)",
    borderRadius: "8px !important",
    cursor: "pointer",
})

export const SocialMediaHeading = styled(Typography)({
    textAlign: "left !important",
    color: "#fff !important",
    fontWeight: "400 !important",
    fontFamily: "Phosphate !important",
    fontSize: "1.5rem !important",
    letterSpacing: "1px !important",
})

export const SocialMediaSubHeading = styled(Typography)({
    marginTop: "0.5rem !important",
    textAlign: "left",
    color: "#fff",
    fontWeight: "400 !important",
    fontFamily: "Helvetica Neue !important",
    fontSize: "1rem !important",
    width: "85%",
})

export const EmailUsSubHeading = styled(Typography)({
    marginTop: "0.5rem !important",
    textAlign: "left",
    color: "#fff",
    fontWeight: "400 !important",
    fontFamily: "Helvetica Neue !important",
    fontSize: "1rem !important",
})



export const ConnectWithUsSubHeading = styled(Typography)({
    marginTop: "0.5rem !important",
    textAlign: "left",
    color: "#fff",
    fontWeight: "400 !important",
    fontFamily: "Helvetica Neue !important",
    fontSize: "1rem !important",

})

export const OtherContactUsWrapper = styled(Box)({
    marginTop: "3rem",
    // minHeight: 720,
    display: "flex",
    flexDirection: "column",
    // justifyContent: "center", alignItems: 'center',
    // border: '1px solid black',
    maxWidth: "62.5rem",
    // minWidth: 390
})

export const OtherContactUsContainer = styled(Box)({
    // p: matchesxss ? 10 : 5,
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
    maxWidth: "50rem",
    padding: props => (props.matchesxssss ? 0 : "1rem"),
    // border: '1px solid white'
})

export const OtherContactUsHeading = styled(Typography)({
    color: "#111827",
    fontWeight: "400 !important",
    fontFamily: "Phosphate !important",
    fontSize: "1.6rem !important",
    // mt: matchesxss ? 10 : 5,
    textAlign: "center",
})

export const OtherContactUsSubHeading = styled(Typography)({
    marginTop: "0.5rem !important",
    textAlign: "left",
    color: "#6D7280",
    fontWeight: "500 !important",
    fontFamily: "Helvetica Neue !important",
    fontSize: "1rem !important",
    textAlign: "center",
})

export const OtherContactUsErrorMsg = styled(Box)({
    marginTop: "0.5rem !important",
    display: "flex",
    textAlign: "center",
    justifyContent: "center",
})

export const OtherContactUsTextBox = styled(Typography)({
    color: "red",
    textAlign: "center",
    height: "1.25rem"
})

export const OtherContactUsMyCompany = styled(Typography)({
    fontFamily: "Helvetica Neue !important",
    color: color.black
})

export const IsFormSubmited = styled(Box)({
    marginTop: "3rem !important",
    // minHeight: 720,
    display: "flex",
    flexDirection: "column",
    // justifyContent: "center", alignItems: 'center'
})

export const IsFormSubmitedContainer = styled(Box)({
    marginTop: "4rem",
    marginBottom: "4rem",
    display: "flex",
    flexDirection: "column",
    backgroundColor: "#fff",
    width: "100%",
    alignItems: "center",
    justifyContent: "center",
    maxWidth: 800,
})

export const IsFormSubmitedOuter = styled(Box)({
    width: "50%",
    marginTop: "2.5rem",
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    marginLeft: "auto",
    marginRight: "auto",
    width: "80%",
    justifyContent: "center",
})

export const MailLogoWrapper = styled(Box)({
    height: "12.5rem",
    width: "12.5rem",
    backgroundColor: "rgba(54, 70, 172, 0.05)",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    borderRadius: "120px",
})

export const IsFormSubmitedHeading = styled(Typography)({
    width: "100%",
    textAlign: "center",
    fontSize: "2.5rem !important",
    marginTop: "1.5rem !important",
    fontWeight: "700 !important",
    letterSpacing: "1px !important",
})

export const IsFormSubmitedSubHeading = styled(Typography)({
    width: "70%",
    textAlign: "center",
    fontSize: "1.25rem !important",
    marginTop: "1rem !important",
    marginBottom: "0.5rem !important",
    fontWeight: "500 !important",
    color: color.themeBlack,
})

export const IsFormSubmitedButton = styled(Box)({
    marginTop: "1.5rem !important",
    display: "flex",
    width: "80%",
    alignItems: "center",
    justifyContent: "center",
})