import { Box, FormControl, InputLabel, Typography } from "@mui/material";
import styled from "styled-components";

import { color } from "../../../utils/Colors";

export const DeleteModalWrapper = styled(Box)({
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  borderRadius: "8px 8px 8px 8px",
  backgroundColor: "#F8F8FE",
  width: 442,
  padding: "32px",
  display: "flex",
  flexDirection: "column",
  justifyContent: "center",
  alignItems: "center",
  "&:focus": {
    outline: "none",
  },
  width: 520,
});

export const Wrapper = styled(Box)({
  display: "flex",
  justifyContent: "space-between",
  alignItems: "center",
});

export const DeleteNameHeading = styled(Typography)({
  fontSize: "22px !important",
  fontFamily: "Helvetica Neue !important",
  color: `${color.buttonColor} !important`,
});

export const DeleteEmailHeading = styled(Typography)({
  fontSize: "1rem !important",
  fontFamily: "Helvetica Neue !important",
  color: "#656C7B !important",
});

export const DeletText = styled(Typography)({
  fontWeight: "400 !important",
  fontFamily: "Helvetica Neue !important",
  fontSize: "1rem !important",
  textAlign: "center !important",
  color: "#656C7B !important",
  width: "70% !important",
  marginBottom: "16px !important",
});

export const InputWrapper = styled(Box)({
  width: "100%",
  display: "flex",
  justifyContent: "center",
  flexDirection: "column",
});

export const InputField = styled(FormControl)({
  width: "70% !important",
  height: "2.75rem !important",
  fontSize: "1rem !important",
  color: "#666666 !important",
  backgroundColor: "#FFFFFF !important",
  fontFamily: "Helvetica Neue !important",
  display: "flex !important",
  justifyContent: "center !important",
  marginRight: "auto !important",
  marginLeft: "auto !important",
});

export const InputLabelStyle = styled(InputLabel)({
    fontSize: "0.875rem !important",
    marginTop: "0.25rem !important",
    opacity: 0.5,
    backgroundColor: "#FFFFFF !important",
    fontFamily: "Helvetica Neue !important",
  });
