import {
    Box,
    Typography,
    OutlinedInput,
    RadioGroup,
    FormControlLabel,
    Radio,
    useMediaQuery,
  } from "@mui/material";
import React, {useEffect} from "react";
import {  OtherContactUsContainer, OtherContactUsErrorMsg, OtherContactUsHeading,
     OtherContactUsMyCompany, OtherContactUsSubHeading, OtherContactUsTextBox } from "./ContactUs.styles";
import CommonInput from "../../components/CommonInput/CommonInput";
import { color } from "../../utils/Colors";
import ReCAPTCHA from "react-google-recaptcha";
import CommonButton from "../../components/CommonButton/CommonButton";
import { Strings } from "../../utils/Strings";


const OthersContactUs = ({
    name,
    setName,
    companyName,
    setCompanyName,
    email,
    setEmail,
    setEnquireAs,
    phoneNumber,
    setPhoneNumber,
    comment,
    setComment,
    captureCaptcha,
    isLoading,
    contactMsg,
    setcontactMsg,
    nameError,
    emailError,
    commentError,
    companyError,
    phoneError,
    setCaptchaValue,
    captchaRef,
  }) => {
    const matchesxss = useMediaQuery("(min-width:900px)");
    const matchesxsss = useMediaQuery("(min-width:600px)");
    const matchesxssss = useMediaQuery("(min-width:420px)");
  
    useEffect(() => {
      window.scrollTo({ behavior: "auto", top: 0 });
    }, [])
  
  
    return (
      <OtherContactUsContainer matchesxssss={matchesxssss}>
        <OtherContactUsHeading>  contact us with any questions. </OtherContactUsHeading>
        <OtherContactUsSubHeading> Our team would love to hear from you!</OtherContactUsSubHeading>
        {/* error message */}
        <OtherContactUsErrorMsg>
          {nameError ? (
            <OtherContactUsTextBox  variant="subtitle2" >
              {"Please enter full name"}
            </OtherContactUsTextBox>
          ) : phoneError ? (
            <OtherContactUsTextBox variant="subtitle2" >
              {"Please enter valid phone number"}
            </OtherContactUsTextBox>
          ) : emailError ? (
            <OtherContactUsTextBox variant="subtitle2"  >
              {"Please enter valid work email"}
            </OtherContactUsTextBox>
          ) : companyError ? (
            <OtherContactUsTextBox variant="subtitle2" >
              {"Please enter valid company name"}
            </OtherContactUsTextBox>
          ) : commentError ? (
            <OtherContactUsTextBox variant="subtitle2" >
              {"Please enter some text on comment box"}
            </OtherContactUsTextBox>
          ) : (
            <Typography
              variant="subtitle2"
              sx={{
                color:
                  contactMsg === "Contact added successfully" ? "green" : "red",
                textAlign: "center",
                height: "1.25rem",
              }}
            >
              {contactMsg}
            </Typography>
          )}
        </OtherContactUsErrorMsg>
         {/* form */}
        <Box sx={{ mt: "1.5rem", width: matchesxsss ? "70%" : "90%" }}>
          <div class='row'>
            <div
              className={`col-${matchesxssss ? "6" : "12"}`}
              style={{ marginBottom: matchesxssss ? 0 : "0.625rem" }}
            >
              <CommonInput
                isFocused={true}
                height={"2.75rem"}
                isError={!!nameError}
                backgroundColor={"white"}
                placeholder={"Full name*"}
                inputType="text"
                value={name}
                onChange={(e) => {
                  setName(e.target.value);
                  setcontactMsg("");
                }}
              ></CommonInput>
            </div>
            <div className={`col-${matchesxssss ? "6" : "12"}`}>
              <CommonInput
                height={"2.75rem"}
                isError={!!companyError}
                backgroundColor={"white"}
                placeholder={"Company*"}
                inputType="text"
                value={companyName}
                onChange={(e) => {
                  setCompanyName(e.target.value);
                  setcontactMsg("");
                }}
              ></CommonInput>
            </div>
          </div>
  
          <div className="row" style={{ marginTop: "0.94rem" }}>
            <div
              className={`col-${matchesxssss ? "6" : "12"}`}
              style={{ marginBottom: matchesxssss ? 0 : "0.625rem" }}
            >
              <CommonInput
                height={"2.75rem"}
                isError={!!emailError}
                backgroundColor={"white"}
                placeholder={"Work Email*"}
                inputType="email"
                value={email}
                onChange={(e) => {
                  setEmail(e.target.value);
                  setcontactMsg("");
                }}
              ></CommonInput>
            </div>
            <div className={`col-${matchesxssss ? "6" : "12"}`}>
              <CommonInput
                height={"2.75rem"}
                isError={!!phoneError}
                backgroundColor={"white"}
                placeholder={"Phone number"}
                inputType="number"
                value={phoneNumber}
                onChange={(e) => {
                  setPhoneNumber(e.target.value);
                  setcontactMsg("");
                }}
              ></CommonInput>
            </div>
          </div>
  
          <Box sx={{ mt: "1rem" }}>
            <OtherContactUsMyCompany>  My company is a...</OtherContactUsMyCompany>
            <RadioGroup
              row
              aria-labelledby="demo-row-radio-buttons-group-label"
              name="row-radio-buttons-group"
              onChange={(e, value) => {
                setcontactMsg("");
                setEnquireAs(value);
              }}
              sx={{
                display: "flex",
                flexDirection: "row",
              }}
              defaultValue="BRAND"
            >
              <FormControlLabel
                value="BRAND"
                control={
                  <Radio
                    sx={{
                      color: color.themeLightGray,
                      "&.Mui-checked": {
                        color: color.buttonColor,
                      },
                    }}
                  />
                }
                label="Brand"
              />
              <FormControlLabel
                value="PUBLISHER"
                control={
                  <Radio
                    sx={{
                      color: color.themeLightGray,
                      "&.Mui-checked": {
                        color: color.buttonColor,
                      },
                    }}
                  />
                }
                label="Publisher"
  
              />
              <FormControlLabel
                value="PLATFORM"
                control={
                  <Radio
                    sx={{
                      color: color.themeLightGray,
                      "&.Mui-checked": {
                        color: color.buttonColor,
                      },
                    }}
                  />
                }
                label="Platform"
              />
              <FormControlLabel
                value="AGENCY"
                control={
                  <Radio
                    sx={{
                      color: color.themeLightGray,
                      "&.Mui-checked": {
                        color: color.buttonColor,
                      },
                    }}
                  />
                }
                label="Agency"
              />
              <FormControlLabel
                value="OTHER"
                control={
                  <Radio
                    sx={{
                      color: color.themeLightGray,
                      "&.Mui-checked": {
                        color: color.buttonColor,
                      },
                    }}
                  />
                }
                label="Other"
              />
            </RadioGroup>
          </Box>
          <OutlinedInput
            multiline
            rows={4}
            placeholder="Comment*"
            error={!!commentError}
            sx={{ width: "100%" }}
            value={comment}
            onChange={(e) => {
              setComment(e.target.value);
              setcontactMsg("");
            }}
          />
          <Box sx={{ mt: 3 }}>
            <ReCAPTCHA
              ref={captchaRef}
              sitekey="6LfxLK0kAAAAABD0Y64yJCusUvIhY4Mo6MMC-cnC"
              // sitekey={process.env.REACT_APP_RECAPTCHA_SITE_KEY}
              onChange={(value) => {
                setcontactMsg("");
                setCaptchaValue(value);
                // console.log("Captcha value:", value);
              }}
            />
          </Box>
          <Box sx={{ mt: 3 }}>
            <CommonButton
              width={`100%`}
              height={55}
              textStyle={{
                fontSize: "18px",
                fontFamily: "Helvetica",
                color: color.whiteColor,
              }}
              onClick={captureCaptcha}
              title={Strings.submit}
              color={"#3843AC"}
              isLoading={isLoading}
              disabled={isLoading}
            ></CommonButton>
          </Box>
        </Box>
      </OtherContactUsContainer>
    );
  };

  export default OthersContactUs