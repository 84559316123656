import React from 'react'
import ReactMarkdown from 'react-markdown';
import remarkGfm from 'remark-gfm';
import rehypeRaw from 'rehype-raw';

import styled from 'styled-components';

const Container = styled.div`
  h1 {
    display: none;
  }

  h4 {
    font-weight: 700 !important;
    font-size: 16px !important;
  }

  ol {
    font-size: 14px !important;
  }

  h3 {
    font-weight: 700 !important;
    font-size: 16px !important;
  }

  p {
    font-size: 14px;
  }

  p a {
    font-size: 14px;
  }

  table {
    margin: 20px 0px;
  }

  table thead th {
    border: 1px solid #d0d7de;
    padding: 12px 6px;
  }

  table tbody td {
    min-width: 150px;
    border: 1px solid #d0d7de;
    padding: 12px 6px;
  }
`;

const OverviewPlatform = ({ markdownContent }) => {

  return (
    <Container>
      <ReactMarkdown children={markdownContent} remarkPlugins={[remarkGfm]} rehypePlugins={[rehypeRaw]} />
    </Container>
  );
}

export default OverviewPlatform

