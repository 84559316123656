import { Box, List, ListItem, ListItemText, Typography } from "@mui/material";
import React from "react";

const TransferDesc = () => {
  return (
    <>
      <Box component="div" display="flex" sx={{ mt: 4 }}>
        <Box
          component="div"
          sx={{
            fontWeight: "bold",
            marginBottom: 1,
            mr: 1.5,
            fontSize: "18px",
          }}
        >
          B.
        </Box>

        <Box
          sx={{
            fontSize: "18px",
            textAlign: "justify",
            fontWeight: "bold",
            marginBottom: 1,
          }}
        >
          DESCRIPTION OF TRANSFER
        </Box>
      </Box>
      <div style={{ padding: "20px" }}>
        {/* Title */}
        <Typography variant="h6" gutterBottom>
          Categories of Data Subjects:
        </Typography>

        <List>
          <ListItem>
            <ListItemText primary="• Client application users" />
          </ListItem>
        </List>

        <Typography variant="h6" gutterBottom>
          Categories of Personal Data Transferred:
        </Typography>

        <List>
          <ListItem>
            <ListItemText primary="• Personal contact details (first name, last name, address, phone number, email address, birthday, social media handles)" />
          </ListItem>
          <ListItem>
            <ListItemText primary="• Demographic data (age, gender, geography, household income, ethnicity, interests)" />
          </ListItem>
          <ListItem>
            <ListItemText primary="• Transactional data (sales / purchase history, user account and customer service data, order data, return data)" />
          </ListItem>
          <ListItem>
            <ListItemText primary="• Behavioral data (product usage and engagement data, marketing campaign engagement data)" />
          </ListItem>
          <ListItem>
            <ListItemText primary="• Third-party data (data generated by, and ingested from third-party datasets, advertising technology, and marketing technology providers)" />
          </ListItem>
          <ListItem>
            <ListItemText primary="• Other data categories as included from time-to-time" />
          </ListItem>
        </List>
      </div>
      <Box style={{ padding: "20px" }}>
        {/* Sensitive Categories of Data */}
        <Typography variant="body1">
          <strong>
            Sensitive Categories of Data, and associated additional
            restrictions/safeguards:
          </strong>{" "}
          Not applicable
        </Typography>

        {/* Frequency of the Transfer */}
        <Typography variant="body1" style={{ marginTop: "10px" }}>
          <strong>Frequency of the Transfer:</strong> Continuous basis
        </Typography>

        {/* Nature and purpose of the Processing */}
        <Typography variant="body1" style={{ marginTop: "10px" }}>
          <strong>Nature and purpose of the Processing:</strong> Provide the
          Services, as more particularly described in the Terms of Service, and
          comply with Client instructions thereunder.
        </Typography>

        {/* Duration of Processing / Retention Period */}
        <Typography variant="body1" style={{ marginTop: "10px" }}>
          <strong>Duration of Processing / Retention Period:</strong> Different
          data retention periods apply depending on the applicable Services.
          When determining the specific retention period, MadTech considers
          various factors, such as the type of service provided to Client, the
          nature and length of our relationship with Client, and mandatory
          retention periods provided by law and the statute of limitations.
        </Typography>

        {/* For Transfers to Subprocessors */}
        <Typography variant="body1" style={{ marginTop: "10px" }}>
          <strong>
            For Transfers to Subprocessors, the Subject Matter, Nature and
            Duration of the Processing:
          </strong>{" "}
          Same as per the above for MadTech
        </Typography>
      </Box>
    </>
  );
};

export default TransferDesc;
