import React from 'react';
import { useMediaQuery } from '@mui/material';

import madConnectLogo from "../../assets/madconnectdark.png";
import CommanForgotPass from '../../components/CommanOAuth/CommanForgotpass';



const OauthForgotpass = () => {
    const maxHeight = useMediaQuery('(max-height:515px)');
    return (
        <div style={{ display: 'flex', justifyContent: 'center', background: "#C4C4C4", alignItems: "center", height:maxHeight ? "" : "100vh" 
        }}>
            <div style={{ display: "block" }}>
                <div style={{ textAlign: "center", padding: "20px" }}>
                    <img
                        style={{ width: "auto", height: "4rem", justifyContent: 'center' }}
                        alt={"MADCONNECT LOGO"}
                        src={madConnectLogo}
                    ></img>
                </div>
                <CommanForgotPass
                background="#C4C4C4"
                classname=""
                OAuthType={true}
                boxRadius={8}
                boxShadow="0px 8px 20px rgba(0, 0, 0, 0.06)"
                TitlefontSize="1.5rem"
                textforntSize="0.9rem"
                 />
            </div>
        </div>
    )
}

export default OauthForgotpass
