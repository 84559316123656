import { Box, TextField, Typography } from "@mui/material";
import styled from "styled-components";
import CloseIcon from "@mui/icons-material/Close";

export const Container = styled(Box)({
  border: "1px solid #dee2e6",
  borderRadius: "10px",
  padding: "10px 10px 10px 14px",
  marginTop: "15px",
});

export const SubContainer = styled(Box)({
  display: "flex",
  justifyContent: "space-between",
  alignItems: "center",
  height:"22px"
});

export const FilterHeading = styled(Typography)({
    color: "rgba(0, 0, 0, 0.3) !important",
  fontFamily: "Helvetica Neue !important",
  fontSize: "0.875rem !important",
  textTransform: "capitalize !important"
});

export const FilterTextField = styled(TextField)({
  "& .MuiInputBase-input": {
    height: "0.60rem",
  },

  marginLeft: "12px",
});

export const CloseIconStyle = styled(CloseIcon)({
  width: "20px",
  height: "20px",
  marginLeft: "12px",
  cursor: "pointer",
});
