import React, { useCallback, useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import PhoneInput from "react-phone-input-2";
import ReCAPTCHA from "react-google-recaptcha";
import "react-phone-input-2/lib/bootstrap.css";

import whiteLogo from "../../assets/madconnect-white.svg";
// import whiteLogo from '../../assets/madtechlight.svg'
// import madConnectLogo from '../../assets/MadConnectNewLogo1.svg'
import madConnectLogo from "../../assets/madconnectdark.png";
// import lineLogo from '../../assets/line_2.svg'
// import bookOpenLogo from '../../assets/book-open.svg'
import userLogo from "../../assets/user.svg";
import linkLogo from "../../assets/link-2.svg";
import briefcase from "../../assets/briefcase.svg";
import selectConnectorLogo from "../../assets/SelectConnector.svg";
import "./signup.css";
import {
  Box,
  FormControl,
  Grid,
  MenuItem,
  Select,
  Typography,
  useMediaQuery,
} from "@mui/material";
import { color } from "../../utils/Colors";
import { Strings } from "../../utils/Strings";
import CommonButton from "../CommonButton/CommonButton";
import CommonInput from "../CommonInput/CommonInput";
import { useOAuthsignupUserMutation, useSignupUserMutation } from "../../services/query";
import MailLogo from "../MailLogo/MailLogo";
import { madTechState, setUserChoice } from "../../features/madTechSlice";
import { useDispatch, useSelector } from "react-redux";
import AuthLayout from "../AuthLayout/AuthLayout";
import useDocumentTitle from "../../hook/useDocumentTitle";
import { BASE_URL } from "../../utils/Baseurl";
import { useRef } from "react";
import { BorderBottom } from "@mui/icons-material";
import { useMyContext } from "../CommanToasterContext/toast";
import { ContactUsLink, DontHaveAcc } from "./CommanSignIn.styles";

function CommanSignUp(props) {
  const { background, textAlign, classname, Boxwidth, TextWidth, OAuthType, AuthError, AuthData, OauthsignupUser, AuthLoading } = props;
  useDocumentTitle("Sign up");
  const matchesxsss = useMediaQuery("(min-width:495px)");

  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const clientId = queryParams.get('clientId');

  // useEffect(() => {
  //   if (OAuthType === true && clientId === null) {
  //     window.close()
  //   }
  // }, [OAuthType])


  const { userChoice } = useSelector(madTechState);
  const captchaRef = useRef(null);

  const [userType, setUserType] = useState(userChoice);
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [workEmail, setWorkEmail] = useState("");
  const [companyName, setCompanyName] = useState("");
  const [title, setTitle] = useState("");
  const [phoneNumber, setPhoneNumber] = useState("");
  const [emailErr, setEmailErr] = useState(false);
  const [phoneError, setPhoneError] = useState(false);
  const [agreement, setAgreement] = useState(false);
  const [completeSignup, setCompleteSignup] = useState(false);
  const [signupErrorMsg, setSignupErrorMsg] = useState("");
  const [captchIsLoading, setCaptchIsLoading] = useState(false);
  const [captchaValue, setCaptchaValue] = useState(null);
  const [requiredCredentials, setrequiredCredentials] = useState(false);

  const [agreementError, setAgreementError] = useState(false);
  const [firstNameError, setFirstNameError] = useState(false);
  const [lastNameError, setLastNameError] = useState(false);
  const [companyNameError, setCompanyNameError] = useState(false);
  const [titleError, setTitleError] = useState(false);

  const { open1, message1, setOpen, open2, setMessage, setOpen2, setMessage2 } = useMyContext();

  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [signupUser, { isLoading, data, error }] = useSignupUserMutation();



  const validateAggrement = useCallback(() => {
    if (!agreement) {
      setAgreementError(true);
      return true;
    } else {
      setAgreementError(false);
    }
    return false;
  }, [agreement]);

  useEffect(() => {
    if (agreement) {
      setAgreementError(false);
    }
  }, [agreement]);

  const onCaptchaChange = (value) => {
    setSignupErrorMsg("");
    setCaptchaValue(value);
    // console.log("Captcha value:", value);
  };

  const captureCaptcha = async () => {
    try {
      // console.log("Captcha value:", captchaValue);
      setSignupErrorMsg("");
      if (
        validateRewquiredField() ||
        validateEmail() ||
        validatePhone() ||
        validateAggrement()
      ) {
        return;
      }
      setCaptchIsLoading(true);
      if (captchaValue) {
        const recaptchaesponse = await fetch(`${BASE_URL}/recaptcha/verify`, {
          method: "POST",
          headers: { "Content-Type": "application/json" },
          body: JSON.stringify({ token: captchaValue }),
        });
        const resData = await recaptchaesponse?.json();
        // console.log('recaptcha response', resData);
        if (resData?.statusCode === 200) {
          _signup();
        } else {
          setSignupErrorMsg(resData?.statusMessage);
        }
      } else {
        // console.log('fill captcha');
        setSignupErrorMsg("Please verify recaptcha");
      }
    } catch (error) {
      console.log("recaptcha error", error);
    }
    setCaptchIsLoading(false);
  };

  const validateRewquiredField = useCallback(() => {
    if (firstName.trim() === "" && lastName.trim() === "" && companyName.trim() === "" && title.trim() === "") {
      setrequiredCredentials(true);
      if (phoneNumber === "") {
        setPhoneError(true)

      }
      return true;
    } else if (firstName.trim() === "") {
      setFirstNameError(true);
      setrequiredCredentials(false);
      return true;
    } else if (lastName.trim() === "") {
      setLastNameError(true);
      setrequiredCredentials(false);
      return true;
    } else if (companyName.trim() === "") {
      setCompanyNameError(true)
      setrequiredCredentials(false);
      return true
    } else if (title === "") {
      setTitleError(true)
      setrequiredCredentials(false);
      return true
    }
    return false;
  }, [firstName, lastName, title, companyName, phoneNumber]);

  const validateEmail = () => {
    if (
      !workEmail.match(
        /^(([^<>()[\]\\.,;:\s@]+(\.[^<>()[\]\\.,;:\s@]+)*)|(.+))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
      )
    ) {
      setEmailErr(true);
      return true;
    } else {
      setEmailErr(false);
    }
    return false;
  };

  const validatePhone = useCallback(() => {
    if (
      phoneNumber.trim() === "" ||
      10 > phoneNumber.length ||
      phoneNumber.length > 15 ||
      !/^[0-9]+$/.test(phoneNumber)
    ) {
      setPhoneError(true);
      return true;
    } else {
      setPhoneError(false);
    }
    return false;
  }, [phoneNumber]);

  const updateAgreement = (event) => {
    setAgreement(event.target.checked);
    setSignupErrorMsg("");
  };

  const _signup = useCallback(() => {
    // if (validateRewquiredField() || validateEmail() || validatePhone() || validateAggrement()) {
    //   return;
    // }
    if (OAuthType === true) {
      OauthsignupUser({
        userType,
        firstName,
        lastName,
        email: workEmail,
        companyName,
        title,
        phoneNumber,
        oauth: OAuthType
      });
    } else {
      signupUser({
        userType,
        firstName,
        lastName,
        email: workEmail,
        companyName,
        title,
        phoneNumber,
        oauth: OAuthType
      });
    }
  }, [
    useSignupUserMutation,
    useOAuthsignupUserMutation,
    userType,
    firstName,
    lastName,
    workEmail,
    companyName,
    title,
    phoneNumber,
    validateEmail,
    validatePhone,
    validateRewquiredField,
    validateAggrement,
    OAuthType
  ]);

  useEffect(() => {
    if (data !== undefined) {
      if (data?.statusCode === 200) {
        if (data?.response?.redirectionUrl) {
          // window.open(data?.response?.redirectionUrl, '_self');
          navigate(data?.response?.redirectionUrl)
          return
        }
        setMessage(data?.statusMessage)
        setOpen(true)
        setCompleteSignup(true);
        captchaRef?.current?.reset();
        setCaptchaValue(null);
        setFirstName("");
        setLastName("");
        setWorkEmail("");
        setCompanyName("");
        setTitle("");
        setPhoneNumber("");
        setEmailErr(false)
        setPhoneError(false)
        setAgreement(false)
        setSignupErrorMsg("");

        // dispatch(setLoggedin())
      } else {
        captchaRef?.current?.reset();
        setMessage2(data?.statusMessage)
        setOpen2(true)
        setCaptchaValue(null);
      }
    }
    if (error !== undefined) {
      console.log("error", error);
    }
  }, [data, error]);

  useEffect(() => {
    const labelElement = document.querySelector('.special-label');
    if (labelElement) {
      labelElement.textContent = 'Phone*';
    }
  }, [])



  useEffect(() => {
    if (AuthData !== undefined) {
      if (AuthData?.statusCode === 200) {
        // Strings.signup_success_msg
        window.open(`${AuthData?.response}&clientId=${clientId}`, '_self');
        // navigate(clientId ? "/oauth/completeProfile?clientId=" + clientId : "/oauth/completeProfile")
      } else {
        captchaRef?.current?.reset();
        setSignupErrorMsg(AuthData?.statusMessage);
        setCaptchaValue(null);
      }
    }
    if (AuthError !== undefined) {
      console.log("AuthError", AuthError);
    }
  }, [AuthData, AuthError]);


  return (
    <AuthLayout OAuthType={OAuthType}>

      <Grid container={!OAuthType} bgcolor={background} sx={{}}>
        {!OAuthType ? <Grid
          item
          md={7}
          lg={7}
          sm={12}
          xs={12}
          bgcolor={color.themeColor}
          sx={{ position: "relative" }}
        >
          <img
            style={{
              position: "absolute",
              top: 32,
              width: "13.44rem",
              height: "2.81rem",
              left: "15%",
              cursor: "pointer",
            }}
            alt={"MADCONNECT"}
            src={whiteLogo}
            onClick={() => navigate("/")}
          ></img>
          <div
            className="login-signin-container"
            style={{ background: color.themeColor }}
          >
            <div
              className="left-container"
              style={{
                display: "flex",
                flexDirection: "column",
                justifyContent: "center",
                alignItems: "center",
                marginLeft: 0,
                padding: matchesxsss ? "2rem 4rem" : "0px 0px",
                // border: '1px solid white'
              }}
            >
              <div
                className={`${matchesxsss ? "row" : "new-row"}`}
                style={{
                  marginTop: 50,
                  // border: '1px solid white'
                }}
              >
                <div className="col-12">
                  <div className={`${matchesxsss ? "row" : "new-row"}`}>
                    <div className="col-12">
                      <Typography
                        sx={{
                          color: "#fff",
                          fontWeight: 400,
                          fontFamily: "Phosphate",
                          fontSize: "4.38rem",
                          letterSpacing: "0.2rem",
                          lineHeight: "100px",
                          textAlign: "center",
                        }}
                      >
                        let’s get started
                      </Typography>
                      {/* <p style={{ fontFamily: 'Phosphate', fontSize: 48, fontWeight: 400, letterSpacing: '0.3rem' }}>Welcome to</p> */}
                    </div>
                  </div>
                </div>
              </div>
              <div
                className={`${matchesxsss ? "row" : "new-row"}`}
                style={{
                  display: "flex",
                  flexDirection: "column",
                  justifyContent: "center",
                  alignItems: "center",
                  //  border: '1px solid white'
                }}
              >
                <div className="col-12">
                  <div className={`${matchesxsss ? "row" : "new-row"} mt-2`}>
                    <div className="col-12">
                      <Typography
                        sx={{
                          color: "#fff",
                          fontWeight: 400,
                          fontFamily: "Phosphate",
                          fontSize: "2.19rem",
                          letterSpacing: "0.2rem",
                          lineHeight: "60px",
                          textAlign: "center",
                        }}
                      >
                        {/* Sign up in four simple steps */}
                        {userChoice === "Platform"
                          ? "Platform sign up"
                          : "Brand / Publisher sign up"}
                      </Typography>
                    </div>
                  </div>
                </div>
                <Box
                  sx={{
                    mt: "3rem",
                    display: "flex",
                    flexDirection: "row",
                    width: "95%",
                    justifyContent: "center",
                    alignItems: "center",
                  }}
                >
                  <div className="text-center" style={{ marginRight: 50 }}>
                    <div
                      className="temp-logo"
                      style={{
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                      }}
                    >
                      <img
                        style={{ width: "2.5rem" }}
                        alt=""
                        src={userLogo}
                      ></img>
                    </div>
                    <p
                      style={{
                        fontFamily: "Helvetica Neue",
                        color: color.whiteColor,
                        fontWeight: 500,
                        fontSize: "1rem",
                        marginTop: 10,
                        marginBottom: 0,
                      }}
                    >
                      Create{" "}
                    </p>
                    <p
                      style={{
                        fontFamily: "Helvetica Neue",
                        color: color.whiteColor,
                        fontWeight: 500,
                        fontSize: "1rem",
                      }}
                    >
                      Account
                    </p>
                  </div>

                  {userChoice === "Platform" ? (
                    <>
                      <div
                        className="text-center"
                        style={{ width: 150, marginRight: 18 }}
                      >
                        <div
                          className="temp-logo"
                          style={{
                            display: "flex",
                            justifyContent: "center",
                            alignItems: "center",
                          }}
                        >
                          <img
                            style={{ width: "2.5rem" }}
                            alt=""
                            src={selectConnectorLogo}
                          ></img>
                        </div>
                        <div>
                          <p
                            style={{
                              fontFamily: "Helvetica Neue",
                              color: color.whiteColor,
                              fontWeight: 500,
                              fontSize: "1rem",
                              marginTop: 10,
                              marginBottom: 0,
                            }}
                          >
                            Add
                          </p>
                          <p
                            style={{
                              fontFamily: "Helvetica Neue",
                              color: color.whiteColor,
                              fontWeight: 500,
                              fontSize: "1rem",
                            }}
                          >
                            Platform Details
                          </p>
                        </div>
                      </div>
                    </>
                  ) : (
                    <div>
                      {/* <p
                          style={{
                            fontFamily: "Helvetica Neue",
                            color: color.whiteColor,
                            fontWeight: 500,
                            fontSize: "1rem",
                            marginTop: 10,
                            marginBottom: 0,
                          }}
                        >
                          Select
                        </p>
                        <p
                          style={{
                            fontFamily: "Helvetica Neue",
                            color: color.whiteColor,
                            fontWeight: 500,
                            fontSize: "1rem",
                          }}
                        >
                          Connectors
                        </p> */}
                    </div>
                  )}
                  {/* {userChoice !== "Platform" && (
                    <div className="text-center" style={{ marginRight: 50 }}>
                      <div
                        className="temp-logo"
                        style={{
                          display: "flex",
                          justifyContent: "center",
                          alignItems: "center",
                        }}
                      >
                        <img
                          style={{ width: "2.5rem" }}
                          alt=""
                          src={linkLogo}
                        ></img>
                      </div>
                      <p
                        style={{
                          fontFamily: "Helvetica Neue",
                          color: color.whiteColor,
                          fontWeight: 500,
                          fontSize: "1rem",
                          marginTop: 10,
                          marginBottom: 0,
                        }}
                      >
                        Create
                      </p>
                      <p
                        style={{
                          fontFamily: "Helvetica Neue",
                          color: color.whiteColor,
                          fontWeight: 500,
                          fontSize: "1rem",
                        }}
                      >
                        Connections
                      </p>
                    </div>
                  )} */}
                  {userChoice !== "Platform" && (
                    <div className="text-center" style={{ width: 150 }}>
                      <div
                        className="temp-logo"
                        style={{
                          display: "flex",
                          justifyContent: "center",
                          alignItems: "center",
                        }}
                      >
                        <img
                          style={{ width: "2.5rem" }}
                          alt=""
                          src={briefcase}
                        ></img>
                      </div>
                      <p
                        style={{
                          fontFamily: "Helvetica Neue",
                          color: color.whiteColor,
                          fontWeight: 500,
                          fontSize: "1rem",
                          marginTop: 10,
                          marginBottom: 0,
                        }}
                      >
                        Enter Business
                      </p>
                      <p
                        style={{
                          fontFamily: "Helvetica Neue",
                          color: color.whiteColor,
                          fontWeight: 500,
                          fontSize: "1rem",
                        }}
                      >
                        Profile
                      </p>
                    </div>
                  )}
                </Box>
              </div>
            </div>
          </div>

        </Grid> : ''}
        <Grid
          item
          md={5}
          lg={5}
          sm={12}
          xs={12}
          bgcolor={background}
        >
             <div
              className={classname}
              style={{ backgroundColor: background }}
            >
              <div
                className="signup-container"
                style={{
                  backgroundColor: "#fff",
                  borderRadius: 8,
                  boxShadow: "0px 8px 20px rgba(0, 0, 0, 0.06)",
                  minWidth: matchesxsss ? 200 : 100,
                  marginBottom: !OAuthType ? "" : "20px",
                  // padding: matchesxsss ? 50 : 30
                }}
              >
                <Box
                  sx={{
                    display: "flex",
                    textAlign: "center",
                    justifyContent: "center",
                    height: "1.25rem",
                  }}
                >
                  {
                    requiredCredentials || firstNameError || lastNameError ? (
                      <span className="form-error">
                        {Strings.wrong_credentials}
                      </span>
                    ) : phoneError ? (
                      <span className="form-error">{Strings.phone_error}</span>
                    ) : emailErr ? (
                      <span className="form-error">{Strings.email_error}</span>
                    ) : agreementError ? (
                      <span className="form-error">
                        {Strings.check_aggrement}
                      </span>
                    ) : (
                      <span className="form-error">{signupErrorMsg}</span>
                    )
                    // <Typography variant='subtitle2' sx={{ color: "red", textAlign: "center", height: "1.25rem" }}>{signupErrorMsg}</Typography>
                  }
                </Box>
                <Box
                  sx={{
                    display: "flex",
                    flexDirection: "row",
                    justifyContent: "space-between",
                    aligniItems: "center",
                    width: "100%",
                    mb: "1.5rem",
                  }}
                >
                  <Box sx={{ width: Boxwidth }}>
                    <Typography
                      sx={{
                        fontSize: "1.5rem",
                        fontWeight: 700,
                        fontFamily: "Helvetica Neue",
                        textAlign: textAlign
                      }}
                    >
                      {Strings.signup}
                    </Typography>
                    <Typography
                      sx={{
                        width: TextWidth,
                        fontWeight: 400,
                        fontSize: "0.75rem",
                        color: "#999999",
                        fontFamily: "Helvetica Neue",
                        textAlign: textAlign
                      }}
                    >
                      Please fill your details below to create your account!
                    </Typography>
                  </Box>
                  {!OAuthType ? <img
                    style={{ width: "auto", height: "4rem" }}
                    alt={"MADCONNECT LOGO"}
                    src={madConnectLogo}
                  ></img> : " "}
                  {/* <Box sx={{ border: '1px solid black', display: "flex", justifyContent: "flex-end", width: '50%' }}>
                  </Box> */}
                  {/* <p className='text-center' style={{ fontSize: '1.5rem', fontWeight: 700 }}>{Strings.signup}</p>
                    <p className='text-center sub-text-color' style={{ fontSize: '0.8rem' }}>Please fill your details below to create your account!</p> */}
                </Box>
                <div className="row">
                  <div className="col-12">
                    <Select
                      value={userType}
                      onChange={(event) => {
                        setUserType(event.target.value);
                        dispatch(setUserChoice(event.target.value));
                      }}
                      displayEmpty
                      inputProps={{ "aria-label": "Without label" }}
                      style={{
                        width: "100%",
                        height: "2.75rem",
                        fontSize: "16px",
                        fontWeight: "500",
                        fontFamily: "Helvetica Neue",
                      }}
                    >
                      <MenuItem
                        style={{
                          fontSize: "14px",
                          fontFamily: "Helvetica Neue",
                          fontWeight: 500,
                        }}
                        value={"Brand"}
                      >
                        {Strings.user_type1}
                      </MenuItem>
                      <MenuItem
                        style={{
                          fontSize: "14px",
                          fontFamily: "Helvetica Neue",
                          fontWeight: 500,
                        }}
                        value={"Publisher"}
                      >
                        {Strings.user_type2}
                      </MenuItem>
                      {/* <MenuItem
                        style={{
                          fontSize: "14px",
                          fontFamily: "Helvetica Neue",
                          fontWeight: 500,
                        }}
                        value={"Platform"}
                      >
                        {Strings.user_type3}
                      </MenuItem> */}
                      {/* <MenuItem
                        style={{
                          fontSize: "14px",
                          fontFamily: "Helvetica Neue",
                          fontWeight: 500,
                        }}
                        value={"Agency"}
                      >
                        {Strings.user_type4}
                      </MenuItem>
                      <MenuItem
                        style={{
                          fontSize: "14px",
                          fontFamily: "Helvetica Neue",
                          fontWeight: 500,
                        }}
                        value={"Other"}
                      >
                        {Strings.user_type5}
                      </MenuItem> */}
                    </Select>
                  </div>
                </div>
                <div className="row mt-3">
                  <div className="col-6">
                    <CommonInput
                      height={"2.75rem"}
                      isError={!!requiredCredentials || !!firstNameError}
                      backgroundColor={"white"}
                      placeholder={`${Strings.firstName}*`}
                      inputType="text"
                      value={firstName}
                      isFocused={true}
                      onChange={(event) => {
                        setFirstNameError(false);
                        setrequiredCredentials(false);
                        setFirstName(event.target.value);
                        setSignupErrorMsg("");
                      }}
                    ></CommonInput>
                  </div>
                  <div className="col-6">
                    <CommonInput
                      height={"2.75rem"}
                      isError={!!requiredCredentials || !!lastNameError}
                      backgroundColor={"white"}
                      placeholder={`${Strings.lastName}*`}
                      inputType="text"
                      value={lastName}
                      onChange={(event) => {
                        setLastNameError(false);
                        setrequiredCredentials(false);
                        setLastName(event.target.value);
                        setSignupErrorMsg("");
                      }}
                    ></CommonInput>
                  </div>
                </div>
                <div className="row mt-3">
                  <div className="col-12">
                    <CommonInput
                      height={"2.75rem"}
                      isError={!!emailErr}
                      backgroundColor={"white"}
                      placeholder={`${Strings.workEmai}*`}
                      inputType="email"
                      value={workEmail}
                      onChange={(event) => {
                        setrequiredCredentials(false);
                        setEmailErr(false);
                        setWorkEmail(event.target.value);
                        setSignupErrorMsg("");
                      }}
                    ></CommonInput>
                    {/* {
                      emailErr ?
                        <span className='form-error'>{Strings.email_error}</span> : <></>
                    } */}
                  </div>
                </div>
                <div className="row mt-3">
                  <div className="col-12">
                    <CommonInput
                      height={"2.75rem"}
                      backgroundColor={"white"}
                      isError={!!requiredCredentials || !!companyNameError}
                      placeholder={`${Strings.companyName}*`}
                      inputType="text"
                      value={companyName}
                      onChange={(event) => {
                        setCompanyName(event.target.value);
                        setrequiredCredentials(false)
                        setCompanyNameError(false)
                        setSignupErrorMsg("");
                      }}
                    ></CommonInput>
                  </div>
                </div>
                <div className="row mt-3">
                  <div className="col-12">
                    <CommonInput
                      height={"2.75rem"}
                      backgroundColor={"white"}
                      placeholder={`${Strings.title}*`}
                      isError={!!requiredCredentials || !!titleError}
                      inputType="text"
                      value={title}
                      onChange={(event) => {
                        setTitle(event.target.value);
                        setrequiredCredentials(false)
                        setTitleError(false)
                        setSignupErrorMsg("");
                      }}
                    ></CommonInput>
                  </div>
                </div>
                <div className="row mt-3">
                  <div className="col-12">
                    <FormControl fullWidth variant="outlined" size="small">
                      <PhoneInput
                        placeholder={`${Strings.phoneNumber}*`}
                        inputClass="colors"
                        country={"us"}
                        isValid={!phoneError}
                        countryCodeEditable={false}
                        enableSearch={true}
                        value={phoneNumber}
                        onChange={(phone) => {
                          setPhoneError(false);
                          setPhoneNumber(phone);
                          setrequiredCredentials(false);
                          setSignupErrorMsg("");
                        }}
                        onFocus={() => { }}
                        onBlur={() => { }}
                        style={{
                          width: "100%",
                          height: "2.75rem",
                          fontSize: "0.875rem",
                          color: "#666666",
                          backgroundColor: "#FFFFFF",
                          borderRadius: "5px",
                          fontFamily: "Helvetica Neue",

                        }}
                      />
                    </FormControl>

                  </div>
                </div>
                {/* <div className='row mt-3'>
                  <div className='col-12'>
                    <CommonInput
                      height={"2.75rem"}
                      isError={!!phoneError}
                      backgroundColor={'white'}
                      placeholder={Strings.phoneNumber}
                      inputType='number'
                      value={phoneNumber}
                      onChange={(event) => {
                        setPhoneError(false)
                        setPhoneNumber(event.target.value);
                        setSignupErrorMsg('')
                      }}
                    ></CommonInput>
                  </div>
                </div> */}
                <div className="row mt-3">
                  <div className="col-12">
                    <ReCAPTCHA
                      ref={captchaRef}
                      sitekey="6LfxLK0kAAAAABD0Y64yJCusUvIhY4Mo6MMC-cnC"
                      // sitekey={process.env.REACT_APP_RECAPTCHA_SITE_KEY}
                      onChange={onCaptchaChange}
                    />
                  </div>
                </div>
                {/* <div className='row mt-3'>
                  <div className='col-12'>
                    <CommonInput
                      height={"2.75rem"}
                      backgroundColor={'white'}
                      placeholder={Strings.codeText}
                      inputType='text'
                      // value={text}
                      // onChange={(event) => {
                      //   setText(event.target.value);
                      // }}
                      onKeyDown={(event) => {
                        if (event.key === 'Enter') {
                          _signup(event);
                        }
                      }}
                    ></CommonInput>
                  </div>
                </div> */}
                <div className="mt-3">
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "row",
                      alignItems: "center",
                    }}
                  >
                    <input
                      type="checkbox"
                      value="lsRememberMe"
                      id="rememberMe"
                      style={{ marginRight: "0.31rem" }}
                      onChange={updateAgreement}
                    />
                    <Typography
                      style={{
                        marginTop: "0.125rem",
                        color: "#6D7280",
                        fontSize: "0.875rem",
                        fontWeight: 400,
                        fontFamily: "Helvetica Neue",
                      }}
                    >
                      I agree to
                      {/* <span style={{
                        cursor: 'pointer', color: color.themeColor, marginLeft: "0.25rem", fontSize: "0.875rem", fontFamily: 'Helvetica Neue',
                        textDecorationLine: 'underline'
                      }}
                        onClick={() => { window.open('/terms') }}>
                       terms & conditions
                      </span>
                      <span style={{ color: color.themeColor, marginLeft: "0.25rem", fontSize: "0.875rem", fontFamily: 'Helvetica Neue' }}>
                        &
                      </span> */}
                      <span
                        style={{
                          cursor: "pointer",
                          color: color.themeColor,
                          marginLeft: "0.25rem",
                          marginRight: "0.2rem",
                          fontSize: "0.875rem",
                          fontFamily: "Helvetica Neue",
                          textDecorationLine: "underline",
                        }}
                        onClick={() => {
                          window.open("/privacy");
                        }}
                      >
                        privacy policy
                      </span>
                      and
                      <span
                        style={{
                          cursor: "pointer",
                          color: color.themeColor,
                          marginLeft: "0.25rem",
                          fontSize: "0.875rem",
                          fontFamily: "Helvetica Neue",
                          textDecorationLine: "underline",
                        }}
                        onClick={() => {
                          window.open("/terms");
                        }}
                      >
                        terms of service
                      </span>
                      <span
                        style={{
                          color: color.themeColor,
                          marginLeft: 1,
                          fontSize: "0.875rem",
                          fontFamily: "Helvetica Neue",
                        }}
                      >
                        *
                      </span>
                    </Typography>
                    {/* <label htmlFor="rememberMe" style={{ color: '#6D7280', fontSize: "0.875rem", fontFamily: 'Helvetica Neue' }}>I agree to</label> */}
                    {/* <p style={{ cursor: 'pointer', color: color.themeColor, marginLeft: "0.25rem", fontSize: "0.875rem", fontFamily: 'Helvetica Neue' }}
                      onClick={() => {
                        window.open('/terms')
                      }}
                    ><u style={{ color: color.themeColor }}>{Strings.terms}</u>
                    </p> */}
                  </div>
                </div>
                <div className="mt-3 mb-4">
                  <CommonButton
                    width={`100%`}
                    height={"2.75rem"}
                    textStyle={{
                      fontSize: "18px",
                      fontWeight: "500",
                      fontFamily: "Helvetica Neue",
                    }}
                    onClick={captureCaptcha}
                    title={Strings.signup}
                    color={"#3843AC"}
                    isLoading={(captchIsLoading || (OAuthType ? AuthLoading : isLoading))}
                  // disabled={!_validateSignUpForm()}
                  ></CommonButton>
                </div>
                <hr className='dashed' />

                <div className='mt-3 text-center'>
                  <DontHaveAcc className='sub-text-color'>Already have an account?
                    <ContactUsLink
                      onClick={() => {
                        // navigate(clientId ? "/oauth/signup?clientId=" + clientId : "/oauth/signup");
                        navigate("/signin");
                      }
                      }
                    >Log in
                    </ContactUsLink>
                  </DontHaveAcc>
                </div>
                {/* <hr className='dashed' />
                <div className='mt-3 text-center'>
                  <p className='sub-text-color'>Already have an account?
                    <a style={{ cursor: 'pointer', color: color.buttonColor, fontWeight: "bold", marginLeft: 4 }}
                      onClick={() => {
                        navigate('/signin');
                      }}
                    >{Strings.login}
                    </a>
                  </p>
                </div> */}
              </div>
            </div>
        </Grid>
      </Grid>
    </AuthLayout>
  );
}

export default CommanSignUp;
