import { Box, Paper, Table, TableBody, TableCell, TableContainer, TableRow, Typography } from "@mui/material";
import React from "react";

const AmendmentsSection = () => {
    const firstListItems = [
        {
            id: "a",
            description:
                ` makes reasonable and proportionate changes to the Approved Addendum, including correcting errors 
                in the Approved Addendum; and/ or `,
        },
        {
            id: "b",
            description:
                `reflects changes to UK Data Protection Laws; `,
        },
    ];
    const secondListItems = [
        {
            id: "a",
            description:
                `a its direct costs of performing its obligations under the Addendum; and/or `,
        },
        {
            id: "b",
            description:
                `its risk under the Addendum, `,
        },
    ];


    return (
        <>
            <Typography variant="h6" gutterBottom>
                Amendments to this Addendum
            </Typography>

            <Typography variant="body1" paragraph>
                The Parties may agree to change Clauses 17 and/or 18 of the Addendum EU SCCs to refer to the laws and/or
                courts of Scotland or Northern Ireland.
            </Typography>
            <Typography variant="body1" paragraph>
                If the Parties wish to change the format of the information included in Part 1: Tables of the Approved Addendum,
                they may do so by agreeing to the change in writing, provided that the change does not reduce the
                Appropriate Safeguards.
            </Typography>
            <Typography variant="body1" paragraph>
                From time to time, the ICO may issue a revised Approved Addendum which:
            </Typography>

            {firstListItems.map((item) => (
                <Box
                    key={item.id}
                    sx={{
                        display: "flex",
                        alignItems: "flex-start",
                        mb: 2,
                        ml: 10,
                        gap: 2,
                    }}
                >
                    <Typography
                        variant="body1"
                        sx={{ fontWeight: "bold", marginRight: 1 }}
                    >
                        {item.id}.
                    </Typography>
                    <Typography variant="body1">{item.description}</Typography>
                </Box>
            ))}

            <Typography variant="body1" paragraph>
                The revised Approved Addendum will specify the start date from which the changes to the Approved
                Addendum are effective and whether the Parties need to review this Addendum including the Appendix
                Information. This Addendum is automatically amended as set out in the revised Approved Addendum from
                the start date specified.
            </Typography>
            <Typography variant="body1" paragraph>
                If the ICO issues a revised Approved Addendum under Section 18, if any Party selected in Table 4 “Ending the
                Addendum when the Approved Addendum changes”, will as a direct result of the changes in the Approved
                Addendum have a substantial, disproportionate and demonstrable increase in:
            </Typography>

            {secondListItems.map((item) => (
                <Box
                    key={item.id}
                    sx={{
                        display: "flex",
                        alignItems: "flex-start",
                        mb: 2,
                        ml: 10,
                        gap: 2,
                    }}
                >
                    <Typography
                        variant="body1"
                        sx={{ fontWeight: "bold", marginRight: 1 }}
                    >
                        {item.id}.
                    </Typography>
                    <Typography variant="body1">{item.description}</Typography>
                </Box>
            ))}
            <Typography variant="body1" paragraph>
                and in either case it has first taken reasonable steps to reduce those costs or risks so that it is not
                substantial and disproportionate, then that Party may end this Addendum at the end of a reasonable notice
                period, by providing written notice for that period to the other Party before the start date of the revised
                Approved Addendum.
            </Typography>
            <Typography variant="body1" paragraph>
                The Parties do not need the consent of any third party to make changes to this Addendum, but any changes must
                be made in accordance with its terms.
            </Typography>

            <Typography variant="h6" gutterBottom>
                ALTERNATIVE PART 2 MANDATORY CLAUSES:
            </Typography>

            <TableContainer component={Paper} sx={{ mt: 2 }}>
                <Table sx={{ border: "1px solid #f2c200" }}>
                    <TableBody>
                        {/* Row 1 */}
                        <TableRow>
                            <TableCell
                                sx={{
                                    backgroundColor: "#fef7e4",
                                    fontWeight: "bold",
                                    border: "1px solid #f2c200",
                                }}
                            >
                                Mandatory Clauses
                            </TableCell>
                            <TableCell sx={{ border: "1px solid #f2c200" }}>
                                Part 2: Mandatory Clauses of the Approved Addendum, being the template Addendum
                                B.1.0 issued by the ICO and laid before Parliament in accordance with s119A of the
                                Data Protection Act 2018 on 2 February 2022, as it is revised under Section 18 of
                                those Mandatory Clauses.
                            </TableCell>
                        </TableRow>
                    </TableBody>
                </Table>
            </TableContainer>
        </>
    );
};

export default AmendmentsSection;
