import { Box, Button, CircularProgress, Typography } from "@mui/material";
import React, { useState } from "react";

import { SDropDown } from "./SDropdown";
import { MDropDown } from "./MDropdown";
import { ButtonComponent } from "./ButtonComponent";
import { TextFieldComponent } from "./TextFieldComponent";
import { color } from "../../utils/Colors";
import save from "../../assets/save.svg";
import clear from "../../assets/clear.svg";
import trash from "../../assets/trash-2.svg";
import { useSelector } from "react-redux";
import { madTechState } from "../../features/madTechSlice";

const ShowDisclouser = ({ label, value }) => {
  return (
    <Box className="disclouser-wrapper">
      <strong className="font-bold">{label}: </strong>
      <span
        className="disclouser-container"
        dangerouslySetInnerHTML={{ __html: value }}
      />
    </Box>
  );
};

const ConfigurationForm = ({
  configuration,
  control,
  platformByIdDatas,
  saveEnable,
  onSubmit,
  handleSubmit,
  saveConfigLoading,
  reset,
  isDirty,
  id,
  deleteRequestPlatformLoading,
  platfromData,
  platformDetails
}) => {

  const { platformConfigurations } = useSelector(madTechState);

  const [isDeleteModal, setIsDeleteModal] = useState(false);

  const isAllButton = !configuration?.some((item) => item.type !== "button");
  const isDisclouser = configuration?.some(
    (item) => item.type === "disclosure"
  );

  const HandleDelete = () => {
    if (platformConfigurations?.[0]?.inUse == true) {
      setIsDeleteModal(true);
    } else {
      // setIsDeleteOpen(true);
    }
  };

  return (
    <Typography>
      <Box
        sx={{
          display: ` ${isDisclouser ? "flex" : "grid"}`,
          gap: "1rem",
          gridTemplateColumns: "auto auto",
          alignItems: `${isDisclouser ? "baseline" : "center"}`,
          flexDirection: "column",
        }}
      >
        {configuration?.map((fieldConfig) => {
          const { type, id, name, label, required, list, ...rest } =
            fieldConfig;
          switch (type) {
            case "sdropdown":
              return (
                <SDropDown
                  key={id}
                  control={control}
                  name={name}
                  label={label}
                  list={list}
                  required={required}
                  disabled={!platfromData?.editable}
                />
              );
            case "mdropdown":
              return (
                <MDropDown
                  control={control}
                  name={fieldConfig?.name}
                  list={fieldConfig?.list? JSON.parse(fieldConfig?.list):[]}
                  required={required}
                  disabled={!platfromData?.editable}
                />
              );
            case "button":
              return (
                <ButtonComponent
                  label={fieldConfig?.label}
                  configured={platformDetails?.[0]?.configured}
                  required={fieldConfig?.required}
                  readonly={fieldConfig?.readonly || !platfromData?.editable}
                  color={color}
                  name={name}
                  id={id}
                  Platform={platformByIdDatas}
                  // platformType={types}
                />
              );
            case "disclosure":
              return (
                <ShowDisclouser
                  label={fieldConfig?.label}
                  value={fieldConfig?.value}
                  required={required}
                />
              );
            default:
              return (
                <TextFieldComponent
                  key={id}
                  control={control}
                  name={name}
                  label={label}
                  type={type}
                  {...rest}
                  required={required}
                  readonly={fieldConfig?.readonly || !platfromData?.editable}
                />
              );
          }
        })}
      </Box>
      {!isAllButton && !isDisclouser && (
        <Box
          sx={{
            display: "flex",
            flexDirection: "row",
            justifyContent: "end",
            alignItems: "center",
            marginTop: "0.5rem",
            gap: "1rem",
          }}
        >
          <Button
            disabled={!saveEnable || !platfromData?.editable}
            onClick={handleSubmit(onSubmit)}
            style={{
              cursor: "pointer",
              backgroundColor: color.buttonColor,
              width: "6.625rem",
              height: "2.5rem",
              color: color.whiteColor,
              textTransform: "none",
              background: saveEnable ? color.buttonColor : "#CCCCCC",
              border: "1.5px solid",
              borderColor: saveEnable ? color.buttonColor : "#CCCCCC",
              padding: "0px",
            }}
            variant="contained"
          >
            <Box sx={{ display: "flex", gap: "0.5rem", alignItems: "center" }}>
              {saveEnable && (
                <img
                  alt=""
                  src={save}
                  style={{
                    width: "1rem",
                    display: "flex",
                    alignItems: "center",
                  }}
                ></img>
              )}
              {saveConfigLoading && (
                <CircularProgress
                  style={{ color: color.whiteColor }}
                  size={20}
                  thickness={4}
                />
              )}
              <Typography
                style={{
                  fontSize: "1rem",
                  fontWeight: 500,
                  fontFamily: "Helvetica",
                  display: "flex",
                  alignItems: "center",
                  // marginTop: 2,
                }}
              >
                Save
              </Typography>
            </Box>
          </Button>
          {isDirty && (
            <Button
              onClick={() => {
                reset();
              }}
              style={{
                cursor: "pointer",
                backgroundColor: color.whiteColor,
                width: "6.625rem",
                height: "2.5rem",
                color: color.themeBlack,
                textTransform: "none",
              }}
              variant="contained"
            >
              <Box sx={{ display: "flex", gap: "0.5rem" }}>
                <img
                  alt=""
                  src={clear}
                  style={{
                    width: "1rem",
                    display: "flex",
                    alignItems: "center",
                  }}
                />
                <Typography
                  style={{
                    fontSize: "1rem",
                    fontWeight: 500,
                    fontFamily: "Helvetica Neue",
                    display: "flex",
                    alignItems: "center",
                  }}
                >
                  Reset
                </Typography>
              </Box>
            </Button>
          )}
          {id && (
            <Button
              disabled={deleteRequestPlatformLoading}
              onClick={() => {
                HandleDelete();
              }}
              style={{
                display: "none",
                cursor: "pointer",
                backgroundColor: deleteRequestPlatformLoading
                  ? color.themeLightGray
                  : color.whiteColor,
                width: "6.625rem",
                height: "2.5rem",
                color: deleteRequestPlatformLoading
                  ? color.black
                  : color.themeRed,
                textTransform: "none",
                border: "1.5px solid",
                borderColor: deleteRequestPlatformLoading
                  ? color.themeLightGray
                  : color.themeRed,
              }}
              variant="contained"
            >
              <Box
                sx={{ display: "flex", gap: "0.5rem", alignItems: "center" }}
              >
                {deleteRequestPlatformLoading ? (
                  <>
                    <CircularProgress
                      style={{ color: color.black }}
                      size={20}
                      thickness={4}
                    />
                  </>
                ) : (
                  <img
                    alt=""
                    src={trash}
                    style={{
                      width: "1.3rem",
                      display: "flex",
                      alignItems: "center",
                    }}
                  ></img>
                )}

                <Typography
                  style={{
                    fontSize: "1rem",
                    fontWeight: 500,
                    fontFamily: "Helvetica Neue",
                    display: "flex",
                    alignItems: "center",
                    // marginTop: 2,
                  }}
                >
                  Delete
                </Typography>
              </Box>
            </Button>
          )}
        </Box>
      )}
    </Typography>
  );
};

export default ConfigurationForm;

// import {
//   Backdrop,
//   Box,
//   Button,
//   CircularProgress,
//   Fade,
//   FormControl,
//   InputLabel,
//   Modal,
//   OutlinedInput,
//   Typography,
// } from "@mui/material";
// import React, { useMemo, useState } from "react";

// import { color } from "../../utils/Colors";
// import CommonButton from "../CommonButton/CommonButton";
// import { SDropDown } from "./SDropdown";
// import { MDropDown } from "./MDropdown";
// import { ButtonComponent } from "./ButtonComponent";
// import { TextFieldComponent } from "./TextFieldComponent";
// import trash from "../../assets/trash-2.svg";
// import save from "../../assets/save.svg";
// import clear from "../../assets/clear.svg";
// import cancel from "../../assets/cancel.svg";

// const ShowDisclouser = ({label,value}) => {
//   return (
//     <Box className="disclouser-wrapper" >
//       <strong className="font-bold">{label}: {" "} </strong>
//       <span
//         className="disclouser-container"
//         dangerouslySetInnerHTML={{ __html: value }}
//       />
//     </Box>
//   );
// }

// export default function ConfigurationForm({
//   id,
//   configuration,
//   reset,
//   isDirty,
//   isValid,
//   control,
//   srcPlateform,
//   handleSubmit,
//   saveEnable,
//   saveConfigLoading,
//   onSubmit,
//   _deleteRequestedPlatform,
//   deleteRequestPlatformLoading,
//   name,
//   deleteConfigurationClose,
//   isDeleteButtonEnabled,
//   inputText,
//   handleInputChange,
//   isDeleteOpen,
//   setIsDeleteOpen,
//   platformByIdDatas,
//   types
// }) {
//   const style = useMemo(
//     () => ({
//       position: "absolute",
//       top: "50%",
//       left: "50%",
//       transform: "translate(-50%, -50%)",
//       borderRadius: "8px 8px 8px 8px",
//       bgcolor: "#F8F8FE",
//       width: 442,
//       boxShadow: 24,
//       p: 4,
//       display: "flex",
//       flexDirection: "column",
//       justifyContent: "center",
//       alignItems: "center",
//       "&:focus": {
//         outline: "none",
//       },
//     }),
//     []
//   );

//   const IsUse = srcPlateform?.filter((item) => {
//     return item.id == id
//   })
//   const [isDeleteModal, setIsDeleteModal] = useState(false)
//   const HandleDelete = () => {
//     if (IsUse[0]?.inUse == true) {
//       setIsDeleteModal(true)
//     } else {
//       setIsDeleteOpen(true)
//     }
//   }
//   const isAllButton = !configuration.some(item => item.type !== "button");

//   const isDisclouser = configuration.some(item => item.type === "disclosure")

//   return (
//     <>
//       <Typography>
//         <Box
//           sx={{
//             display: ` ${isDisclouser ? "flex": "grid"}`,
//             gap: "1rem",
//             gridTemplateColumns: "auto auto",
//             alignItems: `${isDisclouser ? "baseline" : "center"}`,
//             flexDirection:"column"
//           }}
//         >
//           {configuration?.map((fieldConfig) => {
//             const { type, id, name, label,required, list, ...rest } = fieldConfig;
//             switch (type) {
//               case "sdropdown":
//                 return (
//                   <SDropDown
//                     key={id}
//                     control={control}
//                     name={name}
//                     label={label}
//                     list={list}
//                     required={required}
//                   />
//                 );
//               case "mdropdown":
//                 return (
//                   <MDropDown
//                     control={control}
//                     name={fieldConfig?.name}
//                     list={JSON.parse(fieldConfig?.list)}
//                     required={required}

//                   />
//                 );
//               case "button":
//                 return (
//                   <ButtonComponent
//                     label={fieldConfig?.label}
//                     configured={fieldConfig?.configured}
//                     required={fieldConfig?.required}
//                     readonly={fieldConfig?.readonly}
//                     color={color}
//                     name={name}
//                     id={id}
//                     Platform={platformByIdDatas}
//                     platformType={types}
//                   />
//                 );
//                 case "disclosure":
//                 return (
//                   <ShowDisclouser
//                     label={fieldConfig?.label}
//                    value={fieldConfig?.value}
//                    required={required}

//                   />
//                 );
//               default:
//                 return (
//                   <TextFieldComponent
//                     key={id}
//                     control={control}
//                     name={name}
//                     label={label}
//                     type={type}
//                     {...rest}
//                     required={required}
//                   />
//                 );
//             }
//           })}
//         </Box>
//         {
//           (!isAllButton && !isDisclouser) &&
//           <Box
//             sx={{
//               display: "flex",
//               flexDirection: "row",
//               justifyContent: "end",
//               alignItems: "center",
//               marginTop: "0.5rem",
//               gap: "1rem",
//             }}
//           >
//             <Button
//               disabled={!saveEnable}
//               onClick={handleSubmit(onSubmit)}
//               style={{
//                 cursor: "pointer",
//                 backgroundColor: color.buttonColor,
//                 width: "6.625rem",
//                 height: "2.5rem",
//                 color: color.whiteColor,
//                 textTransform: "none",
//                 background: saveEnable ? color.buttonColor : "#CCCCCC",
//                 border: "1.5px solid",
//                 borderColor: saveEnable ? color.buttonColor : "#CCCCCC",
//                 padding: "0px"
//               }}
//               variant="contained"
//             >
//               <Box sx={{ display: "flex", gap: "0.5rem", alignItems: "center" }}>
//                 {isValid && isDirty && !saveConfigLoading && (
//                   <img
//                     alt=""
//                     src={save}
//                     style={{
//                       width: "1rem",
//                       display: "flex",
//                       alignItems: "center",
//                     }}
//                   ></img>
//                 )}
//                 {saveConfigLoading && (
//                   <CircularProgress
//                     style={{ color: color.whiteColor }}
//                     size={20}
//                     thickness={4}
//                   ></CircularProgress>
//                 )}
//                 <Typography
//                   style={{
//                     fontSize: "1rem",
//                     fontWeight: 500,
//                     fontFamily: "Helvetica",
//                     display: "flex",
//                     alignItems: "center",
//                     // marginTop: 2,
//                   }}
//                 >
//                   Save
//                 </Typography>
//               </Box>
//             </Button>
//             {isDirty && (
//               <Button
//                 onClick={() => {
//                   reset();
//                 }}
//                 style={{
//                   cursor: "pointer",
//                   // border: `1px solid ${color.themeDarkGray}`,
//                   backgroundColor: color.whiteColor,
//                   width: "6.625rem",
//                   height: "2.5rem",
//                   color: color.themeBlack,
//                   textTransform: "none",
//                 }}
//                 variant="contained"
//               >
//                 <Box sx={{ display: "flex", gap: "0.5rem" }}>
//                   <img
//                     alt=""
//                     src={clear}
//                     style={{
//                       width: "1rem",
//                       display: "flex",
//                       alignItems: "center",
//                     }}
//                   ></img>
//                   <Typography
//                     style={{
//                       fontSize: "1rem",
//                       fontWeight: 500,
//                       fontFamily: "Helvetica Neue",
//                       display: "flex",
//                       alignItems: "center",
//                       // marginTop: 2,
//                     }}
//                   >
//                     Reset
//                   </Typography>
//                 </Box>
//               </Button>
//             )}
//             {/*............ Delete button is display none, if use simply remove display none.  */}
//             {id && (
//               <Button
//                 disabled={deleteRequestPlatformLoading}
//                 onClick={() => {
//                   HandleDelete()
//                 }}
//                 style={{
//                   display: "none",
//                   cursor: "pointer",
//                   backgroundColor: deleteRequestPlatformLoading
//                     ? color.themeLightGray
//                     : color.whiteColor,
//                   width: "6.625rem",
//                   height: "2.5rem",
//                   color: deleteRequestPlatformLoading
//                     ? color.black
//                     : color.themeRed,
//                   textTransform: "none",
//                   border: "1.5px solid",
//                   borderColor: deleteRequestPlatformLoading
//                     ? color.themeLightGray
//                     : color.themeRed,
//                 }}
//                 variant="contained"
//               >
//                 <Box
//                   sx={{ display: "flex", gap: "0.5rem", alignItems: "center" }}
//                 >
//                   {deleteRequestPlatformLoading ? (
//                     <>
//                       <CircularProgress
//                         style={{ color: color.black }}
//                         size={20}
//                         thickness={4}
//                       ></CircularProgress>
//                     </>
//                   ) : (
//                     <img
//                       alt=""
//                       src={trash}
//                       style={{
//                         width: "1.3rem",
//                         display: "flex",
//                         alignItems: "center",
//                       }}
//                     ></img>
//                   )}

//                   <Typography
//                     style={{
//                       fontSize: "1rem",
//                       fontWeight: 500,
//                       fontFamily: "Helvetica Neue",
//                       display: "flex",
//                       alignItems: "center",
//                       // marginTop: 2,
//                     }}
//                   >
//                     Delete
//                   </Typography>
//                 </Box>
//               </Button>
//             )}
//           </Box>
//         }
//       </Typography>
//       {/* DELETE ACCOUNT MODAL START */}
//       <Modal
//         aria-labelledby="transition-modal-title"
//         aria-describedby="transition-modal-description"
//         open={isDeleteOpen}
//         onClose={deleteConfigurationClose}
//         closeAfterTransition
//         slots={{ backdrop: Backdrop }}
//         slotProps={{
//           backdrop: {
//             timeout: 500,
//           },
//         }}
//       >
//         <Fade in={isDeleteOpen}>
//           <Box sx={{ ...style, width: 520 }}>
//             <Typography
//               style={{
//                 fontWeight: 700,
//                 fontFamily: "Helvetica Neue",
//                 fontSize: "1rem",
//                 textAlign: "center",
//                 color: "#656C7B",
//                 width: "70%",
//                 marginBottom: 16,
//                 color: color.buttonColor
//               }}
//             >
//               {/* {configuration} */}
//               {name}
//             </Typography>
//             <Typography
//               style={{
//                 fontWeight: 400,
//                 fontFamily: "Helvetica Neue",
//                 fontSize: "1rem",
//                 textAlign: "center",
//                 color: "#656C7B",
//                 width: "70%",
//                 marginBottom: 16,
//               }}
//             >
//               Are you sure you want delete this configuration?
//             </Typography>
//             <Box
//               sx={{
//                 width: "100%",
//                 display: "flex",
//                 justifyContent: "center",
//                 flexDirection: "column",
//               }}
//             >
//               <FormControl
//                 sx={{
//                   width: "70%",
//                   height: "2.75rem",
//                   fontSize: "1rem",
//                   color: "#666666",
//                   backgroundColor: "#FFFFFF",
//                   fontFamily: "Helvetica Neue",
//                   display: "flex",
//                   justifyContent: "center",
//                   marginRight: "auto",
//                   marginLeft: "auto",
//                 }}
//                 size="small"
//               >
//                 <InputLabel
//                   sx={{
//                     fontSize: "0.875rem",
//                     marginTop: "0.25rem",
//                     opacity: 0.5,
//                     backgroundColor: "#FFFFFF",
//                     fontFamily: "Helvetica Neue",
//                   }}
//                   id="delete"
//                 >
//                   Type DELETE to confirm
//                 </InputLabel>
//                 <OutlinedInput
//                   placeholder={""}
//                   sx={{
//                     width: "100%",
//                     height: "2.75rem",
//                     fontSize: "1rem",
//                     color: "#666666",
//                     backgroundColor: "#FFFFFF",
//                     fontFamily: "Helvetica Neue",
//                   }}
//                   labelid="delete"
//                   id="delete"
//                   label="Type DELETE to confirm"
//                   onChange={handleInputChange}
//                   value={inputText}
//                   autoComplete="off"
//                 />
//               </FormControl>
//             </Box>
//             <Box
//               sx={{
//                 mt: 3,
//                 display: "flex",
//                 flexDirection: "row",
//                 justifyContent: "center",
//                 alignItems: "center",
//                 gap: "1rem",
//               }}
//             >
//               {/* <Button
//                 disabled={deleteRoleLoading || !isDeleteButtonEnabled}
//                 onClick={(event) => {
//                   _handleDeleteRole(event);
//                 }}
//                 style={{
//                   cursor: "pointer",
//                   backgroundColor: color.buttonColor,
//                   width: "5.625rem",
//                   height: "2.5rem",
//                   marginRight: "0.625rem",
//                   color: color.whiteColor,
//                   textTransform: "none",
//                   backgroundColor: isDeleteButtonEnabled
//                     ? color.buttonColor
//                     : "#CCCCCC",
//                 }}
//                 variant="contained"
//               >
//                 <Typography
//                   style={{
//                     fontSize: "1rem",
//                     fontWeight: 500,
//                     fontFamily: "Helvetica Neue",
//                     marginTop: 2,
//                   }}
//                 >
//                   Delete
//                 </Typography>
//               </Button> */}
//               <Button
//                 disabled={
//                   deleteRequestPlatformLoading || !isDeleteButtonEnabled
//                 }
//                 onClick={() => {
//                   _deleteRequestedPlatform();
//                   // setIsDeleteOpen(false);
//                 }}
//                 style={{
//                   cursor: "pointer",
//                   backgroundColor:
//                     deleteRequestPlatformLoading || !isDeleteButtonEnabled
//                       ? color.themeLightGray
//                       : color.whiteColor,
//                   width: "6.625rem",
//                   height: "2.5rem",
//                   color:
//                     deleteRequestPlatformLoading || !isDeleteButtonEnabled
//                       ? color.black
//                       : color.themeRed,
//                   textTransform: "none",
//                   border: "1.5px solid",
//                   borderColor:
//                     deleteRequestPlatformLoading || !isDeleteButtonEnabled
//                       ? color.themeLightGray
//                       : color.themeRed,
//                 }}
//                 variant="contained"
//               >
//                 <Box sx={{ display: "flex", gap: "0.5rem" }}>
//                   {deleteRequestPlatformLoading ? (
//                     <>
//                       <CircularProgress
//                         style={{ color: color.black }}
//                         size={20}
//                         thickness={4}
//                       ></CircularProgress>
//                     </>
//                   ) : (
//                     <img
//                       alt=""
//                       src={trash}
//                       style={{
//                         width: "1.3rem",
//                         display: "flex",
//                         alignItems: "center",
//                         filter: !isDeleteButtonEnabled && "grayscale(200%)",
//                       }}
//                     ></img>
//                   )}
//                   <Typography
//                     style={{
//                       fontSize: "1rem",
//                       fontWeight: 500,
//                       fontFamily: "Helvetica",
//                       display: "flex",
//                       alignItems: "center",
//                       color: isDeleteButtonEnabled ? "" : "#fff"
//                       // marginTop: 2,
//                     }}
//                   >
//                     Delete
//                   </Typography>
//                 </Box>
//               </Button>
//               {/* <Button
//                 onClick={deleteRoleClose}
//                 style={{
//                   cursor: "pointer",
//                   border: `1px solid ${color.buttonColor}`,
//                   backgroundColor: color.whiteColor,
//                   width: "5.625rem",
//                   height: "2.5rem",
//                   color: color.buttonColor,
//                   textTransform: "none",
//                 }}
//                 variant="contained"
//               >
//                 <Typography
//                   style={{
//                     fontSize: "1rem",
//                     fontWeight: 500,
//                     fontFamily: "Helvetica Neue",
//                     marginTop: 2,
//                   }}
//                 >
//                   Cancel
//                 </Typography>
//               </Button> */}
//               <Button
//                 onClick={deleteConfigurationClose}
//                 style={{
//                   cursor: "pointer",
//                   // border: `1px solid ${color.themeDarkGray}`,
//                   backgroundColor: color.whiteColor,
//                   width: "6.625rem",
//                   height: "2.5rem",
//                   color: color.themeBlack,
//                   textTransform: "none",
//                   width: "106px",
//                 }}
//                 variant="contained"
//               >
//                 <Box sx={{ display: "flex", gap: "0.5rem" }}>
//                   <img
//                     alt=""
//                     src={cancel}
//                     style={{
//                       width: "0.8rem",
//                       display: "flex",
//                       alignItems: "center",
//                     }}
//                   ></img>
//                   <Typography
//                     style={{
//                       fontSize: "1rem",
//                       fontWeight: 500,
//                       fontFamily: "Helvetica",
//                       display: "flex",
//                       alignItems: "center",
//                       // marginTop: 2,
//                     }}
//                   >
//                     Cancel
//                   </Typography>
//                 </Box>
//               </Button>
//             </Box>
//           </Box>
//         </Fade>
//       </Modal>
//       <Modal
//         aria-labelledby="transition-modal-title"
//         aria-describedby="transition-modal-description"
//         open={isDeleteModal}
//         onClose={() => setIsDeleteModal(false)}
//         closeAfterTransition
//         slots={{ backdrop: Backdrop }}
//         slotProps={{
//           backdrop: {
//             timeout: 500,
//           },
//         }}
//       >
//         <Fade in={isDeleteModal}>
//           <Box sx={{ ...style, height: "12rem" }}>
//             {/* {selectedItem && (
//                 <Box
//                   sx={{
//                     display: "flex",
//                     flexDirection: "row",
//                     justifyContent: "space-between",
//                     mb: "1rem",
//                     alignItems: "center",
//                   }}
//                 >
//                   <Box
//                     sx={{
//                       display: "flex",
//                       flexDirection: "row",
//                       alignItems: "center",
//                       width: "100%",
//                       justifyContent: "space-between",
//                     }}
//                   >
//                     <Box
//                       sx={{
//                         display: "flex",
//                         flexDirection: "row",
//                         alignItems: "center",
//                         p: "0.5rem",
//                         width: "100%",
//                         height: "3.75rem",
//                         borderRadius: 1,
//                         //   border: "1px solid rgba(54, 70, 172, 0.15)",
//                         position: "relative",
//                       }}
//                     >
//                       <Box
//                         sx={{
//                           display: "flex",
//                           justifyContent: "center",
//                           alignItems: "center",
//                           width: "100%",
//                           height: "2.5rem",
//                           borderRadius: 1,
//                           // backgroundColor: "rgba(54, 70, 172, 0.07)",
//                           p: 0.4,
//                         }}
//                       >
//                         <img
//                           alt=""
//                           src={selectedItem?.logo}
//                           // src='http://ec2-44-213-15-199.compute-1.amazonaws.com/platforms/Freewheel.svg'
//                           style={{ maxWidth: "100%", maxHeight: "100%" }}
//                         ></img>
//                       </Box>
//                     </Box>
//                   </Box>
//                 </Box>
//               )} */}
//             {/* <Box sx={{ width: 68, height: 68, mb: 3 }}>
//                                 <img src={ModalAlertIcons} style={{ maxWidth: '100%', maxHeight: '100%' }} />
//                             </Box> */}
//             <Typography
//               style={{
//                 fontWeight: 700,
//                 fontFamily: "Helvetica Neue",
//                 fontSize: "1rem",
//                 textAlign: "center",
//                 color: "#656C7B",
//                 width: "70%",
//                 marginBottom: 18,
//                 color: color.buttonColor
//               }}
//             >
//               {/* {configuration} */}
//               {name}
//             </Typography>
//             <Typography
//               style={{
//                 // fontWeight: 700, width: '80%',
//                 // fontFamily: 'Helvetica Neue', fontSize: "1.25rem",
//                 // textAlign: 'center', color: '#25262D'
//                 fontWeight: 400,
//                 fontFamily: "Helvetica Neue",
//                 fontSize: "1rem",
//                 textAlign: "center",
//                 color: "#656C7B",
//                 width: "80%",
//               }}
//             >
//               {/* {deleteErrorMsg} */}
//               Cannot delete the configuration as it is being used in a connection
//             </Typography>
//             {/* <Typography sx={{
//                                 fontWeight: 400,
//                                 fontFamily: 'Helvetica Neue', fontSize: "1rem",
//                                 textAlign: 'center', color: '#656C7B',
//                                 width: '70%',
//                             }}>
//                                 {deleteErrorMsg}
//                             </Typography> */}
//             <Box sx={{ mt: 3 }}>
//               <CommonButton
//                 width={"4.375rem"}
//                 height={"2.25rem"}
//                 textStyle={{
//                   fontSize: "1rem",
//                   fontWeight: "500",
//                   fontFamily: "Helvetica Neue",
//                 }}
//                 onClick={() => setIsDeleteModal(false)}
//                 title={"Okay"}
//                 color={color.buttonColor}
//               ></CommonButton>
//             </Box>
//           </Box>
//         </Fade>
//       </Modal>
//       {/* DELETE ACCOUNT MODAL END */}
//     </>
//   );
// }
