import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import { BASE_URL } from "../utils/Baseurl";
import {
  setAllPlatfomrs,
  setPlatformConfigurations,
  setSelectedPlatforms,
  setUser,
} from "../features/madTechSlice";

// Define a service using a base URL and expected endpoints
export const adminAPI = createApi({
  reducerPath: "adminAPI",
  // baseQuery: fetchBaseQuery({
  //   baseUrl: `${BASE_URL}`,
  //   prepareHeaders: (headers, { getState }) => {
  //     // const token = (getState() as RootState).auth.token;
  //     const userData = JSON.parse(localStorage.getItem("USER"));
  //     if (userData) {
  //       headers.set("Authorization", `${userData.token}`);
  //     }
  //     return headers;
  //   },
  // }),
  baseQuery: async (args, api, extraOptions) => {
    // Prepare headers
    const headers = new Headers();
    const userData = JSON.parse(localStorage.getItem("USER"));
    if (userData) {
      headers.set("Authorization", `${userData.token}`);
    }

    let accountId;
    const queryParams = new URLSearchParams(window.location.search);
    if (
      queryParams !== null &&
      queryParams !== undefined &&
      queryParams.has("accountId")
    ) {
      accountId = queryParams.get("accountId");
    }
    const modifiedUrl =
      accountId != null && accountId !== undefined
        ? `${args.url}?accountId=${accountId}`
        : args.url;

    // Perform the actual query
    const modifiedArgs = {
      ...args,
      url: `${BASE_URL}/${modifiedUrl}`,
      headers,
    };
    const result = await fetchBaseQuery()(modifiedArgs, api, extraOptions);
    return result;
  },
  tagTypes: ["Platform"],
  endpoints: (builder) => ({
    fetchPartner: builder.query({
      // note: an optional `queryFn` may be used in place of `query`
      query: () => ({
        url: ``,
        method: "GET",
      }),
      // Pick out data and prevent nested properties in a hook or selector
      transformResponse: (response, meta, arg) => response,
      providesTags: (result, error) =>
        result
          ? result?.response?.platforms?.map((item) => ({
            type: "Platform",
            id: item?.id,
          }))
          : [{ type: "Platform", id: "LIST" }],
      async onQueryStarted(
        arg,
        {
          dispatch,
          getState,
          extra,
          requestId,
          queryFulfilled,
          getCacheEntry,
          updateCachedData,
        }
      ) {
        // const globalState = getState()
        // console.log('queryStart', getCacheEntry());
        try {
          const { data } = await queryFulfilled;
          // console.log('data.response', data.response);
          dispatch(
            setAllPlatfomrs({
              platforms: data?.response?.platforms,
              categories: data?.response?.categories,
            })
          );
        } catch (err) {
          // `onError` side-effect
          console.log("partners error", err);
          // dispatch(messageCreated('Error fetching posts!'))
        }
      },
      // The 2nd parameter is the destructured `QueryCacheLifecycleApi`
      async onCacheEntryAdded(
        arg,
        {
          dispatch,
          getState,
          extra,
          requestId,
          cacheEntryRemoved,
          cacheDataLoaded,
          getCacheEntry,
          updateCachedData,
        }
      ) { },
    }),
    fetchUser: builder.query({
      query: () => {
        let url = `admin/user`;
        return {
          url,
          method: 'GET',
        };
      },
      transformResponse: (response, meta, arg) => response,
      providesTags: (result, error) =>
        result
          ? result?.response?.map((user) => ({ type: "User", id: user?.userId }))
          : [{ type: "User", id: "LIST" }],
      async onQueryStarted(
        arg,
        {
          dispatch,
          getState,
          extra,
          requestId,
          queryFulfilled,
          getCacheEntry,
          updateCachedData,
        }
      ) {
        try {
          const { data } = await queryFulfilled;
          dispatch(setUser(data?.response)); // Adjust this action to match your state management setup
        } catch (err) {
          console.log("users error", err);
        }
      },
      async onCacheEntryAdded(
        arg,
        {
          dispatch,
          getState,
          extra,
          requestId,
          cacheEntryRemoved,
          cacheDataLoaded,
          getCacheEntry,
          updateCachedData,
        }
      ) { },
    }),
  }),
});

export const { useFetchUserQuery } = adminAPI;
