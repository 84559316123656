import React, { useMemo } from "react";
import { Backdrop, Box, Fade, Modal, Typography } from "@mui/material";

import CommonButton from "../../../../components/CommonButton/CommonButton";
import { color } from "../../../../utils/Colors";
import { Para } from "../Styles/ConnectionList.styles";

const DeleteSuccessModal = ({isDeleteSuccess , setIsDeleteSuccess , handleDeleteCloseSuccess}) => {

  const style = useMemo(
    () => ({
      position: "absolute",
      top: "50%",
      left: "50%",
      transform: "translate(-50%, -50%)",
      borderRadius: "8px 8px 8px 8px",
      bgcolor: "#F8F8FE",
      width: "31.25rem",
      boxShadow: 24,
      p: 4,
      display: "flex",
      flexDirection: "column",
      justifyContent: "center",
      alignItems: "center",
      "&:focus": {
        outline: "none",
      },
    }),
    []
  );



  return (
    <Modal
      aria-labelledby="transition-modal-title"
      aria-describedby="transition-modal-description"
      open={isDeleteSuccess}
      onClose={() => setIsDeleteSuccess(false)}
      closeAfterTransition
      slots={{ backdrop: Backdrop }}
      slotProps={{
        backdrop: {
          timeout: 500,
        },
      }}
    >
      <Fade in={isDeleteSuccess}>
        <Box sx={{ ...style, height: "12.5rem" }}>
          <Para>
            Your connection is marked as deleted. It will take up to 2 working
            days to reflect the changes.
          </Para>
          <Box sx={{ mt: 3 }}>
            <CommonButton
              width={"4.375rem"}
              height={"2.25rem"}
              textStyle={{
                fontSize: "1rem",
                fontWeight: "500",
                fontFamily: "Helvetica Neue",
              }}
              onClick={handleDeleteCloseSuccess}
              title={"Okay"}
              color={color.buttonColor}
            ></CommonButton>
          </Box>
        </Box>
      </Fade>
    </Modal>
  );
};

export default DeleteSuccessModal;
