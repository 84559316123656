import { Box, Typography } from "@mui/material";
import styled from "styled-components";


// (props)=>(props
export const Wrapper = styled(Box)({
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    backgroundColor: 'rgba(25, 50, 65, 0.08)', height: '90vh'
})
export const ContainerWhoAreYou = styled(Box)({
    paddingTop: "1.5rem !important",
    paddingBottom: "1.5rem !important",
    paddingLeft: "32px !important",
    paddingRight: "32px !important",
    width: (props) => (props.matchesxsss ? "480px !important" : '100% !important'),
    height: (props) => (props.matchesxsss ? "380px !important" : "420px !important"),
    borderRadius: "8px !important",
    backgroundColor: '#fff',
    boxShadow: '0px 8px 20px rgba(0, 0, 0, 0.06)',
    border: '1px solid #DEDEDE'
})

export const Heading = styled(Typography)({
    textAlign: 'center',
    fontSize: "1.5rem !important",
    color: '#000000',
    fontWeight: "500 !important",
    fontFamily: 'Helvetica Neue !important'
})

export const BrandPlatformWrapper = styled(Box)({
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    marginTop: "24px !important"
})

export const BrandWrapper = styled(Box)({
    cursor: 'pointer',
    width: "185px",
    height: "120px",
    border: '1px solid #E4E4E4',
    borderRadius: "4px !important",
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
})

export const BrandImage = styled(Box)({
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    width: "46px !important",
    height: "46px !important",
    borderRadius: "20px !important",
    // borderRadius:5
    backgroundColor: 'rgba(54, 70, 172, 0.05)'
})

export const ImageStyled = styled('img')({
    width: "2rem", height: 32
})

export const BusinessTypeHeading = styled(Typography)({
    textAlign: 'center !important',
    fontSize: "1rem !important",
    color: '#000000',
    fontWeight: '500 !important',
    marginTop: "1rem !important",
    fontFamily: 'Helvetica Neue !important'
})

export const PublisherWrapper = styled(Box)({
    cursor: 'pointer',
    marginTop: "2rem !important",
    width: "185px",
    height: "120px",
    border: '1px solid #E4E4E4',
    marginLeft: 'auto !important',
    marginRight: 'auto !important',
    borderRadius: "4px !important ",
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
})