import React from "react";
import { useSelector } from "react-redux";
import { Box, Typography } from "@mui/material";

import {
  ArrowForwardIconComp,
  BreadCrumWrapper,
  GapSpan,
  PathName,
  SettingText,
  SPathName
} from "./Setting.styles";
import { madTechState } from "../../features/madTechSlice";
import "../../App.css"
import { color } from "../../utils/Colors";

const SBreadCrumb = ({ path, pathName, isUsers,ModulepathName, modulePath, state, setState }) => {
  const { selectedAccount } = useSelector(madTechState);

  const handleSettingClick = () => {
    window.location.replace(
      selectedAccount ? `/${path}?accountId=` + selectedAccount.id : `/${path}`
    );
  };

  const handleModuleClick = () => {
    window.location.replace(
      selectedAccount ? `/${modulePath}?accountId=` + selectedAccount.id : `/${modulePath}`
    );
  };

  return (

    <>
    {isUsers ? (
      <>
        <SettingText onClick={handleSettingClick}>Settings</SettingText>
        <Box display="flex">
          <SPathName>
            <ArrowForwardIconComp /> 
            <GapSpan>{pathName}</GapSpan>
          </SPathName>
        </Box>
      </>
    ) : (
      <BreadCrumWrapper>
      <SettingText onClick={handleSettingClick}>Settings</SettingText>
      {ModulepathName && (
        <Box display="flex">
          <SettingText className="margin_style" onClick={handleModuleClick}>
            <ArrowForwardIconComp />{" "}
            {ModulepathName}
          </SettingText>
        </Box>)}
      <Box display="flex">
        <PathName>
          <ArrowForwardIconComp />{" "}
          {pathName}
        </PathName>
        {ModulepathName && !state && (
          <Typography
            sx={{
              fontSize: "0.875rem",
              fontFamily: "Helvetica Neue",
              fontWeight: 500,
              marginTop: "5px",
              marginLeft: "0.625rem",
              letterSpacing: 0.2,
              color: color.buttonColor,
              textDecoration: "underline",
              cursor: "pointer",
            }}
            onClick={() => {
              setState(true);
            }}
          >
            Edit
          </Typography>
        )}
      </Box>
    </BreadCrumWrapper>
    )}
  </>
  );
};

export default SBreadCrumb;
