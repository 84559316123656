import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import './SignIn.css'
import { Strings } from '../../utils/Strings';
import {useSigninUserMutation, useVerifyOtpMutation } from '../../services/query';
import { madTechState, setLoggedin } from '../../features/madTechSlice';
import useDocumentTitle from '../../hook/useDocumentTitle';
import CommanSignIn from '../../components/CommanOAuth/CommanSignIn';


function SignIn() {
  useDocumentTitle('Sign In')

  const { user } = useSelector(madTechState)
  const dispatch = useDispatch()

  const [isOTPSend, setIsOTPSend] = useState(false);

  const [loginError, setLoginError] = useState('')
  const [otpErrorMsg, setOtpError] = useState("");

  const { data, error } = useSigninUserMutation()
  const { data: otpData, error: otpError } = useVerifyOtpMutation()


  useEffect(() => {
    if (data !== undefined && data?.response) {
      // console.log('data', data);
      setIsOTPSend(true);
    }
    if (data !== undefined && !data?.response) {
      setLoginError(data?.statusMessage)
    }
    if (error !== undefined) {
      console.log('error', error);
      setIsOTPSend(false);
    }
  }, [data, error])

  useEffect(() => {
    if (otpData !== undefined) {
      if (otpData?.statusCode === 200) {
        localStorage.setItem("USER", JSON.stringify(user));
        localStorage.setItem("TOKEN", JSON.stringify(user.token));
        if (user.stepPending !== 0) {
          window.location.href = `/completeProfile?u=${user.userId}`
        } else if (user.userType === "platform" || user.userType === "cloud-provider") {
          window.location.href = `/admin/dashboard`
        } else {
          dispatch(setLoggedin("yes"))
        }
      } else {
        setOtpError(otpData?.statusMessage)
        setIsOTPSend(true);
      }
    }
    if (otpError !== undefined) {
      console.log('otpError', otpError);
      setOtpError('OTP not matched')
      setIsOTPSend(true);
    }
  }, [otpData, otpError])
  
 
  return (
    <CommanSignIn 
    classname="login-signin-container"
    LoginText={Strings.login}
    OAuthType={false}
    background="rgb(220,224,226)"
    TextFontSize="2rem"
    ContentFontSize= "16px"
    display="flex"
/>
  );
}

export default SignIn;
