import React from 'react';
import { AverageMonthlyCard, AveragedailyCard, AveragedailyCardHeading, CardHeading, ConnectionCard, Container, CurrentMonthCard, Heading, NoOfValueOfCard, VolumnContainer, VolumnHeading, Wrapper } from "./Dashboard.styles";
import { Box, Typography } from "@mui/material";


import analytics4Img from "../../assets/AnalyticsTab4.svg";
import analytics3Img from "../../assets/AnalyticsTab3.svg";
import analytics2Img from "../../assets/AnalyticsTab2.svg";
import analytics1Img from "../../assets/AnalyticsTab1.svg";


const DashboardCard = ({ analyticsData, timeLineTxt, upArrowImg, downArrowImg, hideAvg }) => {
  return (
    <div>
      <Container>
        {/* connection */}
        <ConnectionCard >
          <Box height="2rem" width="2rem" >
            <img src={analytics1Img} style={{ height: "2rem", width: "2rem" }} />
          </Box>
          <Box sx={{ display: "flex", flexDirection: "column", ml: "1rem" }}>
            <Box display="flex">
              <NoOfValueOfCard>{analyticsData?.connections}  </NoOfValueOfCard>
            </Box>
            <CardHeading>Connections</CardHeading>
          </Box>
        </ConnectionCard>

        {/*  Average daily volume */}
        <AveragedailyCard>
          <Box height="2rem" width="2rem" >
            <img src={analytics2Img} style={{ height: "2rem", width: "2rem" }} />
          </Box>
          <Box display="flex" flexDirection="column" ml="1rem">
            {timeLineTxt !== "Today" ? (
              <Box display="flex" alignItems="center" >
                <AveragedailyCardHeading >
                  {new Intl.NumberFormat("en-US").format(
                    Math.round(analyticsData?.averageDailyVolume)
                  )}
                </AveragedailyCardHeading>
                <Box display="flex" alignItems="center" >
                  <img
                    src={
                      analyticsData?.averageDailyVolumeArrow === "Positive"
                        ? upArrowImg
                        : downArrowImg
                    }
                    style={{
                      height: "0.875rem",
                      width: "0.375rem",
                      marginLeft: "0.625rem",
                      marginRight: "0.3125rem",
                    }}
                  />
                  <Typography
                    sx={{
                      fontWeight: 400,
                      fontFamily: "Helvetica Neue",
                      fontSize: "0.875rem",
                      color: "#3C4049",
                    }}
                  >
                    {analyticsData?.averageDailyVolumePer &&
                      analyticsData?.averageDailyVolumePer !== "NaN" &&
                      analyticsData?.averageDailyVolumePer !== "Infinity" &&
                      analyticsData?.averageDailyVolumePer < 101
                      ? Math.round(
                        (analyticsData?.averageDailyVolumePer +
                          Number.EPSILON) *
                        100
                      ) /
                      100 +
                      "%"
                      : "0.0%"}
                  </Typography>
                </Box>
              </Box>
            ) : (
              <Box display="flex" alignItems="center">
                <Typography
                  sx={{
                    fontWeight: 700,
                    fontFamily: "Helvetica Neue",
                    fontSize: "1.5rem",
                    color: "#25262D",
                  }}
                >
                  NA
                </Typography>
              </Box>
            )}
            <CardHeading> Average daily volume </CardHeading>
          </Box>
        </AveragedailyCard>

        {/* Average monthly volume */}
        <AverageMonthlyCard>
          <Box sx={{ height: "2rem", width: "2rem" }}>
            <img
              src={analytics3Img}
              style={{ height: "2rem", width: "2rem" }}
            />
          </Box>
          <Box
            sx={{ display: "flex", flexDirection: "column", ml: "1rem" }}
          >
            {timeLineTxt !== "Today" &&
              timeLineTxt !== "Last 7 Days" &&
              timeLineTxt !== "Last 30 Days" &&
              timeLineTxt !== "Month to Date" &&
              !hideAvg ? (
              <Box sx={{ display: "flex", flexDirection: "row", alignItems: "center", }} >
                <NoOfValueOfCard>
                  {new Intl.NumberFormat("en-US").format(
                    Math.round(analyticsData?.averageMonthlyVolume)
                  )}
                </NoOfValueOfCard >
                <Box sx={{ display: "flex", flexDirection: "row", alignItems: "center", }}>
                  <img
                    src={
                      analyticsData?.averageMonthlyVolumeArrow ===
                        "Positive"
                        ? upArrowImg
                        : downArrowImg
                    }
                    style={{
                      height: "0.875rem",
                      width: "0.675rem",
                      marginLeft: "0.625rem",
                      marginRight: "0.3125rem",
                    }}
                  />
                  <Typography
                    sx={{
                      fontWeight: 400,
                      fontFamily: "Helvetica Neue",
                      fontSize: "0.875rem",
                      color: "#3C4049",
                    }}
                  >
                    {analyticsData?.averageMonthlyVolumePer &&
                      analyticsData?.averageMonthlyVolumePer !== "NaN" &&
                      analyticsData?.averageDailyVolumePer !== "Infinity" &&
                      analyticsData?.averageDailyVolumePer < 101
                      ? Math.round(
                        (analyticsData?.averageMonthlyVolumePer +
                          Number.EPSILON) *
                        100
                      ) /
                      100 +
                      "%"
                      : "0.0%"}
                  </Typography>
                </Box>
              </Box>
            ) : (
              <Box display="flex" flexDirection="row" alignItems="center">
                <NoOfValueOfCard>
                  NA
                </NoOfValueOfCard>
              </Box>
            )}
            <CardHeading>Average monthly volume </CardHeading>
          </Box>
        </AverageMonthlyCard>

        {/* Current month volume */}
        <CurrentMonthCard>
          <Box height="2rem" width="2rem" >
            <img
              src={analytics4Img}
              style={{ height: "2rem", width: "2rem" }}
            />
          </Box>
          <Box display="flex" flexDirection="column" ml="1rem" >
            <Box display="flex" alignItems="center" >
              <NoOfValueOfCard>
                {new Intl.NumberFormat("en-US").format(
                  Math.round(analyticsData?.currentMonthlyVolume)
                )}
              </NoOfValueOfCard>
            </Box>

            <CardHeading>Current month volume </CardHeading>
          </Box>
        </CurrentMonthCard>

      </Container>
    </div>
  );
}

export default DashboardCard;
