import React, { createContext, useContext, useState } from 'react';

// Create a context with an initial value
const MyContext2 = createContext();

// Create a provider component to wrap your app
export const MyContextProvider2 = ({ children }) => {
    const [responseInvalid, setResponseInvalid] = useState(false);


    // Value to be provided to the context
    const contextValue = {
        responseInvalid, setResponseInvalid
    };

    return <MyContext2.Provider value={contextValue}>{children}</MyContext2.Provider>;
};

// Custom hook to consume the context
export const useMyContext2 = () => {
    return useContext(MyContext2);
};