import { Box, Typography, useMediaQuery } from "@mui/material";
import React from "react"; 
import useDocumentTitle from "../../hook/useDocumentTitle";
import { color } from "../../utils/Colors";
import PolicyHeader from "./policyHeader";
import ConsumerRights from "./CounsumerRights";
import PersonalInformation from "./personalInformation";
import PrivacyTable from "./privacyTable"; 

const PrivacyPolicy = () => {
  useDocumentTitle("Privacy Policy");
  const matcheslg = useMediaQuery("(min-width:1100px)");
  const matchesmd = useMediaQuery("(min-width:980px)");
  const matchessm = useMediaQuery("(min-width:880px)");
  const matchesxs = useMediaQuery("(min-width:760px)");

  return (
    <Box mx="auto">
      <Box py={6} display="flex" flexDirection="column" width="100%" backgroundColor={color.themeColor} justifyContent="center" alignItems="center">
        <Box width="91%" maxWidth={1350}>
          <Typography sx={{
            color: "#fff", fontWeight: 400, fontFamily: "Phosphate",
            fontSize: matcheslg ? "2.5rem" : matchesmd ? "2.3rem" : matchessm ? "2rem" : matchesxs ? "1.8rem" : "1.6rem", letterSpacing: 0.2,
          }}>Privacy policy </Typography>
          <Typography sx={{
            width: "60%", textAlign: "left", mt: "2rem", color: "#fff", fontWeight: 500, fontFamily: "Helvetica Neue",
            fontSize: matcheslg ? "1rem" : matchesmd ? "0.9rem" : matchessm ? "0.8rem" : matchesxs ? "0.7rem" : "0.7rem", letterSpacing: 0.2,
          }}>
            Last Updated: 07 Oct, 2024
          </Typography>
        </Box>
      </Box>
      <iframe src={'/platforms/MadTech_Privacy_Policy_10_07_24.pdf'} style={{width: "100vw", height: "1000px"}}/>
      {/* <Worker workerUrl="/pdf.worker.min.js">
                <Viewer
                    fileUrl='/platforms/MadTech_Privacy_Policy_10_07_24.pdf'
                    plugins={[
                    // Register plugins
                    ]}
                />
      </Worker> */}
    </Box>
  );
};

export default PrivacyPolicy;
