import React, { useEffect, useState } from "react";
import { Box } from "@mui/material";

import ConnectionListRight from "./ConnectionListRight";
import ConnectionListLeft from "./ConnectionListLeft";
import {
  TabsHeading,
  countConnectionsByStatus,
  shouldHideBox,
} from "../Constant";
import { useGetConditionConfirmationMutation } from "../../../../services/query";
import { useMyContext } from "../../../../components/CommanToasterContext/toast";
import {
  TabHeading,
  TabHeadingCount,
  TabStyled,
} from "../Styles/MyConnection.styles";
import { TabsWrapper } from "../Styles/ConnectionList.styles";
// import {rightContainerResponse as conditionData } from '../dumyData'

const ConnectList = ({
  connectors,
  refetch,
  connectionLoading,
  setConnectors,
  connectionLoader,
  setIsStatusChange,
  activeTab,
  setActiveTab,
}) => {
  const { setOpen2, setMessage2 } = useMyContext();

  const [
    conditionCheck,
    { 
       data: conditionData, 
       isLoading: conditionLoading, 
      error: conditionError },
  ] = useGetConditionConfirmationMutation();

  const [tableDetails, setTableDetails] = useState([]);
  const [rowSelectIndex, setRowSelectIndex] = useState(0);
  const [selectedConnection, setSelectedConnection] = useState({});
  const [checkBoxLists, setCheckBoxLists] = useState();
  const [destinationCheckbox, setDestinationCheckbox] = useState({});
  const [sourceCheckbox, setSourceCheckbox] = useState({});

  const getDataForActiveTab = (activeTab) => {
    const filteredData = connectors.filter(
      (connection) => connection.status === activeTab
    );
    return filteredData;
  };

  useEffect(() => {
    const dataForActiveTab = getDataForActiveTab(activeTab);
    setTableDetails(dataForActiveTab);

    setDestinationCheckbox({})
    setSourceCheckbox({})
    if (activeTab === "In-progress" || activeTab === "Offline") {
      const item = connectors?.find((item) => item.status === activeTab);
      if (item) {
        const conId = item.conId;
        conditionCheck({ connectionId: conId });
        setSelectedConnection(item);
      } else {
        console.log(`No item found with status '${activeTab}'`);
      }
    }
  }, [activeTab, connectors]);

  useEffect(() => {
    if (conditionData !== undefined) {
      if (conditionData?.statusCode === 200) {
        setCheckBoxLists(conditionData?.response);
      } else {
        setMessage2(conditionData?.statusMessage);
        setOpen2(true);
      }
    }

    if (conditionError !== undefined) {
      console.log("add Connection Error", conditionError);
      // setMessage2(conditionError.data.error)
      // setOpen2(true)
    }
  }, [conditionData, conditionError]);

  const handleChangeTab = (event, newValue) => {
    setActiveTab(newValue);
  };

  const handleRowChange = (item, index) => {
    if (activeTab === "In-progress" || activeTab === "Offline") {
      setDestinationCheckbox({})
      setSourceCheckbox({})
      setRowSelectIndex(index);
      setSelectedConnection(item);
      conditionCheck({ connectionId: item.conId });
    }
  };

  return (
    <>
      <Box width="100%" display="flex" mt={2}>
        <Box>
          <TabsWrapper
            value={activeTab}
            onChange={handleChangeTab}
            sx={{
              border: "1px solid rgba(234, 237, 246, 1)",
              borderRadius: "8px",
            }}
          >
            {TabsHeading?.map((status, index) => (
              <TabStyled
                key={status}
                value={status}
                label={
                  <div style={{ display: "flex", gap: "10px" }}>
                    <TabHeading>{status}</TabHeading>
                    <TabHeadingCount same={activeTab === status}>
                      {countConnectionsByStatus(connectors, status)}
                    </TabHeadingCount>
                  </div>
                }
                condition={index < TabsHeading?.length - 1}
              />
            ))}
          </TabsWrapper>
        </Box>
      </Box>

      <Box hidden={shouldHideBox(activeTab)} width="100%" display="flex" mt={2}>
        <ConnectionListLeft
          activeTab={activeTab}
          handleRowChange={handleRowChange}
          rowSelectIndex={rowSelectIndex}
          tableDetails={tableDetails}
          connectors={connectors}
          refetch={refetch}
          setConnectors={setConnectors}
          connectionLoader={connectionLoader}
          setIsStatusChange={setIsStatusChange}
          setActiveTab={setActiveTab}
        />
        <ConnectionListRight
          activeTab={activeTab}
          conditionLoading={conditionLoading}
          selectedConnection={selectedConnection}
          checkBoxLists={checkBoxLists}
          connectionLoading={connectionLoading}
          refetch={refetch}
          tableDetails={tableDetails}
          setSourceCheckbox={setSourceCheckbox}
          setDestinationCheckbox={setDestinationCheckbox}
          sourceCheckbox={sourceCheckbox}
          destinationCheckbox={destinationCheckbox}
          setActiveTab={setActiveTab}
        />
      </Box>
    </>
  );
};

export default ConnectList;
