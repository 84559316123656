import React from 'react'
import PropTypes from 'prop-types';
import styled from '@emotion/styled';
import { color } from '../../utils/Colors';

const RootDiv = styled('div')({
    // Define your styles here
    display: 'inline-block',
    width: '200px',
    position: 'relative',
});

const Label = styled('label')({
    // Define your label styles here
    position: 'absolute',
    top: 0,
    left: 14,
    transform: 'translateY(-50%)',
    backgroundColor: '#fff',
    padding: '0 4px',
    fontSize: '0.875rem',
    color: '#66696A',
    whiteSpace: 'nowrap', // Fix label width and prevent wrapping
    overflow: 'hidden', // Hide overflowing content
    background: "white",
    zIndex: 99,
});

const FieldDiv = styled('div')({
    // Define your field styles here
    width: '100%',
    padding: '10px 14px',
    borderRadius: '10px',
    border: '1px solid #EAEDF6',
    fontSize: '1rem',
    fontFamily: 'Helvetica',
    fontWeight: 500,
    boxSizing: 'border-box',
    position: 'relative',
    color: '#66696A',
});
export const DownloadImg = () => {
    // const { themeName } = useThemeContext();
    // const Theme = color[themeName];

    return (
        <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M21 15V19C21 19.5304 20.7893 20.0391 20.4142 20.4142C20.0391 20.7893 19.5304 21 19 21H5C4.46957 21 3.96086 20.7893 3.58579 20.4142C3.21071 20.0391 3 19.5304 3 19V15" stroke={color.themeColor} stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
            <path d="M7 10L12 15L17 10" stroke={color.themeColor} stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
            <path d="M12 15V3" stroke={color.themeColor} stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
        </svg>


    );
};

export const ReportLogo = () => {
    // const { themeName } = useThemeContext();
    // const Theme = color[themeName];
    const cls_1 = {
        opacity: ".97",
        fill: color.themeGray2,
        fillRule: "evenodd",
        isolation: "isolate"
    }
    const cls_2 = {
        opacity: ".93",
        fill: color.themeGray2,
        fillRule: "evenodd",
        isolation: "isolate"
    }
    const cls_3 = {
        opacity: ".96",
        fill: color.themeGray2,
        fillRule: "evenodd",
        isolation: "isolate"
    }
    const cls_4 = {
        opacity: ".96",
        fill: color.themeGray2,
        fillRule: "evenodd",
        isolation: "isolate"
    }
    const cls_5 = {
        opacity: ".95",
        fill: color.themeGray2,
        fillRule: "evenodd",
        isolation: "isolate"
    }
    const cls_6 = {
        opacity: ".97",
        fill: color.themeGray2,
        fillRule: "evenodd",
        isolation: "isolate"
    }
    const cls_7 = {
        opacity: ".98",
        fill: " #193241",
        fillRule: "evenodd",
        isolation: "isolate"
    }
    return (
        <svg id="Layer_1" width="24" height="24" data-name="Layer 1" style={{ marginRight: "10px" }} xmlns="http://www.w3.org/2000/svg" viewBox="0 0 490.06 512">
            <path class="cls_6" style={cls_6} d="m54.06,0h301c32.42,8.43,50.08,29.43,53,63-2,30.65-17.33,51.32-46,62-1,129-1.33,258-1,387H62.06v-29H.06C-.1,340.33.06,197.67.56,55,6.77,25.29,24.61,6.96,54.06,0Zm9,30c21.36,1.19,33.02,12.36,35,33.5-2.11,21.88-14.11,32.38-36,31.5v357h-31c-.17-131.33,0-262.67.5-394,4.44-16.27,14.94-25.61,31.5-28Zm57,1c75.17-1.17,150.5-1.33,226-.5,24.28,5.39,33.78,20.22,28.5,44.5-3.83,9.17-10.33,15.67-19.5,19.5-78,.67-156,.67-234,0,8.36-15.73,10.19-32.23,5.5-49.5-1.4-5.12-3.56-9.78-6.5-14Zm-27,95h237v356H93.06V126Zm112,61c21.22-1.23,36.56,7.61,46,26.5-11.33.67-22.67.67-34,0-8-7.33-16-14.67-24-22,4-1.82,8-3.32,12-4.5Zm-34,28c2.1.91,4.1,2.08,6,3.5,9.33,8.67,18.67,17.33,28,26,15.33.33,30.67.67,46,1-12.31,23.1-30.98,30.77-56,23-21.72-11.61-29.72-29.44-24-53.5Z" />
            <path class="cls_1" style={cls_1} d="m453.06,512h-20c-21.31-6.49-33.48-20.82-36.5-43-.67-93-.67-186,0-279,.7-12.42,4.03-24.09,10-35,11.63-15.46,23.8-30.46,36.5-45,12.75,13.58,24.58,27.91,35.5,43,5.86,9.92,9.53,20.58,11,32,.67,96,.67,192,0,288-4.13,20.63-16.3,33.63-36.5,39Zm-11-353c9.29,6.88,14.79,16.22,16.5,28,.5,58.33.67,116.67.5,175h-32c-.17-57.33,0-114.67.5-172,1.62-11.91,6.46-22.24,14.5-31Zm-15,234h32v22h-32v-22Zm0,53h32c.17,8.01,0,16.01-.5,24-5.24,11.35-13.4,14.18-24.5,8.5-2.21-1.87-4.04-4.04-5.5-6.5-1.38-8.6-1.88-17.27-1.5-26Z" />
            <path class="cls_3" style={cls_3} d="m203.06,156c38.19-1.28,64.36,15.72,78.5,51,9.01,37.79-2.82,66.95-35.5,87.5-32.76,14.61-62.26,9.77-88.5-14.5-19.8-23.57-24.46-49.91-14-79,11.73-25.78,31.57-40.78,59.5-45Zm2,31c-4,1.18-8,2.68-12,4.5,8,7.33,16,14.67,24,22,11.33.67,22.67.67,34,0-9.44-18.89-24.78-27.73-46-26.5Zm-34,28c-5.72,24.06,2.28,41.89,24,53.5,25.02,7.77,43.69.1,56-23-15.33-.33-30.67-.67-46-1-9.33-8.67-18.67-17.33-28-26-1.9-1.42-3.9-2.59-6-3.5Z" />
            <path class="cls_4" style={cls_4} d="m130.06,337h31v30h-31v-30Z" />
            <path class="cls_7" style={cls_7} d="m192.06,337h101v30h-101v-30Z" />
            <path class="cls_2" style={cls_2} d="m130.06,396h31v31h-31v-31Z" />
            <path class="cls_5" style={cls_5} d="m192.06,396h101v31h-101v-31Z" />
        </svg>
    );
};

export const ReportLogoSolid = () => {

    const cls_1 = {
        fill: color.themeColor,
        opacity: ".97",
        fillRule: "evenodd",
        isolation: "isolate"
    }
    const cls_2 = {
        opacity: ".92",
        fill: color.themeColor,
        fillRule: "evenodd",
        isolation: "isolate"
    }
    const cls_3 = {
        opacity: ".99",
        fill: color.themeColor,
        fillRule: "evenodd",
        isolation: "isolate"
    }
    const cls_4 = {
        opacity: ".94",
        fill: color.themeColor,
        fillRule: "evenodd",
        isolation: "isolate"
    }
    const cls_5 = {
        opacity: ".98",
        fill: color.themeColor,
        fillRule: "evenodd",
        isolation: "isolate"
    }
    const cls_6 = {
        opacity: ".99",
        fill: color.themeColor,
        fillRule: "evenodd",
        isolation: "isolate"
    }
    const cls_7 = {
        opacity: ".95",
        fill: color.themeColor,
        fillRule: "evenodd",
        isolation: "isolate"
    }
    const cls_8 = {
        opacity: ".97",
        fill: color.themeColor,
        fillRule: "evenodd",
        isolation: "isolate"
    }
    return (
        <svg id="Layer_1" data-name="Layer 1" xmlns="http://www.w3.org/2000/svg" style={{ marginRight: "10px" }} width="24" height="24" viewBox="0 0 474.23 512">

            <path style={cls_8} class="cls_8" d="m33.06,0h18c28.39,11.3,36.89,31.3,25.5,60-7.95,13.56-19.78,19.56-35.5,18v402H.06C-.1,331.67.06,183.33.56,35,4.02,16.38,14.86,4.71,33.06,0Z" />
            <path style={cls_6} class="cls_6" d="m99.06,0h265c26.34,9.34,35.84,27.67,28.5,55-4.98,10.98-13.15,18.48-24.5,22.5-89.33.83-178.67.67-268-.5,14.88-25.9,14.54-51.57-1-77Z" />
            <path style={cls_3} class="cls_3" d="m342.06,512H70.06V107h272v405ZM195.06,157c42.06-2.57,69.56,16.1,82.5,56,5.41,37.36-8.43,64.86-41.5,82.5-38.04,13.21-68.54,3.37-91.5-29.5-17.1-34.01-12.27-64.51,14.5-91.5,10.62-8.81,22.62-14.64,36-17.5Zm-69,180h29v29h-29v-29Zm63,0h98v29h-98v-29Zm-63,60h29v29h-29v-29Zm63,0h98v29h-98v-29Z" />
            <path style={cls_5} class="cls_5" d="m438.06,164c1.87.36,3.37,1.36,4.5,3,6.62,8.96,13.62,17.62,21,26,4.36,6.4,7.36,13.4,9,21,1.47,45.97,1.97,91.97,1.5,138h-70c-.47-46.37.03-92.7,1.5-139,1.5-6,3.83-11.67,7-17,8.27-10.88,16.77-21.55,25.5-32Z" />
            <path style={cls_2} class="cls_2" d="m197.06,186c23.89-2.89,40.89,6.44,51,28-11.99,1.17-23.99,1.33-36,.5-8.17-7.5-16.33-15-24.5-22.5-.62-.95-.45-1.78.5-2.5,3.22-.9,6.22-2.07,9-3.5Z" />
            <path style={cls_7} class="cls_7" d="m166.06,212c12.07,9.9,23.74,20.4,35,31.5,15.67.33,31.33.67,47,1-12.45,24.22-31.78,32.56-58,25-23.46-12.53-31.46-31.69-24-57.5Z" />
            <path style={cls_4} class="cls_4" d="m404.06,381h70v36h-70v-36Z" />
            <path style={cls_1} class="cls_1" d="m447.06,512h-16c-14.87-4.04-23.7-13.71-26.5-29-.5-12.33-.67-24.66-.5-37h70c.17,12.01,0,24.01-.5,36-3.07,15.23-11.9,25.23-26.5,30Z" />
        </svg>

    );
};

function TextFields({ value, lable, fontSize = "14px" }) {
    return (
        <>
            <RootDiv>
                <Label>{lable}</Label>
                <FieldDiv tabIndex={-1} disabled={true}>
                    {value}
                </FieldDiv>
            </RootDiv>
        </>
    )
}
TextFields.propTypes = {
    register: PropTypes.func.isRequired, // Ensure register is passed as a prop
};


export default TextFields