import { Box, FormControl, InputLabel, OutlinedInput, Typography } from "@mui/material";
import styled from "styled-components";

export const StyledBox = styled(Box)({
    margin: 'auto !important',
    display: 'flex !important',
    flexDirection: 'column !important',
    backgroundColor: '#fff !important',
    width: '100% !important',
    alignItems: 'center !important',
    justifyContent: 'center !important',
    borderRadius: '4px !important ',
    boxShadow: 'rgba(0, 0, 0, 0.06) 0px 8px 20px !important',
});
export const StyledBoxContainer = styled(Box)({
    width: '100% !important',        // Ensuring only one width is specified
    marginTop: '40px !important',
    margin: '40px !important',   // Converted mt to marginTop
    // Converted mt to marginTop
    display: 'flex !important',
    flexDirection: 'column !important',
    alignItems: 'center !important',
    marginX: 'auto !important',     // Using mx for horizontal margin
    justifyContent: 'center !important',
});