import React, { useCallback } from "react";
// import moment from "moment";
import Box from "@mui/material/Box";
import CircularProgress from "@mui/material/CircularProgress";
import Paper from "@mui/material/Paper";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TablePagination from "@mui/material/TablePagination";
import TableRow from "@mui/material/TableRow";
import Tooltip from "@mui/material/Tooltip";
import Typography from "@mui/material/Typography";
import noConfigImg from "../../assets/Illustrations.svg";
// import { DownloadImg } from "../../components/CoustomIcons";
// import { ReportTableCell, StyledLoaderBoxContain } from "../../components/StyledComponents/CommonStyledComponents";
// import { ConnectionTableCell } from "../Dashboard/dashboardStyled";
import { ConnectionTableCell, ReportTableCell, ReportTableCellValue, StyledLoaderBoxContain, TableNameTypography } from "../../components/AppLayout/AppLayout.styles";
import { color } from "../../utils/Colors";
import { DownloadImg } from "../../components/CommanFields/TextFields";
// import { ReportTableCellValue, TableNameTypography } from "../../components/StyledComponents/CommanTableStyledComponents";

const ReportTable = ({
    rows,
    rowsPerPage,
    page,
    handleChangePage,
    handleChangeRowsPerPage,
    searchValue,
    requestSearch,
    reportsLoading,
    _downloadReport,
    // Theme
}) => {

    return (
        <Box display="flex" flexDirection="column" justifyContent="space-between" width="100%" mx="auto" mb="20px">
            {reportsLoading || rows === null ? (
                <StyledLoaderBoxContain height="75vh">
                    <CircularProgress size={45} thickness={8} style={{ color: color.LoadingColor }}></CircularProgress>
                </StyledLoaderBoxContain>
            ) : (
                <Paper sx={{ width: "100%", overflow: "hidden", borderRadius: "8px" }}>
                    <TableContainer>
                        <Table stickyHeader>
                            <TableHead>
                                <TableRow>
                                    <TablePagination
                                        rowsPerPageOptions={[5, 10, 25, 50]}
                                        count={rows.length}
                                        rowsPerPage={rowsPerPage}
                                        page={page}
                                        SelectProps={{
                                            inputProps: { "aria-label": "accounts per page" },
                                            native: true,
                                        }}
                                        onPageChange={handleChangePage}
                                        onRowsPerPageChange={handleChangeRowsPerPage}
                                    />
                                </TableRow>
                                <TableRow>
                                    <TableCell sx={{ fontWeight: 700, backgroundColor: "rgba(25, 50, 65, 0.15)", }}>Connection</TableCell>
                                    {/* <ReportTableCell>Connections</ReportTableCell> */}
                                    <TableCell sx={{ fontWeight: 700, backgroundColor: "rgba(25, 50, 65, 0.15)", }}> Average daily volume</TableCell>
                                    <TableCell sx={{ fontWeight: 700, backgroundColor: "rgba(25, 50, 65, 0.15)", }}> Average monthly volume</TableCell>
                                    <TableCell sx={{ fontWeight: 700, backgroundColor: "rgba(25, 50, 65, 0.15)", }}> Total volume (rows)</TableCell>
                                    <TableCell sx={{ fontWeight: 700, backgroundColor: "rgba(25, 50, 65, 0.15)", }}> Total volume (bytes)</TableCell>
                                    <TableCell sx={{ fontWeight: 700, backgroundColor: "rgba(25, 50, 65, 0.15)", justifyContent: "center", display: "flex" }}>Actions</TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {(rowsPerPage > 0 ? rows.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage) : rows).map((row) => (
                                    <TableRow
                                        key={row.accountId}
                                        sx={{ "&:last-child td, &:last-child th": { border: 0 }, "&:nth-child(even)": { background: "rgba(25, 50, 65, 0.05)", }, }}
                                    >
                                        <TableCell sx={{ p: "0.8125rem 1rem 0.8125rem 1rem" }}>
                                            <Box sx={{ display: "flex", flexDirection: "column", justifyContent: "space-between" }}>
                                                <TableNameTypography
                                                // onClick={() => { window.open("/dashboard?accountId=" + row.accountId); }}
                                                >
                                                    {row.connectionName}
                                                </TableNameTypography>
                                                <Typography fontSize="0.875rem" fontFamily="Helvetica Neue" color="rgb(102, 102, 102)">
                                                    {row.connectionId}
                                                </Typography>
                                            </Box>
                                        </TableCell>
                                        {/* <TableCell padding="0.8125rem 1rem 0.8125rem 1rem" component="th" scope="row">
                                            <Box textAlign="center">
                                                <Typography fontSize="0.875rem" fontFamily="Helvetica Neue" color="rgb(102, 102, 102)">
                                                    {row.connections}
                                                </Typography>
                                            </Box>
                                        </TableCell> */}
                                        <ReportTableCellValue>
                                            {isNaN(row.avgDailyVolume) ? 0 : new Intl.NumberFormat("en-US").format(Math.round(row.avgDailyVolume))}
                                        </ReportTableCellValue>
                                        <ReportTableCellValue>
                                            {isNaN(row.avgMonthlyVolume) ? 0 : new Intl.NumberFormat("en-US").format(Math.round(row.avgMonthlyVolume))}
                                        </ReportTableCellValue>
                                        <ReportTableCellValue>
                                            {isNaN(row.totalVolume) ? 0 : new Intl.NumberFormat("en-US").format(Math.round(row.totalVolume))}
                                        </ReportTableCellValue>
                                        <ReportTableCellValue>
                                            {isNaN(row.totalVolumeBytes) ? 0 : new Intl.NumberFormat("en-US").format(Math.round(row.totalVolumeBytes))}
                                        </ReportTableCellValue>
                                        <ReportTableCellValue>
                                            <Box display="flex" flexDirection="row" gap={3} justifyContent="center">
                                                <Tooltip title="Download" placement="top">
                                                    <Box sx={{ cursor: "pointer" }} width={17} height={17} marginBottom="5px" onClick={() => { _downloadReport(row.connectionId); }}>
                                                        <DownloadImg />
                                                    </Box>
                                                </Tooltip>
                                            </Box>
                                        </ReportTableCellValue>
                                    </TableRow>
                                ))}
                                {rows.length === 0 && (
                                    <TableRow>
                                        <TableCell sx={{ textAlign: "center" }} colSpan={8}>
                                            <Box display={"flex"} justifyContent={"center"} alignItems={"center"} paddingBottom={4}>
                                                <Box display="flex" flexDirection="column" justifyContent="center" alignItems="center" marginRight={4}>
                                                    <Box width="11.875rem" height="11.875rem">
                                                        <img src={noConfigImg} style={{ maxWidth: "100%", maxHeight: "100%" }} />
                                                    </Box>
                                                    <Typography
                                                        sx={{
                                                            color: "#25262D",
                                                            textAlign: "center",
                                                            fontSize: "1rem",
                                                            fontWeight: 500,
                                                            fontFamily: "Helvetica Neue",
                                                        }}
                                                    >
                                                        No reports available!
                                                    </Typography>
                                                </Box>
                                            </Box>
                                        </TableCell>
                                    </TableRow>
                                )}
                            </TableBody>
                        </Table>
                    </TableContainer>
                </Paper>
            )}
        </Box>
    );
};

export default ReportTable;
