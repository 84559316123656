import React from 'react'
import { Box, useMediaQuery } from '@mui/material'
import { useDispatch, useSelector } from 'react-redux'
import { useNavigate } from 'react-router-dom'

import { madTechState, setIsPlatformContact } from '../../features/madTechSlice'
import { LetsConnectBoxContainer, LetsConnectBtnText, LetsConnectDescription, LetsConnectHeading, LetsConnectSuperContainer } from './Home.styles'


const LetsConnect = ({ isLearnmoreScreen }) => {

    const dispatch = useDispatch()
    const navigate = useNavigate()

    const { isGetStarted } = useSelector(madTechState)

    const matcheslg = useMediaQuery('(min-width:1100px)');
    const matchesmd = useMediaQuery('(min-width:980px)');
    const matchessm = useMediaQuery('(min-width:880px)');
    const matchesxs = useMediaQuery('(min-width:760px)');
    const matchesxss = useMediaQuery('(min-width:600px)');

    return (
        <LetsConnectSuperContainer  py={matchesxss ? 10 : 3} >
            <Box width='90%' maxWidth={1360}>
                <LetsConnectHeading fontSize={matcheslg ? '2.5rem' : matchesmd ? '2.3rem' : matchessm ? '2rem' : matchesxs ? '1.8rem' : '1.6rem'}>
                    {isLearnmoreScreen !== undefined ? 'Still have questions?' : 'Let’s connect'}
                </LetsConnectHeading>
                <LetsConnectDescription width= {isLearnmoreScreen !== undefined ? '60%' : '40%'}>
                    {isLearnmoreScreen !== undefined ? 'Please get in touch with our team -- we are happy to help.' :
                        'Get started and sign up in minutes. Have questions? Connect with our team to learn more.'}

                </LetsConnectDescription>
                {isLearnmoreScreen !== undefined ?
                    <Box mt="2rem" display='flex' flexDirection='row' >
                        <LetsConnectBoxContainer
                        matchesxs={matchesxs}
                        matchesxss={matchesxss}
                        onClick={() => {
                            dispatch(setIsPlatformContact(false))
                            navigate('/contact')
                        }}>
                            <LetsConnectBtnText>
                                Contact Us
                            </LetsConnectBtnText>
                        </LetsConnectBoxContainer>
                    </Box>
                    :
                    <Box mt="2rem" display='flex' flexDirection='row' >
                        <LetsConnectBoxContainer
                        matchesxs={matchesxs}
                        matchesxss={matchesxss}
                        
                        onClick={() => {
                            if (!isGetStarted) {
                                dispatch(setIsPlatformContact(false))
                                navigate('/contact')
                            }
                        }}>
                            <LetsConnectBtnText >
                                Contact Us
                            </LetsConnectBtnText>
                        </LetsConnectBoxContainer>
                    </Box>
                }
            </Box>
        </LetsConnectSuperContainer>
    )
}

export default LetsConnect