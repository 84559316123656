import { Box, useMediaQuery } from '@mui/material'
import React from 'react'

// import madSourceDestImg from '../../assets/MadSourceDest.png'
import madSourceDestImg from '../../assets/MadSourceDest2.png'
import { HoItContainer, HoItHeading, HoItSubHeading } from './Home.styles'
import { getFontSize } from '../../utils/utils'


const HoItWorks = () => {

    const matches = {
        lg: useMediaQuery('(min-width:1100px)'),
        md: useMediaQuery('(min-width:980px)'),
        sm: useMediaQuery('(min-width: 880px)'),
        xs: useMediaQuery('(min-width: 760px)'),
      };

    return (
        <HoItContainer>
            <HoItHeading fontSize={getFontSize(matches, {lg: '2.5rem', md: '2.2rem', sm: '1.9rem', xs: '1.7rem'})} >
                 How MadConnect works
            </HoItHeading>
            <HoItSubHeading  mt="1rem" >
                 Log in to the MadConnect UI to add a multitude of destinations.
            </HoItSubHeading>
            <HoItSubHeading>
                Connecting once will turn on multiple connections to other platforms.
            </HoItSubHeading>
            <Box display='flex'  justifyContent='center' width='100%' my={5}>
                <img style={{ width: '90%', maxWidth: 1060, height: '50%' }} alt={'HOW IT WORKS'} src={madSourceDestImg} />
            </Box>
        </HoItContainer>
    )
}

export default HoItWorks