import React from 'react'
import { useDispatch, useSelector } from 'react-redux';
import { useEffect } from 'react';

// import step2 from '../../assets/step_2.svg';
// import step1 from '../../assets/step_1.svg';
// import step3 from '../../assets/step_3.svg';
import Frame1 from '../../assets/Frame1.svg';
import Frame2 from '../../assets/Frame2.svg';
import Frame3 from '../../assets/Frame3.svg';
import Frame4 from '../../assets/Frame4.svg';
import Frame5 from '../../assets/Frame5.svg';
import PlatformStep1 from '../../assets/PlatformStep1.svg';
import PlatformStep2 from '../../assets/PlatformStep2.svg';
import PlatformStep3 from '../../assets/PlatformStep3.svg';
// import Frame6 from '../../assets/Frame6.svg';
// import Frame7 from '../../assets/Frame7.svg';
// import Frame8 from '../../assets/Frame8.svg';
// import Frame9 from '../../assets/Frame9.svg';
// import Frame10 from '../../assets/Frame10.svg';
// import Frame11 from '../../assets/Frame11.svg';
import { madTechState, setIsHomeNav } from '../../features/madTechSlice';
import Navbar from '../../components/Navbar/Navbar';
import { color } from '../../utils/Colors';

const Stepper = ({ step, userType, children }) => { 
    const dispatch = useDispatch()
    const { isHomeNav } = useSelector(madTechState)
    // console.log('userType', userType);

    useEffect(() => {
        if (isHomeNav) {
            dispatch(setIsHomeNav())
        }
    }, [isHomeNav])
    // if (!userType) {
    //     return <>{children}</>
    // }
    return (
        <div className='container-fluid p-0' style={{ height: "100vh", overflowY: "auto" }}>
            <Navbar />
            <div style={{
                height: 150, width: '100%', backgroundColor: color.themeColor,
                display: "flex", justifyContent: "center", alignItems: "center"
            }}>
                {userType === "platform" ?
                    <img alt='' src={step === 1 ? PlatformStep1 : step === 2 ? PlatformStep2 : PlatformStep3}
                        style={{ height: 70, width: 700 }}></img>
                    :
                    <img alt='' src={step === 1 ? Frame1 : step === 2 ? Frame2 : step === 3 ? Frame3 : step === 4 ? Frame4 : step === 6 ? Frame4 : Frame5}
                        style={{ height: 70, width: 700 }}></img>
                }
                {/* <img alt='' src={step === 1 ?
                    userType === "platform" ?
                        Frame6 : Frame11 :
                    step === 2 ?
                        userType === "platform" ?
                            Frame9 : Frame7
                        : userType === "platform" ?
                            Frame10 : Frame8}
                    style={{ height: 70, width: 700 }}></img> */}
            </div>
            {children}
        </div>
    )
}

export default Stepper