import {
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableRow,
  Typography,
} from "@mui/material";
import React from "react";

const MandatoryClauses = () => {
  return (
    <>
      <Typography variant="h6" gutterBottom>
        Entering into this Addendum
      </Typography>

      <Typography variant="body1" paragraph>
        Each Party agrees to be bound by the terms and conditions set out in
        this Addendum, in exchange for the other Party also agreeing to be bound
        by this Addendum.
      </Typography>

      <Typography variant="body1" paragraph>
        Although Annex 1A and Clause 7 of the Approved EU SCCs require signature
        by the Parties, for the purpose of making Restricted Transfers, the
        Parties may enter into this Addendum in any way that makes them legally
        binding on the Parties and allows data subjects to enforce their rights
        as set out in this Addendum. Entering into this Addendum will have the
        same effect as signing the Approved EU SCCs and any part of the Approved
        EU SCCs.
      </Typography>

      <Typography variant="h6" gutterBottom>
        Interpretation of this Addendum
      </Typography>

      <Typography variant="body1" paragraph sx={{ mb: 2 }}>
        Where this Addendum uses terms that are defined in the Approved EU SCCs
        those terms shall have the same meaning as in the Approved EU SCCs. In
        addition, the following terms have the following meanings:
      </Typography>

      <TableContainer component={Paper} sx={{ mt: 2 }}>
        <Table sx={{ border: "1px solid #f2c200" }}>
          <TableBody>
            {/* Row 1 */}
            <TableRow>
              <TableCell
                sx={{
                  backgroundColor: "#fef7e4",
                  fontWeight: "bold",
                  border: "1px solid #f2c200",
                }}
              >
                Addendum
              </TableCell>
              <TableCell sx={{ border: "1px solid #f2c200" }}>
                This International Data Transfer Addendum which is made up of
                this Addendum incorporating the Addendum EU SCCs.
              </TableCell>
            </TableRow>

            {/* Row 2 */}
            <TableRow>
              <TableCell
                sx={{
                  backgroundColor: "#fef7e4",
                  fontWeight: "bold",
                  border: "1px solid #f2c200",
                }}
              >
                Addendum EU SCCs
              </TableCell>
              <TableCell sx={{ border: "1px solid #f2c200" }}>
                The version(s) of the Approved EU SCCs which this Addendum is
                appended to, as set out in Table 2, including the Appendix
                Information.
              </TableCell>
            </TableRow>

            {/* Row 3 */}
            <TableRow>
              <TableCell
                sx={{
                  backgroundColor: "#fef7e4",
                  fontWeight: "bold",
                  border: "1px solid #f2c200",
                }}
              >
                Appendix Information
              </TableCell>
              <TableCell sx={{ border: "1px solid #f2c200" }}>
                As set out in Table 3.
              </TableCell>
            </TableRow>

            {/* Row 4 */}
            <TableRow>
              <TableCell
                sx={{
                  backgroundColor: "#fef7e4",
                  fontWeight: "bold",
                  border: "1px solid #f2c200",
                }}
              >
                Appropriate Safeguards
              </TableCell>
              <TableCell sx={{ border: "1px solid #f2c200" }}>
                The standard of protection over the personal data and of data
                subjects’ rights, which is required by UK Data Protection Laws
                when you are making a Restricted Transfer relying on standard
                data protection clauses under Article 46(2)(d) UK GDPR.
              </TableCell>
            </TableRow>

            {/* Row 5 */}
            <TableRow>
              <TableCell
                sx={{
                  backgroundColor: "#fef7e4",
                  fontWeight: "bold",
                  border: "1px solid #f2c200",
                }}
              >
                Approved Addendum
              </TableCell>
              <TableCell sx={{ border: "1px solid #f2c200" }}>
                The template Addendum issued by the ICO and laid before
                Parliament in accordance with s119A of the Data Protection Act
                2018 on 2 February 2022, as it is revised under Section 18.
              </TableCell>
            </TableRow>

            {/* Row 6 */}
            <TableRow>
              <TableCell
                sx={{
                  backgroundColor: "#fef7e4",
                  fontWeight: "bold",
                  border: "1px solid #f2c200",
                }}
              >
                Approved EU SCCs
              </TableCell>
              <TableCell sx={{ border: "1px solid #f2c200" }}>
                The Standard Contractual Clauses set out in the Annex of
                Commission Implementing Decision (EU) 2021/914 of 4 June 2021.
              </TableCell>
            </TableRow>

            {/* Row 7 */}
            <TableRow>
              <TableCell
                sx={{
                  backgroundColor: "#fef7e4",
                  fontWeight: "bold",
                  border: "1px solid #f2c200",
                }}
              >
                ICO
              </TableCell>
              <TableCell sx={{ border: "1px solid #f2c200" }}>
                The Information Commissioner.
              </TableCell>
            </TableRow>

            {/* Row 8 */}
            <TableRow>
              <TableCell
                sx={{
                  backgroundColor: "#fef7e4",
                  fontWeight: "bold",
                  border: "1px solid #f2c200",
                }}
              >
                Restricted Transfer
              </TableCell>
              <TableCell sx={{ border: "1px solid #f2c200" }}>
                A transfer which is covered by Chapter V of the UK GDPR.
              </TableCell>
            </TableRow>

            {/* Row 9 */}
            <TableRow>
              <TableCell
                sx={{
                  backgroundColor: "#fef7e4",
                  fontWeight: "bold",
                  border: "1px solid #f2c200",
                }}
              >
                UK
              </TableCell>
              <TableCell sx={{ border: "1px solid #f2c200" }}>
                The United Kingdom of Great Britain and Northern Ireland.
              </TableCell>
            </TableRow>

            {/* Row 10 */}
            <TableRow>
              <TableCell
                sx={{
                  backgroundColor: "#fef7e4",
                  fontWeight: "bold",
                  border: "1px solid #f2c200",
                }}
              >
                UK Data Protection Laws
              </TableCell>
              <TableCell sx={{ border: "1px solid #f2c200" }}>
                All laws relating to data protection, the Processing of personal
                data, privacy and/or electronic communications in force from
                time to time in the UK, including the UK GDPR and the Data
                Protection Act 2018.
              </TableCell>
            </TableRow>

            {/* Row 11 */}
            <TableRow>
              <TableCell
                sx={{
                  backgroundColor: "#fef7e4",
                  fontWeight: "bold",
                  border: "1px solid #f2c200",
                  borderBottom: "3px solid #f2c200",
                }}
              >
                UK GDPR
              </TableCell>
              <TableCell
                sx={{
                  border: "1px solid #f2c200",
                  borderBottom: "3px solid #f2c200",
                }}
              >
                As defined in section 3 of the Data Protection Act 2018.
              </TableCell>
            </TableRow>
          </TableBody>
        </Table>
      </TableContainer>

      {/* Section Paragraphs */}
      <Typography variant="body1" paragraph sx={{ my: 2 }}>
        This Addendum must always be interpreted in a manner that is consistent
        with UK Data Protection Laws and so that it fulfils the Parties’
        obligation to provide the Appropriate Safeguards.
      </Typography>

      <Typography variant="body1" paragraph>
        If the provisions included in the Addendum EU SCCs amend the Approved
        SCCs in any way which is not permitted under the Approved EU SCCs or the
        Approved Addendum, such amendment(s) will not be incorporated in this
        Addendum and the equivalent provision of the Approved EU SCCs will take
        their place.
      </Typography>

      <Typography variant="body1" paragraph>
        If there is any inconsistency or conflict between UK Data Protection
        Laws and this Addendum, UK Data Protection Laws applies.
      </Typography>

      <Typography variant="body1" paragraph>
        If the meaning of this Addendum is unclear or there is more than one
        meaning, the meaning which most closely aligns with UK Data Protection
        Laws applies.
      </Typography>

      <Typography variant="body1" paragraph>
        Any references to legislation (or specific provisions of legislation)
        means that legislation (or specific provision) as it may change over
        time. This includes where that legislation (or specific provision) has
        been consolidated, re-enacted and/or replaced after this Addendum has
        been entered into.
      </Typography>

      {/* Hierarchy Section */}
      <Typography variant="h6" gutterBottom>
        Hierarchy
      </Typography>

      <Typography variant="body1" paragraph>
        Although Clause 5 of the Approved EU SCCs sets out that the Approved EU
        SCCs prevail over all related agreements between the Parties, the
        Parties agree that, for Restricted Transfers, the hierarchy in Section
        10 will prevail.
      </Typography>

      <Typography variant="body1" paragraph>
        Where there is any inconsistency or conflict between the Approved
        Addendum and the Addendum EU SCCs (as applicable), the Approved Addendum
        overrides the Addendum EU SCCs, except where (and in so far as) the
        inconsistent or conflicting terms of the Addendum EU SCCs provides
        greater protection for data subjects, in which case those terms will
        override the Approved Addendum.
      </Typography>

      <Typography variant="body1" paragraph>
        Where this Addendum incorporates Addendum EU SCCs which have been
        entered into to protect transfers subject to the General Data Protection
        Regulation (EU) 2016/679 then the Parties acknowledge that nothing in
        this Addendum impacts those Addendum EU SCCs.
      </Typography>
    </>
  );
};

export default MandatoryClauses;
