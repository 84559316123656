import React, { useEffect, useRef, useState } from 'react'
import { Box, ClickAwayListener, Grow, MenuItem, MenuList, Paper, Popper, Typography } from '@mui/material';

import calenderimg from '../../assets/Calender.svg';

const CommonDatePicker = ({ timeLineTxt, setTimeLineTxt,
    setTimeMilliseconds, setIsCustom }) => {

    // Popper setup ================
    const [open, setOpen] = useState(false);
    const anchorRef = useRef(null);

    const handleToggle = () => {
        setOpen((prevOpen) => !prevOpen);
    };

    const handleClose = (event) => {
        if (anchorRef.current && anchorRef.current.contains(event.target)) {
            return;
        }

        setOpen(false);
    };

    function handleListKeyDown(event) {
        if (event.key === 'Tab') {
            event.preventDefault();
            setOpen(false);
        } else if (event.key === 'Escape') {
            setOpen(false);
        }
    }

    // return focus to the button when we transitioned from !open -> open
    const prevOpen = useRef(open);
    useEffect(() => {
        if (prevOpen.current === true && open === false) {
            anchorRef.current.focus();
        }

        prevOpen.current = open;
    }, [open]);

    // =======================

    return (
        <div>
            <Box
                ref={anchorRef}
                id="composition-button"
                aria-controls={open ? 'composition-menu' : undefined}
                aria-expanded={open ? 'true' : undefined}
                aria-haspopup="true"
                onClick={handleToggle}
                sx={{
                    width: 150, height: 36, border: '1px solid #3843AC',
                    backgroundColor: '#fff', borderRadius: 1,
                    display: 'flex', flexDirection: 'row', cursor: 'pointer',
                    '& .css-jcqgxv': {
                        borderRight: '1px solid #DBE7FF',
                        borderTopRightRadius: 0,
                        borderBottomRightRadius: 0
                    }
                }}>
                <Box sx={{
                    width: 120, border: '1px solid #DBE7FF', borderRadius: 1,
                    display: 'flex', justifyContent: 'center', alignItems: 'center'
                }}>
                    <Typography style={{
                        fontSize: "0.875rem", color: '#3843AC',
                        fontWeight: 700,
                        fontFamily: 'Helvetica',
                        // marginTop: 3
                    }}>{timeLineTxt}</Typography>
                </Box>
                <Box sx={{ width: 30, display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                    <img src={calenderimg} style={{ height: 13, width: 13 }} />
                </Box>
            </Box>
            <Popper
                open={open}
                anchorEl={anchorRef.current}
                role={undefined}
                placement="bottom-start"
                transition
                disablePortal
                sx={{ width: 150, zIndex: 10 }}
            >
                {({ TransitionProps, placement }) => (
                    <Grow
                        {...TransitionProps}
                        style={{
                            transformOrigin:
                                placement === 'bottom-start' ? 'left top' : 'left bottom',
                        }}
                    >
                        <Paper>
                            <ClickAwayListener onClickAway={handleClose}>
                                <MenuList
                                    autoFocusItem={open}
                                    id="composition-menu"
                                    aria-labelledby="composition-button"
                                    onKeyDown={handleListKeyDown}
                                >
                                    {/* <MenuItem onClick={(e) => {
                                        setTimeLineTxt('Today')
                                        console.log(timeLineTxt);
                                        handleClose(e)
                                        setTimeMilliseconds(1)
                                    }}
                                        sx={{
                                            fontFamily: "Helvetica Neue", fontSize: "1rem",
                                            fontWeight: 400, color: '#606775',
                                            '&:hover': { backgroundColor: '#3843AC', color: '#FFFFFF', }
                                        }}>Today</MenuItem> */}
                                    {/* <MenuItem onClick={(e) => {
                                        setTimeLineTxt('Yesterday')
                                        handleClose(e)
                                        setTimeMilliseconds(86400)
                                    }}
                                        sx={{
                                            fontFamily: "Helvetica Neue", fontSize: "1rem",
                                            fontWeight: 400, color: '#606775',
                                            '&:hover': { backgroundColor: '#3843AC', color: '#FFFFFF', }
                                        }}>Yesterday</MenuItem> */}
                                    <MenuItem onClick={(e) => {
                                        setIsCustom(false)
                                        setTimeLineTxt('Last 7 Days')
                                        handleClose(e)
                                        setTimeMilliseconds(604800)
                                    }}
                                        sx={{
                                            fontFamily: "Helvetica Neue", fontSize: "1rem",
                                            fontWeight: 400, color: '#606775',
                                            '&:hover': { backgroundColor: '#3843AC', color: '#FFFFFF', }
                                        }}>Last 7 Days</MenuItem>
                                    <MenuItem onClick={(e) => {
                                        setIsCustom(false)
                                        setTimeLineTxt('Last 30 Days')
                                        handleClose(e)
                                        setTimeMilliseconds(2592000)
                                    }}
                                        sx={{
                                            fontFamily: "Helvetica Neue", fontSize: "1rem",
                                            fontWeight: 400, color: '#606775',
                                            '&:hover': { backgroundColor: '#3843AC', color: '#FFFFFF', }
                                        }}>Last 30 Days</MenuItem>
                                    <MenuItem onClick={(e) => {
                                        setIsCustom(false)
                                        setTimeLineTxt('Month to Date')
                                        handleClose(e)
                                        // Get current date
                                        const today = new Date(); 
                                        // Get current year
                                        const year = today.getFullYear();
                                        // Get current month
                                        const month = today.getMonth();
                                        // Get month's first day
                                        let monthFirstDay = new Date(year, month, 1);
                                        setTimeMilliseconds(parseInt((today.getTime() - monthFirstDay.getTime()) / 1000))
                                    }}
                                        sx={{
                                            fontFamily: "Helvetica Neue", fontSize: "1rem",
                                            fontWeight: 400, color: '#606775',
                                            '&:hover': { backgroundColor: '#3843AC', color: '#FFFFFF', }
                                        }}>Month to Date</MenuItem>
                                    <MenuItem onClick={(e) => {
                                        setIsCustom(false)
                                        setTimeLineTxt('Quarter to Date')
                                        handleClose(e)
                                        // Get current date
                                        const today = new Date();
                                        // Get current quarter
                                        const currentQuarter = Math.floor((today.getMonth() / 3)); 
                                        // Get current year
                                        const year = today.getFullYear();
                                        // Get current quarter's first day
                                        let quarterFirstDay = new Date(year, currentQuarter * 3, 1);
                                        setTimeMilliseconds(parseInt((today.getTime() - quarterFirstDay.getTime()) / 1000))
                                    }}
                                        sx={{
                                            fontFamily: "Helvetica Neue", fontSize: "1rem",
                                            fontWeight: 400, color: '#606775',
                                            '&:hover': { backgroundColor: '#3843AC', color: '#FFFFFF', }
                                        }}>Quarter to Date</MenuItem>
                                    <MenuItem onClick={(e) => {
                                        setIsCustom(false)
                                        setTimeLineTxt('Year to date')
                                        handleClose(e)
                                        // Get current date
                                        const today = new Date();
                                        // Get current year
                                        const year = today.getFullYear();
                                        // Get first day of year
                                        const yearFirstDay = new Date(year, 0, 1);
                                        setTimeMilliseconds(parseInt((today.getTime() - yearFirstDay.getTime()) / 1000))
                                    }}
                                        sx={{
                                            fontFamily: "Helvetica Neue", fontSize: "1rem",
                                            fontWeight: 400, color: '#606775',
                                            '&:hover': { backgroundColor: '#3843AC', color: '#FFFFFF', }
                                        }}>Year to date</MenuItem>
                                    <MenuItem onClick={(e) => {
                                        setTimeLineTxt('Custom Date')
                                        setIsCustom(true)
                                        handleClose(e)
                                    }}
                                        sx={{
                                            fontFamily: "Helvetica Neue", fontSize: "1rem",
                                            fontWeight: 400, color: '#606775',
                                            '&:hover': { backgroundColor: '#3843AC', color: '#FFFFFF', }
                                        }}>Custom Date</MenuItem>
                                </MenuList>
                            </ClickAwayListener>
                        </Paper>
                    </Grow>
                )}
            </Popper>
        </div>
    )
}

export default CommonDatePicker