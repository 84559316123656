import React from 'react'
import { StyledBox, StyledBoxContainer } from './OAuth.style'
import { Box, Typography } from '@mui/material'
import MailLogo from '../../components/MailLogo/MailLogo'
import { color } from '../../utils/Colors'

const AuthSuccessModal = () => {
    return (
        <div>
            <StyledBox>
                <StyledBoxContainer>
                    <Box sx={{
                        height: "8.5rem", width: "8.5rem", backgroundColor: "rgba(54, 70, 172, 0.05)", display: "flex", justifyContent: "center",
                        alignItems: "center", borderRadius: 30
                    }}>
                        <MailLogo Boxheight={60} Boxwidth="5.25rem" Arrowwidth="2.3rem" Arrowheight="1.6rem" />
                    </Box>
                    <Typography sx={{ textAlign: "center", fontFamily: 'Helvetica Neue', fontSize: "1.4rem", mt: "0.5rem", fontWeight: 700, letterSpacing: '0.04em', }}>
                        Thank you for signing up for
                    </Typography>
                    <Typography sx={{ textAlign: "center", fontSize: "1.25rem", fontFamily: 'Helvetica Neue', mt: "0.5rem", mb: 1, fontWeight: 500, color: color.themeBlack }}>
                        You're almost there!
                    </Typography>
                </StyledBoxContainer>
            </StyledBox>
        </div>
    )
}

export default AuthSuccessModal