import styled from 'styled-components';

export const Para = styled('p')({
    textAlign: 'center',
    fontFamily: 'Phosphate !important',
    fontSize: "4.38rem !important",
    fontWeight: "400 !important",
    letterSpacing: '0.3rem !important'
})


export const Logo = styled('img')({
    position: 'absolute',
    top: "32px !important",
    width: "13.44rem",
    height: "2.81rem",
    left: '15%',
    cursor: 'pointer'
})

export const ColumnDiv = styled('div')({
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center'
})

export const LogoSmall = styled('img')({
    height:'auto',
     width: "15rem"
})