import React from "react";
import {
  DownloadBtn,
  DownloadBtnWrapper,
  Heading,
  HeadingWrapper,
  LastUpdatedAt,
} from "./TermsCondition.styles";
import {
  Box,
  List,
  ListItem,
  ListItemText,
  Typography,
  useMediaQuery,
} from "@mui/material";
import useDocumentTitle from "../../hook/useDocumentTitle";
import pdfWhiteImg from "../../assets/pdf_white.svg";
import { color } from "../../utils/Colors";
import { HOST } from "../../utils/Baseurl";
import { conditions } from "./Conditions";
import Annex1 from "./Annex1";
import Annex2 from "./Annex2";
import Exhibit2 from "./Exhibit2";

const CommanAddendum = () => {
  useDocumentTitle("Madconnect Data Processing Addendum");
  const matcheslg = useMediaQuery("(min-width:1100px)");
  const matchesmd = useMediaQuery("(min-width:980px)");
  const matchessm = useMediaQuery("(min-width:880px)");
  const matchesxs = useMediaQuery("(min-width:760px)");

  return (
    <>
      <HeadingWrapper>
        <Box sx={{ width: "91%", maxWidth: 1350 }}>
          <Box
            sx={{
              display: "flex",
            }}
          >
            <Heading
              fontSize={
                matcheslg
                  ? "2.5rem"
                  : matchesmd
                  ? "2.3rem"
                  : matchessm
                  ? "2rem"
                  : matchesxs
                  ? "1.8rem"
                  : "1.6rem"
              }
            >
              MADCONNECT DATA PROCESSING ADDENDUM
            </Heading>
            <DownloadBtnWrapper>
              <a
                style={{ color: color.whiteColor, textDecoration: "none" }}
                download={"MadTech_MC_DPA_10_07_24.pdf"}
                target="_blank"
                rel="noreferrer"
                href={`${HOST}/platforms/MadTech_MC_DPA_10_07_24.pdf`}
              >
                <Box
                  sx={{
                    display: "flex",
                    cursor: "pointer",
                  }}
                >
                  <Typography>
                    <img
                      src={pdfWhiteImg}
                      style={{ maxWidth: "24px", maxHeight: "24px" }}
                    />
                  </Typography>
                  <DownloadBtn>DOWNLOAD</DownloadBtn>
                </Box>
              </a>
            </DownloadBtnWrapper>
          </Box>
          <LastUpdatedAt
            fontSize={
              matcheslg
                ? "1rem"
                : matchesmd
                ? "0.9rem"
                : matchessm
                ? "0.8rem"
                : matchesxs
                ? "0.7rem"
                : "0.7rem"
            }
          >
            Last Updated: 07 October, 2024
          </LastUpdatedAt>
        </Box>
      </HeadingWrapper>
      <Box
        sx={{
          py: 10,
          display: "block",
          justifyContent: "center",
          alignItems: "center",
          backgroundColor: "#eeeeee",
        }}
      >
        <Box
          sx={{
            maxWidth: 1350,
            backgroundColor: "#fff",
            p: 3,
            borderRadius: 2,
            border: "1px solid rgba(54, 70, 172, 0.24)",
            marginLeft: "auto",
            marginRight: "auto",
          }}
        >
          <div>
            <meta httpequiv="Content-Type" content="text/html; charset=utf-8" />
            <meta name="Generator" content="Microsoft Word 15 (filtered)" />
            <style
              dangerouslySetInnerHTML={{
                __html:
                  '\n<!--\n /* Font Definitions */\n @font-face\n\t{font-family:"Cambria Math";\n\tpanose-1:2 4 5 3 5 4 6 3 2 4;}\n@font-face\n\t{font-family:Roboto;}\n@font-face\n\t{font-family:"Noto Sans Symbols";}\n /* Style Definitions */\n p.MsoNormal, li.MsoNormal, div.MsoNormal\n\t{margin-top:0in;\n\tmargin-right:0in;\n\tmargin-bottom:6.0pt;\n\tmargin-left:0in;\n\tline-height:170%;}\nh1\n\t{mso-style-link:"Heading 1 Char";\n\tmargin-top:12.0pt;\n\tmargin-right:0in;\n\tmargin-bottom:6.0pt;\n\tmargin-left:.25in;\n\ttext-indent:-.25in;\n\tline-height:107%;\n\tfont-size:;\n\tfont-family:"Arial",sans-serif;}\nh1.CxSpFirst\n\t{mso-style-link:"Heading 1 Char";\n\tmargin-top:12.0pt;\n\tmargin-right:0in;\n\tmargin-bottom:0in;\n\tmargin-left:.25in;\n\ttext-indent:-.25in;\n\tline-height:107%;\n\tfont-size:;\n\tfont-family:"Arial",sans-serif;}\nh1.CxSpMiddle\n\t{mso-style-link:"Heading 1 Char";\n\tmargin-top:0in;\n\tmargin-right:0in;\n\tmargin-bottom:0in;\n\tmargin-left:.25in;\n\ttext-indent:-.25in;\n\tline-height:107%;\n\tfont-size:;\n\tfont-family:"Arial",sans-serif;}\nh1.CxSpLast\n\t{mso-style-link:"Heading 1 Char";\n\tmargin-top:0in;\n\tmargin-right:0in;\n\tmargin-bottom:6.0pt;\n\tmargin-left:.25in;\n\ttext-indent:-.25in;\n\tline-height:107%;\n\tfont-size:;\n\tfont-family:"Arial",sans-serif;}\nspan.Heading1Char\n\t{mso-style-name:"Heading 1 Char";\n\tmso-style-link:"Heading 1";\n\tfont-family:"Arial",sans-serif;\n\tcolor:windowtext;\n\tfont-weight:bold;}\n.MsoChpDefault\n\t{font-family:"Arial",sans-serif;}\n.MsoPapDefault\n\t{margin-bottom:6.0pt;\n\tline-height:107%;}\n /* Page Definitions */\n @page WordSection1\n\t{size:8.5in 11.0in;\n\tmargin:.5in 1.0in 1.25in 1.0in;}\ndiv.WordSection1\n\t{page:WordSection1;}\n /* List Definitions */\n ol\n\t{margin-bottom:0in; padding-left: 1rem;}\nul\n\t{margin-bottom:0in;}\n-->\n',
              }}
            />
            <Box>
              This Data Processing Addendum (“<b>DPA</b>”) is between MadTech,
              LLC with offices at 7 Toffee Lane, Madison, CT 06443 ("
              <b>MadTech</b>") and the counter-party identified in a mutually
              executed ordering document (“Order”) executed pursuant to the
              Terms of Service (as defined in Section 1.1 below) (“<b>Client</b>
              ”), each a “<b>Party</b>” and together the “<b>Parties</b>”.
            </Box>
            <Box sx={{ mt: 2 }}>
              {conditions.map((section) => (
                <Box key={section.id}>
                  <Box display="flex" alignItems="center" sx={{ mb: 1 }}>
                    <Box sx={{ fontWeight: "bolder", marginRight: 1.5 }}>
                      <Typography variant="h5">{section.id}.</Typography>
                    </Box>
                    <Box>
                      <Typography variant="h5">{section.title}</Typography>
                    </Box>
                  </Box>
                  <List dense>
                    {section.items.map((item) => (
                      <ListItem key={item.id} sx={{ pl: 4, display: "block" }}>
                        <ListItemText
                          primary={
                            <Box component="div" display="flex">
                              {item?.id && (
                                <Box
                                  component="div"
                                  sx={{
                                    fontWeight: "bold",
                                    marginBottom: 1,
                                    mr: 1.5,
                                    fontSize: "16px",
                                  }}
                                >
                                  {item.id}
                                </Box>
                              )}

                              <Box
                                sx={{ fontSize: "16px", textAlign: "justify" }}
                              >
                                {item.text}
                              </Box>
                            </Box>
                          }
                        />
                        {item.subItems && (
                          <List component="div" disablePadding sx={{ pl: 4.5 }}>
                            {item.subItems.map((subItem) => (
                              <ListItem key={subItem.id} sx={{ pl: 0 }}>
                                <ListItemText
                                  primary={
                                    <Box component="div" display="flex">
                                      <Box
                                        component="div"
                                        sx={{
                                          fontWeight: "bold",
                                          marginRight: 1.5,
                                          fontSize: "16px",
                                        }}
                                      >
                                        {subItem.id}
                                      </Box>
                                      <Box
                                        sx={{
                                          fontSize: "16px",
                                          textAlign: "justify",
                                        }}
                                      >
                                        {subItem.text}
                                      </Box>
                                    </Box>
                                  }
                                />
                              </ListItem>
                            ))}
                          </List>
                        )}
                      </ListItem>
                    ))}
                  </List>
                </Box>
              ))}
            </Box>
          </div>
        </Box>
        <Box
          sx={{
            maxWidth: 1350,
            backgroundColor: "#fff",
            p: 3,
            borderRadius: 2,
            border: "1px solid rgba(54, 70, 172, 0.24)",
            marginLeft: "auto",
            marginRight: "auto",
            mt: 4,
          }}
        >
          <Box
            sx={{
              textDecoration: "underline",
              textAlign: "center",
              fontWeight: "bolder",
              fontSize: "20px",
            }}
          >
            DPA Exhibit 1
          </Box>
         
          <Annex1></Annex1>
        </Box>
        <Box
          sx={{
            maxWidth: 1350,
            backgroundColor: "#fff",
            p: 3,
            borderRadius: 2,
            border: "1px solid rgba(54, 70, 172, 0.24)",
            marginLeft: "auto",
            marginRight: "auto",
            mt: 4,
          }}
        >
          <Annex2></Annex2>
        </Box>
        <Box
          sx={{
            maxWidth: 1350,
            backgroundColor: "#fff",
            p: 3,
            borderRadius: 2,
            border: "1px solid rgba(54, 70, 172, 0.24)",
            marginLeft: "auto",
            marginRight: "auto",
            mt: 4,
          }}
        >
          <Exhibit2></Exhibit2>
        </Box>
      </Box>
    </>
  );
};

export default CommanAddendum;
