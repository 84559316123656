import React, { useCallback, useEffect, useMemo, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Box, Typography, Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  } from "@mui/material";
import { useLocation } from "react-router-dom";
import { useForm } from "react-hook-form";

import { madTechState, updateConfiguration } from "../../features/madTechSlice";
import ConfigurationForm from "./ConfigurationForm";
import {
  useDeletePlatformConfigurationMutation,
  useSavePlatformConfigurationMutation,
} from "../../services/platformQuery";
import { useMyContext } from "../CommanToasterContext/toast";
import noConfigImg from "../../assets/Illustrations.svg";

const ConfigurationTab = ({ platformByIdDatas, accountid, connectorId, platfromData , platformConfigurations}) => {
  // const { platformConfigurations } = useSelector(madTechState);
  const dispatch = useDispatch();
  
  const location = useLocation();
  const queryParams = new URLSearchParams(location?.search);
  const account_id = queryParams.get("accountId");

  const { setOpen: setOpenSuccess, setMessage: setSuccessMessage } =
    useMyContext();

  const { control, handleSubmit, formState, reset, watch } = useForm();
  const { isDirty, isValid } = formState;

  const [submittedData, setSubmittedData] = useState(null);

  const [
    saveConfig,
    {
      data: saveConfigData,
      isLoading: saveConfigLoading,
      error: saveConfigError,
    },
  ] = useSavePlatformConfigurationMutation();

  const [
    deleteRequestPlatform,
    {
      data: deleteRequestedPlatformData,
      isLoading: deleteRequestPlatformLoading,
      error: deleteRequestPlatformError,
    },
  ] = useDeletePlatformConfigurationMutation();

  const saveEnable = useMemo(() => {
    if (isDirty && isValid && !saveConfigLoading) {
      return true;
    }
    return false;
  }, [isDirty, isValid, saveConfigLoading]);

  const onSubmit = (data) => {
    setSubmittedData(data);
    const mapping = {};
    for (const key in data) {
      mapping[key] = data[key];
    }
    const mergedConfiguration = platformConfigurations?.[0]?.configuration.map(
      (item) => {
        const name = item?.name;
        const value = mapping[name] || item?.value;
        return {
          ...item,
          value,
          name,
          lastUpdated: platformConfigurations?.[0]?.lastUpdated,
        };
      }
    );

    const configToSave = {
      configBody: {
        name: watch("configrationName"),
        configurationId: platformConfigurations?.[0]?.id,
        nameChanged:
          watch("configrationName") !== platformConfigurations?.[0]?.name,
        platformConfigurations: mergedConfiguration,
        connectorId: connectorId,
      },
      accountId: account_id?.length > 0 ? "" : accountid,
    };

    saveConfig(configToSave);
  };

  const _deleteRequestedPlatform = useCallback(() => {
    let deleteRequestPlatformBody = {
      platformBody: {
        id: platformConfigurations?.[0]?.id,
      },
      accountId: account_id?.length > 0 ? "" : accountid,
    };

    deleteRequestPlatform(deleteRequestPlatformBody);
    // setIsDeleteOpen(false)
  }, [useDeletePlatformConfigurationMutation]);

  useEffect(() => {
    if (saveConfigData) {
      if (saveConfigData.statusCode === 200) {
        setSuccessMessage(saveConfigData?.statusMessage);
        setOpenSuccess(true);
        reset(submittedData);
        const data = submittedData;
        const mapping = {};
        for (const key in data) {
          mapping[key] = data[key];
        }
        const mergedConfiguration =
          platformConfigurations?.[0]?.configuration.map((item) => {
            const name = item?.name;
            const value = mapping[name] || item?.value;
            return {
              ...item,
              value,
              name,
              lastUpdated: platformConfigurations?.[0]?.lastUpdated,
            };
          });
        dispatch(
          updateConfiguration({
            configuration: [
              {
                id:
                  platformConfigurations?.[0]?.id &&
                  platformConfigurations?.[0]?.id != null
                    ? platformConfigurations?.[0]?.id
                    : saveConfigData?.response,
                name: watch("configrationName"),
                lastUpdated: Math.ceil(Date.now() / 1000) - 5,
                configuration: mergedConfiguration,
              },
            ],
          })
        );
      }
    }

    if (saveConfigError) {
      console.log("saveConfigError", saveConfigError);
    }
  }, [saveConfigData, saveConfigError, submittedData]);

  return (
    <Box>
      {platformConfigurations?.[0]?.configuration?.length > 0 ? (
        <>
        <ConfigurationForm
          configuration={platformConfigurations?.[0]?.configuration}
          platformByIdDatas={platformByIdDatas}
          control={control}
          saveEnable={saveEnable}
          onSubmit={onSubmit}
          handleSubmit={handleSubmit}
          saveConfigLoading={saveConfigLoading}
          reset={reset}
          isDirty={isDirty}
          id={platformConfigurations?.[0]?.id}
          deleteRequestPlatformLoading={deleteRequestPlatformLoading}
          platfromData={platfromData}
          platformDetails={platformConfigurations}
          />
            {platformConfigurations?.[0]?.additionalInfo?.length > 0 && (
            <Box display="flex" marginTop={2.5}>
              {platformConfigurations?.[0]?.additionalInfo?.map((tableData, index) => {
                return (
                  <TableContainer
                    id="scrolling"
                    key={index}
                    sx={{ marginLeft: index >= 1 && "12px", width: "550px" }}
                  >
                    <Typography sx={{ paddingLeft: "16px", fontWeight: "600" }}>
                      {tableData?.title}
                    </Typography>
                    <Table
                    // style={{
                    //   border: "none",
                    // }}
                    >
                      <TableHead>
                        <TableRow>
                          {tableData?.columns?.map((value, i) => (
                            <TableCell key={i}>{value}</TableCell>
                          ))}
                        </TableRow>
                      </TableHead>
                      <TableBody>
                        {tableData?.rows?.length > 0 ? (
                          tableData?.rows?.map((item, index) => (
                            <TableRow key={index}>
                              {tableData?.columns?.map((column, colIndex) => (
                                <TableCell
                                  key={colIndex}
                                  sx={{ color: "#616161 !important" }}
                                >
                                  {item[column] || "-"}
                                </TableCell>
                              ))}
                            </TableRow>
                          ))
                        ) : (
                          <TableRow>
                            <TableCell
                              colSpan={tableData?.columns?.length}
                              sx={{ color: "#616161 !important" }}
                              align="left"
                            >
                              {`No ${tableData?.title} found`}
                            </TableCell>
                          </TableRow>
                        )}
                      </TableBody>
                    </Table>
                  </TableContainer>
                );
              })}
            </Box>
          )}
          </>
      ) : (
        <Box
          display={"flex"}
          justifyContent={"center"}
          alignItems={"center"}
          height={400}
          mt={2}
        >
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <Box
              sx={{
                width: "11.875rem",
                height: "11.875rem",
              }}
            >
              <img
                src={noConfigImg}
                style={{
                  maxWidth: "100%",
                  maxHeight: "100%",
                }}
              />
            </Box>
            <Typography
              sx={{
                color: "#25262D",
                textAlign: "center",
                fontSize: "1.125rem",
                fontWeight: 700,
                fontFamily: "Helvetica Neue",
              }}
            >
              No Configuration Details Available!
            </Typography>
            <Typography
              sx={{
                width: "60%",
                mt: "1rem",
                color: "#bdbdbd",
                textAlign: "center",
                fontSize: "1rem",
                fontWeight: 400,
                fontFamily: "Helvetica Neue",
              }}
            >
              Our team will assist you to setup API integration once you create
              the connection.
            </Typography>
          </Box>
        </Box>
      )}
    </Box>
  );
};

export default ConfigurationTab;
