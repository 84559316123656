import { Typography } from "@mui/material";
import styled from "styled-components";

export const MaintextTypograpy = styled(Typography)({
    fontWeight: "600 !important",
    fontFamily: "Helvetica Neue !important",
    fontSize: "16px !important",
    textAlign: "start !important",
    color: "#25262D !important",
    width: "100% !important",
    padding: "8px 8px 0px 8px !important"

})

export const ContentTypograpy = styled(Typography)({
    fontWeight: "400 !important",
    fontFamily: "Helvetica Neue !important",
    fontSize: "14px !important",
    textAlign: "start !important",
    color: "#66696A !important",
    width: "100% !important",
    padding: "5px 8px 8px 8px !important"
})
