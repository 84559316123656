export const permissionAdmin = {
    "Dashboard": {
        view: "unchecked",
        create: "disabled",
        modify: "disabled",
        delete: "disabled",
    },
    "Accounts": {
        view: "unchecked",
        create: "unchecked",
        modify: "unchecked",
        delete: "unchecked",
    },
    "Connections": {
        view: "unchecked",
        create: "unchecked",
        modify: "unchecked",
        delete: "unchecked",
    },
    "Users": {
        view: "unchecked",
        create: "unchecked",
        modify: "unchecked",
        delete: "unchecked",
    },
    "Reports": {
        view: "unchecked",
        create: "disabled",
        modify: "disabled",
        delete: "disabled",
    },
    "Contract & Invoice": {
        view: "unchecked",
        create: "disabled",
        modify: "disabled",
        delete: "disabled",
    },
    "Invoices": {
        view: "unchecked",
        create: "disabled",
        modify: "disabled",
        delete: "disabled",
    },
    "Contracts": {
        view: "unchecked",
        create: "disabled",
        modify: "disabled",
        delete: "disabled",
    },
    "Platform Details": {
        view: "unchecked",
        create: "disabled",
        modify: "unchecked",
        delete: "disabled",
    },
    "My Profile": {
        view: "unchecked",
        create: "disabled",
        modify: "unchecked",
        delete: "disabled",
    },
    "Account Profile": {
        view: "unchecked",
        create: "disabled",
        modify: "unchecked",
        delete: "disabled",
    },
    "Roles": {
        view: "unchecked",
        create: "unchecked",
        modify: "unchecked",
        delete: "unchecked",
    },
};

export const permissionPlatform = {
    name: "",
    description: "",
    "Dashboard": {
        view: "unchecked",
        create: "disabled",
        modify: "disabled",
        delete: "disabled",
    },
    "Connections": {
        view: "unchecked",
        create: "disabled",
        modify: "disabled",
        delete: "unchecked",
    },
    "Platform Details": {
        view: "unchecked",
        create: "disabled",
        modify: "unchecked",
        delete: "disabled",
    },
    "Users": {
        view: "unchecked",
        create: "unchecked",
        modify: "unchecked",
        delete: "unchecked",
    },
    "Reports": {
        view: "unchecked",
        create: "disabled",
        modify: "disabled",
        delete: "disabled",
    },
    "My Profile": {
        view: "unchecked",
        create: "disabled",
        modify: "unchecked",
        delete: "disabled",
    },
    "Account Profile": {
        view: "unchecked",
        create: "disabled",
        modify: "unchecked",
        delete: "disabled",
    },
    "Roles": {
        view: "unchecked",
        create: "disabled",
        modify: "disabled",
        delete: "disabled",
    },
    "User": {
        view: "unchecked",
        create: "unchecked",
        modify: "unchecked",
        delete: "unchecked",
    },
    "Contract & Invoice": {
        view: "unchecked",
        create: "disabled",
        modify: "disabled",
        delete: "disabled",
    },
    "Invoices": {
        view: "unchecked",
        create: "disabled",
        modify: "disabled",
        delete: "disabled",
    },
    "Contracts": {
        view: "unchecked",
        create: "disabled",
        modify: "disabled",
        delete: "disabled",
    },
};
