import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react'
import { BASE_URL } from '../utils/Baseurl'
import { setAccountDetails, setAllConnectors, setSelectedAccount } from "../features/madTechSlice"

// Define a service using a base URL and expected endpoints
export const accountApi = createApi({
    reducerPath: 'accountApi',
    // baseQuery: fetchBaseQuery({
    //     baseUrl: `${BASE_URL}/`,
    //     prepareHeaders: (headers, { getState }) => {
    //         const userData = JSON.parse(localStorage.getItem('USER'))
    //         if (userData) {
    //             headers.set('Authorization', `${userData.token}`);
    //         }
    //         return headers;
    //     },
    // }),
    baseQuery: async (args, api, extraOptions) => {
        // Prepare headers
        const headers = new Headers();
        const userData = JSON.parse(localStorage.getItem("USER"));
        if (userData) {
            headers.set("Authorization", `${userData.token}`);
        }

        let accountId;
        const queryParams = new URLSearchParams(window.location.search);
        if (
            queryParams !== null &&
            queryParams !== undefined &&
            queryParams.has("accountId")
        ) {
            accountId = queryParams.get("accountId");
        }
        const modifiedUrl =
            accountId != null && accountId !== undefined
                ? `${args.url}?accountId=${accountId}`
                : args.url;

        // Perform the actual query
        const modifiedArgs = {
            ...args,
            url: `${BASE_URL}/${modifiedUrl}`,
            headers,
        };
        const result = await fetchBaseQuery()(modifiedArgs, api, extraOptions);
        return result;
    },
    tagTypes: ['Account'],
    endpoints: (builder) => ({
        getAllAccounts: builder.query({
            query: () =>
            ({
                url: `account/all`,
                method: 'GET', headers: { "Content-Type": "application/json" }
            }),
            transformResponse: (response, meta, arg) => response,
            async onQueryStarted(
                arg,
                {
                    dispatch,
                    getState,
                    extra,
                    requestId,
                    queryFulfilled,
                    getCacheEntry,
                    updateCachedData,
                }
            ) {
                try {
                    const { data } = await queryFulfilled
                    // console.log('connections data', data);
                    if (data.response) {
                        // dispatch(setAllConnectors(data.response))
                    }
                } catch (err) {
                    console.log('fetch chapter error', err);
                }
            }
        }),

        getAccount: builder.mutation({
            query: (accountId) =>
            ({
                url: `account/${accountId}`,
                method: 'GET'
            }),
            transformResponse: (response, meta, arg) => response,
            async onQueryStarted(
                arg,
                {
                    dispatch,
                    getState,
                    extra,
                    requestId,
                    queryFulfilled,
                    getCacheEntry,
                    updateCachedData,
                }
            ) {
                try {
                    const { data } = await queryFulfilled
                    if (data.response) {
                        dispatch(setSelectedAccount(data?.response))
                    }
                } catch (err) {
                    console.log('fetch chapter error', err);
                }
            }
        }),
        getBillableAccount: builder.query({
            query: (accountId) =>
            ({
                url: `account/${accountId}`,
                method: 'GET', headers: { "Content-Type": "application/json" }
            }),
            transformResponse: (response, meta, arg) => response,
            async onQueryStarted(
                arg,
                {
                    dispatch,
                    getState,
                    extra,
                    requestId,
                    queryFulfilled,
                    getCacheEntry,
                    updateCachedData,
                }
            ) {   
                try {          
                    const { data } = await queryFulfilled
                    // console.log(data.response ,"kkkkkkk")
                    if (data?.response) {
                        dispatch(setAccountDetails(data?.response))
                    }
                } catch (err) {
                    console.log('fetch chapter error', err);
                }
            }
        }),
        getAllSProfileAccounts: builder.mutation({
            query: () =>
            ({
                url: `account/all`,
                method: 'GET', headers: { "Content-Type": "application/json" }
            }),
            transformResponse: (response, meta, arg) => response,
            async onQueryStarted(
                arg,
                {
                    dispatch,
                    getState,
                    extra,
                    requestId,
                    queryFulfilled,
                    getCacheEntry,
                    updateCachedData,
                }
            ) {
                try {
                    const { data } = await queryFulfilled
                    // console.log('connections data', data);
                    if (data.response) {
                        // dispatch(setAllConnectors(data.response))
                    }
                } catch (err) {
                    console.log('fetch chapter error', err);
                }
            }
        }),
    })
})

export const {
    useGetAccountMutation,
    useGetAllAccountsQuery,
    useGetAllSProfileAccountsMutation,
    useGetBillableAccountQuery
} = accountApi