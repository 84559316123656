import React from 'react';

function ErrorHandler({error}) {
  return (
    <div role="alert">
      <p>An error occurred(May be unable to reach MadConnect Backend Service):</p>
      <pre>{error.message}</pre>
    </div>
  )
}


export default ErrorHandler;
