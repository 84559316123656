import { useMediaQuery } from '@mui/material'
import React from 'react'
import { useDispatch } from 'react-redux'
import { useNavigate } from 'react-router-dom'

import shieldLogo from "../../assets/ic_outline-shield.svg"
import shakeHandLogo from "../../assets/ShakeHand.svg"
import usersLogo from "../../assets/users.svg"
import { setIsGetStarted, setIsPlatformContact, setUserChoice } from '../../features/madTechSlice'
import { BrandImage, BrandPlatformWrapper, BrandWrapper, BusinessTypeHeading, ContainerWhoAreYou, Heading, PublisherWrapper, Wrapper } from './NavLayout.styles'
import { ImageStyled } from '../Navbar/Navbar.styles'


const WhoAreYou = () => {

    const dispatch = useDispatch()
    const navigate = useNavigate()
    const matchesxsss = useMediaQuery('(min-width:480px)');

    return (
        <Wrapper>
            <ContainerWhoAreYou matchesxsss={matchesxsss}>
                <Heading>
                    What type of business are you?
                </Heading>
                <BrandPlatformWrapper>
                    <BrandWrapper onClick={() => {
                        dispatch(setIsGetStarted())
                        dispatch(setIsPlatformContact(false))
                        dispatch(setUserChoice('Brand'))
                        navigate('/signup')
                    }}>
                        <BrandImage>
                            <ImageStyled src={shieldLogo} alt='shield logo' />
                        </BrandImage>
                        <BusinessTypeHeading>
                            Brand
                        </BusinessTypeHeading>
                    </BrandWrapper>
                    <BrandWrapper onClick={() => {
                        dispatch(setIsGetStarted())
                        dispatch(setIsPlatformContact(true))
                        dispatch(setUserChoice('Platform'))
                        navigate('/signup')
                    }}>
                        <BrandImage>
                            <ImageStyled src={shakeHandLogo}  alt='shield logo' />
                        </BrandImage>
                        <BusinessTypeHeading>
                            Platform
                        </BusinessTypeHeading>
                    </BrandWrapper>
                </BrandPlatformWrapper>
                <PublisherWrapper onClick={() => {
                    dispatch(setIsGetStarted())
                    dispatch(setIsPlatformContact(false))
                    dispatch(setUserChoice('Publisher'))
                    navigate('/signup')
                }}>
                    <BrandImage>
                        <ImageStyled src={usersLogo} style={{ width: "2rem", height: 32 }} alt='shield logo' />
                    </BrandImage>
                    <BusinessTypeHeading>
                        Publisher
                    </BusinessTypeHeading>
                </PublisherWrapper>
            </ContainerWhoAreYou>
        </Wrapper>
    )
}

export default WhoAreYou