import React from 'react';
import { Grid, Card, CardContent, Typography , Box } from '@mui/material';
import madConnectLogo from "../../assets/madconnectdark.png";
import VerifiedIcon from '@mui/icons-material/Verified';
import NaLayout from "../../components/NavLayout/NaLayout";
import { Navigate } from 'react-router-dom';

function SuccessCard() {
  if (document.referrer === "") {
    return <Navigate to="/page-not-found" /> 
  }
  return (
    <Box>
      <NaLayout />
      <Grid
        container
        justifyContent="center"
        alignItems="center"
        sx={{ minHeight: '60vh', bgcolor: '' }}
      >
        <Grid
          item
          md={5}
          lg={5}
          sm={12}
          xs={12}
          sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}
        >
          <Card
            sx={{
              maxWidth: '100%',
              boxShadow: 'lg',
              borderRadius: "10px",
              padding: "10px"
            }}
          >
            <div style={{ textAlign: "center", padding: "20px" }}>
              <img
                style={{ width: "auto", height: "5rem", justifyContent: 'center' }}
                alt={"MADCONNECT LOGO"}
                src={madConnectLogo}
              ></img>

            </div>
            <div style={{ textAlign: "center", padding: "5px" }}>
              <VerifiedIcon sx={{ color: "green", justifyContent: "center", width: "50px", height: "50px" }} />
            </div>
            <CardContent sx={{ alignItems: 'center', textAlign: 'center' }}>
              <Typography level="body-sm" sx={{ maxWidth: '400px', fontSize: "1rem", fontFamily: "Helvetica Neue", color: "#999999" }}>
                Authorization successful! Thank you for authorizing access to your account using MadConnect.
              </Typography>
            </CardContent>
          </Card>
        </Grid>
      </Grid>
    </Box>
  );
}

export default SuccessCard;
