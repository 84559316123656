export const PrivacyPolicyData0 = [
    {
        title: "1.  PERSONAL INFORMATION WE COLLECT",
        content: `Generally, we collect four (4) types of information about you: (A) information and content
                      you give us directly; (B) information we obtain automatically when you use our Service; (C)
                      demographic information; and (D) information we get about you from other sources. When
                      we talk about “<b>Personal Information</b>” in this Privacy Policy, we are talking about any
                      information collected in accordance with this section. Please see below for more information
                      on each category.`
    }
]




export const PrivacyPolicyData = [
    {
        section: "A. Information and Content You Give Us Directly",
        items: [
            {
                title: "Personal Information.",
                content: "Personal information, such as your name, address, e-mail address, username, password, phone number, and any other information you directly provide us on or through the Service."
            },
            {
                title: "Email Correspondences.",
                content: "Records and copies of your email messages together with your email address and our responses, if you choose to correspond with us through email."
            },
            {
                title: "Transaction Information.",
                content: "We or service providers working on our behalf may collect information and details about any purchase or transactions made on the Service. This includes payment information, such as your credit or debit card number and other card information; other account and authentication information; and billing, shipping, and contact details. We do not collect or store payment card information ourselves; rather we rely on third-party payment processors (e.g., Stripe or PayPal) to store and process this information as part of the Service."
            }
        ]
    },
    {
        section: "B. Information We Obtain Automatically When You Use Our Service",
        items: [
            {
                title: "Activity Information.",
                content: "Details of your visits to our Service, including the types of content you view or engage with; the features you use; the actions you take; the people or accounts you interact with; the time, frequency, and duration of your activities; and other information about your use of and actions on the Service."
            },
            {
                title: "Equipment Information.",
                content: "Information about your computer and internet connection, including your device or computer operating system, IP address, browser type, and browser language."
            },
            {
                title: "Location Information.",
                content: "Information about the location of your device, including GPS location, for purposes of enhancing or facilitating the Service. For example, we may use information about the location of the device you are using to help us understand how the Service and functionality are being used and to deliver more relevant advertising. If you do not want to share your location, you can disable location sharing in the settings on your device."
            },
            {
                title: "Cookies, Pixel Tags/Web Beacons, and Other Technologies.",
                content: "Cookies, pixel tags, web beacons, clear GIFs, javascript, entity tags, HTML5 local storage, resettable device identifiers, or other similar technologies (collectively, the “Technologies”) may be used by us, as well as third parties that provide the content, advertising, or other functionality on the Service to automatically collect information through your use of the Service. Please see Section 8 for more information on the technologies we may use for this automatic data collection."
            }
        ]
    }
];

export const PrivacyPolicyData2 = [
    {
        title: "C. Demographic Information",
        content: "We may collect demographic, statistical, or other aggregate information that is about you, but individually does not identify you. Some of this information may be derived from Personal Information, but it is not Personal Information and cannot be tied back to you. Examples of such aggregate information include gender, age, and race."
    },
    {
        title: "D. Information We Get About You from Other Sources",
        content: "We may receive information about you from other sources and add it to our information, including from third-party services and organizations who have the right to provide us with such information. We protect this information according to the practices described in this Privacy Policy, plus any additional restrictions imposed by the source of the data. These sources may include: online and offline data providers, from which we obtain demographic, interest-based, and online advertising related data; publicly-available sources such as open government databases or social networks; and service providers who provide us with information, and updates to that information, based on their relationship with you. By gathering additional information about you, we can correct inaccurate information, enhance the security of your transactions, and give you product or service recommendations and special offers that are more likely to interest you."
    }
];

export const PrivacyPolicyDataPoint2 = [
    {
        title: "2. HOW WE USE YOUR INFORMATION",
        content: `We may use the information we collect about you in a variety of ways, to provide our
Service, for administrative purposes, and to market and advertise our Service and products.`
    }
]


export const PrivacyPolicyDataPoint2SubData = [
    {
        section: "A. We Use Your Personal Information to Provide Our Service",
        subsecion: "We may use your Personal Information to:",
        items: [
            {
                title: "",
                content: "provide the Service and its content to you."
            },
            {
                title: "",
                content: "respond to comments, questions, and provide customer service."
            },
            {
                title: "",
                content: "fulfill any other purpose for which you provide Personal Information."
            },
            {
                title: "",
                content: "communicate with you about an account."
            },
            {
                title: "",
                content: `inform you about important changes to, or other news about, the Service or any
of its features or content.`
            }
        ]
    },
    {
        section: "B. We Use Your Information for Administrative Purposes",
        subsecion: "We may use your Personal Information to:",
        items: [
            {
                title: "",
                content: "operate, maintain, improve, personalize, and analyze the Service."
            },
            {
                title: "",
                content: `monitor and analyze trends, usage, and activities for marketing or advertising
purposes.`
            },
            {
                title: "",
                content: `detect, prevent, or investigate security breaches, fraud, and other unauthorized
or illegal activity.`
            },
            {
                title: "",
                content: `carry out our obligations and enforce our rights arising from any contracts
entered into between you and us, including for billing and collection.`
            },
            {
                title: "",
                content: `maintain appropriate records for internal administrative purposes.`
            },
            {
                title: "",
                content: `allow you to participate in interactive features on the Service.`
            }
        ]
    },
    {
        section: `C. We Use Your Information to Market and Advertise Our Service and Products.`,
        subsecion: "We may use your Personal Information to:",
        items: [
            {
                title: "",
                content: `send promotional communications, such as information about features,
newsletters, offers, promotions, contests, and events.`
            },
            {
                title: "",
                content: `share information across Service and devices to provide a more tailored and
consistent experience on the Service.`
            },
            {
                title: "",
                content: `develop, test, and improve new products or services, including by conducting
surveys and research and testing and troubleshooting new products and features.`
            },

        ]
    }
];

export const PrivacyPolicyDataPoint3 = [
    {
        title: "3. HOW WE MAY S HARE OR DISCLOSE YOUR INFORMATION",
        content: `We may share or disclose aggregated or anonymized information about our users to third
parties for a variety of business purposes without any restrictions, including to provide our
Service and/or to protect us or others. We may also share or disclose information in the event
of a major business transaction such as a merger, sale, or asset transfer. The following
circumstances describe in additional detail the ways we may share or disclose your Personal
Information that we collect or that you provide under this Privacy Policy:`
    }
]

export const PrivacyPolicyDataPoint3SubData = [
    {
        section: "A. We Disclose Your Information to Provide Our Service",
        subsecion: "",
        items: [
            {
                title: "Subsidiaries and Affiliates.",
                content: `We may share your Personal Information with our
parent companies, subsidiaries, joint ventures, and affiliated companies for
purposes of management and analysis, decision-making, and other business
purposes, consistent with this Privacy Policy. Legal basis: Art. 6(1)b
GDPR (situation similar to contract).`
            },
            {
                title: "Service Providers.",
                content: `We may share your Personal Information with our third-
party service providers, contractors, and any other similar third parties that help
us provide our Service. This may include service providers that help us with
analytics services or support services, website hosting, email and postal
delivery, location mapping, product and service delivery. Service providers are
bound by contractual obligations to keep Personal Information confidential and
use it only for the purposes for which we disclose it to them. Legal basis: Art.
6(1)a GDPR (consent); Art. 6(1)b GDPR (situation similar to contract).`
            },
            {
                title: "Advertising/Marketing Service Providers.",
                content: `We may share your Personal
Information with marketing service providers to assess, develop, and provide
you with promotions and special offers that may interest you, administer
contests, sweepstakes, events, or for other promotional purposes. Legal basis:
Art. 6(1)f GDPR (legitimate interest).`
            },
            {
                title: "Consent or to Fulfill the Purpose that Information was Provided",
                content: `We may share
your Personal Information to fulfill the purpose for which you provide that
information, with your consent, or for any other purpose disclosed by us when
you provide the information. Legal basis: Art. 6(1)a GDPR (consent); Art. 6(1)b
GDPR (situation similar to contract).`
            },

        ]
    },

];

export const PrivacyPolicyDataPoint3SubData2 = [
    {
        title: `B. We May Disclose Your Information in the Event of a Merger, Sale or Other
Asset Transfers`,
        content: `If we become involved in a merger, acquisition, financing due diligence, divestiture,
restructuring, reorganization, bankruptcy, dissolution, sale, or transfer of some or all
of our assets (whether as a going concern or as part of bankruptcy, liquidation, or
similar proceeding), or transition of Service to another provider, your Personal
Information may be sold or transferred to business entities or people involved in such
process. Legal basis: Art. 6(1)f GDPR (legitimate interest).`
    }
]

export const PrivacyPolicyDataPoint3SubData3 = [
    {
        section: "C. We Disclose Your Information to Protect Us or Others",
        subsecion: "",
        items: [
            {
                title: "When Required by Law.",
                content: `We may share your Personal Information to comply
with any court order, law, or legal process, including to respond to any
government or regulatory request. Legal basis: Art. 6(1)c GDPR (legal
obligation).`
            },
            {
                title: "To Enforce Our Rights.",
                content: `We may share your Personal Information to enforce or
apply this Privacy Policy, our Terms of Service, and other agreements,
including for billing and collection purposes. Legal basis: Art. 6(1)b
GDPR (situation similar to contract).`
            },
            {
                title: "To Protect Lawful Interests.",
                content: `We may share your personal information if we
believe disclosure will help us protect the rights, property, or safety of
Company, our users, partners, agents, and others. This may include exchanging
information with other companies and organizations for fraud protection,and spam and malware prevention. Legal basis: Art. 6(1)b GDPR (situation
similar to contract); Art. 6(1)f GDPR (legitimate interest).`
            },

        ]
    },

];

export const PrivacyPolicyDataPoint4 = [
    {
        title: `4. YOUR PRIVACY CHOICES AND R IGHTS`,
        content: `You have certain choices and rights with respect to your privacy. For example, you may be
able to opt out of receiving marketing messages from us, make choices regarding cookies,
and exercise other privacy rights under applicable law.`
    }
]

export const PrivacyPolicyDataPoint4SubData = [
    {
        section: "A. Mechanisms to Control Your Information",
        subsecion: "",
        items: [
            {
                title: "Cookies and Other Tracking Technologies.",
                content: `You may be able to set your
browser to reject cookies and certain other technologies by adjusting the
appropriate settings in your browser. Each browser is different, but many
common browsers have preferences that may be adjusted to allow you to either
accept or reject cookies and certain other technologies before they are set or
installed, or allow you to remove or reject the use or installation of certain
technologies altogether. We recommend that you refer to the “Help” menu in
your browser to learn how to modify your browser settings. If you disable or
refuse cookies, please note that some parts of the Service may become
inaccessible or may not function properly.`
            },
            {
                title: "Communications from Company.",
                content: `If you do not wish to have your contact
information used by Company to promote our own or third-party products or
services, you can opt-out by: (1) informing us of your preference at the time you
sign up for your Service account (if applicable), or complete any other form on
or through the Service which we collect your data; (2) modifying your user
preferences in your account profile by checking or unchecking the relevant
boxes; (3) following the opt-out instructions at the bottom of the promotional
emails we send you; or (4) sending us an email stating your request. Please note
that we may also send you non-promotional communications, however you will
not be able to opt-out of these communications (e.g., transactional
communications, including emails about your account; communications
regarding our Service; and communications about updates to this Privacy Policy
and the Terms of Service).`
            },
            {
                title: "“Do Not Track”",
                content: `Do Not Track" (“DNT”) is a privacy preference you can set
in certain web browsers. When you turn on this preference, it sends a signal or
message to the websites you visit indicating that you do not wish to be tracked.
Please note that we currently respond to or honor DNT signals or similar
mechanisms transmitted by web browsers.`
            },

        ]
    },

];


export const PrivacyPolicyDataPoint4subdata1 = [
    {
        title: ``,
        content: `The online advertising industry also provides websites from which you may opt out
of receiving targeted ads from data partners and other advertising partners that
participate in self-regulatory programs. You can access these and learn more about
targeted advertising and consumer choice and privacy by visiting the Network
Advertising Initiative, the Digital Advertising Alliance, the European Digital
Advertising Alliance, and the Digital Advertising Alliance of Canada.`
    }
]


export const PrivacyPolicyDataPoint4SubData2 = [
    {
        section: "B. Accessing and Correcting Your Information",
        subsecion: "In accordance with applicable law, you may have the right to:",
        items: [
            {
                title: "Access Personal Information.",
                content: `You may access Personal Information about you,
including: (1) confirming whether we are processing your Personal Information;
(2) obtaining access to or a copy of your Personal Information; and (3)
receiving an electronic copy of personal information that you have provided to
us, or asking us to send that information to another company (the "right of data
portability").`
            },
            {
                title: "Request Correction of Personal Information.",
                content: `You may request correction of
your Personal Information where it is inaccurate, incomplete, or improperly
possessed.`
            },
            {
                title: "Request Deletion/Erasure of Personal Information.",
                content: `You may request
deletion/erasure of your Personal Information held by us about you. Please note:
we cannot delete your Personal Information except by also deleting your
account.`
            },
            {
                title: "Restrict/Opt-out of Processing.",
                content: `You may request to restrict/opt-out of the
processing of your Personal Information, including for the purpose(s) of: (1)
targeted advertising; (2) sale or sharing of personal information; or (3) profiling
to make decisions that have legal or other significant effects on you.`
            },
            {
                title: "Withdraw Consent.",
                content: `You may have the right to withdraw consent where such
consent is required to share or use Personal Information.`
            },

        ]
    },

];

export const PrivacyPolicyDataPoint4subdata3 = [
    {
        title: ``,
        content: `If you would like to exercise any of these rights, you may send us an email to request
access to, correction of or removal of any Personal Information that you have
provided to us. We will process such requests in accordance with applicable law.`
    }
]


export const PrivacyPolicyDataPoint4SubData4 = [
    {
        section: "B. Accessing and Correcting Your Information",
        subsecion: "In accordance with applicable law, you may have the right to:",
        items: [
            {
                title: "Access Personal Information.",
                content: `You may access Personal Information about you,
including: (1) confirming whether we are processing your Personal Information;
(2) obtaining access to or a copy of your Personal Information; and (3)
receiving an electronic copy of personal information that you have provided to
us, or asking us to send that information to another company (the "right of data
portability").`
            },
            {
                title: "Request Correction of Personal Information.",
                content: `You may request correction of
your Personal Information where it is inaccurate, incomplete, or improperly
possessed.`
            },
            {
                title: "Request Deletion/Erasure of Personal Information.",
                content: `You may request
deletion/erasure of your Personal Information held by us about you. Please note:
we cannot delete your Personal Information except by also deleting your
account.`
            },
            {
                title: "Restrict/Opt-out of Processing.",
                content: `You may request to restrict/opt-out of the
processing of your Personal Information, including for the purpose(s) of: (1)
targeted advertising; (2) sale or sharing of personal information; or (3) profiling
to make decisions that have legal or other significant effects on you.`
            },
            {
                title: "Withdraw Consent.",
                content: `You may have the right to withdraw consent where such
consent is required to share or use Personal Information.`
            },
        ]
    },

];


export const PrivacyPolicyDataPoint4subdata5 = [
    {
        title: ``,
        content: `If you would like to exercise any of these rights, you may send us an email to request
access to, correction of or removal of any Personal Information that you have
provided to us. We will process such requests in accordance with applicable law.`
    }
]

export const PrivacyPolicyDataPoint4SubData6 = [
    {
        section: "",
        subsecion: "The following are additional Consumer Privacy Rights:",
        items: [
            {
                title: "Non-Discrimination.",
                content: `Residents have the right not to receive discriminatory
treatment by covered businesses for the exercise of their rights conferred by the
applicable privacy law.`
            },
            {
                title: "Verification.",
                content: `To protect your privacy, we will take the following steps to verify
your identity before fulfilling your request. When you make a request, we will
ask you to provide sufficient information that allows us to reasonably verify you
are the person about whom we collected Personal Information or an authorized
representative, which may include asking you to answer questions regarding
your account and use of our Service.`
            },

        ]
    },

];


export const PrivacyPolicyDataPoint4subdata7 = [
    {
        title: `C. State Specific Privacy Rights`,
        content: `A number of states require us to provide residents of those states with additional
information and rights. Please see below to see the state-specific disclosures.`
    }
]

export const PrivacyPolicyDataPoint4subdata8 = [
    {
        title: `D. Your Right to Appeal`,
        content: `If you are dissatisfied with the refusal of Company to take action in accordance with
the exercise of your rights in the “Accessing and Correcting Your Information”
section above, you may request reconsideration by Company, by sending a written
request for reconsideration to the mailing address found in the “Contact Information”
section below. Within sixty (60) days (or such shorter period of time as required by
applicable law) of Company’s receipt of such written request for reconsideration,
Company shall inform you in writing (at the address indicated in your initial written
request) of any action taken or not taken in response to your request forreconsideration, including a written explanation of the reasons for the decision. In
addition, if your request for reconsideration is denied, you have the right to appeal to
the applicable supervisory authority in your jurisdiction of residence (see subsection
(E) below and the state-specific privacy notices for more information).`
    }
]

export const PrivacyPolicyDataPoint4subdata9 = [
    {
        title: `E. Complaints to Data Protection Authority`,
        content: `You have the right to complain to a Data Protection Authority about our collection
and use of your Personal Information. For more information, if you are in the
European Economic Area (EEA), please contact your local data protection authority
in the EEA.`
    }
]

export const PrivacyPolicyDataPoint5 = [
    {
        title: `5. HOW WE PROTECT YOUR INFORMATION`,
        content: `We have implemented safeguards reasonably designed to secure your Personal Information.
Such safeguards include the implementation of various technical, physical, administrative
and organizational security measures intended to reduce the risk of loss, misuse,
unauthorized access, disclosure, or modification of your information. All information you
provide to us is stored on our secure servers behind firewalls.
The safety and security of your information is also dependent on you. If we have given you
(or where you have chosen) a password for access to certain parts of the Service, you are
responsible for keeping this password confidential. We ask you not to share your password
with anyone.
While we have employed security technologies and procedures to assist safeguarding your
Personal Information, no system or network can be guaranteed to be 100% secure, and we
cannot ensure or warrant the security of any information you provide to us. Unauthorized
entry or use, hardware or software failure, and other factors may compromise the security of
user information at any time. Any transmission of Personal Information is at your own risk.
We are not responsible for circumvention of any privacy settings or security measures
contained on the Service.`
    }
]

export const PrivacyPolicyDataPoint6 = [
    {
        title: `6. INTERNATIONAL TRANSFER OF PERSONAL INFORMATION`,
        content: `If you provide Personal Information through the Service, you acknowledge and agree that
such Personal Information may be transferred from your current location to the offices and
servers of Company and the other third parties referenced in this Privacy Policy located in
the United States or other countries, which may have data protection laws that are different
from the laws where you live. We endeavor to safeguard your information consistent with the
requirements of such laws, where applicable.`
    }
]

export const PrivacyPolicyDataPoint7 = [
    {
        title: `7. P ERSONAL I NFORMATION R ETENTION P ERIOD`,
        content: `We keep your information for the length of time needed to carry out the purpose outlined in
this Privacy Policy and to adhere to our policies on keeping records (unless a longer period is
needed by law). Our records policies reflect applicable laws. We will retain and use your
information to the extent necessary to manage your relationship with us, personalize and
improve your overall customer experience, and to comply with our legal obligations. Where
we retain data, we do so in accordance with our record retention policies and any limitation
periods and records retention obligations that are imposed by applicable law.`
    }
]

export const PrivacyPolicyDataPoint8 = [
    {
        title: `8. COOKIES AND OTHER TECHNOLOGIES`,
        content: ``
    }
]

export const PrivacyPolicyDataPoint8SubData = [
    {
        section: "A. Description of the Technologies.",
        subsecion: `We as well as third parties that provide the content,
advertising, or other functionality on the Service may use Technologies to automatically
collect information through your use of the Service. The following describes some of
these Technologies we may use for this automatic data collection:`,
        items: [
            {
                title: "Cookies.",
                content: `A cookie is a small data file stored on the hard drive of your computer
either for (a) the duration of your visit on a website ("session cookies") or (b)
for a fixed period ("persistent cookies"). Cookies contain information that can
later be read by a web server. We may use cookies to provide you with a more
personal and interactive experience on the Service.`
            },
            {
                title: "Web Beacons.",
                content: `Web beacons are small files that are embedded in webpages,
applications, and emails (also known as "clear gifs", "pixel tags", "web bugs",
and "single-pixel gifs") that collect information about engagement on our
Service. For example, web beacons allow us to track who has visited those
webpages or opened an email, to test the effectiveness of our marketing, and for
other related website statistics.`
            },
            {
                title: "JavaScripts.",
                content: `JavaScripts are code snippets embedded in various parts of
websites and applications that facilitate a variety of operations including
accelerating the refresh speed of certain functionality or monitoring usage of
various online components.`
            },
            {
                title: "Entity Tags",
                content: `Entity Tags are HTTP code mechanisms that allow portions of
websites to be stored or "cached" within your browser and validates these
caches when the website is opened, accelerating website performance since the
web server does not need to send a full response if the content has not changed.`
            },
            {
                title: "HTML5 Local Storage.",
                content: `HTML5 local storage allows data from websites to be
stored or "cached" within your browser to store and retrieve data in HTML5
pages when the website is revisited.`
            },
            {
                title: "Resettable Device Identifiers.",
                content: `Resettable device identifiers (also known as
"advertising identifiers") are similar to cookies and are found on many mobile
devices and tablets (for example, the "Identifier for Advertisers" or "IDFA" on
Apple iOS devices and the "Google Advertising ID" on Android devices), and
certain streaming media devices. Like cookies, resettable device identifiers are
used to make online advertising more relevant.`
            },

        ]
    },

];

export const PrivacyPolicyDataPoint8subdata1 = [
    {
        title: `B. Our Uses of the Technologies.`,
        content: `We may also use these technologies for security
purposes, to facilitate navigation, to display information more effectively, and to better
serve you with more tailored information, as well as for website administration purposes,
e.g., to gather statistical information about the usage of our websites in order to
continually improve the design and functionality, to understand how users use our
websites, and to assist us with resolving questions regarding use of the websites.`
    }
]

export const PrivacyPolicyDataPoint8subdata2 = [
    {
        title: `C. Mechanisms to Control Cookies and Other Technologies.`,
        content: `You may be able to set your
browser to reject cookies and certain other technologies by adjusting the appropriate
settings in your browser. Each browser is different, but many common browsers have
preferences that may be adjusted to allow you to either accept or reject cookies and
certain other technologies before they are set or installed, or allow you to remove or
reject the use or installation of certain technologies altogether. We recommend that yourefer to the “Help” menu in your browser to learn how to modify your browser settings.
To learn how you can manage your Flash cookie settings, visit the Flash player settings
page on Adobe's website. If you disable or refuse cookies, please note that some parts of
the Service may become inaccessible or may not function properly.`
    }
]

export const PrivacyPolicyDataPoint8subdata3 = [
    {
        title: `D. Third Party Technologies.`,
        content: `This Privacy Policy covers the use of cookies by Company
and does not cover the use of tracking technologies by any third parties. The Service may
contain links, content, advertising, or references to other websites or applications run by
third parties, including advertisers, ad networks and servers, content providers, and
application providers. These third parties may use cookies or other tracking technologies
to collect information about you when you interact with their content on the Service, such
as customer recruitment vendors using Web beacons and cookies on our registration
pages, to payment processors using technologies for payment verification. The
information they collect may be associated with your Personal Information or they may
collect information about your online activities over time and across different websites.
Please be aware that we do not control these third parties' tracking technologies or when
and how they may be used. Therefore, Company does not claim nor accept responsibility
for any privacy policies, practices, or procedures of any such third party. We encourage
you to read the privacy statements and terms and conditions of linked or referenced
websites you enter. We do not endorse, screen, or approve, and are not responsible for the
practices of such third parties or the content of their application or website. Providing
Personal Information to third-party websites or applications is at your own risk. If you
have any questions about an ad or other targeted content, you should contact the
responsible provider directly.`
    }
]

export const PrivacyPolicyDataPoint9 = [
    {
        title: `9. CHILDREN U SING OR A CCESSING THE SERVICE`,
        content: `We are especially committed to protecting the privacy of children. Company’s Service is
directed at a general audience over the age of eighteen (18) and are not intentionally targeted
to children, and Company does not knowingly collect personal information directly from
children under the age of thirteen (13) (“Child” or “Children”). If we learn that we have
inadvertently collected or received Personal Information from a Child, we will use
reasonable efforts to immediately remove such information, unless we have a legal obligation
to keep it. If you are a parent or legal guardian and think your Child has given us information
without your consent, please contact us via the information found in the “Contact
Information” section below.`
    }
]

export const PrivacyPolicyDataPoint10 = [
    {
        title: `10. C HANGES TO O UR P RIVACY P OLICY`,
        content: `We reserve the right to update this Privacy Policy from time to time in order to reflect,
changes to our practices or for other operational, legal, or regulatory reasons. When we do
update this Privacy Policy, we will post the updates and changes on our website. We may
elect to notify you of material changes by mail, email, posting of modified Privacy Policy, or
some other similar manner. However, it is your responsibility to check our website regularly
for changes to this Privacy Policy. Your continued use of or access to the Service following
the posting of any changes to this Privacy Policy constitutes acceptance of those changes.`
    }
]

export const PrivacyPolicyDataPoint11 = [
    {
        title: `11. T HIRD-PARTY WEBSITES AND APPLICATIONS`,
        content: `Our Service may offer links to websites or applications that are not run by us but by third
parties. These third-party services, websites or applications are not controlled by us, and mayhave privacy policies that differ from our own. We encourage our users to read the privacy
policies and terms and conditions of each website and application with which they interact.
We do not endorse, screen or approve, and are not responsible for the practices of such third
parties or the content of their application or website. Providing Personal Information to third-
party websites or applications is at your own risk.`
    }
]

export const PrivacyPolicyDataPoint12 = [
    {
        title: `12. CONTACT INFORMATION`,
        content: `For more information about our privacy practices, if you have questions, or if you would like
to make a complaint, please contact us by e-mail at: partnerships@madtech.io or by mail at:
MadTech, LLC, 7 Toffee Lane, Madison, CT 06443.`
    }
]

export const California1 = [
    {
        title: ``,
        content: `California law requires us to disclose certain information related to our privacy practices. This
California Privacy Notice (the “CA Notice”) supplements the information contained in the
MadTech, LLC Privacy Policy (the “Privacy Policy”) and applies solely to all visitors, users,
and others who reside in the State of California ("consumers" or "you"). We adopt this notice to
comply with the California Consumer Privacy Act of 2018 (CCPA), and California Privacy
Rights Act of 2020 (CPRA) (together, the “CCPA”) and any terms defined under the CCPA
have the same meaning when used in this CA Notice. Capitalized terms used but not defined
herein shall have the respective meanings set forth in the Privacy Policy. As used in this CA
Notice only, “personal information” has the meaning set forth in the CCPA.`
    }
]

export const California2 = [
    {
        title: ``,
        content: `To understand our privacy practices, you should refer to our Privacy Policy in addition to this
supplement applicable to California residents.`
    }
]

export const CaliforniaPoint1 = [
    {
        title: `1. Categories of Personal Information Collected and Disclosed`,
        content: `The CCPA provides California residents with the right to know what categories of personal
information covered businesses have collected about them and whether such businesses have
disclosed that personal information for a business purpose (e.g., to a service provider) in the
preceding twelve (12) months. This information can be found in the table below:`
    }
]

export const CaliforniaPoint1subdata1 = [
    {
        title: ``,
        content: `We may use any of the categories of information listed above for other business or
operational purposes compatible with the context in which the personal information wascollected. The categories of sources from which we collect personal information and our
business and commercial purposes for using personal information are set forth in "Personal
Information We Collect" and "How We Use Your Information" of the Privacy Policy,
respectively`
    }
]

export const CaliforniaPoint1subdata2 = [
    {
        title: ``,
        content: `We may share any of the information listed above with service providers, which are
companies that we engage for business purposes to conduct activities on our behalf. Service
providers are restricted from using personal information for any purpose that is not related to
our engagement.`
    }
]

export const CaliforniaPoint2 = [
    {
        title: `2. “Sales” or “Sharing” of Personal Information under the CCPA`,
        content: `California residents have the right to opt out of the “sale” or “sharing” of their personal
information to third parties. The CCPA defines “sale” to mean selling, renting, releasing,
disclosing, disseminating, making available, transferring, or otherwise communicating a
consumer’s personal information to a third party for monetary or other valuable consideration
(which may be considered “sales” under the CCPA even if no money is exchanged). The
CCPA defines “sharing” to mean sharing, renting, releasing, disclosing, disseminating,
making available, transferring, or otherwise communicating a consumer’s personal
information to a third party for cross-context behavioral advertising, whether or not for
monetary or other valuable consideration. We have not "sold" or “shared” any personal
information with third parties in the preceding twelve months.`
    }
]



export const CaliforniaPoint3SubData1 = [
    {
        section: "3. Other California Consumer Privacy Rights",
        subsecion: `California residents have additional rights regarding their personal information. This section
describes those additional rights and explains how to exercise those rights.`,
        items: [
            {
                title: "Authorized Agent",
                content: `Only you, or someone legally authorized to act on your behalf, may
make a verifiable consumer request related to your Personal Information. You may also
make a verifiable consumer request on behalf of your minor child. To designate an
authorized agent, please contact us as set forth in “Contact Information” in the Privacy
Policy and provide written authorization signed by you and your designated agent.`
            },
            {
                title: "California Shine the Light.",
                content: `The California "Shine the Light" law permits users who are
California residents to request and obtain from us once a year, free of charge, a list of the
third parties to whom we have disclosed their personal information (if any) for their
direct marketing purposes in the prior calendar year, as well as the type of personal
information disclosed to those parties. If you are a California resident and would like to
exercise any of your rights under the law, please contact us as set forth in the "Contact
Information" section of the Privacy Policy. We will process such requests in accordance
with applicable laws.`
            },
            {
                title: `Opt-out of "Sales or Sharing".`,
                content: `California residents may opt-out of the "sale or sharing" of
their personal information by contacting us as set forth in the "Contact
Information" section of the Privacy Policy. California residents (or their authorized
agent) may also exercise your right to limit the disclosure of your sensitive personalinformation, by clicking on this "Limit the Use of My Sensitive Personal Information"
link.`
            },

        ]
    },

];
export const TableHeading = [
    'Category of personal information:',
    'Collected?',
]

export const TableData = [
    {
        title: 'Identifiers:',
        description: 'A real name, postal address, Internet Protocol address, email address, or other similar identifiers.',
    },
    {
        title: 'Personal information categories listed in the California Customer Records statute (Cal. Civ. Code § 1798.80(e))',
        description: 'A name, address, telephone number.',
    },
    {
        title: 'Business-to-Business related information',
        description: 'A name, address, telephone number.',
    },
]