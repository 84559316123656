import {
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from "@mui/material";
import React from "react";

const PartyTable = () => {
  return (
    <>
      <TableContainer component={Paper}>
        <Table sx={{ minWidth: 650 }} aria-label="simple table">
          <TableHead>
            <TableRow>
              <TableCell
                sx={{
                  fontWeight: "bold",
                  backgroundColor: "#f4e7be",
                  border: "1px solid #d4b886",
                }}
              >
                Start date
              </TableCell>
              <TableCell
                sx={{
                  fontWeight: "bold",
                  backgroundColor: "#f4e7be",
                  border: "1px solid #d4b886",
                }}
              />
              <TableCell
                sx={{
                  fontWeight: "bold",
                  backgroundColor: "#f4e7be",
                  border: "1px solid #d4b886",
                }}
              />
            </TableRow>
            <TableRow>
              <TableCell
                sx={{
                  fontWeight: "bold",
                  backgroundColor: "#f4e7be",
                  border: "1px solid #d4b886",
                }}
              >
                The Parties
              </TableCell>
              <TableCell
                sx={{
                  fontWeight: "bold",
                  backgroundColor: "#f4e7be",
                  border: "1px solid #d4b886",
                }}
              >
                Exporter (who sends the Restricted Transfer)
              </TableCell>
              <TableCell
                sx={{
                  fontWeight: "bold",
                  backgroundColor: "#f4e7be",
                  border: "1px solid #d4b886",
                }}
              >
                Importer (who receives the Restricted Transfer)
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {/* Parties' details */}
            <TableRow>
              <TableCell
                sx={{
                  fontWeight: "bold",
                  border: "1px solid #d4b886",
                  backgroundColor: "#f9f5e9",
                }}
              >
                Parties' details
              </TableCell>
              <TableCell sx={{ border: "1px solid #d4b886" }}>
                Full legal name: [Exporter Name]
                <br />
                Trading name (if different): [Trading Name]
                <br />
                Main address (if a company registered address): [Exporter
                Address]
                <br />
                Official registration number (if any) (company number or similar
                identifier): [Exporter Registration Number]
              </TableCell>
              <TableCell sx={{ border: "1px solid #d4b886" }}>
                Full legal name: MadTech, LLC
                <br />
                Trading name (if different): [Trading Name]
                <br />
                Main address (if a company registered address): 7 Toffee Lane,
                Madison, CT 06443
                <br />
                Official registration number (if any) (company number or similar
                identifier): [Importer Registration Number]
              </TableCell>
            </TableRow>

            {/* Key Contact */}
            <TableRow>
              <TableCell
                sx={{
                  fontWeight: "bold",
                  border: "1px solid #d4b886",
                  backgroundColor: "#f9f5e9",
                }}
              >
                Key Contact
              </TableCell>
              <TableCell sx={{ border: "1px solid #d4b886" }}>
                Full Name (optional): [Exporter Key Contact Name]
                <br />
                Job Title: [Exporter Job Title]
                <br />
                Contact details including email: [Exporter Email]
              </TableCell>
              <TableCell sx={{ border: "1px solid #d4b886" }}>
                Full Name (optional): Nicole Evans
                <br />
                Job Title: Chief of Staff
                <br />
                Contact details including email:
                <a href="mailto:nicole@madtech.io">nicole@madtech.io</a>, (908)
                656-5450
              </TableCell>
            </TableRow>

            {/* Signature */}
            <TableRow>
              <TableCell
                sx={{
                  fontWeight: "bold",
                  border: "1px solid #d4b886",
                  backgroundColor: "#f9f5e9",
                }}
              >
                Signature (if required for the purposes of Section 2)
              </TableCell>
              <TableCell sx={{ border: "1px solid #d4b886" }}></TableCell>
              <TableCell sx={{ border: "1px solid #d4b886" }}></TableCell>
            </TableRow>
          </TableBody>
        </Table>
      </TableContainer>
    </>
  );
};

export default PartyTable;
