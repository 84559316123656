import { Box, Typography } from "@mui/material";
import React from "react";

const IncorporationSection = () => {
  // Array of first list items
  const firstListItems = [
    {
      id: "a",
      description:
        "together they operate for data transfers made by the data exporter to the data importer, to the extent that UK Data Protection Laws apply to the data exporter’s Processing when making that data transfer, and they provide Appropriate Safeguards for those data transfers;",
    },
    {
      id: "b",
      description:
        "Sections 9 to 11 override Clause 5 (Hierarchy) of the Addendum EU SCCs; and",
    },
    {
      id: "c",
      description:
        "this Addendum (including the Addendum EU SCCs incorporated into it) is (1) governed by the laws of England and Wales and (2) any dispute arising from it is resolved by the courts of England and Wales, in each case unless the laws and/or courts of Scotland or Northern Ireland have been expressly selected by the Parties.",
    },
  ];

  const secondListItems = [
    {
      id: "a",
      description:
        "References to the “Clauses” means this Addendum, incorporating the Addendum EU SCCs; ",
    },
    {
      id: "b",
      description:
        `In Clause 2, delete the words: 
        “and, with respect to data transfers from controllers to Processors and / or Processors to Processors,
  standard contractual clauses pursuant to Article 28(7) of Regulation(EU) 2016 / 679”; `,
    },
    {
      id: "c",
      description:
        `Clause 6 (Description of the transfer(s)) is replaced with: 
“The details of the transfers(s) and in particular the categories of personal data that are transferred 
and the purpose(s) for which they are transferred) are those specified in Annex I.B where UK Data 
Protection Laws apply to the data exporter’s Processing when making that transfer.”; `,
    },
    {
      id: "d",
      description:
        `Clause 8.7(i) of Module 1 is replaced with:
“it is to a country benefitting from adequacy regulations pursuant to Section 17A of the UK GDPR that 
covers the onward transfer”; `,
    },
    {
      id: "e",
      description:
        `Clause 8.8(i) of Modules 2 and 3 is replaced with: 
“the onward transfer is to a country benefitting from adequacy regulations pursuant to Section 17A of 
the UK GDPR that covers the onward transfer;”`,
    },
    {
      id: "f",
      description:
        `References to “Regulation (EU) 2016/679”, “Regulation (EU) 2016/679 of the European Parliament 
and of the Council of 27 April 2016 on the protection of natural persons with regard to the Processing 
of personal data and on the free movement of such data (General Data Protection Regulation)” and 
“that Regulation” are all replaced by “UK Data Protection Laws”. References to specific Article(s) of 
“Regulation (EU) 2016/679” are replaced with the equivalent Article or Section of UK Data Protection 
Laws; `,
    },
    {
      id: "g",
      description:
        `References to Regulation (EU) 2018/1725 are removed; `,
    },
    {
      id: "h",
      description:
        `References to the “European Union”, “Union”, “EU”, “EU Member State”, “Member State” and “EU or 
Member State” are all replaced with the “UK”; `,
    },
    {
      id: "i",
      description:
        `The reference to “Clause 12(c)(i)” at Clause 10(b)(i) of Module one, is replaced with “Clause 11(c)(i)”;`,
    },
    {
      id: "j",
      description:
        `Clause 13(a) and Part C of Annex I are not used; `,
    },
    {
      id: "k",
      description:
        `The “competent supervisory authority” and “supervisory authority” are both replaced with the 
“Information Commissioner”; `,
    },
    {
      id: "l",
      description:
        `In Clause 16(e), subsection (i) is replaced with: 
“the Secretary of State makes regulations pursuant to Section 17A of the Data Protection Act 2018 
that cover the transfer of personal data to which these clauses apply;”; `,
    },
    {
      id: "m",
      description:
        `Clause 17 is replaced with: 
“These Clauses are governed by the laws of England and Wales.”;`,
    },
    {
      id: "n",
      description:
        `Clause 18 is replaced with: 
“Any dispute arising from these Clauses shall be resolved by the courts of England and Wales. A data 
subject may also bring legal proceedings against the data exporter and/or data importer before the 
courts of any country in the UK. The Parties agree to submit themselves to the jurisdiction of such 
courts.”; and`,
    },
    {
      id: "o",
      description:
        `The footnotes to the Approved EU SCCs do not form part of the Addendum, except for footnotes 8, 9, 
10 and 11.`,
    },
  ];


  return (
    <>
      <Typography variant="h6" gutterBottom>
        Incorporation of and changes to the EU SCCs
      </Typography>

      <Typography variant="body1" paragraph>
        This Addendum incorporates the Addendum EU SCCs which are amended to the
        extent necessary so that:
      </Typography>

      {firstListItems.map((item) => (
        <Box
          key={item.id}
          sx={{
            display: "flex",
            alignItems: "flex-start",
            mb: 2,
            ml: 10,
            gap: 2,
          }}
        >
          <Typography
            variant="body1"
            sx={{ fontWeight: "bold", marginRight: 1 }}
          >
            {item.id}.
          </Typography>
          <Typography variant="body1">{item.description}</Typography>
        </Box>
      ))}

      <Typography variant="body1" paragraph>
        Unless the Parties have agreed alternative amendments which meet the
        requirements of Section 12, the provisions of Section 15 will apply.
      </Typography>
      <Typography variant="body1" paragraph>
        No amendments to the Approved EU SCCs other than to meet the
        requirements of Section 12 may be made.
      </Typography>
      <Typography variant="body1" paragraph>
        The following amendments to the Addendum EU SCCs (for the purpose of
        Section 12) are made:
      </Typography>

      {secondListItems.map((item) => (
        <Box
          key={item.id}
          sx={{
            display: "flex",
            alignItems: "flex-start",
            mb: 2,
            ml: 10,
            gap: 2,
          }}
        >
          <Typography
            variant="body1"
            sx={{ fontWeight: "bold", marginRight: 1 }}
          >
            {item.id}.
          </Typography>
          <Typography variant="body1">{item.description}</Typography>
        </Box>
      ))}

    </>
  );
};

export default IncorporationSection;
