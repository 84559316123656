import { configureStore } from '@reduxjs/toolkit'
import madTechReducer from "../features/madTechSlice"
import { api } from "../services/query"
import { connectionApi } from "../services/connectionsQuery"
import { platformApi } from "../services/platformQuery"
import { accountApi } from '../services/accountsQuery'
import { setupListeners } from '@reduxjs/toolkit/query'
import { adminAPI } from '../services/adminQuery'
import snackbarReducer, { setSnackbar } from "../features/snackbarSlice";

const customMiddleware =
    ({ dispatch, getState }) =>
        (next) =>
            (action) => {
                if (action?.payload?.status === 403) {
                    if (action?.payload?.data?.statusMessage === "Session Expired") {
                        localStorage.clear();
                         window.location.href = "/signin"
                        return;
                    }
                }
                else if (action?.payload?.data?.statusCode === 403 || action?.payload?.statusCode === 403) {
                    if (action?.meta?.baseQueryMeta?.request?.method !== "GET") {
                        dispatch(
                          setSnackbar({
                            message: action?.payload?.statusMessage,
                            severity: "error",
                          })
                        );
                    }
                }

                return next(action);
            };


export const store = configureStore({
    reducer: {
        madTechReducer,
        [api.reducerPath]: api.reducer,
        [connectionApi.reducerPath]: connectionApi.reducer,
        [platformApi.reducerPath]: platformApi.reducer,
        [accountApi.reducerPath]: accountApi.reducer,
        [adminAPI.reducerPath]: adminAPI.reducer,
        snackbar: snackbarReducer,
    },
    middleware: (getDefaultMiddleware) =>
        getDefaultMiddleware({
            serializableCheck: {
                // Ignore these action types
                ignoredActions: ['madTech/OpenMenu'],
                // Ignore these field paths in all actions
                // ignoredActionPaths: ['meta.arg', 'payload.timestamp'],
                // Ignore these paths in the state
                ignoredPaths: ['madTechReducer.customize'],
            }
        })
            .concat(customMiddleware)
            .concat(api.middleware)
            .concat(connectionApi.middleware)
            .concat(platformApi.middleware)
            .concat(accountApi.middleware)
            .concat(adminAPI.middleware)
})

setupListeners(store.dispatch)