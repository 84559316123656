import React, { createContext, useContext, useState } from 'react';

// Create a context with an initial value
const MyContext = createContext();

// Create a provider component to wrap your app
export const MyContextProvider = ({ children }) => {
    const [open1, setOpen] = useState(false);
    const [message1, setMessage] = useState('');
    const [open2, setOpen2] = useState(false);
    const [message2, setMessage2] = useState('');


    // Value to be provided to the context
    const contextValue = {
        open1,
        setOpen,
        setMessage,
        message1, open2, setOpen2, message2, setMessage2
    };

    return <MyContext.Provider value={contextValue}>{children}</MyContext.Provider>;
};

// Custom hook to consume the context
export const useMyContext = () => {
    return useContext(MyContext);
};