import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import { BASE_URL } from "../utils/Baseurl";
import { setAllConnectors } from "../features/madTechSlice";

// Define a service using a base URL and expected endpoints
export const connectionApi = createApi({
  reducerPath: "connectionApi",
  // baseQuery: fetchBaseQuery({
  //     baseUrl: `${BASE_URL}/`,
  //     prepareHeaders: (headers, { getState }) => {
  //         // const token = (getState() as RootState).auth.token;
  //         const userData = JSON.parse(localStorage.getItem("USER"));
  //         if (userData) {
  //             headers.set("Authorization", `${userData.token}`);
  //         }
  //         return headers;
  //     },
  // }),
  baseQuery: async (args, api, extraOptions) => {
    // Prepare headers
    const headers = new Headers();
    const userData = JSON.parse(localStorage.getItem("USER"));
    if (userData) {
      headers.set("Authorization", `${userData.token}`);
    }

    let accountId;
    const queryParams = new URLSearchParams(window.location.search);
    if (
      queryParams !== null &&
      queryParams !== undefined &&
      queryParams.has("accountId")
    ) {
      accountId = queryParams.get("accountId");
    }
    const modifiedUrl =
    accountId != null && accountId !== undefined
      ? `${args.url}${args.url.includes('?') ? '&' : '?'}accountId=${accountId}`
      : args.url;

    // Perform the actual query
    const modifiedArgs = {
      ...args,
      url: `${BASE_URL}/${modifiedUrl}`,
      headers,
    };
    const result = await fetchBaseQuery()(modifiedArgs, api, extraOptions);
    return result;
  },
  tagTypes: ["Connection"],
  endpoints: (builder) => ({
    getConnections: builder.query({
      query: () => ({
        url: `connection`,
        method: "GET",
        headers: { "Content-Type": "application/json" },
      }),
      transformResponse: (response, meta, arg) => response,
      // providesTags: (result, error) =>
      //     result && result.response
      //         ?
      //         result.response.map(item => ({ type: 'Connection', id: item.id }))
      //         :
      //         [{ type: 'Connection', id: 'LIST' }]
      // ,
      async onQueryStarted(
        arg,
        {
          dispatch,
          getState,
          extra,
          requestId,
          queryFulfilled,
          getCacheEntry,
          updateCachedData,
        }
      ) {
        try {
          const { data } = await queryFulfilled;
          // console.log('connections data', data);
          if (data.response) {
            dispatch(setAllConnectors(data?.response));
          }
        } catch (err) {
          console.log("fetch chapter error", err);
        }
      },
    }),
    getDashboardforParamsConnections: builder.mutation({
      query: () => ({
        url: `connection?skipDeleted=${true}`,
        method: "GET",
        headers: { "Content-Type": "application/json" },
      }),
      // Pick out data and prevent nested properties in a hook or selector
      transformResponse: (response, meta, arg) => response,
      // invalidatesTags: (result, error, id) =>
      // [{ type: 'Connection', id: result._id }],
      async onQueryStarted(
        arg,
        {
          dispatch,
          getState,
          extra,
          requestId,
          queryFulfilled,
          getCacheEntry,
          updateCachedData,
        }
      ) {
        try {
          const { data } = await queryFulfilled;
          // console.log('add connection data', data);
        } catch (err) {
          // `onError` side-effect
          console.log("fetch chapter error", err);
          // dispatch(messageCreated('Error fetching posts!'))
        }
      },
    }),

    addConnections: builder.mutation({
      query: (connectionData) => ({
        url: `connection`,
        method: "POST",
        body: connectionData,
        headers: { "Content-Type": "application/json" },
      }),
      // Pick out data and prevent nested properties in a hook or selector
      transformResponse: (response, meta, arg) => response,
      // invalidatesTags: (result, error, id) =>
      // [{ type: 'Connection', id: result._id }],
      async onQueryStarted(
        arg,
        {
          dispatch,
          getState,
          extra,
          requestId,
          queryFulfilled,
          getCacheEntry,
          updateCachedData,
        }
      ) {
        try {
          const { data } = await queryFulfilled;
          // console.log('add connection data', data);
        } catch (err) {
          // `onError` side-effect
          console.log("fetch chapter error", err);
          // dispatch(messageCreated('Error fetching posts!'))
        }
      },
    }),
    snowflakeTransfer: builder.mutation({
      query: ({id}) => ({
        url: `connection/transfer?id=${id}`,
        method: "POST",
        // body: connectionData,
        headers: { "Content-Type": "application/json" },
      }),
      // Pick out data and prevent nested properties in a hook or selector
      transformResponse: (response, meta, arg) => response,
      // invalidatesTags: (result, error, id) =>
      // [{ type: 'Connection', id: result._id }],
      async onQueryStarted(
        arg,
        {
          dispatch,
          getState,
          extra,
          requestId,
          queryFulfilled,
          getCacheEntry,
          updateCachedData,
        }
      ) {
        try {
          const { data } = await queryFulfilled;
          // console.log('add connection data', data);
        } catch (err) {
          // `onError` side-effect
          console.log("fetch chapter error", err);
          // dispatch(messageCreated('Error fetching posts!'))
        }
      },
    }),
    UpdateConnections: builder.mutation({
      query: (connectionData) => ({
        url: `connection`,
        method: "PUT",
        body: connectionData,
        headers: { "Content-Type": "application/json" },
      }),
      // Pick out data and prevent nested properties in a hook or selector
      transformResponse: (response, meta, arg) => response,
      // invalidatesTags: (result, error, id) =>
      // [{ type: 'Connection', id: result._id }],
      async onQueryStarted(
        arg,
        {
          dispatch,
          getState,
          extra,
          requestId,
          queryFulfilled,
          getCacheEntry,
          updateCachedData,
        }
      ) {
        try {
          const { data } = await queryFulfilled;
          // console.log('add connection data', data);
        } catch (err) {
          // `onError` side-effect
          console.log("fetch chapter error", err);
          // dispatch(messageCreated('Error fetching posts!'))
        }
      },
    }),
    deleteConnection: builder.mutation({
      query: ({ id }) => ({
        url: `connection`,
        method: "DELETE",
        body: { id },
        headers: { "Content-Type": "application/json" },
      }),
      // Pick out data and prevent nested properties in a hook or selector
      transformResponse: (response, meta, arg) => response,
    }),

    updateConnectionsStatus: builder.mutation({
      query: (connectionData) => ({
        url: `connection/update/status`,
        method: "put",
        body: connectionData,
        headers: { "Content-Type": "application/json" },
      }),
      // Pick out data and prevent nested properties in a hook or selector
      transformResponse: (response, meta, arg) => response,
      // invalidatesTags: (result, error, id) =>
      // [{ type: 'Connection', id: result._id }],
      async onQueryStarted(
        arg,
        {
          dispatch,
          getState,
          extra,
          requestId,
          queryFulfilled,
          getCacheEntry,
          updateCachedData,
        }
      ) {
        try {
          const { data } = await queryFulfilled;
          // console.log('add connection data', data);
        } catch (err) {
          // `onError` side-effect
          console.log("fetch chapter error", err);
          // dispatch(messageCreated('Error fetching posts!'))
        }
      },
    }),

    filterAnalytics: builder.mutation({
      query: (analyticsBody) => ({
        url: `analytics/connections`,
        method: "POST",
        body: analyticsBody,
        headers: { "Content-Type": "application/json" },
      }),
      // Pick out data and prevent nested properties in a hook or selector
      transformResponse: (response, meta, arg) => response,
      async onQueryStarted(
        arg,
        {
          dispatch,
          getState,
          extra,
          requestId,
          queryFulfilled,
          getCacheEntry,
          updateCachedData,
        }
      ) {
        try {
          const { data } = await queryFulfilled;
          // console.log('analytics data', data);
        } catch (err) {
          // `onError` side-effect
          console.log("analytics error", err);
          // dispatch(messageCreated('Error fetching posts!'))
        }
      },
    }),
    getConfigurationPlatform: builder.mutation({
      query: ({ connectorId }) => ({
        url: `connection/configurations/connector/${connectorId}`,
        method: "GET",
        headers: { "Content-Type": "application/json" },
      }),
      transformResponse: (response, meta, arg) => response,
      async onQueryStarted(
        arg,
        {
          dispatch,
          getState,
          extra,
          requestId,
          queryFulfilled,
          getCacheEntry,
          updateCachedData,
        }
      ) {
        try {
          const { data } = await queryFulfilled;
          // console.log('connections data', data);
          //   if (data.response) {
          //     dispatch(setAllConnectors(data.response));
          //   }
        } catch (err) {
          console.log("fetch chapter error", err);
        }
      },
    }),
    getConfigurationPlatformDest: builder.mutation({
      query: ({ connectorId }) => ({
        url: `connection/configurations/connector/${connectorId}`,
        method: "GET",
        headers: { "Content-Type": "application/json" },
      }),
      transformResponse: (response, meta, arg) => response,
      async onQueryStarted(
        arg,
        {
          dispatch,
          getState,
          extra,
          requestId,
          queryFulfilled,
          getCacheEntry,
          updateCachedData,
        }
      ) {
        try {
          const { data } = await queryFulfilled;
          // console.log('connections data', data);
          //   if (data.response) {
          //     dispatch(setAllConnectors(data.response));
          //   }
        } catch (err) {
          console.log("fetch chapter error", err);
        }
      },
    }),
    getConfigurationById: builder.mutation({
      query: ({ connectionId }) => ({
        url: `connection/configurations/${connectionId}`,
        method: "GET",
        headers: { "Content-Type": "application/json" },
      }),
      transformResponse: (response, meta, arg) => response,
      async onQueryStarted(
        arg,
        {
          dispatch,
          getState,
          extra,
          requestId,
          queryFulfilled,
          getCacheEntry,
          updateCachedData,
        }
      ) {
        try {
          const { data } = await queryFulfilled;
          // console.log('connections data', data);
          //   if (data.response) {
          //     dispatch(setAllConnectors(data.response));
          //   }
        } catch (err) {
          console.log("fetch chapter error", err);
        }
      },
    }),

  }),
});

export const {
  useGetConnectionsQuery,
  useGetDashboardforParamsConnectionsMutation,
  useAddConnectionsMutation,
  useUpdateConnectionsMutation,
  useDeleteConnectionMutation,
  useFilterAnalyticsMutation,
  useUpdateConnectionsStatusMutation,
  useGetConfigurationPlatformMutation,
  useGetConfigurationPlatformDestMutation,
  useGetConfigurationByIdMutation,
  useSnowflakeTransferMutation,

} = connectionApi;
