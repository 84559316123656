import { Box, OutlinedInput, Typography } from "@mui/material";
import styled from "styled-components";

import { color } from "../../utils/Colors";

export const NoDataMainContainer = styled(Box)({
  display: "flex",
  flexDirection: "column",
  justifyContent: "center",
  alignItems: "center",
  width: "100%",
  padding: "4rem 0rem",
  backgroundColor: "#F8F9F9",
  display: "none",
});

export const NoDataHeading = styled(Typography)({
  textAlign: "center",
  color: color.themeColor,
  fontWeight: 400,
  fontFamily: "Phosphate !important",
  fontSize: "2.5rem !important",
  letterSpacing: "0.2px !important",
});

export const NoDataSubHeading = styled(Typography)({
  textAlign: "center",
  color: color.themeColor,
  fontWeight: "500 !important",
  fontFamily: "Helvetica Neue !important",
  fontSize: "1rem !important",
  letterSpacing: "0.2px !important",
});

export const NoDataGetStartedBtn = styled(Box)({
  backgroundColor: "#3843AC",
  cursor: "pointer",
  width: "21.25rem",
  height: "3.125rem",
  borderRadius: "4px",
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
});

export const Wrapper = styled(Box)({
  padding: (props) => (props.matchesxss ? "0px 80px" : "0px 24px"),
  marginTop: "40px",
  marginBottom: "60px",
  display: "flex",
  flexDirection: "row",
});

export const Container = styled(Box)({
  width: 300,
  height: 980,
  paddingTop: "1rem",
  borderRadius: "4px",
  border: `1px solid ${color.themeLightGray}`,
});

export const Heading = styled(Box)({
  padding: "0px 16px",
  display: "flex",
  flexDirection: "row",
  justifyContent: "space-between",
  alignItems: "center",
});

export const FilterBy = styled(Typography)({
  fontSize: "1.1rem !important",
  color: "#8B989F",
  fontWeight: "500 !important",
  fontFamily: "Helvetica Neue !important",
});

export const Resetfilters = styled(Typography)({
  fontSize: "1rem !important",
  color: color.themeColor,
  fontWeight: "500 !important",
  cursor: "pointer",
  fontFamily: "Helvetica Neue !important",
});

export const Divider = styled(Box)({
  height: 1,
  border: "1px solid #eeeeee",
  marginTop: "10px",
  marginBottom: "5px",
});

export const ConnectorFilterListContainer = styled(Box)({
  height: 900,
  padding: "0px 16px",
});

export const ConnectorFilterListSubContainer = styled(Box)({
  display: "flex",
  flexDirection: "column",
  marginBottom: "1rem",
});

export const ConnectorFilterListSubContainerHeading = styled(Typography)({
  fontSize: "1rem !important",
  marginBottom: "8px !important",
  lineHeight: 1.5,
  cursor: "pointer",
  fontFamily: "Helvetica Neue !important",
});

export const ConnectorFilterListSubContainerData = styled(Box)({
  display: "flex",
  flexDirection: "column",
});

export const ConnectorFilterData = styled(Typography)({
  fontSize: "0.875rem !important",
  color: "#666666",
  marginBottom: "8px !important",
  cursor: "pointer",
  fontFamily: "Helvetica Neue !important",
});

export const ListHeading = styled(Box)({
  display: "flex",
  flexDirection: (props) => (props.matchesxss ? "row" : "column"),
  alignItems: "center",
  justifyContent: "space-between",
});

export const LoaderContainer = styled(Box)({
  height: "100%",
});

export const LoaderWrapper = styled(Box)({
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
  width: "100%",
  height: "100%",
});

export const ListHeader = styled(Typography)({
  fontSize: "1.2rem",
  fontWeight: 700,
  cursor: "pointer",
  marginLeft: "5px !important",
});

export const ListHeaderImg = styled("img")({
  width: "0.5rem",
  height: "0.75rem",
  marginRight: "0.625rem",
  cursor: "pointer",
  marginBottom: "9px",
});

export const ListHeaderSpan = styled("span")({
  fontSize: "1.4rem !important",
  fontWeight: 700,
  fontFamily: "Helvetica Neue !important",
  cursor: "pointer",
});

export const ListHeader2 = styled(Typography)({
  fontSize: "1.5rem !important",
  fontWeight: "700 !important",
  fontFamily: "Helvetica Neue !important",
});

export const FilterSearch = styled(OutlinedInput)({
  width: (props) => (props.matchesxss ? "45%" : "75%"),
  backgroundColor: "#fff",
  height: "2.75rem",
});

export const DetailContainer = styled(Box)({
  display: "flex",
  opacity: 1,
  alignItems: "center",
  position: "relative",
  // margin: "auto",
  width: "768px",
});

export const ToolTipTitle = styled("span")({
  color: "#000000c2",
  fontWeight: 600,
  fontFamily: "Helvetica Neue",
  textTransform: "capitalize",
  fontSize: "12px",
  whiteSpace: "nowrap",
  overflow: "hidden",
  textOverflow: "ellipsis",
});

export const ToolTipContainer = styled("span")({
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
  position: "absolute",
  maxWidth: "70%",
  top: 90,
  right: 12,
});

export const TooltipSpan = styled("span")({
  textTransform: "capitalize",
  fontSize: "12px !important",
});

export const ToolTipComingSoonContainer = styled(Box)({
  paddingTop: "0.8px",
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
  position: "absolute",
  top: 8,
  right: 8,
  width: "4.875rem",
  height: "1.3125rem",
  border: "1px solid #3646AC",
  borderRadius: "20px",
  backgroundColor: "#FFFFFF",
});

export const CommingSoonText = styled(Typography)({
  fontWeight: "400 !important",
  fontFamily: "Helvetica Neue !important",
  fontSize: "0.625rem !important",
  color: "#3646AC",
});
