import { Box, useMediaQuery } from "@mui/material";
import React, { useMemo } from "react";
import { useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";
import { Helmet } from "react-helmet";

import NaLayout from "../../components/NavLayout/NaLayout";
import LetsConnect from "../Home/LetsConnect";
import HomeFooter from "../Home/HomeFooter";
import useDocumentTitle from "../../hook/useDocumentTitle";
import { madTechState } from "../../features/madTechSlice";
import { BtnClick, Heading, ListMain, QuestionDescription, QuestionInnerTitle, QuestionListContainer, QuestionTitle, QuestionWrapper, SubHeading, Wrapper } from "./LearnMore.styles";

const LearnMore = () => {
  useDocumentTitle("Learn More");

  const navigate = useNavigate();
  const { isGetStarted } = useSelector(madTechState);

  const matcheslg = useMediaQuery("(min-width:1100px)");
  const matchesmd = useMediaQuery("(min-width:980px)");
  const matchessm = useMediaQuery("(min-width:880px)");
  const matchesxs = useMediaQuery("(min-width:760px)");

  const faqLists = useMemo(
    () => [
      {
        title: "What is MadConnect?",
        description:
          "MadConnect is the one-to-many connector hub for data delivery that is built for the AdTech and MarTech category.",
      },
      {
        title: "What does MadConnect do?",
        description:
          "As a connector hub, MadConnect allows for the privacy-safe transfer and normalization of data between platforms.",
      },
      {
        title: "Who is MadConnect for?",
        description:
          "Publishers, Brands, Agencies, Data Platforms - any company looking to transfer and normalize data to and from their licensed data platforms.",
      },
      {
        title: "What are the benefits of and why do I need MadConnect?",
        description:
          "MadConnect is solving an industry-wide challenge of interoperability between data platforms. This challenge is affecting brands, publishers, and platforms.",
        description2:
          "MadConnect is the answer to data platform connectivity at scale. Brands and publishers can now realize the true investment of their licensed data platforms to execute on their advertising goals. Platforms benefit by saving on engineering resources and driving greater adoption of their tools.",
        list: [
          {
            title: "Publishers/Brands",
            description:
              "Brands and publishers are heavily investing in CDPs, clean rooms, and other platforms to organize, collaborate, enrich and activate their data. However, many of these tools are not interoperable with each other - data cannot be properly transferred or normalized from one platform to another. This lack of connectivity will stymie the adoption of these tools despite the large investment.",
          },
          {
            title: "Platforms",
            description:
              "With the flood of demand from brands and publishers to license multiple tools for their first party data initiatives, platforms must keep up with the increasing requests to connect with other platforms. Building and maintaining integrations between platforms is a constraint on engineering resources and an opportunity cost to innovation.",
          },
        ],
      },
      {
        title: "How does MadConnect work?",
        list: [
          {
            title: "Brands and Publishers:",
            description:
              "MadConnect was designed to allow brands and publishers to connect any platform they have licensed for their AdTech or MarTech stack via API within minutes.",
            description2:
              "The MadConnect platform normalizes the data from the “Source” platform to ensure it is mapped and formatted correctly to be ingested by the “Receiving” platform. There is no coding or additional work required. A user can log in, select which platforms to connect, enter the associated API key and start activating data flows between platforms.",
          },
          {
            title: "Platforms:",
            description:
              "MadConnect works with platforms that are source, destination or bi-directional data providers of virtually any type of AdTech or MarTech data.",
            description2:
              "Platforms only need to supply a well documented and tested API and support testing of the connector once development is complete.",
            description3:
              "Source platforms send the data to MadConnect, our system normalizes it and maps it to the destination platforms schema for ingestion.",
            description4:
              "MadConnect does NOT see any data from a platform, our system is end-to-end encrypted and SOC 2 compliant. MadConnect is solely focused on connecting platforms and their customers data for the benefits of the platform and their customers.",
          },
        ],
      },
      {
        title: "Any technical requirements I would need to use MadConnect?",
        list: [
          {
            title: "Brands and Publishers:",
            description:
              "MadConnect is a no-code solution. MadConnect customers only need the API keys for the platforms they would like to connect and can toggle on a connection in minutes through the MadConnect self-service user interface.",
          },
          {
            title: "Platforms:",
            description:
              "The MadConnect team does all the work. Platforms only need to supply a well documented and tested API and support testing of the connector once development is complete.",
          },
        ],
      },
      {
        title: "Why is MadTech uniquely positioned to bring you MadConnect?",
        list: {
          1: "MadTech is a product focused consultancy specializing in AdTech and MarTech. MadTech has deep expertise in identity solutions, first party data, and programmatic through our team of engineers, product managers and product marketers. We work with publishers, brands, agencies and platforms as an extension of their teams on the product and engineering side to solve hard problems and accelerate revenue growth. MadConnect is our answer to an industry-wide challenge around the lack of interoperability between data platforms based on our partners feedback.",
        },
      },
      {
        title: "Which platforms can MadConnect work with?",
        list: {
          1: "MadConnect can work with all platforms – CDPs, clean rooms, DMPs, data platforms, email marketing, etc. As long as there is an API, MadTech can enable a secure connection point.",
          2: "We can connect any platform that the brand/publisher has licensed as an internal platform.",
        },
      },
      {
        title: "Who on my team is going to use MadConnect?",
        list: {
          1: "Technical Operations or Data Engineering teams will most likely be the primary users of the MadConnect system.",
        },
      },
      {
        title: "How much data can I transfer? ",
        list: {
          1: "Unlimited data transfer per connection.",
        },
      },
      {
        title: "How much does MadConnect cost? ",
        list: {
          1: "Our pricing is based on the length of the term of the relationship and the number of connections. Regardless of number, all connections will have an unlimited volume of rows of data per month (for fair use).",
          2: "Reach out to our team for more details partnerships@madconnect.io.",
        },
      },
      {
        title: "Is it privacy-safe?",
        list: {
          1: "Yes, the data is end-to-end encrypted. MadTech does not store any PII in our environments, nor do we collect any data on behalf of our clients. We contractually ensure all of our platform connectors are CCPA and GDPR compliant, have updated privacy policies and adhere to compliant consumer data sharing and opt-out procedures.",
        },
      },
      {
        title: "Where does MadConnect sit in the cleanroom landscape?",
        list: {
          1: "We are a non-bias intermediary connection between platforms, inclusive of clean room technologies.",
        },
      },
      {
        title: "Which type of data can MadConnect transfer?",
        list: {
          1: "We can support any data type within a connection between systems, most common uses are for audience targeting enrichment, event logging or measurement.",
        },
      },
      {
        title: "How do I get started?",
        linklist: {
          1: `Go to the Contact Us page to reach out to the MadTech team about MadConnect.`,
          2: `Go to the Get Started page to sign up for MadConnect.`,
        },
      },
      {
        title: "Do I need a contract with MadTech?",
        list: {
          1: "Yes, when signing up for MadConnect you will need to sign a contract to complete the process.",
        },
      },
      {
        title: "Who is billing me?",
        list: {
          1: "Based on our partnership with the platform, the bill may come from MadTech Advisors or you may be billed from the platform directly.",
        },
      },
      {
        title: "Does MadTech have a privacy policy?",
        linklist: {
          1: `Here is our privacy policy.`,
        },
      },
    ],
    []
  );

  return (
    <NaLayout>
      {/* <Helmet>
        <script
          dangerouslySetInnerHTML={{
            __html: (function (w, d, s, l, i) {
              var f = d.getElementsByTagName(s)[0],
                j = d.createElement(s);
              j.async = !0;
              j.src =
                "//launch.amplifyreach.com/" +
                s +
                "/" +
                l +
                "/" +
                i +
                ".js?t=" +
                new Date().getTime();
              f.parentNode.insertBefore(j, f);
            })(window, document, "script", "2005", "c97f6e41b15f6dd9"),
          }}
        ></script>
      </Helmet> */}
      <Box mx="auto">
        <Wrapper>
          <Box width="91%" maxWidth={1350}>
            <Heading
              fontSize={matcheslg
                ? "2.5rem"
                : matchesmd
                  ? "2.3rem"
                  : matchessm
                    ? "2rem"
                    : matchesxs
                      ? "1.8rem"
                      : "1.6rem"}
            >
              frequently asked questions
            </Heading>
            <SubHeading
              fontSize={matcheslg
                ? "1rem"
                : matchesmd
                  ? "0.9rem"
                  : matchessm
                    ? "0.8rem"
                    : matchesxs
                      ? "0.7rem"
                      : "0.7rem"}
            >
              Everything you need to know about MadConnect.
            </SubHeading>
          </Box>
        </Wrapper>
        <QuestionWrapper>
          {faqLists?.map((item, idx) => (
            <QuestionListContainer key={idx} >
              <Box display="flex" flexDirection="column" >
                <QuestionTitle>
                  {item.title}
                </QuestionTitle>
                <Box>
                  {item.description !== undefined && (
                    <QuestionDescription>
                      {item.description}
                    </QuestionDescription>
                  )}
                  {item.description2 !== undefined && (
                    <QuestionDescription>
                      {item.description2}
                    </QuestionDescription>
                  )}
                  {item.description3 !== undefined && (
                    <QuestionDescription>
                      {item.description3}
                    </QuestionDescription>
                  )}
                </Box>
                {item.list !== undefined ? (
                  <Box>
                    {Array.isArray(item.list) ? (
                      <Box>
                        {item.list.map((lI, idx) => (
                          <ListMain
                            key={idx}
                          >
                            <li>
                              <QuestionInnerTitle>
                                {lI.title}
                              </QuestionInnerTitle>
                              {lI.description !== undefined && (
                                <ul style={{ listStyleType: "none" }}>
                                  <li>
                                    <QuestionDescription >
                                      {lI.description}
                                    </QuestionDescription>
                                  </li>
                                </ul>
                              )}
                              {lI.description2 !== undefined && (
                                <ul style={{ listStyleType: "none" }}>
                                  <li>
                                    <QuestionDescription >
                                      {lI.description2}
                                    </QuestionDescription>
                                  </li>
                                </ul>
                              )}
                              {lI.description3 !== undefined && (
                                <ul style={{ listStyleType: "none" }}>
                                  <li>
                                    <QuestionDescription >
                                      {lI.description3}
                                    </QuestionDescription>
                                  </li>
                                </ul>
                              )}
                            </li>
                          </ListMain>
                        ))}
                      </Box>
                    ) : (
                      typeof item.list === "object" && (
                        <Box>
                          {item.list["1"] !== undefined && (
                            <ListMain
                            >
                              <li>
                                <QuestionDescription>
                                  {item.list["1"]}
                                </QuestionDescription>
                              </li>
                            </ListMain>
                          )}
                          {item.list["2"] !== undefined && (
                            <ListMain
                            >
                              <li>
                                <QuestionDescription>
                                  {item.list["2"]}
                                </QuestionDescription>
                              </li>
                            </ListMain>
                          )}
                        </Box>
                      )
                    )}
                  </Box>
                ) : (
                  item.linklist !== undefined && (
                    <Box>
                      {Object.keys(item.linklist).length === 2 ? (
                        <Box>
                          {item.linklist["1"] !== undefined && (
                            <ListMain
                            >
                              <li>
                                <QuestionDescription>
                                  Go to the{" "}
                                  <BtnClick
                                    onClick={() => navigate("/contact")}
                                  >
                                    Contact Us
                                  </BtnClick>{" "}
                                  page to reach out to the MadTech team about
                                  MadConnect.
                                </QuestionDescription>
                              </li>
                            </ListMain>
                          )}
                          {item.linklist["2"] !== undefined && (
                            <ListMain
                            >
                              <li>
                                <QuestionDescription>
                                  Go to the{" "}
                                  <BtnClick
                                    onClick={() => {
                                      if (!isGetStarted) {
                                        // dispatch(setIsGetStarted());
                                        navigate("/contact");
                                      }
                                    }}
                                  >
                                    Get Started
                                  </BtnClick>{" "}
                                  page to sign up for MadConnect.
                                </QuestionDescription>
                              </li>
                            </ListMain>
                          )}
                        </Box>
                      ) : (
                        <Box>
                          {item.linklist["1"] !== undefined && (
                            <ListMain
                            >
                              <li>
                                <QuestionDescription>
                                  Here is our{" "}
                                  <BtnClick onClick={() => window.open("/privacy")} >
                                    privacy policy
                                  </BtnClick>
                                  .
                                </QuestionDescription>
                              </li>
                            </ListMain>
                          )}
                        </Box>
                      )}
                    </Box>
                  )
                )}
              </Box>
            </QuestionListContainer>
          ))}
        </QuestionWrapper>
        <LetsConnect isLearnmoreScreen />
        <HomeFooter />
      </Box>
    </NaLayout>
  );
};

export default LearnMore;
