import { Box, Typography, useMediaQuery } from "@mui/material";
import React from "react";

const PartyList = () => {
  return (
    <>
      <Box component="div" display="flex" sx={{ mt: 2 }}>
        <Box
          component="div"
          sx={{
            fontWeight: "bold",
            marginBottom: 1,
            mr: 1.5,
            fontSize: "18px",
          }}
        >
          A.
        </Box>

        <Box
          sx={{
            fontSize: "18px",
            textAlign: "justify",
            fontWeight: "bold",
            marginBottom: 1,
          }}
        >
          LIST OF PARTIES
        </Box>
      </Box>
      <Box>
        <Box component="div" display="flex" sx={{ mt: 2 }}>
          <Box
            component="div"
            sx={{
              fontWeight: "bold",
              marginBottom: 1,
              mr: 1.5,
              fontSize: "16px",
            }}
          >
            1.
          </Box>

          <Box
            sx={{
              fontSize: "16px",
              textAlign: "justify",
              fontWeight: "bold",
              marginBottom: 1,
            }}
          >
            Data exporter(s):
          </Box>
        </Box>
        <Box sx={{ mt: 1 }}>
          <Box component="div" display="flex" gap={1}>
            <div style={{ fontWeight: "600" }}>Name:</div>
            <div>Client, to the extent deemed a Controller under this DPA.</div>
          </Box>
        </Box>
        <Box sx={{ mt: 1 }}>
          <Box component="div" display="flex" gap={1}>
            <div style={{ fontWeight: "600" }}>Address:</div>
            <div>
              Client’s address set forth in the Order between the Parties.
            </div>
          </Box>
        </Box>
        <Box sx={{ mt: 1 }}>
          <Box component="div" display="flex" gap={1}>
            <div style={{ fontWeight: "600" }}>
              Contact person’s name, position and contact details:
            </div>
            <div>
              Client’s contact details as set forth in the Order between the
              Parties.
            </div>
          </Box>
        </Box>
        <Box sx={{ mt: 1 }}>
          <Box component="div" display="flex" gap={1}>
            <div>
              <span style={{ fontWeight: "600" }}>
                Activities relevant to the data transferred under these Clauses:
                &nbsp;&nbsp;&nbsp;
              </span>
              The Processing of Personal Data in connection with MadTech’s
              provision of Services to Client pursuant to the Terms of Service.
            </div>
          </Box>
        </Box>
        <Box sx={{ mt: 1 }}>
          <Box component="div" display="flex" gap={1}>
            <div style={{ fontWeight: "600" }}>
              Role (controller/Processor):
            </div>
            <div>Controller</div>
          </Box>
        </Box>
      </Box>
      <Box>
        <Box component="div" display="flex" sx={{ mt: 2 }}>
          <Box
            component="div"
            sx={{
              fontWeight: "bold",
              marginBottom: 1,
              mr: 1.5,
              fontSize: "16px",
            }}
          >
            2.
          </Box>

          <Box
            sx={{
              fontSize: "16px",
              textAlign: "justify",
              fontWeight: "bold",
              marginBottom: 1,
            }}
          >
            Data importer(s):
          </Box>
        </Box>
        <Box sx={{ mt: 1 }}>
          <Box component="div" display="flex" gap={1}>
            <div style={{ fontWeight: "600" }}>Name:</div>
            <div>MadTech, LLC</div>
          </Box>
        </Box>
        <Box sx={{ mt: 1 }}>
          <Box component="div" display="flex" gap={1}>
            <div style={{ fontWeight: "600" }}>Address:</div>
            <div>7 Toffee Lane, Madison, CT 06443</div>
          </Box>
        </Box>
        <Box sx={{ mt: 1 }}>
          <Box component="div" display="block" gap={1}>
            <div style={{ fontWeight: "600" }}>
              Contact person’s name, position and contact details:
            </div>
            <div>
              Nicole Evans, <br /> Chief of Staff, <br />{" "}
              <a href="mailto:nicole@madtech.io">nicole@madtech.io</a>,
              <br /> <a href="tel:(908) 656-5450">(908) 656-5450</a>
            </div>
          </Box>
        </Box>
        <Box sx={{ mt: 1 }}>
          <Box component="div" display="block" gap={1}>
            <div>
              <span style={{ fontWeight: "600" }}>
                Activities relevant to the data transferred under these
                Clauses:&nbsp;&nbsp;&nbsp;
              </span>
              The Processing of Personal Data in connection with MadTech’s
              provision of Services to Client pursuant to the Terms of Service.
            </div>
          </Box>
        </Box>
        <Box sx={{ mt: 1 }}>
          <Box component="div" display="flex" gap={1}>
            <div style={{ fontWeight: "600" }}>
              Role (controller/Processor):
            </div>
            <div>Processor</div>
          </Box>
        </Box>
      </Box>
    </>
  );
};

export default PartyList;
