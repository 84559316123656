import React, { useEffect, useRef } from "react";
import Box from "@mui/material/Box";
import TextField from "@mui/material/TextField";
import { Controller } from "react-hook-form";

export const TextFieldComponent = ({
  control,
  name,
  required,
  label,
  borderColor,
  borderRadius,
  color,
  autoFocus
}) => {

  const inputRef = useRef(null); 

  useEffect(() => {
    if (autoFocus && inputRef.current) {
      inputRef.current.focus();
    }
  }, [autoFocus]);

  return (
    <Controller
      name={name}
      control={control}
      rules={required ? { required: "This field is required" } : {}}
      render={({ field }) => (
        <Box
          component="form"
          sx={{
            "& > :not(style)": { m: 1, width: "25ch" },
          }}
          noValidate
          autoComplete="off"
        >
          <TextField
            {...field}
            id="outlined-basic"
            label={label}
            variant="outlined"
            inputRef={inputRef} 
            InputLabelProps={{
                style: {
                  fontSize: "0.875rem",
                  opacity: 0.5,
                  width: "fit-content",
                  backgroundColor: "#FFFFFF",
                  fontFamily: "Helvetica Neue",
                },
              }}
            sx={{
              width: "100% !important",
              height: "2.75rem",
              fontSize: "1rem",
              color: color ? color : "#666666",
              borderRadius: borderRadius,
              backgroundColor: "#FFFFFF",
              fontFamily: "Helvetica Neue",
              margin:"0px !important",
              "& fieldset": {
                borderColor: borderColor ? borderColor : "",
              },
              "& .MuiInputBase-input": {

                padding: "12px 14px" 
              },
            }}
          />
        </Box>
      )}
    />
  );
};
