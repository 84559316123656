import React, { useEffect } from "react";
import { useSelector } from "react-redux";

import { madTechState } from "../../features/madTechSlice";
import { useGetUserQuery } from "../../services/query";

const AuthLayout = ({ children }) => {
  const { isLoggedin } = useSelector(madTechState);
  // const navigate = useNavigate();
  var user = JSON.parse(localStorage.getItem("USER"));
  const { data: userDetails } = useGetUserQuery(
    { userid: user !== undefined && user?.userId },
    { skip: !user }
  );



  useEffect(() => {
    if(!userDetails) return;
    if (isLoggedin === "yes") {
      if (
        userDetails !== undefined &&
        userDetails?.response !== null &&
        userDetails?.response?.stepPending !== undefined &&
        userDetails?.response?.stepPending !== null &&
        userDetails?.response?.stepPending !== 0
      ) {
        // navigate('/completeProfile?u=' + user.userId);
        window.location.href = `/completeProfile?u=${userDetails?.response?.userId}`;
      } else {
        // navigate('/dashboard');
        if (userDetails === undefined && user === undefined) {
          window.location.href = `/dashboard`;
        } else if (
          (userDetails !== undefined &&
            (userDetails?.response?.userType === "agency" ||
              userDetails?.response?.userType === "madconnect")) ||
          (user !== undefined &&
            (user?.userType === "agency" || user?.userType === "madconnect" || user?.userType === "platform"))
        ) {
          window.location.href = `/admin/dashboard`;
        } else {
          window.location.href = `/dashboard`;
        }
      }
    }
  }, [isLoggedin]);

  return (
    <div>
      {children}
    </div>
  );
};

export default AuthLayout;
