import React from 'react'
import { Box } from '@mui/material'

import { ConnectorFilterData, ConnectorFilterListContainer, ConnectorFilterListSubContainer, ConnectorFilterListSubContainerData, ConnectorFilterListSubContainerHeading, Container, Divider, FilterBy, Heading, Resetfilters } from './Connectors.styles';
import { useNavigate } from 'react-router-dom';


const ConnectoreFilter = ({ allCategories, setSelectedCategories, setCategoryName, categoryName, selectedCategories, setShowDoc,setLoader }) => {
    const navigate = useNavigate()
    const handledResetFilter = () => {
        setShowDoc(false);
        setLoader(false)
        navigate("/connector")
        setSelectedCategories([])
        setCategoryName('')
    }

    const handleSelectFilter = (catItem) => {
        setShowDoc(false);
        setLoader(false)
        navigate("/connector")
        if (selectedCategories?.length === 0 || categoryName !== catItem.name) {
            setCategoryName(catItem.name)
            setSelectedCategories(catItem?.subCategories?.map(item => item.id))
        } else if (selectedCategories?.length > 0) {
            setCategoryName(catItem.name)
            setSelectedCategories(catItem?.subCategories?.map(item => item.id))
        } else {
            setSelectedCategories([])
            setCategoryName('')
        }
    }

    const handleSelectDetailFilter = (subCat, catItem) => {
        setShowDoc(false);
        setLoader(false)
        navigate("/connector")
        if (!selectedCategories?.includes(subCat.id)) {
            setSelectedCategories([subCat.id])
            setCategoryName(catItem.name)
            // setSelectedCategories([...selectedCategories, subCat.id])
        } else {
            setSelectedCategories(selectedCategories?.filter(scItem => scItem === subCat.id))
        }
    }



    return (
        <Container>
            <Heading>
                <FilterBy className='heading'>Filter by</FilterBy>
                <Resetfilters className='heading' onClick={handledResetFilter}>Reset filters</Resetfilters>
            </Heading>
            <Box px={2}>
                <Divider />
            </Box>
            <ConnectorFilterListContainer overflowY={allCategories?.length > 4 ? 'scroll' : 'none'} >
                {allCategories?.map((catItem, idx) =>
                    <ConnectorFilterListSubContainer key={idx}>
                        <ConnectorFilterListSubContainerHeading className='heading'
                            fontWeight={categoryName === catItem.name ? 700 : 500}
                            color={`rgba(18, 18, 18, ${categoryName === catItem.name ? '1' : '0.9'})`}
                            onClick={() => handleSelectFilter(catItem)}>
                            {catItem.name}
                        </ConnectorFilterListSubContainerHeading>
                        <ConnectorFilterListSubContainerData>
                            {catItem.subCategories.map((subCat, index) =>
                                <ConnectorFilterData
                                    key={index}
                                    fontWeight={selectedCategories?.includes(subCat.id) ? 700 : 400}
                                    onClick={() => handleSelectDetailFilter(subCat, catItem)}>{subCat.name}
                                </ConnectorFilterData>
                            )}
                        </ConnectorFilterListSubContainerData>
                    </ConnectorFilterListSubContainer>
                )}
            </ConnectorFilterListContainer>
        </Container>
    )
}

export default ConnectoreFilter
