import styled from "styled-components";
import { color } from '../../utils/Colors';
import { Typography } from "@mui/material";

export const LeftSectionImage = styled('img')({
    position: 'absolute',
    top: 32,
    width: "13.44rem",
    height: "2.81rem",
    left: '15%',
    cursor: 'pointer'
})

export const LeftSection = styled('div')({
    background: color.themeColor
})

export const ColumDiv = styled('div')({
    display: 'flex', flexDirection: 'column', justifyContent: 'center',
    alignItems: 'center'
})

export const Para = styled('p')({
    textAlign: 'center', fontFamily: 'Phosphate !important', 
    fontSize: "4.38rem !important", fontWeight: "400 !important", letterSpacing: '0.3rem !important'
})

export const MadconnectBetaLogo = styled('img')({
    height: 'auto', width: "15rem" 
})

export const RightSectionDiv = styled('div')({
    width: "60%",
    minWidth:(props)=>( props.matchesxsss ? "400 !important" : "320 !important"),
    padding:(props)=>( props.matchesxsss ? "50 !important" : "30 !important")
})

export const PassErrorMsg = styled(Typography)({
    color: "red", textAlign: "center", height: "1.25rem"
})

export const SetNewPassword = styled('p')({
    fontSize: "2rem !important", 
    fontWeight: "700 !important", 
    fontFamily: 'Helvetica Neue !important',
})

export const LeftSectionPara = styled('p')({
    fontSize: "1rem !important", fontFamily: 'Helvetica Neue !important', fontWeight: "400 !important" 
})