import React from 'react'
import { useMediaQuery } from '@mui/material'
import { useNavigate } from 'react-router-dom';
import arrowRight from "../../assets/arrow-right.svg"
import { FAQViewMore, HeadingFAQ, ParaFAQ, ViewMoreHeading, WrapperFAQ } from './Home.styles';
import Accordian from '../../components/CommonAccordian/Accordian';


const FAQ = () => {
    const navigate = useNavigate()
    const matches = {
        lg: useMediaQuery('(min-width:1100px)'),
        md: useMediaQuery('(min-width:980px)'),
        sm: useMediaQuery('(min-width:880px)'),
        xs: useMediaQuery('(min-width:760px)'),
        xss: useMediaQuery('(min-width:600px)'),
    }

    return (
        <WrapperFAQ>
            <HeadingFAQ matcheslg={matches.lg} matchesmd={matches.md} matchessm={matches.sm} matchesxs={matches.xs} matchesxss={matches.xss} >
                FAQ
            </HeadingFAQ>
            <ParaFAQ
                matcheslg={matches.lg}
                matchesmd={matches.md}
                matchessm={matches.sm}
                matchesxs={matches.xs}
                matchesxss={matches.xss}>
                View the frequently asked questions below
            </ParaFAQ>

            <Accordian />
            
            <FAQViewMore
                onClick={() => {
                    navigate('/learnmore')
                    window.scrollTo({ behavior: 'auto', top: 0 })
                }}>
                <ViewMoreHeading> View more</ViewMoreHeading>
                <img src={arrowRight} alt="right-arrow" style={{ marginLeft: 8 }} />
            </FAQViewMore>
        </WrapperFAQ>
    )
}

export default FAQ




