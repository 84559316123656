import "./App.css";
import { Routes, Route, Navigate, useSearchParams, useNavigate, useLocation } from "react-router-dom";
// import { useEffect, useMemo, useState } from 'react';
// import { useDispatch, useSelector } from 'react-redux';
// import { madTechState, setIsScriptTagVisible } from './features/madTechSlice';
import Navbar from "./components/Navbar/Navbar";
import SignIn from "./screens/SignIn/SignIn";
import SignUp from "./screens/SignUp/SignUp";
import NavStore from "./stores/NavStore";
import CompleteProfile from "./screens/CompleteProfile/CompleteProfile";
import DashBoard from "./screens/Dashboard/Dashboard";
import ForgotPass from "./screens/ForgotPass/ForgotPass";
import SetNewPass from "./screens/SetNewPass/SetNewPass";
import Home from "./screens/Home/Home";
import HomeConnectors from "./screens/HomeConnectors/HomeConnectors";
import ContactUs from "./screens/ContactUs/ContactUs";
import LearnMore from "./screens/LearnMore/LearnMore";
import TremsCondition from "./screens/TermCondition/TremsCondition";
import PrivacyPolicy from "./screens/PrivacyPolicy/PrivacyPolicy";
import PrivacyPolicy1 from "./components/PrivacyPolicy/PrivacyPolicy";
import FAQ from "./screens/FAQ/FAQ";
import Platform from "./screens/Platforms/Platforms";
import ProfileDetails from "./screens/ProfileDetails/ProfileDetails";
import Invoice from "./screens/Invoice/Invoice";
import SRoles from "./screens/SRoles/SRoles";
import SUsers from "./screens/SUsers/SUsers";
import SAddUserForm from "./screens/SUsers/AddUser/SAddUserForm.js";
import SEditUserForm from "./screens/SUsers/EditUser/SEditUserForm.js";
import SAddRole from "./screens/SRoles/SAddRole";
import SRoleAddForm from "./screens/SRoles/SRoleAddForm";
import SRoleEditForm from "./screens/SRoles/SRoleEditForm";
import Permission from "./permission/Permission.js";
import NoPermission from "./screens/NoPermission/NoPermission";
import { madTechState, setLoggedin, setIsAdminAgencyUser } from "./features/madTechSlice";
import { useDispatch, useSelector } from "react-redux";
import { closeSnackbar } from "./features/snackbarSlice";
import { Alert, Box, CircularProgress, Snackbar } from "@mui/material";
import ErrorOutlineIcon from "@mui/icons-material/ErrorOutline";
import SMyProfile from "./screens/MyProfile/SMyProfile";
import AddPlatform from "./screens/Platforms/AddPlatform";
import Configurations from "./screens/Platforms/Configurations";
import SignUpOAuth from "./screens/OAuth/signup";
import SignInOAuth from "./screens/OAuth/signin";
import Authorization from "./screens/OAuth/authorization";
import OauthForgotpass from "./screens/OAuth/OauthForgotpass";
import OAuthCompleteProfile from "./screens/OAuth/OauthCompleteProfile";
import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline';
import { useMyContext } from "./components/CommanToasterContext/toast";
import { useEffect, useLayoutEffect, useState } from "react";
import { HOST } from "./utils/Baseurl";
import { useGetUserProfileMutation, useGetUserStatusMutation, useLogOutMutation } from "./services/query";
import Addendum from "./screens/TermCondition/Addendum";
import Pricing from "./screens/SNewPricing";
import CreateConnections from "./components/Plans/createConnection";
import Connection from "./screens/Connections/Connection.js";
import SucessCard from "./screens/OAuth/SucessCard.js";
import FailCard from "./screens/OAuth/FailCard.js";
import PageNotFound from "./components/AppLayout/PageNotFound.js";
import Reports from "./screens/Reports/index.js";
import BeforeSignup from "./components/CommanOAuth/BeforeSignUp.js";
import { useGetIntegrationStatusMutation, useGetMockUserStatusMutation } from "./services/proxyServerQuery.js";
import { isSnowFlake } from "./utils/utils.js";
import CommanAddendum from "./screens/TermCondition/CommanAddendum.js";
import CommanTermsCondition from "./screens/TermCondition/CommanTermsCondition.js";
import PendingSnowflakeStep from "./components/SnowFlakeStep/PendingSnowflake.js";
import ProtectedRoutes from "./components/Routing/ProtectedRoutes.js";
import Support from "../src/screens/Support/Support.js"

function App() {
  // const navigate = useNavigate()
  // const { isLoggedin } = useSelector(madTechState)

  // useEffect(() => {
  //   if (!isLoggedin) {
  //     navigate('/signin')
  //   }
  // }, [isLoggedin]) 
  // const integrationId = localStorage.getItem("integrationId") || "37d99f9b-e32a-420d-975d-a8a882cea9fe/";
  const { pathname } = useLocation();
  const dispatch = useDispatch();
  const navigate = useNavigate()
  const [searchParams] = useSearchParams();
  const accountId = searchParams.get('accountId');
  const { user } = useSelector(madTechState);
  const { message, severity, open } = useSelector((state) => state.snackbar);
  const { open1, message1, setOpen, open2, message2, setOpen2, setMessage2 } = useMyContext();
  const [logOutUser, { data, isLoading, error }] = useLogOutMutation();
  const [getIntegrationStatus, { data: statusData, error: statusError, isSuccess, isLoading: isStatusLoading }] = useGetIntegrationStatusMutation();

  const userDetailsString = localStorage.getItem('USER');


  const [getUsersAgain, {
    data: userData,
    error: userError,
    isLoading: userLoading,
  }] = useGetUserProfileMutation();

  useEffect(() => {
    if (isSnowFlake()) {
      //Fail
      getIntegrationStatus();
      // getIntegrationStatus("37d99f9b-e32a-420d-975d-a8a882cea9fe/")
    }
  }, []);

  useEffect(() => {
    const userDetails = JSON.parse(userDetailsString);
    if (userDetails?.userId) {
      getUsersAgain({ userid: userDetails?.userId })
    }
  }, [])

  useEffect(() => {
    if (statusData) {
      if (statusData?.statusCode !== 200) {
        navigate("/intialize")
      }
    } else if (statusError) {
      console.log('statusError', statusError)

    }
  }, [statusData, statusError])


  useEffect(() => {
    if (userData !== undefined) {
      if (userData?.statusCode === 200 && userData.response) {
        if (userData?.response?.permissions?.length === 0) {
          dispatch(setLoggedin("none"));
        } else if (accountId === null && (userData?.response?.userType.toLowerCase() === "madconnect" || userData?.response?.userType.toLowerCase() === "agency")) {
          dispatch(setIsAdminAgencyUser(true))
        }
        else {
          return dispatch(setLoggedin("yes"))
        }
      } else if (userData?.statusCode !== 200) {
        dispatch(setLoggedin("none"));
      }
    }
  }, [userData, userError])

  const handleClose = (event, reason) => {

    if (reason === "clickaway") {
      return;
    }
    dispatch(closeSnackbar());
  };

  useEffect(() => {
    setTimeout(() => {
      setOpen(false)
      setOpen2(false)
    }, 5000);
  }, [])
  const handleClose1 = () => {
    setOpen(false)
  }
  const handleClose2 = () => {
    setOpen2(false)
  }


  const checkPermission = (name, action) => {
    // const routePermission = Permission.find(item => item.name === name);
    const routePermission = user?.permissions?.find(
      (item) => item.name === name
    );
    return routePermission ? routePermission.permissions[action] : false;
  };

  // useEffect(() => {
  //   if (userData !== undefined) {
  //     if (userData.statusCode === 200 && userData.response) {
  //       dispatch(setUser(userData.response));
  //     } else {
  //       localStorage.clear();
  //       window.location.href = HOST + "/signin";
  //     }
  //   }
  // }, [userData])


  // useEffect(() => {
  //   if (user?.permissions?.length === 0) {
  //     localStorage.clear();
  //     window.location.href = HOST + "/signin";
  //   } else if (!userDetails) {
  //     localStorage.clear();
  //     window.location.href = HOST + "/signin";
  //   }
  // }, []);

  useEffect(() => {
    if (data !== undefined) {
      dispatch(setLoggedin("no"));
      localStorage.clear();
      // window.location.href = "/signin";
      navigate("/signin")
    }

    if (error !== undefined) {
      console.log("error", error);
    }
  }, [data, error]);

  if (statusError && isSnowFlake()) {
    return <span style={{
      width: "100%", justifyContent: "center", display: "flex", fontSize: "36px", background: "transparent", height: "100vh", alignItems: "center"
    }}>Something is wrong with proxy server.</span>
  }

  if (userLoading || (isStatusLoading && isSnowFlake())) return <Box sx={{ height: "100vh", width: '100vw', display: 'flex', alignItems: "center" }}><CircularProgress style={{ margin: 'auto' }} size={50} thickness={8}></CircularProgress></Box>

  return (
    <NavStore>
      <Snackbar
        open={open}
        autoHideDuration={6000}
        onClose={handleClose}
        anchorOrigin={{ vertical: "top", horizontal: "right" }}
      >
        <Alert
          icon={<ErrorOutlineIcon style={{ color: "#fff" }} />}
          onClose={handleClose}
          severity="error"
          sx={{
            width: "100%",
            display: "flex",
            alignItems: "center",
            backgroundColor: "#d32f2f",
            color: "#fff",
            fontWeight: 600,
            ".MuiAlert-action": {
              padding: "3px",
            },
          }}
        >
          {message}
        </Alert>
      </Snackbar>
      <Snackbar
        open={open2}
        autoHideDuration={5000}
        onClose={handleClose2}
        anchorOrigin={{ vertical: "top", horizontal: "right" }}
      >
        <Alert
          icon={<ErrorOutlineIcon style={{ color: "#fff" }} />}
          onClose={handleClose2}
          severity="error"
          sx={{
            width: "100%",
            display: "flex",
            alignItems: "center",
            backgroundColor: "#d32f2f",
            color: "#fff",
            fontWeight: 600,
            ".MuiAlert-action": {
              padding: "3px",
            },
          }}
        >
          {message2}
        </Alert>
      </Snackbar>
      <Snackbar
        open={open1}
        autoHideDuration={6000}
        onClose={handleClose1}
        anchorOrigin={{ vertical: "top", horizontal: "right" }}
      >
        <Alert
          icon={<CheckCircleOutlineIcon style={{ color: "white" }} />}
          onClose={handleClose1}
          severity="success"
          sx={{
            width: "100%",
            display: "flex",
            alignItems: "center",
            backgroundColor: "green",
            color: "#fff",
            fontWeight: 600,
            ".MuiAlert-action": {
              padding: "3px",
            },
          }}
        >
          {message1}
        </Alert>
      </Snackbar>
      <Routes>
        <Route exact path="/" element={isSnowFlake() ? <SignIn /> : <Home />} />
        {/* <Route exact path="/" element={ <ProfileDetails />} /> */}
        {/* <Route element={<ProtectedRoutes />}> */}
        <Route
          exact
          path="/dashboard"
          element={
            checkPermission("Dashboard", "view") ? (
              <DashBoard />
            ) : (
              <Navigate to="/no-permission" />
            )
          }
        />
        <Route exact path="/platform" element={<Platform />} />
        <Route exact path="/platform/add" element={<AddPlatform />} />
        <Route exact path="/platform/:id" element={<Configurations />} />
        <Route exact path="/connections" element={<Connection />} />
        <Route exact path="/reports" element={<Reports />} />
        <Route exact path="/support" element={<Support />} />
        <Route exact path="/create-connections" element={<CreateConnections />} />
        <Route
          exact
          path="/profileDetails"
          element={
            checkPermission("Account Profile", "view") ? (
              <ProfileDetails />
            ) : (
              <Navigate to={accountId ? `/no-permission?accountId=${accountId}` : "/no-permission"} />
            )
          }
        />
        <Route exact path="/myprofile" element={
          checkPermission("My Profile", "view") ? (
            <SMyProfile />
          ) : (
            <Navigate to={accountId ? `/no-permission?accountId=${accountId}` : "/no-permission"} />
          )
        }
        />
        <Route exact path="/invoice" element={<Invoice />} />
        <Route
          exact
          path="/roles"
          element={
            checkPermission("Roles", "view") ? (
              <SRoles />
            ) : (
              <Navigate to={accountId ? `/no-permission?accountId=${accountId}` : "/no-permission"} />
            )
          }
        />
        <Route
          exact
          path="/roles/add"
          element={
            checkPermission("Roles", "create") ? (
              <SRoleAddForm />
            ) : (
              <Navigate to={accountId ? `/no-permission?accountId=${accountId}` : "/no-permission"} />
            )
          }
        />
        <Route
          exact
          path="/roles/:id"
          element={
            checkPermission("Roles", "modify") ? (
              <SRoleEditForm />
            ) : (
              <Navigate to={accountId ? `/no-permission?accountId=${accountId}` : "/no-permission"} />
            )
          }
        />
        <Route
          exact
          path="/users"
          element={
            checkPermission("Users", "view") ? (
              <SUsers />
            ) : (
              <Navigate to={accountId ? `/no-permission?accountId=${accountId}` : "/no-permission"} />
            )
          }
        />
        <Route
          exact
          path="/users/add"
          element={
            checkPermission("Users", "create") ? (
              <SAddUserForm />
            ) : (
              <Navigate to={accountId ? `/no-permission?accountId=${accountId}` : "/no-permission"} />
            )
          }
        />
        <Route
          exact
          path="/users/:id"
          element={
            checkPermission("Users", "modify") ? (
              <SEditUserForm />
            ) : (
              <Navigate to={accountId ? `/no-permission?accountId=${accountId}` : "/no-permission"} />
            )
          }
        />
        <Route
          exact
          path="/pricing"
          element={
            <Pricing />
          }
        />
        <Route exact path="/connector/:id" element={<HomeConnectors />} />
        <Route exact path="/connector" element={<HomeConnectors />} />

        {/* </Route> */}

        <Route exact path="/signin" element={<SignIn />} />
        <Route exact path="/signup" element={isSnowFlake() ? <SignUp /> : <Navigate to="/page-not-found" />} />

        <Route exact path="/intialize" element={isSnowFlake() ? <BeforeSignup /> : <Navigate to="/page-not-found" />} />



        <Route exact path="/oauth/signup" element={<SignUpOAuth />} />
        <Route exact path="/oauth/signin" element={<SignInOAuth />} />
        <Route exact path="/oauth/authorize" element={<Authorization />} />
        <Route exact path="/oauth/forgotpass" element={<OauthForgotpass />} />
        <Route exact path="/oauth/password" element={<OAuthCompleteProfile />} />
        <Route exact path="/oauth-success-fd13as6h" element={<SucessCard />} />
        <Route exact path="/oauth-fail-s7had13a" element={<FailCard />} />

        <Route exact path="/contact" element={<ContactUs />} />
        <Route exact path="/learnmore" element={<LearnMore />} />
        <Route exact path="/faq" element={<FAQ />} />
        <Route exact path="/terms" element={isSnowFlake() ? <CommanTermsCondition /> : <TremsCondition />} />
        <Route exact path="/data-processing-addendum" element={isSnowFlake() ? <CommanAddendum /> : <Addendum />} />
        <Route exact path="/privacy" element={isSnowFlake() ? <PrivacyPolicy1 /> : <PrivacyPolicy />} />
        <Route exact path="/privacy-policy" element={<PrivacyPolicy1 />} />
        <Route exact path="/completeProfile" element={isSnowFlake() ? <CompleteProfile /> : <Navigate to="/page-not-found" />} />
        <Route exact path="/forgotPass" element={<ForgotPass />} />
        <Route exact path="/setnewPass" element={<SetNewPass />} />
        <Route exact path="/snowflake-configuration" element={isSnowFlake() ? <PendingSnowflakeStep onVerify={() => { navigate('/dashboard') }} /> : <Navigate to={"/page-not-found"} />} />
        {/* <Route exact path="/*" element={<Navbar></Navbar>} /> */}
        <Route exact path="/no-permission" element={<NoPermission />} />,
        <Route exact path="/page-not-found" element={<PageNotFound />} />,
        <Route path="*" element={<Navigate to="/page-not-found" />} />
      </Routes>

    </NavStore>
  );
}

export default App;