import { Box, Typography } from "@mui/material";
import styled from "styled-components";

import AppLayout from "../../components/AppLayout/AppLayout";

const Container = styled(Box)({
  minHeight: "100vh",
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
})

const Heading = styled(Typography)({
  fontSize: "1.25rem !important",
  fontWeight: '700 !important',
  fontFamily: "Helvetica Neue !important",
  textAlign: "center",
})

const NoPermission = () => {
  return (
    <AppLayout>
      <Container>
        <Box>
          <Heading>
            Sorry, You don't have permission
          </Heading>
        </Box>
      </Container>
    </AppLayout>
  );
};

export default NoPermission;
