import { Box, Typography } from "@mui/material";
import styled from "styled-components";

import { color } from "../../utils/Colors";

export const HeadingWrapper = styled(Box)({
    padding: "48px 0px", 
    display: 'flex',
    flexDirection: 'column', 
    width: '100%',
    backgroundColor: color.themeColor,
    justifyContent: 'center', 
    alignItems: 'center'
})


export const Heading = styled(Typography)({
    color: "#fff !important",
    fontWeight: "400 !important",
    fontFamily: "Phosphate !important",
    fontSize: (props) => `${props.fontSize} !important`,
    letterSpacing: "0.2px !important",
})

export const DownloadBtnWrapper = styled(Box)({
    marginLeft: "auto",
    justifyContent: "center",
    alignItems: "center",
    padding: "1rem",
    color: "#fff",
})

export const DownloadBtn = styled(Typography)({
    verticalAlign: "middle !important",
    fontWeight: "700 !important",
    marginLeft: "5px !important",
    marginTop: "3px !important",
})


export const LastUpdatedAt = styled(Typography)({
    width: "60% !important",
    textAlign: "left !important",
    marginTop: "2rem !important",
    color: "#fff !important",
    fontWeight: "500 !important",
    fontFamily: "Helvetica Neue !important",
    letterSpacing: "0.2px !important",
    fontSize: (props) => `${props.fontSize} !important`,
})