import { Box, Typography } from "@mui/material";
import React from "react";
import PartyTable from "./PartyTable";
import SCCsTable from "./SCCsTable";
import AppendixInformation from "./AppendixInformation";
import EndingAddendumTable from "./EndingAddendumTable";
import MandatoryClauses from "./MandatoryClauses";
import IncorporationSection from "./IncorporationSection";
import AmendmentsSection from "./AmendmentsSection";

const Exhibit2 = () => {
  return (
    <>
      <Box
        sx={{
          textDecoration: "underline",
          textAlign: "center",
          fontWeight: "bolder",
          fontSize: "20px",
        }}
      >
        DPA Exhibit 2
      </Box>
      <Typography
        variant="subtitle1"
        gutterBottom
        sx={{ mt: 2, fontWeight: "bold" }}
      >
        International Data Transfer Addendum to the EU Commission Standard
        Contractual Clauses
      </Typography>
      <Typography variant="body1" gutterBottom>
        This Addendum has been issued by the Information Commissioner for
        Parties making Restricted Transfers. The Information Commissioner
        considers that it provides Appropriate Safeguards for Restricted
        Transfers when it is entered into as a legally binding contract.
      </Typography>
      <Typography variant="h6" gutterBottom sx={{ mt: 2 }}>
        PART 1: TABLES
      </Typography>
      <Typography variant="body1" gutterBottom>
        The Table 1 information below shall reflect the information set forth in
        DPA Exhibit 1, Annex I set forth above.
      </Typography>
      <Typography variant="h6" gutterBottom sx={{ mt: 4 }}>
        Table 1: Parties
      </Typography>

      <PartyTable></PartyTable>
      <SCCsTable></SCCsTable>
      <AppendixInformation></AppendixInformation>
      <EndingAddendumTable></EndingAddendumTable>

      <Typography variant="h6" gutterBottom sx={{ mt: 2 }}>
        PART 2: MANDATORY CLAUSES
      </Typography>
      <Typography variant="body1" gutterBottom>
        The Table 1 information below shall reflect the information set forth in
        DPA Exhibit 1, Annex I set forth above.
      </Typography>

      <MandatoryClauses></MandatoryClauses>
      <IncorporationSection></IncorporationSection>
      <AmendmentsSection></AmendmentsSection>
    </>
  );
};

export default Exhibit2;
