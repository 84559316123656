import { Button, OutlinedInput, Typography } from "@mui/material";
import styled from "styled-components";

import { color } from "../../../../utils/Colors";

export const Heading = styled(Typography)({
  marginLeft: "0px !important",
  fontSize: "1.25rem !important",
  fontWeight: "700 !important",
  fontFamily: "Helvetica Neue !important",
});

export const CreateBtnContainer = styled(Button)({
    border: "1px solid black !important",
    color: `${color.whiteColor} !important`,
    textTransform: "capitalize !important",
    background: "#25262D !important",
    borderRadius: "10px !important",
    "&:hover": {
      background: "#000 !important",
      border: "1px solid black !important",
      cursor: "pointer !important",
    },
    padding: "7px 22px !important",
})

export const InputContainer = styled(OutlinedInput)({
    margin: "auto",
    height: "2.7rem",
    borderRadius: "10px !important",
    background: color.whiteColor,
    display: "flex",
    justifyContent: "center",
    maxWidth: "247px !important"
})