export const conditions = [
  {
    id: 1,
    title: "Introduction",
    items: [
      {
        id: "1.1",
        text: (
          <span>
            These terms govern the transfer of Personal Data between Client and
            MadTech and the Processing of that Personal Data under those certain
            MadTech Terms of Services dated October 07, 2024 between the Parties
            (the “<b>Terms of Service</b>”), pursuant to which MadTech provides
            certain services to Client. By agreeing to the Terms of Service, of
            which this DPA forms an integral part, the Parties agree to be bound
            by the following terms and conditions of this DPA relating to the
            Processing of Personal Data.
          </span>
        ),
      },
      {
        id: "1.2",
        text: (
          <span>
            Except as modified by this DPA, the Terms of Service shall remain in
            full force and effect.
          </span>
        ),
      },
    ],
  },
  {
    id: 2,
    title: "Definitions; Interpretation",
    items: [
      {
        id: "2.1",
        text: (
          <span>
            In this DPA, unless the context otherwise requires, the following
            words shall have the meanings set forth below.
          </span>
        ),
        subItems: [
          {
            id: "2.1.1",
            text: (
              <span>
                “<b>CCPA</b>” means the California Consumer Privacy Act of 2018,
                as amended by the California Privacy Rights Act of 2020 (the “
                <b>CPRA</b>”), and any regulations promulgated thereunder, in
                each case, as amended from time to time.
              </span>
            ),
          },
          {
            id: "2.1.2",
            text: (
              <span>
                “<b>Client Personal Data</b>” means Personal Data in any form
                made Processed by MadTech in connection with and in the
                provision of the Services to Client.
              </span>
            ),
          },
          {
            id: "2.1.3",
            text: (
              <span>
                “<b>Data Protection Laws</b>” means, the laws and regulations,
                including any amendment, supplement, update, modification to or
                re-enactment of such laws and regulations, of any relevant
                jurisdiction applicable to the Processing of Personal Data in
                connection with the Services, including, as relevant: (i) US
                Privacy Laws; (ii) the EU General Data Protection Regulation (“
                <b>EU GDPR</b>”) and the UK General Data Protection Regulation
                pursuant to the UK Data Protection Act 2018 (“
                <b>UK GDPR</b>” and together, “<b>GDPR</b>”); (iii)
                Switzerland’s Federal Act on Data Protection of 19 June 1992 as
                revised 25 September 2020 (“<b>FADP</b>”); (iv) the Personal
                Information Protection and Electronic Documents Act (“
                <b>PIPEDA</b>”) and any related provincial privacy laws in
                Canada; and (v) all other data privacy and/or security
                legislation applicable to those jurisdictions whose residents
                have Client Personal Data that is Processed by MadTech.
              </span>
            ),
          },
          {
            id: "2.1.4",
            text: (
              <span>
                “<b>Data Subject</b>” shall mean a natural person whose personal
                information/data is Processed.
              </span>
            ),
          },
          {
            id: "2.1.5",
            text: (
              <span>
                “<b>Data Subject Request</b>” means an allegation, request or
                complaint received directly from Data Subjects relating to
                MadTech’s Processing of Client Personal Data, including any
                request to exercise such individual’s rights under Data
                Protection Laws (e.g., to access, correct, delete, opt-out
              </span>
            ),
          },
          {
            id: "2.1.6",
            text: (
              <span>
                "<b>EEA</b>” means the European Economic Area;
              </span>
            ),
          },
          {
            id: "2.1.7",
            text: (
              <span>
                “<b>Information Security Incident</b>” means any actual or
                reasonably suspected accidental, unauthorized or unlawful
                destruction, encryption, loss, use, alteration, unavailability,
                disclosure of, or access to, or any other misuse of, Client
                Personal Data while in MadTech’s possession or under MadTech’s
                control.
              </span>
            ),
          },
          {
            id: "2.1.8",
            text: (
              <span>
                “<b>Personal Data</b>” means information that (i) relates to an
                identified or identifiable natural person or (ii) constitutes
                Personal Data, Personal Information, or any other analogous term
                under Data Protection Laws.
              </span>
            ),
          },
          {
            id: "2.1.9",
            text: (
              <span>
                “<b>Processing</b>” means any operation or set of operations
                which is performed on Personal Data or on sets of Personal Data,
                whether or not by automated means, such as collection,
                recording, organization, structuring, storage, adaptation or
                alteration, retrieval, consultation, use, disclosure by
                transmission, dissemination or otherwise making available,
                alignment or combination, restriction, erasure or destruction. “
                <b>Process</b>“, “<b>Processes</b>” and “<b>Processed</b>” will
                be interpreted accordingly.
              </span>
            ),
          },
          {
            id: "2.1.10",
            text: (
              <span>
                "<b>Restricted Transfer</b>” means a Transfer to another
                country, territory, sector or international organization which
                the European Commission has not recognized via an adequacy
                decision as providing an equivalent level of protection for
                Personal Data, necessitating the need for contractual clauses
                ensuring appropriate data protection safeguards (e.g., SCCs).
              </span>
            ),
          },
          {
            id: "2.1.11",
            text: (
              <span>
                "<b>SCCs”</b> means (i) the standard contractual clauses set out
                in Commission Implementing Decision (EU) 2021/914 for the
                transfer of Personal Data to third countries pursuant to the
                GDPR as updated, amended, replaced and superseded from time to
                time ("<b>EU SCCs"</b>); and where applicable (ii) the United
                Kingdom’s International Data Transfer Addendum to the EU
                Commission Standard Contractual Clauses issued by the UK
                Information Commissioner under section 119A(1) Data Protection
                Act 2018 as updated, amended, replaced and superseded from time
                to time (the “<b>UK Addendum</b>”).
              </span>
            ),
          },
          {
            id: "2.1.12",
            text: (
              <span>
                “<b>Security Measures</b>” has the meaning given in Section 4.1.
              </span>
            ),
          },
          {
            id: "2.1.13",
            text: (
              <span>
                "<b>Services”</b> means any services provided by MadTech to
                Client under the Terms of Service.
              </span>
            ),
          },
          {
            id: "2.1.14",
            text: (
              <span>
                “<b>Subprocessors</b>” means vendors or other third parties that
                MadTech engages to Process Client Personal Data relating to the
                Services.
              </span>
            ),
          },
          {
            id: "2.1.15",
            text: (
              <span>
                “<b>US Privacy Laws</b>” means any United States data protection
                or privacy laws or regulations, when applicable, including the
                CCPA, Connecticut Data Privacy Act (“
                <b>CTDPA</b>”), Virginia Consumer Data Protection Act (“
                <b>VCDPA</b>”), the Colorado Privacy Act (<b>“CPA”</b>), the
                Utah Consumer Privacy Act (<b>“UCPA”</b>), the Texas Data
                Privacy and Security Act (<b>“TDPSA”</b>), Oregon Consumer
                Privacy Act (<b>“OCPA”</b>), the Montana Consumer Data Privacy
                Act ("<b>MTCDPA</b>"), state security breach notification laws
                and guidance promulgated by the Federal Trade Commission
                pursuant to Section 5 of the Federal Trade Commission Act.
              </span>
            ),
          },
          {
            id: "2.1.16",
            text: (
              <span>
                The terms <b>“Controller”</b>, <b>“Personal Data Breach”</b>,{" "}
                <b>“Processor”</b>, <b>“Sale”</b>, <b>“Sell”</b>,{" "}
                <b>“Selling”</b>,<b>“Share”</b>, <b>“Sharing”</b>,{" "}
                <b>“Special Categories of Personal Data”</b>,{" "}
                <b>“Supervisory Authority”</b> and <b>“Transfer”</b> (or their
                corresponding terms in individual Data Protection Laws) have the
                meanings given to those terms under their relevant Data
                Protection Laws, as applicable.
              </span>
            ),
          },
        ],
      },
      {
        id: "2.2",
        text: (
          <span>
            The headings in this DPA are inserted for convenience and do not
            affect the construction of this DPA.
          </span>
        ),
      },
      {
        id: "2.3",
        text: (
          <span>
            Any words following the terms including, include, in particular or
            for example or any similar phrase shall be construed as illustrative
            and shall not limit the generality of the related general words.
          </span>
        ),
      },
      {
        id: "2.4",
        text: (
          <span>
            References to the word: (i) “ensure” and its derivatives means to
            use commercially reasonable efforts to pursue the stated aim and
            under no circumstances implies or constitutes any guaranty of
            results or outcomes or any express or implied legal covenant,
            warranty or representation; (ii) ”best efforts”, “commercially
            reasonable efforts” or “reasonable efforts” means acting with
            diligence and good faith in the performance of the obligation; and
            (iii) “immediately” means promptly and without undue delay
          </span>
        ),
      },
    ],
  },
  {
    id: 3,
    title: "Application and Duration of Addendum",
    items: [
      {
        text: (
          <span>
            This DPA shall come into full force and effect on the Effective Date
            (as defined in the Terms of Service), and remain in effect so long
            as, MadTech retains any Client Personal Data in its possession or
            control (<b>“Term”</b>).
          </span>
        ),
      },
    ],
  },
  {
    id: 4,
    title: "Client Obligations",
    items: [
      {
        id: "4.1",
        text: (
          <span>
            For purposes of this DPA, Client is the Controller of the Client
            Personal Data.
          </span>
        ),
      },
      {
        id: "4.2",
        text: (
          <span>
            Client will not make accessible, communicate, transfer or transmit
            to MadTech, or instruct MadTech to Process, any Personal Data that
            MadTech should not receive in connection with the delivery of the
            Services or for which Client has not obtained all consents and other
            legal authorizations necessary to permit MadTech access to such
            Personal Data.
          </span>
        ),
      },
      {
        id: "4.3",
        text: (
          <span>
            Client shall at all times during this DPA comply with Client’s
            obligations under the Data Protection Laws. In the event Client
            makes a determination that it is no longer able to meet its
            obligations under the Data Protection Laws, Client shall notify
            MadTech in writing within five (5) business days of such
            determination, and MadTech may suspend or terminate this DPA and the
            Terms of Service upon written notice to Client, unless and until
            Client first notifies MadTech that it able to resume meeting
            Client’s obligations under such Data Protection Laws.
          </span>
        ),
      },
    ],
  },
  {
    id: 5,
    title: "Definitions; Interpretation",
    items: [
      {
        id: "5.1",
        text: (
          <span>
            For purposes of this DPA, MadTech is a Processor of Client Personal
            Data.
          </span>
        ),
      },
      {
        id: "5.2",
        text: (
          <span>
            With respect to the CCPA and Client Personal Data, MadTech will act
            as a “service provider” and Client will act as a “business”, as each
            of those terms are defined under Cal. Civ. Code § 1798.140.
          </span>
        ),
      },
      {
        id: "5.3",
        text: (
          <span>
            Client hereby instructs and authorizes MadTech to receive and
            Process Client Personal Data on its behalf in the capacity of a
            Processor and/or Service Provider (as applicable) for the purpose of
            providing the Services (including to build, enhance or improve the
            quality of MadTech’s services).
          </span>
        ),
      },
      {
        id: "5.4",
        text: (
          <span>
            MadTech acknowledges and agrees that it will in all material
            respects comply with its obligations under Data Protection Laws and
            will only Process Client Personal Data, which is described in Annex
            I.B to the SCCs, in order to provide the Services and perform its
            obligations under this DPA. In the event MadTech makes a
            determination that it is no longer able to meet its obligations
            under the Data Protection Laws, MadTech shall notify Client in
            writing within five (5) business days of such determination, and
            Client may terminate this DPA and the Terms of Service upon written
            notice to MadTech, unless and until MadTech first notifies Client
            that MadTech is able to resume meeting MadTech’s obligations under
            the Data Protection Laws.
          </span>
        ),
      },
      {
        id: "5.5",
        text: <span>MadTech shall:</span>,
        subItems: [
          {
            id: "5.5.1",
            text: (
              <span>
                Process Client Personal Data only as and to the extent necessary
                for MadTech to perform its obligations under this DPA and the
                Terms of Service, in accordance with Client’s instructions
                (which may be specific instructions or instructions of a general
                nature as set out in this DPA or the Terms of Service or as
                otherwise notified by Client to MadTech from time to time), in
                compliance with MadTech’s obligations under the Data Protection
                Laws;
              </span>
            ),
          },
          {
            id: "5.5.2",
            text: (
              <span>
                provide training to its personnel with respect to Data
                Protection Laws;
              </span>
            ),
          },
          {
            id: "5.5.3",
            text: (
              <span>
                not transfer Client Personal Data to a third country or any
                party located outside the EEA without the prior consent of
                Client, unless required or permitted to do so by applicable
                European Union law; provided however that MadTech may transfer
                Client Personal Data to any of its Subprocessors located outside
                the EEA where (i) such Subprocessor is located in a third
                country in respect of which the European Commission has issued a
                finding of adequacy with regard to data protection; or (ii)
                there are appropriate safeguards (at least as protective as the
                Security Measures) in place in accordance with Article 46 of the
                GDPR between MadTech and the Subprocessor and at least the same
                level of privacy protection is provided as required under Annex
                II attached hereto and Client and MadTech hereby enter into the
                SCCs in the case of such a transfer;
              </span>
            ),
          },
          {
            id: "5.5.4",
            text: (
              <span>
                only use, reproduce or otherwise Process any Client Personal
                Data collected in connection with providing the Services to (i)
                the extent necessary to provide the Services, (ii) the extent
                permitted by Data Protection Laws and (iii) build, enhance or
                improve the quality of MadTech’s services; the Parties agree
                that the disclosure, dissemination, transfer, use, reproduction
                or other Processing of any Client Personal Data under this DPA
                is not a “Selling” or “Sharing” of Personal Data under US
                Privacy Laws or any other Data Protection Laws;
              </span>
            ),
          },
          {
            id: "5.5.5",
            text: (
              <span>
                not modify, amend, or alter the contents of Client Personal
                Data, except as directed by Client (explicitly or as directed by
                the Terms of Service) or expressly permitted by Data Protection
                Laws (e.g., MadTech will not combine or update Client Personal
                Data received from, or on behalf of, Client with Personal Data
                that it received from another source unless and to the extent
                permitted by Data Protection Laws); and
              </span>
            ),
          },
          {
            id: "5.5.6",
            text: (
              <span>
                upon notice, permit Client to take such steps as may be
                reasonable (i) to stop and remediate any unauthorized use of
                Client Personal Data, and (ii) to help ensure that MadTech uses
                Client Personal Data in a manner consistent with Client’s
                obligations under Data Protection Laws.
              </span>
            ),
          },
        ],
      },
      {
        id: "5.6",
        text: (
          <span>
            MadTech may disclose Client Personal Data to its personnel who (i)
            need to know for the purpose of providing the Services and (ii) have
            committed themselves to confidentiality or are subject to a signed
            agreement which imposes obligations of confidentiality no less
            strict than as described in this DPA.
          </span>
        ),
      },
      {
        id: "5.7",
        text: (
          <span>
            If MadTech or its respective personnel are required by law and / or
            an order of any court of competent jurisdiction or any applicable
            regulatory, judicial or governmental body to disclose Client
            Personal Data, MadTech shall, to the extent practicable and except
            where prohibited by law, first:
          </span>
        ),
        subItems: [
          {
            id: "5.7.1",
            text: (
              <span>
                Give Client notice of the details of the proposed disclosure;
              </span>
            ),
          },
          {
            id: "5.7.2",
            text: (
              <span>
                give Client a reasonable opportunity to take any steps it
                considers necessary to protect the confidentiality of Client
                Personal Data including seeking such judicial redress as Client
                may reasonably see fit in the circumstances; and
              </span>
            ),
          },
          {
            id: "5.7.3",
            text: (
              <span>
                inform the proposed recipient that the information is
                confidential Client Personal Data.
              </span>
            ),
          },
        ],
      },
    ],
  },
  {
    id: 6,
    title: "Security",
    items: [
      {
        text: (
          <span>
            MadTech will implement and maintain the reasonable technical,
            administrative and physical measures set forth in Annex II (the
            “Security Measures”) to protect Client Personal Data against
            Information Security Incidents. Such Security Measures shall be
            designed in a manner intended to provide a level of security
            reasonable and appropriate to the risks presented by the Processing
            and the nature of Client Personal Data to be protected having regard
            to the state of the art and the cost of their implementation.
          </span>
        ),
      },
    ],
  },
  {
    id: 7,
    title: "Information Security Incidents",
    items: [
      {
        id: "7.1",
        text: (
          <span>
            <b style={{ textDecoration: "underline" }}>
              Notice of Information Security Incident.
            </b>{" "}
            If MadTech becomes aware of any Information Security Incident,
            MadTech shall (a) notify Client of the Information Security Incident
            without undue delay and in no event later than two (2) business days
            after becoming aware of the Information Security Incident and (b)
            take commercially reasonable steps to identify the cause of such
            Information Security Incident, minimize harm and reduce the
            likelihood of a recurrence. Notifications made pursuant to this
            Section 7.1 shall include, to the extent possible, a description of
            the Information Security Incident, including the suspected cause,
            the nature of the information affected and the number and categories
            of individuals affected.
          </span>
        ),
      },
      {
        id: "7.2",
        text: (
          <span>
            <b style={{ textDecoration: "underline" }}>
              Cooperation with Client.
            </b>{" "}
            MadTech will cooperate with Client and take such commercially
            reasonable steps as may be reasonably directed by Client to assist
            in the investigation of any such Information Security Incident,
            including providing Client with facts about the Information Security
            Incident reasonably necessary to support Client’s own assessment of
            the associated risk to Client Personal Data. Client shall reimburse
            MadTech for all such efforts, unless and to the extent the
            applicable Information Security Incident was caused by MadTech’s
            breach of its obligations under this DPA.
          </span>
        ),
      },
    ],
  },
  {
    id: 8,
    title: "Data Subject Requests; Assistance and Co-operation",
    items: [
      {
        id: "8.1",
        text: (
          <span>
            <b style={{ textDecoration: "underline" }}>
              Notice to Client.
            </b>{" "}
            If either Party receives any Data Subject Request, such Party shall
            notify the other Party in writing of such request or complaint
            promptly and in no event later than five (5) calendar days after
            such Party’s receipt thereof, providing full details of the request
            or complaint sufficient to enable the other Party to respond.
            MadTech shall not take any action in response to such Data Subject
            Request except in accordance with Client’s written instructions
            unless otherwise required by Data Protection Laws.
          </span>
        ),
      },
      {
        id: "8.2",
        text: (
          <span>
            <b style={{ textDecoration: "underline" }}>
              Data Subject Request Assistance.
            </b>{" "}
            Each Party shall provide the other Party with such assistance as a
            Party may reasonably request to timely respond to Data Subject
            Requests within any deadlines imposed by Data Protection Laws.
          </span>
        ),
      },
      {
        id: "8.3",
        text: (
          <span>
            <b style={{ textDecoration: "underline" }}>
              Co-operation with Supervisory Authority Requests.
            </b>{" "}
            Each Party shall co-operate with the other Party to the extent
            necessary to enable such other Party to comply with any requests of
            any relevant Supervisory Authority or any other competent
            supervisory or other regulatory authority in respect of Client
            Personal Data Processed by MadTech under this DPA. In particular,
            each Party shall:
          </span>
        ),
        subItems: [
          {
            id: "8.3.1",
            text: (
              <span>
                Promptly inform the other Party upon becoming aware if, in its
                opinion, an instruction given, or request made by such other
                Party infringes Data Protection Laws;
              </span>
            ),
          },
          {
            id: "8.3.2",
            text: (
              <span>
                Provide such reasonable co-operation and assistance as the other
                Party may require to enable such other Party to comply with its
                obligations under Data Protection Laws; and
              </span>
            ),
          },
          {
            id: "8.3.3",
            text: (
              <span>
                Notify the other Party as soon as reasonably practicable if it
                makes a determination that it can no longer meet its obligations
                under the Data Protection Laws.
              </span>
            ),
          },
        ],
      },
    ],
  },
  {
    id: 9,
    title: "Audits",
    items: [
      {
        id: "9.1",
        text: (
          <span>
            <b style={{ textDecoration: "underline" }}>Scope.</b> MadTech
            shall make available to Client the information reasonably necessary
            for Client to demonstrate compliance with its obligations under Data
            Protection Laws. Client (or an auditor appointed by Client that
            enters into a confidentiality agreement with MadTech, in form and
            substance satisfactory to MadTech) may but is not obligated to audit
            (including through inspections, manual reviews, and automated scans
            and other technical and operational testing) MadTech’s compliance
            with its obligations under this DPA and applicable Data Protection
            Laws, up to once per year and more often if required by Data
            Protection Laws or by a governmental authority, and MadTech will
            reasonably cooperate with such audits. Such audits may require
            MadTech to complete questionnaires and/or make available relevant
            documents and records for review and relevant MadTech personnel for
            interviews. Any audit by or on behalf of Client must be conducted
            during MadTech’s regular business hours, and may not unreasonably
            interfere with or disrupt MadTech’s business or operations.
          </span>
        ),
      },
      {
        id: "9.2",
        text: (
          <span>
            <b style={{ textDecoration: "underline" }}>Audit Reports.</b>{" "}
            If the controls or measures to be assessed in the requested audit
            are addressed in an SOC 2 Type 2, ISO, or NIST audit report
            performed by a qualified and independent third party auditor within
            the 12 months preceding Client’s audit request and MadTech has
            certified in writing that there are no known material changes in the
            controls audited, Client shall accept such report in lieu of
            performing an audit of such controls or measures, unless otherwise
            expressly required by Data Protection Laws or a governmental
            authority. MadTech will provide Client with a copy of any such audit
            report obtained by MadTech, upon Client’s request.
          </span>
        ),
      },
    ],
  },
  {
    id: 10,
    title: "Subprocessors",
    items: [
      {
        id: "10.1",
        text: (
          <span>
            <b style={{ textDecoration: "underline" }}>
              Consent to Subprocessor Engagement.{" "}
            </b>
            MadTech shall have the right at its discretion to appoint one or
            more Subprocessors. Without limiting the generality of the
            foregoing, Client acknowledges and consents to MadTech’s engagement
            of the Subprocessors (together with their locations), if any, listed
            in the space below:
            <div style={{ marginTop: "8px" }}>
              <i>
                Amazon Web Services, Inc. 410 Terry Avenue North, Seattle, WA
                98109-5210, U.S.A.{" "}
              </i>
            </div>
            <div style={{ marginTop: "8px" }}>
              <i>
                Aqfer, Inc. 9100 Conroy Windermere Rd., Suite 200, Windermere,
                FL 34786
              </i>
            </div>
          </span>
        ),
      },
      {
        id: "10.2",
        text: (
          <span>
            <b style={{ textDecoration: "underline" }}>
              Subprocessor Requirements.
            </b>{" "}
            MadTech shall enter into a written contract with each Subprocessor
            containing privacy, confidentiality and data security obligations
            with respect to Client Personal Data at least equivalent in
            substance to the terms which MadTech is bound relating to this DPA.
            MadTech will remain responsible for its compliance with the
            obligations of this DPA and for any acts or omissions of a
            Subprocessor that cause MadTech to breach any of MadTech’s
            obligations under this DPA.
          </span>
        ),
      },
      {
        id: "10.3",
        text: (
          <span>
            <b style={{ textDecoration: "underline" }}>
              Subprocessor Changes.
            </b>{" "}
            MadTech may engage additional Subprocessors not listed in
            Section10.1, provided that MadTech shall notify Client of each such
            engagement (including the name and location of the relevant
            Subprocessor and the activities it will perform).
          </span>
        ),
      },
    ],
  },
  {
    id: 11,
    title: "Restricted Transfers",
    items: [
      {
        text: (
          <span>
            With respect to Restricted Transfers the SCCs are hereby
            incorporated into this DPA by reference and will come into effect
            upon commencement of any such Restricted Transfer.
          </span>
        ),
      },
      {
        id: "11.1",
        text: (
          <span>
            Where a Restricted Transfer is subject to the GDPR the following
            terms shall apply:
          </span>
        ),
        subItems: [
          {
            id: "11.1.1",
            text: (
              <span>
                Annex IA of the EU SCCs attached hereto will be populated with
                the details of the Parties, Annex IB of the EU SCCs attached
                hereto will be populated with the description of the Processing
                of Personal Data;
              </span>
            ),
          },
          {
            id: "11.1.2",
            text: (
              <span>
                For the purposes of Modules 1, 2 and 3 of the EU SCCs: clause 7
                and the optional language in clause 11(a) shall not apply;
              </span>
            ),
          },
          {
            id: "11.1.3",
            text: (
              <span>
                For the purposes of clause 9, the Parties select Option 2
                (General authorization);
              </span>
            ),
          },
          {
            id: "11.1.4",
            text: (
              <span>
                The technical and organizational security measures set out in
                Annex II of the EU SCCs attached hereto shall apply;
              </span>
            ),
          },
          {
            id: "11.1.5",
            text: (
              <span>
                The supervisory authority for the purposes of Clause 13(a) shall
                be determined by the place of establishment of the data
                exporter; the governing law and choice of forum and jurisdiction
                stipulated in this DPA shall apply to the extent that it is the
                law and the courts of an EU member state otherwise it shall be
                those of the Republic of Ireland; and
              </span>
            ),
          },
          {
            id: "11.1.6",
            text: (
              <span>
                The frequency of the transfer shall be continuous, as necessary
                to deliver the Services, and retention shall be determined by
                Client, except where Client is required by applicable laws to
                retain Personal Data in accordance with Client’s corporate
                record retention schedules and policies.
              </span>
            ),
          },
        ],
      },
      {
        id: "11.2",
        text: (
          <span>
            Where a Restricted Transfer is subject to both EU GDPR and UK GDPR
            the following terms with respect to the UK Addendum shall, in
            addition to Clause 11.1 above, also apply:
          </span>
        ),
        subItems: [
          {
            id: "11.2.1",
            text: (
              <span>
                The EU SCCs shall be read in accordance with, and deemed amended
                by, the provisions of Part 2 (Mandatory Clauses) of the UK
                Addendum;
              </span>
            ),
          },
          {
            id: "11.2.2",
            text: (
              <span>
                At Clause 2(h) of the EU SCCs, the Parties select option (iii);
              </span>
            ),
          },
          {
            id: "11.2.3",
            text: (
              <span>
                The data subjects, categories of Personal Data and the purposes
                of the transfer are as specified in the EU SCCs, and the
                recipients are the recipients to whom it is necessary to
                disclose data to achieve the purposes;
              </span>
            ),
          },
          {
            id: "11.2.4",
            text: (
              <span>
                The contact points for data protection enquiries are the usual
                business contacts for each Party; and
              </span>
            ),
          },
          {
            id: "11.2.5",
            text: (
              <span>
                The Parties confirm that the information required for the
                purposes of Part 1 (Tables) of the UK Addendum is set out in DPA
                Exhibit 2.
              </span>
            ),
          },
        ],
      },
      {
        id: "11.3",
        text: (
          <span>
            With respect to Restricted Transfers from Switzerland, the EU SCCs,
            Section 11.1 and the following additional terms shall apply:
          </span>
        ),
        subItems: [
          {
            id: "11.3.1",
            text: (
              <span>
                For purposes of the EU SCCs, the terms “General Data Protection
                Regulation” or “Regulation (EU) 2016/679” as utilized in the EU
                SCCs shall be interpreted to include the FADP;
              </span>
            ),
          },
          {
            id: "11.3.2",
            text: (
              <span>
                Clause 13 of the EU SCCs is modified to provide that the Federal
                Data Protection and Information Commissioner of Switzerland
                shall have authority over data transfers governed by the FADP
                and the appropriate EU supervisory authority shall have
                authority over data transfers governed by the GDPR (subject to
                the foregoing, all other requirements of Clause 13 shall be
                observed);
              </span>
            ),
          },
          {
            id: "11.3.3",
            text: (
              <span>
                The term “EU Member State” as utilized in the EU SCCs shall not
                be interpreted in such a way as to exclude Data Subjects in
                Switzerland from exercising their rights in their place of
                habitual residence in accordance with Clause 18(c) of the EU
                SCCs; and;
              </span>
            ),
          },
          {
            id: "11.3.4",
            text: (
              <span>The law that applies will be the laws of Switzerland.</span>
            ),
          },
        ],
      },
      {
        id: "11.4",
        text: (
          <span>
            For the avoidance of doubt (and without prejudice to third party
            rights for data subjects under the SCCs) the Parties hereby submit
            to the limitations stipulated in this DPA with respect to their
            respective liability towards one another under the SCCs and FADP.
          </span>
        ),
      },
      {
        id: "11.5",
        text: (
          <span>
            To the extent that there is any conflict or inconsistency between
            the terms of the SCCs or FADP and the terms of this DPA, the terms
            of the SCCs or FADP shall take precedence.
          </span>
        ),
      },
      {
        id: "11.6",
        text: (
          <span>
            If, and to the extent that, the European Commission, the United
            Kingdom or Switzerland issues any amendment to, or replacement of,
            the EU SCCs, the UK Addendum or the FADP (as applicable), the
            Parties agree in good faith to take such additional steps as
            necessary to provide that such replacement terms are implemented
            across all transfers.
          </span>
        ),
      },
      {
        id: "11.7",
        text: (
          <span>
            If, at any time, a supervisory authority or a court with competent
            jurisdiction over a Party mandates that transfers from controllers
            in the EEA, the United Kingdom or Switzerland to controllers or
            Processors established outside the EEA, the United Kingdom or
            Switzerland must be subject to specific additional safeguards
            (including specific technical and organizational measures), the
            Parties shall work together in good faith to implement such
            safeguards and provide that any transfer of Personal Data is
            conducted with the benefit of such additional safeguards.
          </span>
        ),
      },
    ],
  },
  {
    id: 12,
    title: "Limitations of Liability",
    items: [
      {
        id: "12.1",
        text: (
          <span>
            Notwithstanding anything to the contrary in this DPA and/or the
            Terms of Service, MadTech’s total, cumulative liability under this
            DPA for all causes of action (whether in contract, tort, strict
            liability or otherwise) shall be limited to an amount equal to the
            fees (excluding taxes and reimbursable expenses) actually paid by
            Client to MadTech for the Services during the twelve (12) months
            preceding the occurrence giving rise to the applicable claim.
          </span>
        ),
      },
      {
        id: "12.2",
        text: (
          <span>
            Notwithstanding anything to the contrary in this DPA and/or the
            Terms of Service, in no event shall MadTech be liable under this DPA
            for any indirect, incidental, consequential, reliance or punitive
            damages or lost or imputed profits, lost data, unrealized savings,
            lost revenue, diminished share price, loss of good will,
            reputational harm, shareholder derivative suits or other business
            losses of any kind, in each case regardless of whether MadTech was
            advised of the possibility of such losses or damages or such losses
            or damages were otherwise foreseeable, and notwithstanding the
            failure of any agreed or other remedy of its essential purpose.
          </span>
        ),
      },
    ],
  },
  {
    id: 13,
    title: "Termination; Return or Destruction of Personal Data",
    items: [
      {
        id: "13.1",
        text: (
          <span>
            {" "}
            <b style={{ textDecoration: "underline" }}>Termination.</b> If
            either Party fails to perform any of its material obligations under
            this DPA and does not cure such failure within thirty (30) days of
            receipt of a notice of default from the other Party, then the other
            Party may, by giving notice to the defaulting Party terminate this
            DPA as of the date specified in such notice of termination.
          </span>
        ),
      },
      {
        id: "13.2",
        text: (
          <span>
            <b style={{ textDecoration: "underline" }}>
              Return or Destruction of Personal Data.
            </b>{" "}
            MadTech, upon Client’s reasonable request upon termination of the
            Services shall:
          </span>
        ),
        subItems: [
          {
            id: "13.2.1",
            text: (
              <span>
                Cease Processing any Client Personal Data relating to the
                Services; and
              </span>
            ),
          },
          {
            id: "13.2.2",
            text: (
              <span>
                Delete or cause the deletion of all Client Personal Data in
                MadTech’s care, custody or control as soon as reasonably
                practicable, except to the extent applicable Data Protection
                Laws permit the retention or storage of the applicable Client
                Personal Data. Notwithstanding the foregoing, MadTech may retain
                Client Personal Data in backups of its information technology
                devices and systems made in the ordinary course of business;
                provided that it may not seek to access such information except
                to the extent permitted by law, requested by Client or as part
                of a system restoration.
              </span>
            ),
          },
        ],
      },
      {
        id: "13.3",
        text: (
          <span>
            {" "}
            <b style={{ textDecoration: "underline" }}>Survival</b>{" "}
            Subsection 13.3 and Sections 1, 2, and 11-14 shall survive the
            termination of this DPA for any reason.
          </span>
        ),
      },
    ],
  },
  {
    id: 14,
    title: "Miscellaneous",
    items: [
      {
        id: "14.1",
        text: (
          <span>
            <b style={{ textDecoration: "underline" }}>
              {" "}
              Entire Agreement.
            </b>{" "}
            This DPA and the Terms of Service constitute the entire agreement
            between the Parties relating to the Processing of Client Personal
            Data and supersede any previous agreements arrangements or
            understandings between them relating to their subject matter. In the
            event of any inconsistency or ambiguity between the terms of this
            DPA and the terms of the Terms of Service or any other agreement
            between the Parties in relation to the Processing of Client Personal
            Data, the terms of this DPA shall prevail.
          </span>
        ),
      },
      {
        id: "14.2",
        text: (
          <span>
            <b style={{ textDecoration: "underline" }}>
              {" "}
              Changes to this DPA.
            </b>{" "}
            We reserve the right to update this DPA from time to time in order
            to reflect, changes to our practices or for other operational,
            legal, or regulatory reasons. When we do update this DPA, we will
            post the updates and changes on our website. We may elect to notify
            you of material changes by mail, email, posting of modified DPA, or
            some other similar manner. However, it is your responsibility to
            check our website regularly for changes to this DPA. Your continued
            use of or access to the Services following the posting of any
            changes to this DPA constitutes acceptance of those changes.
          </span>
        ),
      },
      {
        id: "14.3",
        text: (
          <span>
            <b style={{ textDecoration: "underline" }}> Waiver.</b> No
            failure or delay by a Party to exercise any right or remedy provided
            under this DPA or any Data Protection Law shall constitute a waiver
            of that or any other right or remedy, nor shall it prevent or
            restrict the further exercise of that or any other right or remedy.
            No single or partial exercise of such right or remedy shall prevent
            or restrict the further exercise of that or any other right or
            remedy.
          </span>
        ),
      },
      {
        id: "14.4",
        text: (
          <span>
            <b style={{ textDecoration: "underline" }}>
              {" "}
              Invalidity and Severability.
            </b>{" "}
            Each and every obligation under this DPA shall be treated as a
            separate obligation and shall be severally enforceable as such, and
            in the event of any obligation or obligations being found by any
            authority of competent jurisdiction to be invalid or unenforceable,
            such invalidity or unenforceability shall not affect the other
            provisions or parts of such provisions of this DPA, all of which
            shall remain in full force and effect.
          </span>
        ),
      },
      {
        id: "14.5",
        text: (
          <span>
            <b style={{ textDecoration: "underline" }}>
              {" "}
              Governing Law / Jurisdiction.
            </b>{" "}
            Except to the limited extent otherwise provided in Article 11 above,
            this DPA shall in all respects be governed by and construed in
            accordance with the laws of the State of Connecticut, without regard
            to its conflicts of laws principles, and the Parties irrevocably
            agree that the state courts of Connecticut or the United States
            District Court of the District of Connecticut (and appellate courts
            therefrom) shall have exclusive jurisdiction to settle any dispute
            or claim that arises out of or in connection with this DPA or its
            subject matter or formation (including non-contractual disputes or
            claims).
          </span>
        ),
      },
    ],
  },
];
