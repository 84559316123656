import styled, { createGlobalStyle } from 'styled-components';
import { Prism as SyntaxHighlighter } from 'react-syntax-highlighter';
    
export const SqlCommandSection = styled(SyntaxHighlighter)`
    span {
        font-family: inherit;
    }
`

// export const SqlCommandSection = createGlobalStyle`
//   /* Target spans inside the code block */
//   .react-syntax-highlighter-line > span {
//     color: red !important; /* Example: Change text color to red */ 
//   }`;