import { Box, Fade, Modal, Typography } from '@mui/material'
import React from 'react'
import { useMemo } from 'react';

import Cancel from "../../assets/cancelWhite.svg";
import Info from "../../assets/infoWhite.svg";
import { ContentTypograpy, MaintextTypograpy } from './styledComponent';

const InfoModal = ({ infoOpen, setInfoOpen, formdata, ModalName, isBatch }) => {

    const style = useMemo(() => ({
        position: "absolute",
        top: "50%",
        left: "50%",
        transform: "translate(-50%, -50%)",
        borderRadius: "8px 8px 8px 8px",
        bgcolor: "#F8F8FE",
        width: "36.25rem",
        boxShadow: 24,
        padding: "0px 0px 20px 0px",
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        alignItems: "center",
        "&:focus": {
            outline: "none",
        },
    }),
        []
    );
    return (
        <Modal
            aria-labelledby="transition-modal-title"
            aria-describedby="transition-modal-description"
            open={infoOpen}
            onClose={() => setInfoOpen(false)}
            closeAfterTransition
            // slots={{ backdrop: Backdrop }}
            slotProps={{
                backdrop: {
                    timeout: 500,
                },
            }}
        >
            <Fade in={infoOpen}
            >
                <Box sx={{ ...style, background: "#fff", borderRadius: "20px", }}>
                    <Box
                        sx={{
                            display: "flex",
                            justifyContent: "center",
                            alignItems: "center",
                            width: "100%",
                            height: 60,
                            borderTopLeftRadius: "20px",
                            borderTopRightRadius: "20px",
                            background: "#1C2B47"
                        }}
                    >
                        <Box sx={{
                            display: "flex",
                            justifyContent: "space-between",
                            width: "100%",
                            p: 2
                        }}>
                            <Box>
                                <img
                                    alt=""
                                    src={Info}
                                    style={{ width: 20, height: 20, color: "#fff", cursor: "pointer" }}
                                ></img>
                                <span style={{ color: "white", marginLeft: "10px", marginTop: "2px" }}>Information</span>
                            </Box>
                            <Box onClick={() => setInfoOpen(false)}>
                                <img
                                    alt=""
                                    src={Cancel}
                                    style={{ width: 20, height: 20, color: "#fff", cursor: "pointer" }}
                                ></img>
                            </Box>
                        </Box>
                    </Box>
                    {/* Annual Form Content */}
                    <Box sx={{ padding: "10px", width: "inherit", display: (formdata?.plan === "Annual" || formdata?.plan === "annual") ? "" : "none" }}>
                        <MaintextTypograpy> Annual Spend </MaintextTypograpy>
                        <ContentTypograpy> The annual spend amount committed as a part of the contract.</ContentTypograpy>
                        <MaintextTypograpy>Renewal Date </MaintextTypograpy>
                        <ContentTypograpy> Date when the contract is due for renewal.</ContentTypograpy>
                        <MaintextTypograpy> Min. Monthly Spend    </MaintextTypograpy>
                        <ContentTypograpy > Annual spend divided by twelve. </ContentTypograpy>
                        <MaintextTypograpy> CPC Rate   </MaintextTypograpy>
                        <ContentTypograpy> Starting CPC Teir and rate applied for your account.</ContentTypograpy>
                        <MaintextTypograpy> CPMR Discount </MaintextTypograpy>
                        <ContentTypograpy>Discount percentage applied for Batch connections pricing.</ContentTypograpy>
                    </Box>
                    {/* Pay As You Go Form Content */}
                    <Box sx={{ padding: "10px", width: "inherit", display: (formdata?.plan === "Monthly" || formdata?.plan === "monthly" || formdata?.plan === null) ? "" : "none" }}>
                        <MaintextTypograpy>
                            Pay As You Go
                        </MaintextTypograpy>
                        <Typography
                            sx={{
                                fontWeight: 400,
                                fontFamily: "Helvetica Neue",
                                fontSize: "14px",
                                textAlign: "start",
                                color: "#66696A",
                                width: "100%",
                                padding: "5px 8px 8px 8px"
                            }}
                        >
                            This is the current plan that is active under your account. Please contact MadConnect to make changes to your pricing plan.
                        </Typography>
                    </Box>
                    {/* Graph Content */}
                    <Box sx={{ padding: "10px", width: "inherit", display: ModalName === "graph" ? "" : "none" }}>
                        <MaintextTypograpy>
                            Spending Summary
                        </MaintextTypograpy>
                        <Typography
                            sx={{
                                fontWeight: 400,
                                fontFamily: "Helvetica Neue",
                                fontSize: "14px",
                                textAlign: "start",
                                color: "#66696A",
                                width: "100%",
                                padding: "5px 8px 8px 8px"
                            }}
                        >
                            Yet To Implement
                        </Typography>
                    </Box>
                    {/* Table Straming content Content */}
                    <Box sx={{ padding: "10px", width: "inherit", display: (ModalName === "table" && !isBatch) ? "" : "none" }}>
                        <MaintextTypograpy>
                            Unlimited
                        </MaintextTypograpy>
                        <Typography
                            sx={{
                                fontWeight: 400,
                                fontFamily: "Helvetica Neue",
                                fontSize: "14px",
                                textAlign: "start",
                                color: "#66696A",
                                width: "100%",
                                padding: "5px 8px 8px 8px"
                            }}
                        >
                            These are bi-directional data connectors designed for non-scheduled platform-to-platform communication via individual row or small batch rapid transfer of data via APIs. They follow a Cost-Per-Connection (CPC) pricing model.
                        </Typography>
                        <Typography
                            sx={{
                                fontWeight: 400,
                                fontFamily: "Helvetica Neue",
                                fontSize: "14px",
                                textAlign: "start",
                                color: "#66696A",
                                width: "100%",
                                padding: "5px 8px 8px 8px",
                                marginTop: "10px"
                            }}
                        ><span style={{ color: "black", fontFamily: "helvetica neue", fontWeight: "600", fontSize: "14px" }}>CPC pricing</span>: Each bi-directional connection costs a flat monthly CPC rate based on the current customer tier. There is no cost based on the amount of rows transferred. The following two pricing plans are offered </Typography>
                        <Box>
                            <ul>
                                <li style={{ marginTop: "10px", fontFamily: "helvetica neue", color: "#66696A", fontSize: "14px" }}> Pay-As-you-Go</li>
                                <ul style={{ marginTop: "10px", fontFamily: "helvetica neue", color: "#66696A", fontSize: "14px" }}> No annual commitment, pay for the number of active connectors used in a month based on the rate card.</ul>
                                <li style={{ marginTop: "10px", fontFamily: "helvetica neue", color: "#66696A", fontSize: "14px" }}> Annual Licensing</li>
                                <ul style={{ marginTop: "8px", fontFamily: "helvetica neue", color: "#66696A", fontSize: "14px" }}>Starting Tier: The agreed-upon Tier used to set the maximum cost of a connection throughout the term of the license that establishes the minimum number of monthly connections available to the licensor.</ul>
                                <ul style={{ marginTop: "8px", fontFamily: "helvetica neue", color: "#66696A", fontSize: "14px" }}>Automatic Tier Advancement: If during the term of a license, monthly active connections (MAC) grow to more than the Starting Tier, the CPC rate will automatically move to the next Tier in the CPC pricing schedule. The incremental cost per connection will decrease or increase automatically throughout the term of the license, but never increase above the Starting Tier incremental CPC.</ul>
                                <ul style={{ marginTop: "8px", fontFamily: "helvetica neue", color: "#66696A", fontSize: "14px" }}>CPC Rollover Spend: If the licensor does not use all of the Connections available in their starting tier throughout the year of the license, the licensor may carry over 100% of the unused balance of connections into the subsequent annual term. Upon contract completion, any unused balance will either be lost or 100% will be rolled into a new Order Form as a credit. </ul>
                                <ul style={{ marginTop: "8px", fontFamily: "helvetica neue", color: "#66696A", fontSize: "14px" }}>Monthly Cost Calculation: Base Cost Per Month + (Actual MAC - MAC Tier Threshold)  X Incremental CPC </ul>


                            </ul>
                        </Box>
                    </Box>
                    {/* Table Batch content Content */}
                    <Box sx={{ padding: "10px", width: "inherit", display: (ModalName === "table" && isBatch) ? "" : "none" }}>
                        <MaintextTypograpy >
                            Batch
                        </MaintextTypograpy>
                        <Typography
                            sx={{
                                fontWeight: 400,
                                fontFamily: "Helvetica Neue",
                                fontSize: "14px",
                                textAlign: "start",
                                color: "#66696A",
                                width: "100%",
                                padding: "5px 8px 8px 8px"
                            }}
                        >
                            These Connectors are used for scheduled, large file transfer of data, between platforms, designed for the purpose of transferring large volumes of data. They follow a Cost Per Month Row (CPMR) pricing structure.
                        </Typography>
                        <Typography sx={{
                            fontWeight: 400,
                            fontFamily: "Helvetica Neue",
                            fontSize: "14px",
                            textAlign: "start",
                            color: "#66696A",
                            width: "100%",
                            padding: "5px 8px 8px 8px",
                            marginTop: "10px"
                        }}
                        ><span style={{ color: "black", fontFamily: "helvetica neue", fontWeight: "600", fontSize: "14px" }}>Cost Per Monthly Rows</span> (CPMR) Monthly Transferred Rows (MTR) are calculated based on rows of data transferred between source and destination platforms. There is no cost per connection (CPC) in the CPMR pricing schedule, pricing is solely based on MTR usage. The number of rows is calculated as the total # of rows in a batch file or the total size of the file in bytes divided by 1024, whichever is higher. Under CPMR, the following two pricing plans are offered</Typography>
                        <Box>
                            <ul>
                                <li style={{ marginTop: "10px", fontFamily: "helvetica neue", color: "#66696A", fontSize: "14px" }}> Pay-As-you-Go</li>
                                <ul style={{ marginTop: "10px", fontFamily: "helvetica neue", color: "#66696A", fontSize: "14px" }}>Standard CPMR pricing schedule is used for calculations.</ul>
                                <li style={{ marginTop: "10px", fontFamily: "helvetica neue", color: "#66696A", fontSize: "14px" }}> Annual Licensing</li>
                                <ul style={{ marginTop: "8px", fontFamily: "helvetica neue", color: "#66696A", fontSize: "14px" }}>A discounted pricing schedule is applied for accounts that have an annual licensing contract. </ul>

                            </ul>
                            <Typography sx={{
                                fontWeight: 400,
                                fontFamily: "Helvetica Neue",
                                fontSize: "14px",
                                textAlign: "start",
                                color: "#66696A",
                                width: "100%",
                                padding: "5px 8px 8px 8px",
                                marginTop: "10px"
                            }}
                            >The pricing is calculated using a tiered approach. For each portion of volume falling under a tier, that portion is multiplied by the rate associated with the tier. The total price is a sum of each tier’s calculation.</Typography>
                        </Box>
                    </Box>
                </Box>
            </Fade>
        </Modal>
    )
}

export default InfoModal