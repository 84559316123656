import React, { useCallback, useEffect, useState } from "react";
import {
    useMediaQuery,
} from "@mui/material";

// import whiteLogo from '../../assets/madtechlight.svg'
// import madConnectLogo from '../../assets/MadConnectNewLogo1.svg'
import madConnectLogo from "../../assets/madconnectdark.png";
// import lineLogo from '../../assets/line_2.svg'
// import bookOpenLogo from '../../assets/book-open.svg'
import { Strings } from "../../utils/Strings";
import "react-phone-input-2/lib/bootstrap.css";
import CommanSignIn from "../../components/CommanOAuth/CommanSignIn";

function SignInOAuth() {
    const maxHeight = useMediaQuery('(max-height:515px)');

    return (
        <div style={{ display: 'flex', justifyContent: 'center', background: "#C4C4C4", alignItems: "center", height: maxHeight ? "" : "100vh" }}>
            <div style={{ display: "block" }}>
                <div style={{ textAlign: "center", padding: "20px" }}>
                    <img
                        style={{ width: "auto", height: "4rem", justifyContent: 'center' }}
                        alt={"MADCONNECT LOGO"}
                        src={madConnectLogo}
                    ></img>
                </div>
                <CommanSignIn
                    classname=""
                    LoginText={Strings.OAuthLogin}
                    OAuthType={true}
                    background="#C4C4C4"
                    TextFontSize="1rem"
                    ContentFontSize="13px"
                    display="none"

                />
            </div>
        </div>
    );
}

export default SignInOAuth;
