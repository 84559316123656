import { Box } from '@mui/material'
import React from 'react'
import useDocumentTitle from '../../hook/useDocumentTitle';
import HeaderPart from './HeaderPart';
import ContentPart from './ContentPart';

const FAQ = () => {
    useDocumentTitle('FAQ')
    return (
        <Box sx={{ mx: 'auto' }}>
           <HeaderPart />
           <ContentPart />
        </Box >
    )
}
export default FAQ