import { Typography } from '@mui/material'
import styled from 'styled-components'


export const ErrorMsg = styled(Typography)({
    color: "red",
    textAlign: "center",
    height: "1.25rem"
})

export const Heading= styled('p')({
    fontSize:(props)=> (props.TitlefontSize), 
    fontWeight: "700 !important" , 
    fontFamily: 'Helvetica Neue !important',
})

export const Para= styled('p')({
    fontSize: (props)=>(props.textforntSize), 
    fontFamily: 'Helvetica Neue !important', 
    fontWeight: '400 !important'
})

export const BackToLogin= styled('p')({
fontSize: "1rem !important",
 margin: "0 !important", 
 fontFamily: 'Helvetica Neue !important'
})