import {
  Box,
  FormControl,
  InputLabel,
  OutlinedInput,
  TableCell,
  TableRow,
  Typography,
} from "@mui/material";
import styled from "styled-components";

import { color } from "../../utils/Colors";
import PhoneInput from "react-phone-input-2";

export const HeadingWrapper = styled(Box)({
  display: "flex",
  justifyContent: "space-between",
  marginBottom: "16px",
  alignItems: "center",
});

export const BodyWrapper = styled(Box)({
  display: "flex",
  flexDirection: "column",
  justifyContent: "space-between",
  width: "100%",
  margin: "0px auto",
  marginBottom: "20px",
});

export const TableCellStyle = styled(TableCell)({
  fontWeight: "700 !important",
  backgroundColor: "rgba(25, 50, 65, 0.15) !important",
});

export const TableBodyRowStyle = styled(TableRow)({
  "&:last-child td, &:last-child th": { border: "0 !important" },
  "&:nth-child(even)": {
    background: "rgba(25, 50, 65, 0.05) !important",
  },
});

export const TableNameWrapper = styled(Box)({
  display: "flex",
  flexDirection: "column",
  justifyContent: "space-between",
});

export const TableUserName = styled(Typography)({
  fontSize: "0.875rem !important",
  fontFamily: "Helvetica Neue !important",
  textDecoration: "underline !important",
  color: color.buttonColor,
  cursor: "pointer !important",
});

export const TableUserId = styled(Typography)({
  fontSize: "0.875rem !important",
  fontFamily: "Helvetica Neue !important",
  color: "rgb(102, 102, 102)",
});

export const TableCellWrapper = styled(TableCell)({
  padding: "0.8125rem 1rem 0.8125rem 1rem !important",
  maxWidth: (props) => props.maxWidth,
});

export const EditImg = styled(Box)({
  width: 17,
  height: 17,
  marginBottom: "5px",
  marginRight: "20px",
  cursor: "pointer",
});

export const DeleteImg = styled(Box)({
  width: "1.25rem",
  height: "1.25rem",
  marginBottom: "5px",
  cursor: "pointer",
});

export const NoUserAvailable = styled(Typography)({
  color: "#25262D !important",
  textAlign: "center !important",
  fontSize: "1rem !important",
  fontWeight: "700 !important",
  fontFamily: "Helvetica Neue !important",
});

export const AddUserFromWrapper = styled(Box)({
  width: "100%",
  marginTop: "16px",
  border: "1px solid #E4E4E4",
  padding: "24px",
  display: "flex",
  flexDirection: "column",
  backgroundColor: "#fff",
});

export const AddUserFromHeading = styled(Typography)({
  fontSize: "1.125rem !important",
  fontFamily: "Helvetica Neue !important",
  fontWeight: "500 !important",
  letterSpacing: "0.2px !important",
  marginBottom: "16px !important",
});

export const AddUserFieldWrapper = styled(Box)({
  display: "flex",
  marginTop: 0,
  width: "100%",
  justifyContent: "space-between",
});

export const InputsWrapper = styled(FormControl)({
  width: (props)=> props?.width ? props.width : "45%",
  height: "2.75rem",
  fontSize: "1rem",
  color: "#666666",
  backgroundColor: "#FFFFFF",
  fontFamily: "Helvetica Neue",
  marginTop: (props)=> props.mt && `${props.mt} !important`
});

export const InputLabelWrapper = styled(InputLabel)({
  fontSize: "0.875rem !important",
  marginTop: "0.25rem !important",
  opacity: 0.5,
  backgroundColor: "#FFFFFF !important",
  fontFamily: "Helvetica Neue !important",
});

export const InputFieldWrapper = styled(OutlinedInput)({
    width: "100% !important",
    height: "2.75rem !important",
    fontSize: "1rem !important",
    color: "#666666 !important",
    backgroundColor: "#FFFFFF !important",
    fontFamily: "Helvetica Neue !important",
  });

export const PhoneInputWrapper = styled(PhoneInput)({
    width: "100% !important",
    height: "2.75rem !important",
    fontSize: "0.875rem !important",
    color: "#666666 !important",
    backgroundColor: "#FFFFFF !important",
    fontFamily: "Helvetica Neue !important",
})