import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import { BASE_URL, HOST } from "../utils/Baseurl";

export const adminAPI = createApi({
    reducerPath: "adminAPI",
    baseQuery: async (args, api, extraOptions) => {
        // Prepare headers
        const headers = new Headers();
        const userData = JSON.parse(localStorage.getItem("USER"));
        if (userData) {
            headers.set("Authorization", `${userData?.token}`);
        }

        let accountId;
        const queryParams = new URLSearchParams(window.location.search);
        if (
            queryParams !== null &&
            queryParams !== undefined &&
            queryParams.has("accountId")
        ) {
            accountId = queryParams.get("accountId");
        }
        const modifiedUrl =
            accountId != null && accountId !== undefined
                ? `${args.url}?accountId=${accountId}`
                : args.url;

        // Perform the actual query
        const modifiedArgs = {
            ...args,
            url: `${HOST}/${modifiedUrl}`,
            headers,
        };
        const result = await fetchBaseQuery()(modifiedArgs, api, extraOptions);
        return result;
    },
    tagTypes: ["Platform"],
    endpoints: (builder) => ({
        getIntegrationStatus: builder.mutation({
            // note: an optional `queryFn` may be used in place of `query`
            query: () => ({
                url: `server/consumer/integration/status`,
                method: "GET",
                headers: { "Content-Type": "application/json" },
            }),
            transformResponse: (response, meta, arg) => response,
        }),
        getRSAkey: builder.mutation({
            // note: an optional `queryFn` may be used in place of `query`
            query: () => ({
                url: `server/user/keys`,
                method: "GET",
                headers: { "Content-Type": "application/json" },
            }),
            transformResponse: (response, meta, arg) => response,
        }),
        getUserActionStatus: builder.mutation({
            // note: an optional `queryFn` may be used in place of `query`
            query: () => ({
                url: `server/consumer/action/status`,
                method: "GET",
                headers: { "Content-Type": "application/json" },
            }),
            transformResponse: (response, meta, arg) => response,
        }),
    }),
});

export const {
    useGetIntegrationStatusMutation,
    useGetRSAkeyMutation,
    useGetUserActionStatusMutation
} = adminAPI;
