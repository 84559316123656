import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import 'intro.js/introjs.css';
import './fonts/Phosphate/PhosphateRR-Solid.ttf';
// PhosphateRR-Solid.ttf
import './fonts/Helvetica-Neue/HelveticaNeue-Medium.otf';
// Helvetica Neue Medium Extended.ttf
import App from './App';
import reportWebVitals from './reportWebVitals';
import '../node_modules/bootstrap/dist/css/bootstrap.min.css' 
import '../node_modules/font-awesome/css/font-awesome.min.css'
import { BrowserRouter } from 'react-router-dom';
import { Provider } from 'react-redux'
import { store } from './app/store'
import { MyContextProvider } from './components/CommanToasterContext/toast';
import { MyContextProvider2 } from './components/CommanToasterContext/commanplatformCheck';
import { WidthProvider } from './components/CommanToasterContext/WidthContext'

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <Provider store={store}>
    <MyContextProvider>
      <MyContextProvider2>
        <WidthProvider>
          <BrowserRouter>
            <App />
          </BrowserRouter>
        </WidthProvider>
      </MyContextProvider2>
    </MyContextProvider>
  </Provider>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
