import { Box, Typography } from '@mui/material';
import ArrowForwardIcon from "@mui/icons-material/ArrowForward";
import { useSelector } from "react-redux";
import React from 'react';
import { madTechState } from "../../features/madTechSlice";
import { ArrowForwardIconStyled, HeaderWrapper, Headering, Heading, Subheading, SubheadingBox } from './Invoice.styles';

const Header = () => {
    const { invoices, selectedAccount } = useSelector(madTechState);
    return (
        <>
            <HeaderWrapper>  
                <Heading
                    onClick={() => {
                        window.location.replace(
                            selectedAccount
                                ? "/profileDetails?accountId=" +
                                selectedAccount.id
                                : "/profileDetails"
                        );
                    }}
                >
                    Settings
                </Heading>
                <SubheadingBox>
                    <Subheading>
                        <ArrowForwardIconStyled />{" "}
                        Contract & Invoice
                    </Subheading>
                </SubheadingBox>
            </HeaderWrapper>
        </>
    );
}

export default Header;
