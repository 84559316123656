export const convertUnixTimestampToAgo = (timestamp) => {
    const now = Math.floor(Date.now() / 1000); // Current Unix timestamp in seconds
    const secondsAgo = now - timestamp;

    if (secondsAgo < 60) {
        return `${secondsAgo}s ago`;
    } else if (secondsAgo < 3600) {
        const minutesAgo = Math.floor(secondsAgo / 60);
        return `${minutesAgo}m ago`;
    } else if (secondsAgo < 86400) {
        const hoursAgo = Math.floor(secondsAgo / 3600);
        return `${hoursAgo}h ago`;
    } else {
        // Calculate the full date in "DD/MM/YYYY" format
        const date = new Date(timestamp * 1000);
        const day = String(date.getDate()).padStart(2, '0');
        const month = String(date.getMonth() + 1).padStart(2, '0');
        const year = date.getFullYear();
        return `${month}/${day}/${year}`;
    }
};


export const getFontSize = (matches ,size) =>
    matches.lg
      ? size.lg
      : matches.md
      ? size.md
      : matches.sm
      ? size.sm
      : matches.xs
      ? size.xs
      : size.xss;

export const isSnowFlake = () => { 
    return false;
}