import { Box, Typography, useMediaQuery } from '@mui/material'
import React, { useEffect } from 'react'
import { useDispatch } from 'react-redux'


import NaLayout from '../../components/NavLayout/NaLayout'
import { setImageLoaded } from '../../features/madTechSlice'
import useDocumentTitle from '../../hook/useDocumentTitle'
import { color } from '../../utils/Colors'
import HomeFooter from '../Home/HomeFooter'
import LetsConnect from '../Home/LetsConnect'
import pdfWhiteImg from '../../assets/pdf_white.svg'
import { HOST } from '../../utils/Baseurl'
import {
    DownloadBtn,
    DownloadBtnWrapper,
    Heading,
    HeadingWrapper,
    LastUpdatedAt,
} from "./TermsCondition.styles";
import CommanAddendum from './CommanAddendum'

const Addendum = () => {

    useDocumentTitle('Madconnect Data Processing Addendum')
    const matcheslg = useMediaQuery('(min-width:1100px)');
    const matchesmd = useMediaQuery('(min-width:980px)');
    const matchessm = useMediaQuery('(min-width:880px)');
    const matchesxs = useMediaQuery('(min-width:760px)');
    const dispatch = useDispatch()

    useEffect(() => {
        dispatch(setImageLoaded(true))
    }, [])

    return (
        <NaLayout>
            <Box sx={{ mx: 'auto' }}>
                <CommanAddendum />
                <LetsConnect />
                <Box sx={{ maxWidth: 1550, mx: 'auto' }}>
                    <HomeFooter />
                </Box>
            </Box>
        </NaLayout>
    )
}

export default Addendum