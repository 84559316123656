import { Box, Typography } from "@mui/material";
import React from "react";
import PartyList from "./PartyList";
import TransferDesc from "./TransferDesc";

const Annex1 = () => {
  return (
    <Box sx={{ mt: 2 }}>
      <Box
        sx={{
          textDecoration: "underline",
          fontWeight: "bolder",
          fontSize: "16px",
        }}
      >
        Annex I
      </Box>

      <PartyList></PartyList>
      <TransferDesc></TransferDesc>
      <Box>
        <Box component="div" display="flex" sx={{ mt: 4 }}>
          <Box
            component="div"
            sx={{
              fontWeight: "bold",
              marginBottom: 1,
              mr: 1.5,
              fontSize: "18px",
            }}
          >
            C.
          </Box>

          <Box
            sx={{
              fontSize: "18px",
              textAlign: "justify",
              fontWeight: "bold",
              marginBottom: 1,
            }}
          >
            COMPETENT SUPERVISORY AUTHORITY
          </Box>
        </Box>
        <Typography variant="body1">
          The competent supervisory authority as determined by the place of
          establishment of Client.
        </Typography>
      </Box>
    </Box>
  );
};

export default Annex1;
