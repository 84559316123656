import {
  Box,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from "@mui/material";
import React from "react";

const SCCsTable = () => {
  return (
    <>
      <Typography variant="h6" gutterBottom sx={{ my: 4 }}>
        Table 2: Selected SCCs, Modules and Selected Clauses
      </Typography>
      <TableContainer component={Paper}>
        <Table sx={{ minWidth: 650 }} aria-label="sccs table">
          <TableBody>
            <TableRow>
              <TableCell
                sx={{
                  width: "25%",
                  backgroundColor: "#f4e7be",
                  fontWeight: "bold",
                  border: "1px solid #d4b886",
                }}
              >
                Addendum EU SCCs
              </TableCell>
              <TableCell
                sx={{ border: "1px solid #d4b886", backgroundColor: "#f9f5e9" }}
              >
                <Box sx={{ marginBottom: 2 }}>
                  ☒ The version of the Approved EU SCCs which this Addendum is
                  appended to, detailed below, including the Appendix
                  Information:
                </Box>
                <Box>
                  Date: [Insert Date]
                  <br />
                  Reference (if any): [Insert Reference]
                  <br />
                  Other identifier (if any): [Insert Identifier]
                </Box>
                <br />
                <Box>Or</Box>
                <br />
                <Box>
                  ☐ the Approved EU SCCs, including the Appendix Information and
                  with only the following modules, clauses, or optional
                  provisions of the Approved EU SCCs brought into effect for the
                  purposes of this Addendum:
                </Box>
              </TableCell>
            </TableRow>
          </TableBody>
        </Table>
      </TableContainer>

      {/* Table for modules */}
      <TableContainer component={Paper}>
        <Table sx={{ minWidth: 650 }} aria-label="sccs module table">
          <TableHead>
            <TableRow>
              <TableCell
                sx={{
                  backgroundColor: "#f4e7be",
                  fontWeight: "bold",
                  border: "1px solid #d4b886",
                }}
              >
                Module
              </TableCell>
              <TableCell
                sx={{
                  backgroundColor: "#f4e7be",
                  fontWeight: "bold",
                  border: "1px solid #d4b886",
                }}
              >
                Module in operation
              </TableCell>
              <TableCell
                sx={{
                  backgroundColor: "#f4e7be",
                  fontWeight: "bold",
                  border: "1px solid #d4b886",
                }}
              >
                Clause 7 (Docking Clause)
              </TableCell>
              <TableCell
                sx={{
                  backgroundColor: "#f4e7be",
                  fontWeight: "bold",
                  border: "1px solid #d4b886",
                }}
              >
                Clause 11 (Option)
              </TableCell>
              <TableCell
                sx={{
                  backgroundColor: "#f4e7be",
                  fontWeight: "bold",
                  border: "1px solid #d4b886",
                }}
              >
                Clause 9a (Prior Authorisation or General Authorisation)
              </TableCell>
              <TableCell
                sx={{
                  backgroundColor: "#f4e7be",
                  fontWeight: "bold",
                  border: "1px solid #d4b886",
                }}
              >
                Clause 9a (Time period)
              </TableCell>
              <TableCell
                sx={{
                  backgroundColor: "#f4e7be",
                  fontWeight: "bold",
                  border: "1px solid #d4b886",
                }}
              >
                Is personal data received from the Importer combined with
                personal data collected by the Exporter?
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {/* Row 1 */}
            <TableRow>
              <TableCell sx={{ border: "1px solid #d4b886" }}>1</TableCell>
              <TableCell sx={{ border: "1px solid #d4b886" }}></TableCell>
              <TableCell sx={{ border: "1px solid #d4b886" }}></TableCell>
              <TableCell sx={{ border: "1px solid #d4b886" }}></TableCell>
              <TableCell
                sx={{ border: "1px solid #d4b886", backgroundColor: "#f4e7be" }}
              ></TableCell>
              <TableCell
                sx={{ border: "1px solid #d4b886", backgroundColor: "#f4e7be" }}
              ></TableCell>
              <TableCell
                sx={{ border: "1px solid #d4b886", backgroundColor: "#f4e7be" }}
              ></TableCell>
            </TableRow>

            {/* Row 2 */}
            <TableRow>
              <TableCell sx={{ border: "1px solid #d4b886" }}>2</TableCell>
              <TableCell sx={{ border: "1px solid #d4b886" }}>X</TableCell>
              <TableCell sx={{ border: "1px solid #d4b886" }}>N/A</TableCell>
              <TableCell sx={{ border: "1px solid #d4b886" }}>N/A</TableCell>
              <TableCell sx={{ border: "1px solid #d4b886" }}>
                General Authorisation
              </TableCell>
              <TableCell sx={{ border: "1px solid #d4b886" }}>N/A</TableCell>
              <TableCell
                sx={{ border: "1px solid #d4b886", backgroundColor: "#f4e7be" }}
              ></TableCell>
            </TableRow>

            {/* Row 3 */}
            <TableRow>
              <TableCell sx={{ border: "1px solid #d4b886" }}>3</TableCell>
              <TableCell sx={{ border: "1px solid #d4b886" }}></TableCell>
              <TableCell sx={{ border: "1px solid #d4b886" }}></TableCell>
              <TableCell sx={{ border: "1px solid #d4b886" }}></TableCell>
              <TableCell sx={{ border: "1px solid #d4b886" }}></TableCell>
              <TableCell sx={{ border: "1px solid #d4b886" }}></TableCell>
              <TableCell
                sx={{ border: "1px solid #d4b886", backgroundColor: "#f4e7be" }}
              ></TableCell>
            </TableRow>

            {/* Row 4 */}
            <TableRow>
              <TableCell sx={{ border: "1px solid #d4b886" }}>4</TableCell>
              <TableCell sx={{ border: "1px solid #d4b886" }}></TableCell>
              <TableCell sx={{ border: "1px solid #d4b886" }}></TableCell>
              <TableCell sx={{ border: "1px solid #d4b886" }}></TableCell>
              <TableCell
                sx={{ border: "1px solid #d4b886", backgroundColor: "#f4e7be" }}
              ></TableCell>
              <TableCell
                sx={{ border: "1px solid #d4b886", backgroundColor: "#f4e7be" }}
              ></TableCell>
              <TableCell sx={{ border: "1px solid #d4b886" }}></TableCell>
            </TableRow>
          </TableBody>
        </Table>
      </TableContainer>
    </>
  );
};

export default SCCsTable;
