import { Box, Typography } from "@mui/material";
import styled from "styled-components";
import { color } from '../../utils/Colors'


export const Header = styled(Box)({
    paddingTop: "48px !important",
    paddingBottom: "48px !important",
    display: 'flex',
    flexDirection: 'column',
    width: '100%',
    backgroundColor: color.themeColor,
    justifyContent: 'center',
    alignItems: 'center'
})

export const HeaderContainer = styled(Box)({
    width: '91%',
    maxWidth: 1350,
})

export const HeaderHeading = styled(Typography)({
    color: '#fff',
    fontWeight: "400 !important",
    fontFamily: 'Phosphate !important',
    // fontSize: '2.5rem',
    fontSize: (props) => (props.matcheslg ? '2.5rem !important' : props.matchesmd ? '2.3rem !important' : props.matchessm ? '2rem !important' : props.matchesxs ? '1.8rem !important' : '1.6rem !important'),
    letterSpacing: '0.2px !important',
})

export const HeaderPara = styled(Typography)({
    width: '60%',
    textAlign: "left",
    marginTop: "2rem !important",
    color: '#fff',
    fontWeight: "500 !important",
    fontFamily: 'Helvetica Neue !important',
    // fontSize: '1rem',
    fontSize: (props) => (props.matcheslg ? '1rem !important ' : props.matchesmd ? '0.9rem !important' : props.matchessm ? '0.8rem !important' : props.matchesxs ? '0.7rem !important' : '0.7rem !important'),
    letterSpacing: "0.2px !important",
})
export const ContentWrapper = styled(Box)({
    paddingTop: "80px !important",
    paddingBottom: "80px !important",
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    backgroundColor: '#eeeeee'
})

export const ContentContainer = styled(Box)({
    width: '91%',
    maxWidth: "px",
    marginBottom: "1rem !important",
    backgroundColor: '#fff',
    padding: "24px !important",
    borderRadius: "8px !important",
    border: '1px solid rgba(54, 70, 172, 0.24)'
})

export const Heading = styled(Typography)({
    fontSize: "1.25rem !important",
    fontWeight: "700 !important ",
    fontFamily: 'Helvetica Neue !important'
})

export const Description = styled(Typography)({
    fontSize: "1rem !important",
    fontWeight: "400 !important",
    marginTop: "0.5rem !important",
    fontFamily: 'Inter!important'
})

export const DescriptionHeading = styled(Typography)({
    fontSize: "1rem !important", 
    fontWeight: "700 !important",
    marginTop: "0.5rem !important", 
     fontFamily: 'Helvetica Neue !important'   
})

export const Description1 = styled(Typography)({
fontSize: "1rem !important", 
fontWeight: "400 !important", 
marginTop: "0.5rem !important", 
fontFamily: 'Inter !important'
})

export const UnorderList = styled('ul')({
    marginTop: "10px !important ", 
    listStyleType: 'none' 
})

export const LinkWord= styled('span')({
     textDecorationLine: 'underline', 
     cursor: 'pointer',
    fontSize: "1rem !important", 
    fontWeight: "400 !important", 
    fontFamily: 'Inter !important'
})