export const favIconMapping = {
    "/platforms/TikTok.png": "/logos/favicons/tik tok.svg",
    "/platforms/Meta.png": "/logos/favicons/meta logo.svg",
    "/platforms/ActionIQ.png": "/logos/favicons/actionIQ.png",
    "/platforms/MicrosoftBingAds.svg": "/logos/favicons/microsoft bing ads.svg",
    "/platforms/Yahoo.png": "/logos/favicons/yahoo.svg",
    "/platforms/Kochava.svg": "/logos/favicons/kochava.png",
    "/platforms/MParticle.png": "/logos/favicons/mparticle.svg",
    "/platforms/Lotame.png": "/logos/favicons/lotame.png",
    "/platforms/TheTradeDesk.svg": "/logos/favicons/theTrandeDesk.png",
    "/platforms/Freewheel.svg": "/logos/favicons/freewheel.png",
    "/platforms/GoogleDV360.png": "/logos/favicons/google display.svg",
    "/platforms/AppsFlyer.png": "/logos/favicons/appsflyer.png",
    "/platforms/AWS.png": "/logos/favicons/aws.svg",
    "/platforms/Adform.png": "/logos/favicons/adform.svg",
    "/platforms/LiveRamp.png": "/logos/favicons/liveramp.png",
    "/platforms/Hubspot.png": "/logos/favicons/hubspot.svg",
    "/platforms/LinkedIn.png": "/logos/favicons/linkdin.svg",
    "/platforms/Narrative.png": "/logos/favicons/narrative.svg",
    "/platforms/Adobe.png": "/logos/favicons/adobe.svg",
    "/platforms/Spotify.png": "/logos/favicons/spotify.svg",
    "/platforms/SalesforceDMP.png": "/logos/favicons/salesforce.svg",
    "/platforms/Snap.png": "/logos/favicons/snapchat.svg",
    "/platforms/Pinterest.png": "/logos/favicons/pintrest.svg",
    "/platforms/MediaMath.png": "/logos/favicons/medimath.svg",
    "/platforms/Neustar.png": "/logos/favicons/neustar.png",
    "/platforms/Shopify.png": "/logos/favicons/sopify.svg",
    "/platforms/LiveRamp.png": "/logos/favicons/liveramp.png",
    "/platforms/s3.png": "/logos/favicons/aws s3.svg",
    "/platforms/GoogleAds.png": "/logos/favicons/google ads.svg",
    "/platforms/GoogleAdManager.png": "/logos/favicons/ad manager.svg",
    "/platforms/Fetch.png": "/logos/favicons/fetch.svg",
    "/platforms/Snowflake.png": "/logos/favicons/snowflake_favicon.png",


}

export const favIconMapping1 = {
    "platforms/TikTok.png": "/logos/favicons/tik tok.svg",
    "platforms/Meta.png": "/logos/favicons/meta logo.svg",
    "platforms/ActionIQ.png": "/logos/favicons/actionIQ.png",
    "platforms/MicrosoftBingAds.svg": "/logos/favicons/microsoft bing ads.svg",
    "platforms/Yahoo.png": "/logos/favicons/yahoo.svg",
    "platforms/Kochava.svg": "/logos/favicons/kochava.png",
    "platforms/MParticle.png": "/logos/favicons/mparticle.svg",
    "platforms/Lotame.png": "/logos/favicons/lotame.png",
    "platforms/TheTradeDesk.svg": "/logos/favicons/theTrandeDesk.png",
    "platforms/Freewheel.svg": "/logos/favicons/freewheel.png",
    "platforms/GoogleDV360.png": "/logos/favicons/google display.svg",
    "platforms/AppsFlyer.png": "/logos/favicons/appsflyer.png",
    "platforms/AWS.png": "/logos/favicons/aws.svg",
    "platforms/Adform.png": "/logos/favicons/adform.svg",
    "platforms/LiveRamp.png": "/logos/favicons/liveramp.png",
    "platforms/Hubspot.png": "/logos/favicons/hubspot.svg",
    "platforms/LinkedIn.png": "/logos/favicons/linkdin.svg",
    "platforms/Narrative.png": "/logos/favicons/narrative.svg",
    "platforms/Adobe.png": "/logos/favicons/adobe.svg",
    "platforms/Spotify.png": "/logos/favicons/spotify.svg",
    "platforms/SalesforceDMP.png": "/logos/favicons/salesforce.svg",
    "platforms/Snap.png": "/logos/favicons/snapchat.svg",
    "platforms/Pinterest.png": "/logos/favicons/pintrest.svg",
    "platforms/MediaMath.png": "/logos/favicons/medimath.svg",
    "platforms/Neustar.png": "/logos/favicons/neustar.png",
    "platforms/Shopify.png": "/logos/favicons/sopify.svg",
    "platforms/LiveRamp.png": "/logos/favicons/liveramp.png",
    "platforms/s3.png": "/logos/favicons/aws s3.svg",
    "platforms/GoogleAds.png": "/logos/favicons/google ads.svg",
    "platforms/GoogleAdManager.png": "/logos/favicons/ad manager.svg",
    "platforms/Fetch.png": "/logos/favicons/fetch.svg",
    "platforms/Snowflake.png": "/logos/favicons/snowflake_favicon.png",


}