import React from 'react'
import PropTypes from 'prop-types';
import styled from '@emotion/styled';



function TextNormalFields({ value, lable, StartPrefix, EndPrefix, isLoading, currentPlan }) {
    const RootDiv = styled('div')({
        display: 'inline-block',
        width: '200px',
        position: 'relative',
    });

    const Label = styled('label')({
        position: 'absolute',
        top: 0,
        left: 14,
        transform: 'translateY(-50%)',
        backgroundColor: '#fff',
        padding: '0 4px',
        fontSize: '0.875rem',
        color: '#66696A',
        whiteSpace: 'nowrap', 
        overflow: 'hidden',
        background: "white",
        zIndex: 99,
    });

    const FieldDiv = styled('div')({
        width: '100%',
        padding: '15.5px 14px',
        borderRadius: '10px',
        border: '1px solid #EAEDF6',
        fontSize: '1rem',
        fontFamily: 'Helvetica',
        fontWeight: 500,
        boxSizing: 'border-box',
        position: 'relative',
        color: lable === "Min. Monthly Spend" ? "#66696A" : '#25262D',
    });
    return (
        <>
            {isLoading || currentPlan === "monthly" ?
                "" :
                <RootDiv>
                    <Label>{lable}</Label>
                    <FieldDiv tabIndex={-1} disabled={true}>
                        <> {StartPrefix}{value}{EndPrefix}</>
                    </FieldDiv>
                </RootDiv>}
        </>
    )
}
TextNormalFields.propTypes = {
    register: PropTypes.func.isRequired, 
};


export default TextNormalFields