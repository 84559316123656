import { Box, FormControl, InputLabel, OutlinedInput, Typography } from "@mui/material";
import styled from "styled-components";

export const FormNameWrapper = styled(FormControl)({
    width: "45%",
    height: "2.75rem",
    fontSize: "1rem",
    color: "#666666",
    backgroundColor: "#FFFFFF",
    fontFamily: "Helvetica Neue",
});

export const FormNameLabelWrapper = styled(InputLabel)({
    fontSize: "0.875rem !important",
    marginTop: "0.25rem !important",
    opacity: 0.5,
    backgroundColor: "#FFFFFF !important",
    fontFamily: "Helvetica Neue !important",
});

export const FormNameInputWrapper = styled(OutlinedInput)({
    width: "100% !important",
    height: "2.75rem !important",
    fontSize: "1rem !important",
    color: "#666666 !important",
    backgroundColor: "#FFFFFF !important",
    fontFamily: "Helvetica Neue !important",
});

export const PermissionHeading = styled(Typography)({
    fontSize: "1.125rem !important",
    fontFamily: "Helvetica Neue !important",
    fontWeight: 500,
    letterSpacing: 0.2,
    marginTop: "24px !important",
    marginBottom: "8px !important",
});
export const CenteredBox = styled(Box)({
    position: 'absolute !important',
    top: '50% !important',
    left: '50% !important',
    transform: 'translate(-50%, -50%) !important',
    borderRadius: '8px !important',
    backgroundColor: '#F8F8FE !important',
    width: '520px !important',
    boxShadow: '24px !important',
    padding: '16px !important',
    display: 'flex !important',
    flexDirection: 'column !important',
    justifyContent: 'center !important',
    alignItems: 'center !important',
    '&:focus': {
        outline: 'none !important',
    },
});

export const ConfirmationTextBox = styled(Typography)({
    fontWeight: 400,
    fontFamily: 'Helvetica Neue !important',
    fontSize: '1rem !important',
    textAlign: 'center !important',
    color: '#656C7B !important',
    width: '70% !important',
    marginBottom: '16px !important',
});