import React, { useCallback, useEffect, useState } from 'react';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { Box, Grid, useMediaQuery } from '@mui/material';

import whiteLogo from '../../assets/madconnect-white.svg'
import madConnectLogo from '../../assets/madconnectlight.png'
import '../SignIn/SignIn.css'
import { Strings } from '../../utils/Strings';
import CommonButton from '../../components/CommonButton/CommonButton'
import CommonInput from '../../components/CommonInput/CommonInput';
import showPwdImg from '../../assets/show_password.svg';
import hidePwdImg from '../../assets/hide_password.svg';
import AuthLayout from '../../components/AuthLayout/AuthLayout';
import { useCreateNewPasswordMutation } from '../../services/query';
import useDocumentTitle from '../../hook/useDocumentTitle';
import { ColumDiv, LeftSection, LeftSectionImage, LeftSectionPara, MadconnectBetaLogo, Para, PassErrorMsg, RightSectionDiv, SetNewPassword } from './SetNewPass.styles';

const SetNewPass = () => {

    useDocumentTitle('Set new password')
    const matchesxsss = useMediaQuery('(min-width:495px)');

    const [newPassword, setNewPassword] = useState("");
    const [confirmNewPassword, setConfirmNewPassword] = useState("");
    const [pwdError, setPwdError] = useState(false);
    const [misMatchedPassword, setMisMatchedPassword] = useState(false);
    const [isRevealNewPwd, setIsRevealNewPwd] = useState(false);
    const [isRevealConfirmNewPwd, setIsRevealConfirmNewPwd] = useState(false);
    const [passErrorMsg, setPassErrorMsg] = useState('')

    const [searchParams, setSearchParams] = useSearchParams();
    const token = searchParams.get('t')
    const userId = searchParams.get('u')

    const navigate = useNavigate();

    const [createNewPass, { data, error, isLoading }] = useCreateNewPasswordMutation()

    const validatePassword = () => {
        if (!newPassword.match(/^(?=.*?[A-Z])(?=.*?[a-z])(?=.*[!@#$&*])(?=.*?[0-9]).{8,}$/)) {
            setPwdError(true);
            return true;
        } else {
            setPwdError(false);
        }
        return false;
    };

    const completePassword = useCallback((event) => {
        event.preventDefault();
        if (validatePassword()) {
            return;
        }
        if (newPassword !== confirmNewPassword) {
            setMisMatchedPassword(true);
            return;
        }
        createNewPass({
            token,
            userId,
            password: newPassword
        })
    }, [useCreateNewPasswordMutation, validatePassword, token, newPassword, confirmNewPassword])

    useEffect(() => {
        if (data !== undefined) {
            if (data?.statusCode === 200) {
                // data.statusMessage === Strings.createpass_success_msg
                // console.log('data', data);
                setMisMatchedPassword(false);
                navigate('/signin')
            } else {
                setPassErrorMsg(data?.statusMessage)
            }
        }
        if (error !== undefined) {
            console.log('error', error);
        }
    }, [data, error])

    return (
        <AuthLayout>
            <Grid container>
                {/* Left column */}
                <Grid item md={7} lg={7} sm={12} xs={12} sx={{ position: 'relative' }}>
                    <LeftSectionImage
                        alt={'MADCONNECT'}
                        src={whiteLogo}
                        onClick={() => navigate('/')}>
                    </LeftSectionImage>
                    <LeftSection className='login-signin-container'>
                        <div className='left-container'>
                            <div className='row'>
                                <ColumDiv className='col-12'>
                                    <div className='row'>
                                        <div className='col-12'>
                                            <Para>Welcome to</Para>
                                        </div>
                                    </div>
                                    <div className='row'>
                                        <div className='col-12'>
                                            <MadconnectBetaLogo alt={'MADCONNECT'} src={madConnectLogo}>
                                            </MadconnectBetaLogo>
                                        </div>
                                    </div>
                                </ColumDiv>
                            </div>

                        </div>
                    </LeftSection>
                </Grid>
                {/* Right column */}
                <Grid item md={5} lg={5} sm={12} xs={12}>
                    <div className='login-signin-container'>
                        <RightSectionDiv className='signin-container'
                            matchesxsss={matchesxsss}>
                            <Box  display= "flex" textAlign= "center" justifyContent= "center">
                                <PassErrorMsg variant='subtitle2'>{passErrorMsg}</PassErrorMsg>
                            </Box>
                            <SetNewPassword className='text-center mb-2'>Set new password</SetNewPassword>
                            <LeftSectionPara className='text-center mb-4 sub-text-color'>Your new password must be different from previous password.</LeftSectionPara>
                            <div className=''>
                                <CommonInput
                                    height={"2.75rem"}
                                    backgroundColor={'white'}
                                    placeholder={Strings.newPassword}
                                    inputType={isRevealNewPwd ? "text" : "password"}
                                    value={newPassword}
                                    onChange={(event) => {
                                        setPwdError(false);
                                        setMisMatchedPassword(false);
                                        setNewPassword(event.target.value);
                                        setPassErrorMsg('')
                                    }}
                                    endAdornment={<img
                                        title={isRevealNewPwd ? "Hide password" : "Show password"}
                                        src={isRevealNewPwd ? hidePwdImg : showPwdImg}
                                        onClick={() => setIsRevealNewPwd(prevState => !prevState)}
                                        style={{ width: 18, height: 18, cursor: "pointer" }} />}
                                ></CommonInput>
                                {
                                    pwdError ?
                                        <span className='form-error'>{Strings.password_error}{Strings.password_rule}</span> : <></>
                                }
                            </div>
                            <div className='mt-3'>
                                <CommonInput
                                    height={"2.75rem"}
                                    backgroundColor={'white'}
                                    placeholder={Strings.confirmNewPassword}
                                    inputType={isRevealConfirmNewPwd ? "text" : "password"}
                                    value={confirmNewPassword}
                                    onChange={(event) => {
                                        setPwdError(false);
                                        setMisMatchedPassword(false);
                                        setConfirmNewPassword(event.target.value);
                                        setPassErrorMsg('')
                                    }}
                                    onKeyDown={(event) => {
                                        if (event.key === 'Enter') {
                                            setPassErrorMsg('')
                                        }
                                    }}
                                    endAdornment={<img
                                        title={isRevealConfirmNewPwd ? "Hide password" : "Show password"}
                                        src={isRevealConfirmNewPwd ? hidePwdImg : showPwdImg}
                                        onClick={() => setIsRevealConfirmNewPwd(prevState => !prevState)}
                                        style={{ width: 18, height: 18, cursor: "pointer" }} />}
                                ></CommonInput>
                                {
                                    misMatchedPassword ?
                                        <span className='form-error'>{Strings.confirm_password_error}</span> : <></>
                                }
                            </div>
                            <div className='mt-3 mb-4'>
                                <CommonButton
                                    width={"100%"}
                                    height={"2.75rem"}
                                    textStyle={{
                                        fontSize: '1.125rem',
                                        fontWeight: '500',
                                        fontFamily: 'Helvetica'
                                    }}
                                    onClick={(event) => {
                                        completePassword(event)
                                    }}
                                    isLoading={isLoading}
                                    title={Strings.submit}
                                    color={'#3843AC'}
                                    disabled={isLoading}
                                ></CommonButton>
                            </div>
                        </RightSectionDiv>
                    </div>
                </Grid>
            </Grid>

        </AuthLayout>
    );
}

export default SetNewPass