import React, { createContext, useContext,  useState } from "react";

const WidthContext = createContext();
export const useWidthContext = () => {
    return useContext(WidthContext);
};
export const WidthProvider = ({ children }) => {
    const [width, setWidth] = useState(151)
    const [width2, setWidth2] = useState(89)


    const contextValue = {
        width, setWidth,
        width2, setWidth2
    };
    return (
        <WidthContext.Provider value={contextValue}>
            {children}
        </WidthContext.Provider>
    );
};