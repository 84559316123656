import CloudQueueIcon from '@mui/icons-material/CloudQueue';
export const TabsHeading = [
  "In-progress",
  "Active",
  "Offline",
  "Disabled",
  "Deleted",
];

export const TableHeading = [
  { label: "Connection Name", key: "connectionName" },
  { label: "Source", key: "source", },
  { label: "Destination", key: "destination", },
  {
    label: "Data Type",
    key: "dataType",
    hidden: (status) => status === "In-progress",
  },
  { label: 
  <CloudQueueIcon sx={{width:"20px", height:"20px" , margin:'0 auto'}} /> ,key: "ENV"},

  { label: "Actions", key: "actions", center: true, head: true },
];

export const shouldHideBox = (activeTab) => {
  switch (activeTab) {
    case "In-progress":
      return activeTab !== "In-progress";
    case "Active":
      return activeTab !== "Active";
    case "Offline":
      return activeTab !== "Offline";
    case "Disabled":
      return activeTab !== "Disabled";
    case "Deleted":
      return activeTab !== "Deleted";
    default:
      return true;
  }
};

export const filteredData = (finalData, inputValue) => {
  const filteredItems = finalData?.filter((item) => {
    return (
      item?.name?.toLowerCase().includes(inputValue?.toLowerCase()) ||
      item?.src?.dataType?.toLowerCase().includes(inputValue?.toLowerCase()) ||
      item?.src?.name?.toLowerCase().includes(inputValue?.toLowerCase()) ||
      item?.dest?.name?.toLowerCase().includes(inputValue?.toLowerCase())
    );
  });
  return filteredItems;
};

export const processConnectionData = (data, setConnectors, setFinalData) => {
  if (data?.response) {
    const allConnections = data?.response?.map((item) => ({
      id: item?.id,
      src: item.src,
      dest: item.dest,
      usedVolume: item.usedVolume,
      enabled: item.enabled,
      status: item.status,
      deleted: item.deleted,
      conId: item.conId,
      name: item.name,
    }));
    const connectionDummyData = [];
    const updatedConnections = [...allConnections, ...connectionDummyData];
    setConnectors(updatedConnections);
    setFinalData(updatedConnections);
  }
};

export const countConnectionsByStatus = (connections, status) => {
  return connections?.filter((connection) => connection?.status === status)
    .length;
};

export const destinationSourceFunc = (arr, setData) => {
  const initialState = arr?.reduce((acc, item) => {
    acc[item.key] = item.value;
    return acc;
  }, {});
  setData(initialState || {});
};
