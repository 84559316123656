import { AppBar, Box, IconButton, Menu, Toolbar, Typography } from "@mui/material";
import styled from "styled-components";
import { color } from '../../utils/Colors';


export const AppBarStyled = styled(AppBar)({
    backgroundColor: ` ${color.whiteColor} !important`,
    marginLeft: "auto !important",
    marginRight: "auto !important",
    boxShadow: '0px 6px 16px rgba(0, 0, 0, 0.08) !important',
    height: (props) => (props.matchesxs ? "104px !important" : "70px !important"),
    maxWidth: (props) => (props.highResolution) && "1920px !important",
    right: (props)=> (props.highResolution) && "auto !important",
})
export const Toolbarstyle = styled(Toolbar)({
    maxWidth: "1450px !important",
    justifyContent: 'space-between'
})

export const ImageBox = styled(Box)({
    position: 'relative',
    display: 'flex',
    flexDirection: 'row',
    width: (props) => (props.matchesxs ? '22% !important' : props.matchesxss ? '40% !important' : props.matchesxsss ? '60% !important' : '80% !important'),
    paddingLeft: (props) => (props.matchesxs ? "16px !important" : props.matchesxss ? "8px !important" : "0px !important "),
    alignItems: (props) => (props.matchesxs ? 'center' : 'flex-start'),
    justifyContent: (props) => (props.matchesxs ? 'center' : 'flex-start'),
    cursor: 'pointer',
})

export const ImageStyled = styled('img')({
    marginLeft: (props) => (props.matchesxs ? "20px !important" : "0px !important"),
    width: "13.44rem",
    height: "43px",
})

export const MenuBox = styled(Box)({
    flexGrow: "1",
    display: (props) => (props.matchesxs ? 'flex !important ' : 'none !important'),
    marginLeft: "64px !important",
    alignItems: 'center',
    justifyContent: 'space-between'
})

export const MenuInnerBox = styled(Box)({
    width: '60%',
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'space-evenly'
})
export const MenuItemBox = styled(Box)({
    position: 'relative',
    width: "6.25rem",
    textAlign: 'center'
})

export const MenuItemname = styled(Typography)({
    fontSize: "1.1rem!important",
    cursor: "pointer",
    color: (props) => (!props.isGetStarted && props.pathname === '/' ? color.themeColor : "#626262"),
    fontFamily: 'Helvetica Neue !important',
    fontWeight: (props) => (!props.isGetStarted && props.pathname === '/' ? "500 !important" : "400 !important")
})

export const MenuUnderLineBox = styled(Box)({
    position: "absolute",
    bottom: "-39px",
    left: "8px",
    width: "90px",
    height: "2px",
    backgroundColor: "#3843AC"
})

export const ResposiveMenuBox = styled(Box)({
    flexGrow: (props) => (props.matchesxs ? 1 : 0),
    display: (props) => (props.matchesxs ? 'none' : 'flex'),
    flexDirection: 'row-reverse'
})

export const IconButtonStyled = styled(IconButton)({
    cursor: 'pointer'
})

export const MenuStyled = styled(Menu)({
    marginTop: '45px !important'
})

//Navbar page

export const NavStyled = styled('nav')({
    marginLeft: 'auto !important',
    marginRight: 'auto !important',
    background: color.whiteColor
})

export const NavBox = styled(Box)({
    position: 'relative',
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'flex-end',
})

export const LogoStyled = styled('img')({
    width: 'auto',
    height: "4rem"
})

export const NameContentBox = styled(Box)({
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
})

export const ImageDiv= styled('div')({
    width: "35px", 
    height: "35px", 
    borderRadius: "35px",
    background: color.buttonColor
})
