import { Box, Card, Table, TableCell, TableHead, Typography } from "@mui/material";
import styled from "styled-components";


export const Heading = styled(Typography)({
    fontSize: "1.25rem !important",
    fontWeight: "700 !important",
    fontFamily: "Helvetica Neue !important",
    cursor: "pointer",
    textDecoration: "underline",
})

export const SubHeadingWrapper = styled(Box)({
    display: "flex",
    flexDirection: "row",
    backgroundColor: "#fff",
})

export const SubHeading = styled(Typography)({
    fontSize: "1.25rem !important",
    fontFamily: "Helvetica Neue !important",
    fontWeight: "500 !important",
    letterSpacing: " 0.2px !important",
    marginLeft: "0.625rem !important",
})

export const SpanStyled = styled('span')({
    fontSize: "1.25rem !important",
    fontFamily: "Helvetica Neue !important",
    fontWeight: "500 !important",
    letterSpacing: "0.2px !important",
    marginLeft: "0.625rem !important",
    cursor: "default",
    textDecoration: "none",
})

export const CurrentPlanWrapper = styled(Box)({
    backgroundColor: "#fff",
    marginTop: "1rem !important",
    marginBottom: "1.562rem !important",
    padding: "1rem !important",
    boxShadow:
        "0px 0px 1px rgba(37, 38, 45, 0.25), 0px 2px 4px rgba(37, 38, 45, 0.15)",
    borderRadius: "8px !important",
})

// GraphContent page

export const ContentWrapper = styled(Box)({
    display: "flex", alignItems: "center",
    marginTop: "10px !important",
    width: "100%",
    gap: "30px"
})

export const LeftContentWrapper = styled(Box)({
    width: "50%",
    border: "1px solid #EFEFEF",
    borderRadius: "13px !important",
    padding: "10px"
})

export const RightContentWrapper = styled(Box)({
    width: "50%"
})

export const CardStyled = styled(Card)({
    borderRadius: "13px !important"
})

// Table Pricing Page


export const TablePricingMargin = styled(Box)({
    marginTop: "1.562rem !important"
})

export const TabelPricingWrapper = styled(Box)({
    height: (props) => (props.isStreaming ? "auto !important" : "auto !important")
})

export const TabelPricingHeader = styled(Box)({
    display: "flex",
    background: "#EFEFEF",
    borderRadius: "13px !important",
    padding: "5px !important"
})

export const UnlimitedWrapper = styled(Box)({
    borderRadius: (props) => (props.isStreaming ? "13px !important" : ""),
    background: (props) => (props.isStreaming ? "black !important" : "")
})

export const UnlimitedHading = styled(Typography)({
    color: (props) => (props.isStreaming ? "#fff" : "#25262D")
})

export const BatchWrapper = styled(Box)({
    borderRadius: (props) => (props.isBatch ? "13px !important " : ""),
    background: (props) => (props.isBatch ? "black !important" : "")
})

export const BatchdHading = styled(Typography)({
    color: (props) => (props.isBatch ? "#fff !important" : "#25262D !important")
})

export const TableStyled = styled(Table)({
    minWidth: "400px", overflow: "auto", marginTop: "50px"
})
export const TableHeadStyled = styled(TableHead)({
    background: "#FAFCFF", width: "100%", position: "relative"
})

export const AnnualLicenseBox = styled(Box)({
    position: "absolute",
    top: -52,
    right: 0,
    textAlign: "center",
    width: (props) => (props.isBatch && props.name ? "42% !important" : "38% !important"),
    background: "rgba(83, 139, 244, 0.1)   ",
    padding: "14px 0px",
    borderTopRightRadius: "20px",
    borderTopLeftRadius: "20px",
    color: "#538BF4",
    fontSize: "1rem",
    fontWeight: 600
})

export const TableCellStyled = styled(TableCell)({
    textAlign: "center !important",
    padding: "12px !important",
    width: "12%",
    color: "#1C2B47",
    fontWeight: "400 !important",
    fontSize: "1rem !important"
})

export const MonthlyActive = styled(TableCell)({
    textAlign: "center !important",
    padding: "12px",
    width: "23%",
    color: "#1C2B47",
    fontWeight: "400 !important",
    fontSize: "1rem !important"
})

export const PayAsYouGo = styled(TableCell)({
    textAlign: "center !important",
    width: "26% ",
    position: "relative",
    color: "#1C2B47",
    fontWeight: "400 !important",
    fontSize: "1rem !important"
})

export const PayAsYouGoBox = styled(Box)({
    position: "absolute",
    top: -52,
    textAlign: "center",
    width: "-webkit-fill-available",
    background: "rgba(83, 139, 244, 0.1)   ",
    padding: "14px 0px",
    margin: "0px 16px 0px 2px",
    borderTopRightRadius: "20px",
    borderTopLeftRadius: "20px",
    color: "#538BF4",
    fontSize: "1rem",
    fontWeight: 600
})

export const IsStreaming = styled(TableCell)({
    textAlign: "center !important",
    padding: "12px !important",
    width: (props) => (props.widthData),
    color: "#1C2B47",
    fontWeight: "400 !important",
    fontSize: "1rem !important"
})


export const Savings = styled(TableCell)({
    textAlign: "center !important",
    padding: "12px !important",
    width: "15%",
    color: "#1C2B47",
    fontWeight: "400 !important",
    fontSize: "1rem !important"
})

export const TabelCellContent = styled(TableCell)({
    border: "none",
    textAlign: "center",
    color: "#616161",
    fontSize: "16px",
    fontWeight: "500 !important",
    padding: "0px 16px !important",
    borderTopLeftRadius: (props) => (props.isBatch ? "" : props.index === props.tier ? "10px" : ''),
    borderBottomLeftRadius: (props) => (props.isBatch ? "" : props.index === props.tier ? "10px" : '')
})

export const TabelCellBox = styled(Box)({
    position: "relative",
    // borderBottom: (props) => (props.index === props.tier && !props.isBatch ? "none" : props.index === props.editedData - 1 ? "" : "1px solid #61616124"),
    padding: "16px !important",
    textAlign: "center !important"
})

