import React from "react";
import { CircularProgress } from "@mui/material";

import { LoaderWrapper } from "./Setting.styles";

const Loader = () => {
  return (
    <LoaderWrapper>
      <CircularProgress size={45} thickness={8} sx={{ color: "#3646AC" }} />
    </LoaderWrapper>
  );
};

export default Loader;
