import React, { useState } from 'react';

export const navStore = React.createContext({});

function NavStore(props) {

  const [isVisible, setIsVisible] = useState(false);

  return (
    <navStore.Provider value={{
      state: { isVisible },
      actions: { setIsVisible }
    }}>
      {props.children}
    </navStore.Provider>
  )

}

export default NavStore;
