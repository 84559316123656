import React, { useEffect, useState } from "react";

const useDebounceValue = (inputVlaue, delay) => {
  const [debounceValue, setDebounceValue] = useState(inputVlaue);

  useEffect(() => {
    const handler = setTimeout(() => {
      setDebounceValue(inputVlaue);
    }, delay);

    return () => {
      clearTimeout(handler);
    };
  }, [inputVlaue, delay]);

  return debounceValue;
};

export default useDebounceValue;
