import { Box, Switch, Tooltip, Typography, useMediaQuery } from "@mui/material";
import React, { useMemo } from "react";
import { useLocation } from "react-router-dom";
import { useSelector } from "react-redux";

import connectorImg from "../../assets/Connector.svg";
// import lineImg from '../../assets/Line14.svg';
import lineImg from "../../assets/Line_18.svg";
import edit2 from '../../assets/edit-2.svg';
// import img12 from '../../assets/image12.svg';
// import trash from '../../assets/trash-2.svg';
// import Select from 'react-select';
import rfresh from "../../assets/bitcoin-refresh.svg";
import trash from "../../assets/transhBlack.svg";
import { madTechState } from "../../features/madTechSlice";

const DashboardSingleConnector = ({
  data,
  id,
  setDeleteIds,
  setSelectedItem,
  setIsForDisable,
}) => {
  const location = useLocation();


  const { user } = useSelector(madTechState);
  const isSmallForTableHeader = useMediaQuery('(max-width:1390px)');
  const isSmallConnectionsRecord = useMediaQuery('(max-width:1012px)');

  const findPermissionByName = (name) => {
    // const foundPermission = permission.find((p) => p.name === name);
    const foundPermission = user?.permissions.find((p) => p.name === name);
    return foundPermission ? foundPermission?.permissions : null;
  };

  const connectionsPermissions = useMemo(
    () => findPermissionByName("Connections"),
    [user?.permissions]
  );

  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "row",
        justifyContent: "space-between",
        mb: "1rem",
        alignItems: "center",
      }}
    >
      <Box
        sx={{
          width: isSmallForTableHeader ? "90%" : "95%",
          display: "flex",
          flexDirection: "row",
          alignItems: "center",
          // border: '1px solid black',
          justifyContent: "space-between",
        }}
      >
        <Box
          sx={{
            display: "flex",
            flexDirection: "row",
            alignItems: "center",
            // border: '1px solid black',
            width: isSmallConnectionsRecord ? "53%" : "53%",
          }}
        >
          <Box
            sx={{
              display: "flex",
              flexDirection: "row",
              alignItems: "center",
              p: "0.5rem",
              width: "35%",
              height: "3.75rem",
              borderRadius: "10px",
              border: "1px solid #EAEDF6",
              position: "relative",
            }}
          >
            <Box
              sx={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                width: "2.5rem",
                height: "2.5rem",
                borderRadius: 1,
                backgroundColor: "rgba(54, 70, 172, 0.07)",
                p: 0.4,
              }}
            >
              <img
                alt=""
                src={data?.source.img}
                style={{ maxWidth: "100%", maxHeight: "100%" }}
              ></img>
            </Box>
            <Box
              sx={{ ml: "0.5rem", display: "flex", flexDirection: "column" }}
            >
              <Typography
                sx={{
                  color: "#616161",
                  fontSize: isSmallForTableHeader ? "12px" : "1rem",
                  fontWeight: 500,
                  fontFamily: "Helvetica Neue",
                }}
              >
                {data?.source.label}
              </Typography>
            </Box>
            {id === 0 && (
              <Typography
                sx={{
                  color: "#000000",
                  fontSize: isSmallForTableHeader ? "12px" : "1rem",
                  fontWeight: 500,
                  fontFamily: "Helvetica Neue",
                  position: "absolute",
                  top: -35,
                  left: 0,
                }}
              >
                Source
              </Typography>
            )}
          </Box>
          <div
            style={{
              marginLeft: 5,
              marginRight: 5,
              height: 0,

              width: isSmallConnectionsRecord ? "20px" : "4.5rem",
              borderTop: "2px solid #19324159",
            }}
          />
          <Box
            sx={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              width: "60px",
              height: "60px",
              px: 1,
              borderRadius: 20,
              backgroundColor: "#eaedf647",
            }}
          >
            <Box
              sx={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                width: 56,
                height: "3rem",
                borderRadius: 7,
                backgroundColor: "#EAEDF6",
              }}
            >
              <img
                alt=""
                src={connectorImg}
                style={{ width: "1.5rem", height: "1.5rem", color: "#193241" }}
              ></img>
            </Box>
          </Box>
          {/* <div style={{ marginRight: 5, height: 0, width: "4.5rem", borderTop: "1.2px solid 19324159" }} /> */}
          <img
            src={lineImg}
            style={{ width: isSmallConnectionsRecord ? "27px" : "4.5rem", marginRight: 5, marginLeft: 5 }}
          />
          <Box
            sx={{
              display: "flex",
              flexDirection: "row",
              alignItems: "center",
              p: "0.5rem",
              width: "35%",
              height: "3.75rem",
              borderRadius: "10px",
              border: "1px solid #EAEDF6",
              position: "relative",
            }}
          >
            <Box
              sx={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                width: "2.5rem",
                height: "2.5rem",
                borderRadius: 1,
                backgroundColor: "rgba(54, 70, 172, 0.07)",
                p: 0.4,
              }}
            >
              <img
                alt=""
                src={data?.destination.img}
                style={{ maxWidth: "100%", maxHeight: "100%" }}
              ></img>
            </Box>
            <Box
              sx={{ ml: "0.5rem", display: "flex", flexDirection: "column" }}
            >
              <Typography
                sx={{
                  color: "#616161",
                  fontSize: isSmallForTableHeader ? "12px" : "1rem",
                  fontWeight: 500,
                  fontFamily: "Helvetica Neue",
                }}
              >
                {data?.destination.label}
              </Typography>
            </Box>
            {id === 0 && (
              <Typography
                sx={{
                  color: "#000000",
                  fontSize: isSmallForTableHeader ? "12px" : "1rem",
                  fontWeight: 500,
                  fontFamily: "Helvetica Neue",
                  position: "absolute",
                  top: -35,
                  left: 0,
                }}
              >
                Destination
              </Typography>
            )}
          </Box>

        </Box>
        <Box
          sx={{
            position: "relative",
            height: "3.75rem",
            width: "15%",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <Box
            sx={{
              display: "flex",
              justifyContent: "start",
              alignItems: "center",
              flexDirection: "row",
              height: "100%",
              width: "90%"
            }}
          >
            <Tooltip title={<span style={{
              textTransform: "capitalize",
              fontSize: "12px"
            }}>{data?.source?.dataType?.trim() !== "" ? data.source.dataType : (data?.destination?.dataType || "")}</span>} placeholder="top">
              <span style={{
                color: "#616161", fontWeight: 500,
                fontFamily: "Helvetica Neue",
                textTransform: "capitalize",
                cursor: "default",
                // width: "80%",
                fontSize: "16px", whiteSpace: "nowrap", overflow: "hidden", textOverflow: "ellipsis"
              }}>{data?.source?.dataType?.trim() !== "" ? data.source.dataType : (data?.destination?.dataType || "")}</span>
            </Tooltip>
          </Box>

          {id === 0 && (
            <Typography
              sx={{
                color: "#000000",
                fontSize: isSmallForTableHeader ? "12px" : "1rem",
                fontWeight: 500,
                fontFamily: "Helvetica Neue",
                position: "absolute",
                top: -35,
                left: 8,
                width: isSmallForTableHeader ? "auto" : "max-content",
              }}
            >
              Data type
            </Typography>
          )}
        </Box>
        <Box
          sx={{
            position: "relative",
            height: "3.75rem",
            width: "15%",
            display: "flex",
            justifyContent: "start",
            alignItems: "center",
          }}
        >
          <Box
            sx={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              flexDirection: "row",
              height: "100%",
              width: "72%",
              fontSize: "12px", whiteSpace: "nowrap", overflow: "hidden", textOverflow: "ellipsis"
            }}
          >
            <Tooltip title={<span style={{
              fontSize: "16px"
            }}>{data?.usedVolume !== undefined
              ? new Intl.NumberFormat("en-US")
                .format(Math.round(data?.usedVolume))
                .toString()
              : "1000"}</span>} placeholder="top">
              <span style={{
                fontWeight: 500,
                color: "#616161",
                fontFamily: "Helvetica Neue",
                cursor: "default",
                justifyContent: "center",
                display: "flex",
                fontSize: "16px"
              }}>
                {data?.usedVolume !== undefined
                  ? new Intl.NumberFormat("en-US")
                    .format(Math.round(data?.usedVolume))
                    .toString()
                  : "1000"}</span>
            </Tooltip>
          </Box>


          {id === 0 && (
            <Typography
              sx={{
                color: "#000000",
                fontSize: isSmallForTableHeader ? "12px" : "1rem",
                fontWeight: 500,
                fontFamily: "Helvetica Neue",
                position: "absolute",
                top: -35,
                left: 8,
                width: isSmallForTableHeader ? "auto" : "max-content",
              }}
            >
              Monthly volume
            </Typography>
          )}
        </Box>

        <Box
          sx={{
            height: "3.75rem",
            position: "relative",
            display: "flex",
            width: "14%",
            justifyContent: "center",
            alignItems: "center",
            textAlign: "center"
          }}
        >
          <Box
            sx={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              backgroundColor: data?.deleted
                ? "rgba(249, 100, 131, 0.1)"
                : data?.status === "In-progress"
                  ? "rgb(83 139 244 / 8%)"
                  : data?.status === "Active"
                    ? "#ECFBF4"
                    : "#FFF1F0",
              height: "1.5rem",
              borderRadius: "50px",
              p: "18px",
            }}
          >
            <Typography
              sx={{
                fontFamily: "Helvetica",
                fontSize: isSmallConnectionsRecord ? "10px" : "1rem",
                fontWeight: 600,
                justifyContent: isSmallConnectionsRecord ? "center" : "start",
                color: data?.deleted
                  ? "#F96483"
                  : data?.status === "In-progress"
                    ? "#538BF4"
                    : data?.status === "Active"
                      ? "#283E32"
                      : "#FF4F4F",
              }}
            >
              {/* {data?.status === "IN_PROGRESS" ? 'In-progress' :
                                data?.status === "READY" ? 'Enabled' : 'Disabled'
                            } */}
              {data?.deleted ? "Deleted" : data?.status}
              {/* {data?.status} */}
            </Typography>
          </Box>
          {id === 0 && (
            <Typography
              sx={{
                color: "#000000",
                fontSize: isSmallForTableHeader ? "12px" : "1rem",
                fontWeight: 500,
                fontFamily: "Helvetica Neue",
                position: "absolute",
                width: isSmallForTableHeader ? "inherit" : "100%",
                textAlign: isSmallForTableHeader ? "center" : "",
                top: -35,
                left: 8,
              }}
            >
              Status
            </Typography>
          )}
        </Box>
      </Box>

      {
        location.pathname === "/connections" && !data?.deleted && (
          <>
            <Tooltip title={!data?.enabled ? "Enable" : "Disable"} placement="top">
              <Box className="action_Connection"
                sx={{
                  cursor: "pointer",
                }}
                onClick={() => {
                  // setIsDeleteButtonEnabled(true);
                  setIsForDisable(true);
                  setSelectedItem(data);
                  setDeleteIds([data?.id, id]);
                }}
              >
                <Switch size="small" checked={data?.enabled} />
              </Box>
            </Tooltip>
            {connectionsPermissions?.delete && (
              <Box
                sx={{
                  display: "flex",
                  flexDirection: "row",
                  alignItems: "center",
                  justifyContent: "space-between",
                  ml: "auto",
                }}
              >
                <Box className="action_Connection"
                  onClick={() => {
                    // setDeleteIds([data?.id, id]);
                    // setSelectedItem(data);
                    // deleteConnector(data?.id, id)
                  }}
                  sx={{ cursor: "pointer" }}
                >
                  <img
                    alt=""
                    src={rfresh}
                    style={{ width: "1.5rem", height: "1.5rem", marginBottom: 5 }}
                  ></img>
                </Box>
                <Box className="action_Connection"
                  onClick={() => {
                    // setSelectedItem(data);
                  }}
                  sx={{ cursor: "pointer" }}
                >
                  <img
                    alt=""
                    src={edit2}
                    style={{ width: "1.5rem", height: "1.5rem", marginBottom: 5 }}
                  ></img>
                </Box>
                <Box className="action_Connection"
                  onClick={() => {
                    setDeleteIds([data?.id, id]);
                    setSelectedItem(data);
                    // deleteConnector(data?.id, id)
                  }}
                  sx={{ cursor: "pointer" }}
                >
                  <img
                    alt=""
                    src={trash}
                    style={{ width: "1.5rem", height: "1.5rem", marginBottom: 5 }}
                  ></img>
                </Box>

              </Box>

            )}
          </>
        )
      }
    </Box>
  );
};

export default DashboardSingleConnector;

{
  /* <Box sx={{ width: 270, height: "2.81rem", ml: 4, }}>
                  <Select
                      className="basic-single"
                      classNamePrefix="select"
                      defaultValue={options[0]}
                      onChange={(data) => addDuration(id, data?.value)}
                      isClearable={true}
                      isSearchable={true}
                      name="color"
                      options={options}
                      styles={{
                          singleValue: (base) => ({
                              ...base, color: '#000000', border: "none"
                          }),
                          valueContainer: (base) => ({
                              ...base,
                              background: '#F3F4FA',
                              color: 'white',
                              width: '100%',
                              border: "1px solid #F3F4FA"
                          }),
                          indicatorsContainer: (base) => ({
                              ...base, backgroundColor: "#F3F4FA"
                          }),
                          container: (base) => ({
                              ...base,
                              backgroundColor: '#F3F4FA',
                              border: "1px solid #F3F4FA"
                          }),
                      }}
                  />
              </Box> */
}
