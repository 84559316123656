import { Box } from '@mui/material'
import React, { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'

import { madTechState, setImageLoaded } from '../../features/madTechSlice'
import Navbar from '../Navbar/Navbar'
import WhoAreYou from './WhoAreYou'

const NaLayout = ({ children }) => {

    const { isGetStarted } = useSelector(madTechState)
    const dispatch = useDispatch()
    useEffect(() => {
        dispatch(setImageLoaded(true))
    }, [])

    return (
        <Box>
            <Navbar />
            {!isGetStarted ? children : <WhoAreYou />}
        </Box>
    )
}

export default NaLayout