import React, { useEffect, useState } from "react";
import {
  Box,
  Typography,
} from "@mui/material";
import ArrowForwardIcon from "@mui/icons-material/ArrowForward";
import { useNavigate } from "react-router";
import { useSelector } from "react-redux";

import AppLayout from "../../../components/AppLayout/AppLayout";
import {
  useAddSUserMutation,
  useGetSRoleMutation,
} from "../../../services/query";
import { madTechState } from "../../../features/madTechSlice";
import SAddUser from "./SAddUser";
import { useMyContext } from "../../../components/CommanToasterContext/toast";
import { Strings } from "../../../utils/Strings";
import Loader from "../../../components/Setting/Loader";

const SAddUserForm = () => {
  const { selectedAccount } = useSelector(madTechState);

  const navigate = useNavigate();
  const { setOpen, setMessage, setOpen2, setMessage2 } = useMyContext();

  const [userDetails, setUserDetails] = useState({
    firstName: "",
    lastName: "",
    email: "",
    phoneNumber: "",
    companyName: selectedAccount?.name,
    title: "",
    roleId: "",
    accounts: [],
  });

  const [roles, setRoles] = useState(null);

  const [
    addSUser,
    { data: addSUserData, error: addSUserError, isLoading: addUSerLoading },
  ] = useAddSUserMutation();

  const [
    getRoleData,
    { 
      data: roleData, 
      isLoading: roleLoading, 
      error: roleError },
  ] = useGetSRoleMutation();


  useEffect(() => {
    getRoleData();
  }, []);

  useEffect(() => {
    if (roleData !== undefined) {
      if (roleData.statusCode === 200 && roleData.response) {
        setRoles(roleData?.response);
        return
      } else {
        setRoles([]);
        return
      }
    }

    if (roleError !== undefined) {
      setRoles([]);
      console.log("roleError", roleError);
    }
  }, [roleData, roleError]);

  useEffect(() => {
    if (addSUserData !== undefined) {
      if (addSUserData.statusCode === 200) {
        setMessage(addSUserData?.statusMessage)
        setOpen(true)
        navigate(
          selectedAccount ? "/users?accountId=" + selectedAccount.id : "/users"
        );
        return
      } else {
        setOpen2(true)
        setMessage2(addSUserData.statusMessage);
      }
    }
    if (addSUserError !== undefined) {
      console.log("addSUserError", addSUserError);
    }
  }, [addSUserData, addSUserError]);

  const _handleAddUser = (data) => {
    addSUser(data ? data : userDetails);
  };

  return (
    <AppLayout>
      <Box sx={{ px: "1.5rem", }}>
        <Box
          sx={{
            display: "flex",
            flexDirection: "row",
            mb: 2,
            alignItems: "center",
            marginTop: "14px"
          }}
        >
          <Typography
            sx={{
              fontSize: "1.25rem",
              fontWeight: 700,
              fontFamily: "Helvetica Neue",
              cursor: "pointer",
              textDecoration: "underline",
            }}
            onClick={() => {
              window.location.replace(
                selectedAccount
                  ? "/profileDetails?accountId=" + selectedAccount.id
                  : "/profileDetails"
              );
            }}
          >
            Settings
          </Typography>
          <Box
            sx={{
              display: "flex",
              flexDirection: "row",
            }}
          >
            <Typography
              sx={{
                fontSize: "1.25rem",
                fontFamily: "Helvetica Neue",
                fontWeight: 700,
                letterSpacing: 0.2,
                marginLeft: "10px",
              }}
            >
              <ArrowForwardIcon
                sx={{
                  height: "1.25rem",
                  width: "1.25rem",
                  marginBottom: "3px",
                }}
              />
              <span
                style={{
                  fontSize: "1.25rem",
                  fontFamily: "Helvetica Neue",
                  fontWeight: 700,
                  letterSpacing: 0.2,
                  marginLeft: "10px",
                  cursor: "pointer",
                  textDecoration: "underline",
                }}
                onClick={() => {
                  navigate(
                    selectedAccount
                      ? "/users?accountId=" + selectedAccount.id
                      : "/users"
                  );
                }}
              >
                Users
              </span>
            </Typography>
            <Box
              sx={{
                display: "flex",
                flexDirection: "row",
              }}
            >
              <Typography
                sx={{
                  fontSize: "1.25rem",
                  fontFamily: "Helvetica Neue",
                  fontWeight: 500,
                  letterSpacing: 0.2,
                  marginLeft: "10px",
                }}
              >
                <ArrowForwardIcon
                  sx={{
                    height: "1.25rem",
                    width: "1.25rem",
                    marginBottom: "3px",
                  }}
                />{" "}
                Add New User
              </Typography>
            </Box>
          </Box>
        </Box>
        {roleLoading || roles == null ? (
          <Loader />
        ) : (
          <SAddUser
            userDetails={userDetails}
            roles={roles}
            setUserDetails={setUserDetails}
            _handleAddUser={_handleAddUser}
            addUSerLoading={addUSerLoading}
          />
        )}
      </Box>
    </AppLayout>
  );
};

export default SAddUserForm;
