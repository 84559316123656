import { Box, Typography } from "@mui/material";
import React from "react";

const Annex2 = () => {
  return (
    <Box sx={{ mt: 2 }}>
      <Box
        sx={{
          textDecoration: "underline",
          textAlign: "center",
          fontWeight: "bolder",
          fontSize: "20px",
        }}
      >
        Annex II
      </Box>
      <div style={{ marginTop: "8px", textAlign: "center", fontWeight: "700" }}>
        SECURITY MEASURES, INCLUDING TECHNICAL AND ORGANISATIONAL MEASURES TO
        PROTECT THE SECURITY OF PERSONAL DATA
      </div>

      <Box style={{ padding: "20px" }}>
        <ol>
          {/* First List Item */}
          <li>
            <Typography variant="body1">
              <strong style={{ textDecoration: "underline" }}>
                Written Information Security Program.
              </strong>{" "}
              Develop, implement, maintain, monitor, upgrade and comply with
              administrative, technical and physical safeguards, including,
              without limitation written information security policies and
              procedures, designed to protect the confidentiality, availability
              and integrity of Personal Data and any systems that store or
              otherwise process it, which are: (a) aligned with an
              industry-standard control framework (e.g., NIST SP 800-53, ISO
              27001, SOC 2 Type 2, CIS Critical Security Controls); (b)
              consistent with the safeguards for protection of Personal Data and
              information of a similar character set forth in any state, federal
              or foreign regulations by which MadTech is bound; (c) approved by
              executive management; (d) reviewed and updated at least annually
              or whenever there is a material change in business practices that
              may reasonably implicate the security or integrity of Personal
              Data; and (e) communicated to all personnel with access to
              Personal Data. Assign to an individual or a group of individuals
              the responsibility for developing, implementing, and managing the
              organization’s written information security program.
            </Typography>
          </li>

          {/* Second List Item */}
          <li style={{ marginTop: "15px" }}>
            <Typography variant="body1">
              <strong style={{ textDecoration: "underline" }}>
                Risk Assessment.
              </strong>{" "}
              Maintain risk assessment procedures for the purposes of periodic
              review, identification and assessment of internal and external
              risks to the organization and the security, confidentiality,
              integrity and availability of Personal Data, monitoring and
              maintaining compliance with the organization’s policies and
              procedures, and reporting the condition of the organization’s
              information security and compliance to MadTech’s internal
              executive management and board of directors or similar body.
            </Typography>
          </li>

          {/* Third List Item */}
          <li style={{ marginTop: "15px" }}>
            <Typography variant="body1">
              <strong style={{ textDecoration: "underline" }}>
                Personnel Training.
              </strong>{" "}
              Train personnel on an ongoing basis on the importance of
              information security, the proper use of computer security and on
              maintaining the confidentiality, integrity, availability and
              security of Personal Data and comply with MadTech’s policies and
              procedures, consistent with the terms of the Terms of Service and
              Data Protection Laws.
            </Typography>
          </li>

          {/* Fourth List Item */}
          <li style={{ marginTop: "15px" }}>
            <Typography variant="body1">
              <strong style={{ textDecoration: "underline" }}>
                Disciplinary Measures.
              </strong>{" "}
              Impose disciplinary measures on personnel for violations of
              MadTech’s policies and procedures and information security
              program.
            </Typography>
          </li>

          {/* Fifth List Item */}
          <li style={{ marginTop: "15px" }}>
            <Typography variant="body1">
              <strong style={{ textDecoration: "underline" }}>
                Subcontractor Management.
              </strong>{" "}
              Conduct reasonable due diligence and monitoring to ensure
              subcontractors and Subprocessors are capable of maintaining the
              privacy, confidentiality, security, integrity and availability of
              Personal Data consistent with this DPA.
            </Typography>
          </li>

          {/* Sixth List Item */}
          <li style={{ marginTop: "15px" }}>
            <Typography variant="body1">
              <strong style={{ textDecoration: "underline" }}>
                Access Controls.
              </strong>{" "}
              Maintain logical access controls designed to limit access to
              Personal Data and relevant information systems only to authorized
              personnel and third parties (e.g. granting access on a
              need-to-know basis, use of unique IDs and passwords (which are not
              vendor-supplied default passwords) for all users, periodic review
              and revoking/changing access when personnel are terminated or
              changes in job functions occur), and storing physical records
              containing Personal Data in locked facilities, storage areas or
              containers.
            </Typography>
          </li>

          {/* Seventh List Item */}
          <li style={{ marginTop: "15px" }}>
            <Typography variant="body1">
              <strong style={{ textDecoration: "underline" }}>
                Secure User Authentication.
              </strong>{" "}
              Maintain password controls designed to manage and control password
              strength, expiration and usage including prohibiting users from
              sharing passwords and requiring that passwords controlling access
              to Personal Data must: (a) be at least eight (8) characters in
              length and meet minimum complexity requirements; (b) not be stored
              in readable format on the organization’s computer systems; (c) be
              changed at least every ninety (90) days; (d) have a history
              threshold to prevent reuse of recent passwords; (e) if newly
              issued, be changed after first use; and (f) blocking access to
              user accounts after multiple unsuccessful attempts to gain access.
            </Typography>
          </li>

          {/* Eighth List Item */}
          <li style={{ marginTop: "15px" }}>
            <Typography variant="body1">
              <strong style={{ textDecoration: "underline" }}>
                Multi-factor Authentication and VPNs.
              </strong>{" "}
              Use multi-factor authentication to authenticate access to Personal
              Data by MadTech personnel, and permit remote-access to Personal
              Data only through a MadTech-controlled virtual private network
              (VPN).
            </Typography>
          </li>
          <li style={{ marginTop: "15px" }}>
            <Typography variant="body1">
              <strong style={{ textDecoration: "underline" }}>
                Incident Detection and Response.
              </strong>{" "}
              Maintain policies and procedures to detect, prevent and respond to
              actual or reasonably suspected Information Security Incidents,
              encourage the reporting of such incidents, and document responsive
              actions taken in connection with any such incidents (including
              mandatory post-incident review of events and actions taken, if
              any, to make changes in business practices relating to the
              protection of Personal Data).
            </Typography>
          </li>
          <li style={{ marginTop: "15px" }}>
            <Typography variant="body1">
              <strong style={{ textDecoration: "underline" }}>
                Encryption
              </strong>{" "}
              Apply industry standard encryption to Personal Data: (a) stored on
              any medium (i.e., laptops, mobile devices, portable storage
              devices, file servers and application databases); and (b)
              transmitted across any public network (such as the Internet) or
              wirelessly.
            </Typography>
          </li>
          <li style={{ marginTop: "15px" }}>
            <Typography variant="body1">
              <strong style={{ textDecoration: "underline" }}>
                Network Security.
              </strong>{" "}
              Implement network security controls such as up-to-date firewalls,
              endpoint protection and anti-malware software with up-to-date
              virus definitions, operating system patches, layered DMZs, updated
              intrusion detection/prevention systems and other traffic and event
              correlation procedures designed to protect systems from intrusion
              and limit the scope of any successful attack.
            </Typography>
          </li>
          <li style={{ marginTop: "15px" }}>
            <Typography variant="body1">
              <strong style={{ textDecoration: "underline" }}>
                Vulnerability Management.
              </strong>{" "}
              Implement vulnerability management, threat protection technologies
              and scheduled monitoring procedures to detect, assess, mitigate,
              remove and protect against unauthorized use and new and existing
              security vulnerabilities and threats, including viruses, bots and
              other malicious code.
            </Typography>
          </li>
          <li style={{ marginTop: "15px" }}>
            <Typography variant="body1">
              <strong style={{ textDecoration: "underline" }}>
                Change Control.
              </strong>{" "}
              Follow change management procedures and implement tracking
              mechanisms designed to test, approve and monitor all changes to
              MadTech’s technology and information assets.
            </Typography>
          </li>
          <li style={{ marginTop: "15px" }}>
            <Typography variant="body1">
              <strong style={{ textDecoration: "underline" }}>
                Physical Security.
              </strong>{" "}
              Physical and environmental security of data center, server room
              facilities and other areas containing Personal Data designed to:
              (a) protect information assets from unauthorized physical access;
              (b) manage, monitor and log movement of persons into and out of
              MadTech facilities; and (c) guard against environmental hazards
              such as heat, fire and water damage.
            </Typography>
          </li>
          <li style={{ marginTop: "15px" }}>
            <Typography variant="body1">
              <strong style={{ textDecoration: "underline" }}>
                Business Continuity and Disaster Recovery.
              </strong>{" "}
              Maintain business continuity and disaster recovery policies and
              procedures designed to maintain service and recover from
              foreseeable emergency situations or disasters.
            </Typography>
          </li>
        </ol>
      </Box>
    </Box>
  );
};

export default Annex2;
