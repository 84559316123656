import { Box, FormControl, MenuItem, Select, Checkbox, InputLabel } from "@mui/material";
import { useEffect, useRef, useState } from "react";
import { Controller } from "react-hook-form";

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
    },
  },
};

export function MDropDown({
  control,
  name,
  list,
  required,
  value,
  borderColor,
  borderRadius,
  color,
  marginTop ,
  label,
  autoFocus = false,
  disabled = false
}) {
  const [open, setOpen] = useState(autoFocus);
  const inputRef = useRef(null); 

  return (
    <Box sx={{ display: "flex", flexDirection: "row", }}>
      <Controller
        name={name}
        control={control}
        defaultValue={Array.isArray(value) ? value : []}
        rules={required ? { required: "This field is required" } : {}}
        disabled={disabled}
        render={({ field, fieldState: { error } }) => (
          <FormControl sx={{
            width: "100%",
            height: "2.75rem",
            fontSize: "1rem",
            color: "#666666",
            backgroundColor: "#FFFFFF",
            fontFamily: "Helvetica Neue",
            marginTop: marginTop ? marginTop : ''
          }} size="small" error={!!error}>
            <InputLabel
              sx={{
                fontSize: "0.875rem",
                marginTop: "0.25rem",
                opacity: 0.7,
                background: "#FFFFFF",
                fontFamily: "Helvetica Neue",
                color: "rgba(0, 0, 0, 0.5)"
              }}
              id={`${name}-label`}
            >
              {label ? label : name}
            </InputLabel>
            <Select
              {...field}
              inputProps={{ "aria-label": "Without label" }}
              inputRef={inputRef} 
              open={open}
              onClose={() => setOpen(false)}
              onOpen={() => setOpen(true)}
              sx={{
                fontSize: "1rem",
                fontFamily: "Helvetica Neue",

                color: color ? color : '',
                "& fieldset": {
                  borderColor: borderColor ? borderColor : "",
                },

                borderRadius: borderRadius,
                overflow: "hidden"
              }}
              MenuProps={MenuProps}
              labelId={`${name}-label`}
              label={label ?  label : name}
              id={`${name}-label`}
              multiple
              style={{ height: 50 }}
              onChange={(e) => {
                field.onChange(
                  typeof e.target.value === "string"
                    ? e.target.value.split(",")
                    : e.target.value
                );
              }}
              renderValue={(selected) => (
                <div style={{ display: 'flex', flexWrap: 'wrap' }}>
                  {selected?.map((value, index) => (
                    <span key={value} style={{
                      fontSize: "0.9rem",
                      fontFamily: "Helvetica Neue",
                      color: color ? color : ""
                    }}>
                      {index !== 0 && ', '}
                      {list?.find(item => item.value === value)?.title}
                    </span>
                  ))}
                </div>
              )}
            >
              {list?.map((it, index) => (
                <MenuItem
                  key={index}
                  sx={{
                    fontSize: "0.875rem",
                    fontFamily: "Helvetica Neue",
                  }}
                  value={it?.value}
                >
                  <Checkbox
                    checked={field?.value?.indexOf(it?.value) > -1}
                  />
                  {it?.title}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        )}
      />
    </Box>
  );
}
