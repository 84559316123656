import { Grid, Typography } from '@mui/material'
import React from 'react'
import NaLayout from '../NavLayout/NaLayout'

const PageNotFound = () => {
    return (
        <div>
            <NaLayout />
            <Grid
                container
                justifyContent="center"
                alignItems="center"
                sx={{ minHeight: '60vh', bgcolor: '' }}
            >
                <Typography sx={{ fontSize: "2rem" }}>404 Page Not Found.</Typography>
            </Grid>
        </div>
    )
}

export default PageNotFound
