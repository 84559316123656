import React, { useCallback, useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { Box, Drawer } from "@mui/material";

import crossimg from "../../assets/x.svg";
import showPwdImg from "../../assets/show_password.svg";
import hidePwdImg from "../../assets/hide_password.svg";

import {
  ChangePassHeading,
  ChangePassImgContainer,
  ChangePasswrdContainer,
  ChangePasswrdHeading,
  ChangePwdError,
  DrawerContainer,
  HideShowImg,
} from "./SMyProfile.styles";
import CommonInput from "../../components/CommonInput/CommonInput";
import { Strings } from "../../utils/Strings";
import { useChangePasswordMutation } from "../../services/query";
import CommonButton from "../../components/CommonButton/CommonButton";
import { color } from "../../utils/Colors";
import { madTechState } from "../../features/madTechSlice";

const passWordFields = [
  {
    name: "current",
    label: "Current password",
    showType: "curr",
    placeholder: "Enter Password",
  },
  {
    name: "newPassword",
    label: "New password",
    showType: "new",
    placeholder: "Enter New Password",
  },
  {
    name: "confirmPassword",
    label: "Confirm password",
    showType: "confirm",
    placeholder: "Re-enter Password",
  },
];

const ProfileDrawer = ({ drawerOpen, toggleDrawer, setDrawerOpen }) => {
  const { selectedAccount } = useSelector(madTechState);

  const [
    changePassword,
    {
      data: changePassData,
      isLoading: changePassLoading,
      error: changePassError,
    },
  ] = useChangePasswordMutation();

  const [pwdError, setPwdError] = useState(false);
  const [passErrorMsg, setPassErrorMsg] = useState("");
  const [passSuccessMsg, setPassSuccessMsg] = useState("");
  const [misMatchedPassword, setMisMatchedPassword] = useState(false);

  const [password, setPassword] = useState({
    current: "",
    newPassword: "",
    confirmPassword: "",
  });

  const [isShowPass, setIsShowPass] = useState({
    curr: false,
    new: false,
    confirm: false,
  });

  useEffect(() => {
    if (changePassData !== undefined) {
      if (changePassData?.statusCode === 200) {
        setPassSuccessMsg(changePassData?.statusMessage);
        setDrawerOpen(false);
        setPassword({
          ...password,
          confirmPassword: "",
          current: "",
          newPassword: "",
        });
      } else {
        setPassErrorMsg(changePassData?.statusMessage);
      }
    }
    if (changePassError !== undefined) {
      console.log("error", changePassError);
    }
  }, [changePassData, changePassError]);

  const validatePassword = () => {
    if (
      !password.newPassword.match(
        /^(?=.*?[A-Z])(?=.*?[a-z])(?=.*[!@#$&*])(?=.*?[0-9]).{8,}$/
      )
    ) {
      setPwdError(true);
      return true;
    } else {
      setPwdError(false);
    }
    return false;
  };

  const handlePasswordChange = (e, name) => {
    setPassword({ ...password, [name]: e.target.value });
    setPwdError(false);
    setMisMatchedPassword(false);
    setPassErrorMsg("");
    setPassSuccessMsg("");
  };

  const handleShowHidePass = (name) => {
    setIsShowPass((prevState) => ({
      ...prevState,
      [name]: !prevState[name],
    }));
  };

  const handleChangePass = useCallback(() => {
    if (validatePassword()) {
      return;
    }
    if (password.newPassword !== password.confirmPassword) {
      setMisMatchedPassword(true);
      return;
    }
    changePassword({
      confirmPassword: password.confirmPassword,
      newPassword: password.newPassword,
      userId:
        selectedAccount !== null && selectedAccount !== undefined
          ? selectedAccount.userId
          : null,
    });
  }, [
    useChangePasswordMutation,
    password.confirmPassword,
    password.newPassword,
    validatePassword,
    selectedAccount,
  ]);

  return (
    <Drawer anchor={"right"} open={drawerOpen} onClose={toggleDrawer}>
      <DrawerContainer>
        <ChangePassHeading>Change password</ChangePassHeading>
        <ChangePassImgContainer onClick={toggleDrawer}>
          <img src={crossimg} style={{ maxWidth: "100%", maxHeight: "100%" }} />
        </ChangePassImgContainer>
        <Box sx={{ height: "1.25rem" }}>
          {pwdError ? (
            <ChangePwdError>
              {Strings?.password_error}
              {Strings?.password_rule}
            </ChangePwdError>
          ) : passErrorMsg ? (
            <ChangePwdError>{passErrorMsg}</ChangePwdError>
          ) : (
            passSuccessMsg && (
              <ChangePwdError success={true}>{passSuccessMsg}</ChangePwdError>
            )
          )}
        </Box>
        <Box display="flex" flexDirection="column" mt="2rem">
          {passWordFields?.map((value, index) => {
            return (
              <ChangePasswrdContainer mt={index > 0 && "1.5rem"}>
                <ChangePasswrdHeading>{value?.label}</ChangePasswrdHeading>
                <CommonInput
                  height={"2.75rem"}
                  backgroundColor={"#FFFFFF"}
                  placeholder={value.placeholder}
                  inputType={isShowPass[value.showType] ? "text" : "password"}
                  value={password[value.name]}
                  onChange={(event) => handlePasswordChange(event, value.name)}
                  endAdornment={
                    <HideShowImg
                      title={
                        isShowPass[value.showType]
                          ? "Hide password"
                          : "Show password"
                      }
                      src={isShowPass[value.showType] ?  showPwdImg : hidePwdImg}
                      onClick={() => handleShowHidePass(value.showType)}
                    />
                  }
                />
              </ChangePasswrdContainer>
            );
          })}
          <Box sx={{ height: "0.625rem" }}>
            {misMatchedPassword && (
              <ChangePwdError>{Strings.confirm_password_error}</ChangePwdError>
            )}
          </Box>
          <Box sx={{ mt: "1.5rem" }}>
            <CommonButton
              width={`100%`}
              height={56}
              textStyle={{
                fontSize: "18px",
                fontWeight: "500",
                fontFamily: "Helvetica",
              }}
              onClick={handleChangePass}
              title={Strings.submit}
              color={
                changePassLoading || password.current.trim() === ""
                  ? color.themeLightGray
                  : "#3843AC"
              }
              isLoading={changePassLoading}
              disabled={password.current.trim() === ""}
            />
          </Box>
        </Box>
      </DrawerContainer>
    </Drawer>
  );
};

export default ProfileDrawer;
