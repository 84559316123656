import { Box, Divider, Typography } from "@mui/material";
import React from "react";

const AppendixInformation = () => {
  return (
    <>
      <Typography variant="h6" gutterBottom sx={{ my: 4 }}>
        Table 3: Appendix Information
      </Typography>
      <Typography variant="body1" gutterBottom sx={{ my: 4 }}>
        <b>“Appendix Information”</b> means the information which must be
        provided for the selected modules as set out in the Appendix of the
        Approved EU SCCs (other than the Parties), and which for this Addendum
        is set out in:
      </Typography>

      {/* Items with dividers */}
      <Box sx={{ mt: 2 }}>
        <Typography variant="body1">Annex 1A: List of Parties</Typography>
        <Divider sx={{ borderColor: "orange", my: 1 }} />

        <Typography variant="body1">
          Annex 1B: Description of Transfer
        </Typography>
        <Divider sx={{ borderColor: "orange", my: 1, borderWidth: 1.5 }} />

        <Typography variant="body1">
          Annex II: Security measures, including technical and organisational
          measures to provide security of the data
        </Typography>
        <Divider sx={{ borderColor: "orange", my: 1 }} />

        <Typography variant="body1">
          Annex III: List of Sub Processors
        </Typography>
        <Divider sx={{ borderColor: "orange", my: 1, borderWidth: 2 }} />
      </Box>
    </>
  );
};

export default AppendixInformation;
