import React, { useState } from 'react'
import { Box, FormControl, FormHelperText } from "@mui/material";
import { Controller } from 'react-hook-form';
import { DemoContainer } from '@mui/x-date-pickers/internals/demo';
import { DateTimePicker, LocalizationProvider } from '@mui/x-date-pickers-pro';
import { AdapterDayjs } from '@mui/x-date-pickers-pro/AdapterDayjs';
import { DateTimeRangePicker } from '@mui/x-date-pickers-pro/DateTimeRangePicker';
import dayjs from 'dayjs';

import './Plans.css'

const getRangeDate = (dateSpan) => {
    switch(dateSpan) {
        case "all":
            return {
                minDate: dayjs().subtract(100, 'year'),
                maxDate: dayjs().add(100, 'year'),
            };
        case "past":
            return {
                minDate: dayjs().subtract(100, 'year'),
                maxDate: dayjs(),
            };
        case "future":
        default:
            return {
                minDate: dayjs().add(1, 'day'),
                maxDate: dayjs().add(100, 'year'),
            };
    }
}

const CommanDatePicker = ({
    name,
    control,
    required,
    value,
    label,
    dateSpan,
}) => {
    const [open, setOpen] = useState(false);

    const defaultEndTime = new Date();

    defaultEndTime.setHours(23, 59, 0, 0);
    const formattedDefaultValue = value !== null ? dayjs(value) : null;

    return (
        <Box  sx={{
                marginTop: "15px",
                width: "100%",
                // overflow: 'visible !important'
            }}
            className='changeDate' >
            <LocalizationProvider dateAdapter={AdapterDayjs} sx={{ overflow: 'visible !important' }}>
                <DemoContainer components={['DateTimeRangePicker']} sx={{ overflow: 'visible !important' }}>
                    <Controller
                        name={name}
                        sx={{ overflow: 'visible !important' }}
                        control={control}
                        defaultValue={formattedDefaultValue ? formattedDefaultValue : null}
                        rules={required ? { required: "This field is required" } : {}}
                        render={({ field, fieldState: { error } }) => (
                            <FormControl error={!!error} fullWidth sx={{ overflow: 'visible !important' }}>
                                <DateTimePicker
                                    {...field}
                                    {...dateSpan? getRangeDate(dateSpan): {}}  
                                    label={`${label}`}
                                    className='dateranges'
                                    sx={{
                                        border: !!error ? "1px solid red" : "",
                                        borderRadius: "5px !important",
                                        "& input": {
                                            color: "#1C2B47", fontSize: "13px", fontFamily: "Helvetica Neue"
                                        },
                                        "& .MuiInputLabel-sizeMedium": {
                                            top: "0px !important",
                                          },
                                    
                                    }}
                                    open={open}
                                    onClose={() => setOpen(false)}
                                    slotProps={{
                                        textField: {
                                            onClick: () => setOpen(true),

                                        },
                                    }}
                                />
                                
                            </FormControl>
                        )}
                    />
                </DemoContainer>
            </LocalizationProvider>
        </Box>
    )
}

export default CommanDatePicker