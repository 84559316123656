import { Box, List, ListItem, ListItemButton, OutlinedInput, TableCell, Typography } from "@mui/material";
import styled from "styled-components";
import { color } from "../../utils/Colors";
const appbarHeight = "4rem";

export const ToolbarWrapper = styled(Box)({
    width: "100%",
    height: appbarHeight,
    backgroundColor: color.themeColor,
})

export const LogoHeading = styled(Typography)({
    marginLeft: "1.5rem !important",
    display: "flex",
    fontFamily: "monospace !important ",
    fontWeight: "700 !important ",
    letterSpacing: ".3rem",
    color: color.whiteColor,
    textDecoration: "none",
    flexGrow: 1,
    cursor: "pointer",
})
export const ReportTableCell = styled(TableCell)(({ theme }) => {
    // const { themeName } = useThemeContext();
    // const Theme = color[themeName];
    return {
        fontWeight: 500,
        backgroundColor: `${color.themeColor} !important`,
        color: "white !important",
        width: props => (props.width ? props.width : ""),
        textAlign: "center"
    }
})
export const StyledSearchOutlinedInput = styled(OutlinedInput)(({ theme }) => {
    // const { themeName } = useThemeContext();
    // const Theme = color[themeName];
    return {
        backgroundColor: "#fff",
        height: "2.45rem",
        '&.Mui-focused .MuiOutlinedInput-notchedOutline':
            { borderColor: color.FieldBorderColor, },
        marginLeft: "16px",
    }
})
export const ConnectionTableCell = styled(TableCell)(({ theme }) => {
    // const { themeName } = useThemeContext();
    // const Theme = color[themeName];
    return {
        fontWeight: 500,
        backgroundColor: `${color.themeColor} !important`,
        color: "white !important",
        width: props => (props.width ? props.width : ""),
        textAlign: props => (props.textAlign ? props.textAlign : "")
    }
})
export const ReportTableCellValue = styled(TableCell)(({ theme }) => {
    // const { themeName } = useThemeContext();
    // const Theme = color[themeName];
    return {
        textAlign: "center",
        padding: "0.8125rem 1rem 0.8125rem 1rem",
        justifyContent: "end"
    }
})
export const TableNameTypography = styled(Typography)(({ theme }) => {
    // const { themeName } = useThemeContext();
    // const Theme = color[themeName];
    return {
        fontSize: "0.875rem !important",
        fontFamily: "Helvetica Neue !important",
        // textDecoration: "underline",
        color: color.TableLinkColor,
        cursor: "default",
        width: "fit-content",
    }
})
export const StyledLoaderBoxContain = styled(Box)({
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    width: "100%",
    height: props => (props.height ? props.height : "100vh")
})

export const selectedAccountHeading = styled(Typography)({
    // margin: 'auto 0px auto 130px',
    margin: "auto !important",
    // fontFamily: 'monospace',
    fontFamily: "Helvetica Neue !important",
    fontSize: "1.25rem !important",
    fontWeight: "500 !important",
    letterSpacing: "0rem",
    color: color.whiteColor,
    textDecoration: "none",
    alignItems: "center",
    paddingTop: "0.3125rem !important",
})

export const UserDetailsWrapper = styled(Box)({
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    cursor: "pointer",
})

export const UserDetailsHeading = styled(Typography)({
    fontSize: "0.875rem !important",
    fontWeight: "500 !important",
    fontFamily: "Helvetica Neue !important",
    letterSpacing: 1,
    marginRight: "0.625rem !important",
    marginTop: "0.125rem !important",
    color: "white"
})

export const DashboardList = styled(List)({
    paddingTop: "0px !important",
    paddingBottom: "0px !important",
    flex: "1 1 0",
    overflowY: "auto",
    overflowX: "hidden"
})

export const DashboardListItem = styled(ListItem)({
    width: "100%",
    marginBottom: "0rem !important",
    flexDirection: "column",
    alignItems: "center",
    height:
        ((props => (props.connectionOpen)) && (props => (props.item.text)) === "Connections") ||
            ((props => (props.item.text)) === "Settings" && (props => (props.settingsOpen)))
            ? undefined
            : 64,
})

export const DashboardListItemButton = styled(ListItemButton)({
    position: "relative",
    paddingLeft: "48px !important",
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between !important",
    width: "100%",
    height: "4rem",
    backgroundColor:
        (props => (props.item.isActive)) ||
            ((props => (props.item.text)) === "Connections" && (props => (props.connectionOpen))) ||
            ((props => (props.item.text)) === "Settings" && (props => (props.settingsOpen)))
            ? "rgba(25, 50, 65, 0.05)"
            : "",
    // opacity: (item.text === 'Connections' && connectionOpen) ||
    //     (item.text === 'Settings' && settingsOpen) ? 0.8 : 1,
    "&:hover": {
        backgroundColor: "rgba(25, 50, 65, 0.05)",
        // opacity: 0.8,
    }
})

export const ListHeading = styled(Typography)({
    color:
        (props => (props.item.isActive)) ||
            (props => (props.hoverTextId)) === (props => (props.index)) ||
            ((props => (props.item.text)) === "Connections" && (props => (props.connectionOpen))) ||
            ((props => (props.item.text)) === "Settings" && (props => (props.settingsOpen)))
            ? color.themeColor
            : color.themeDarkGray,
    fontSize: "1rem !important",
    fontWeight: "500 !important",
    letterSpacing: "0.04em !important",
    fontFamily: "Helvetica Neue !important",
})

export const ListItemButtonWrapper = styled(ListItemButton)({
    paddingRight: "0px !important",
    width: "12.5rem !important",
    backgroundColor: "#fff !important",
    // borderLeft: `2px solid ${props => (props.pathname.startsWith("/platform"))
    //     ? color.themeColor
    //     : "rgba(25, 50, 65, 0.12)"
    //     }`,
    marginLeft: "3rem !important",
    "&:hover": {
        backgroundColor: "#fff !important",
        // opacity: 0.6
    },
})

export const ListItemButtonHeading = styled(Typography)({
    fontSize: "1rem !important",
    fontWeight: "500 !important",
    marginTop: "0.5rem !important",
    fontFamily: "Helvetica Neue !important",
    // color:
    //     (props => (props.subheadingText)) === "My platforms" ||
    //         (props => (props.pathname.startsWith("/platform")))
    //         ? color.themeColor
    //         : color.themeDarkGray,
})

export const SettingCollapseWrapper = styled(ListItemButton)({
    paddingRight: "0px !important",
    width: "12.5rem",
    marginLeft: "3rem !important",
    "&:hover": {
        backgroundColor: "#fff",
        // opacity: 0.6
    },
})

export const SettingCollapseHeading = styled(Typography)({
    fontSize: "1rem !important",
    fontWeight: "500 !important",
    marginTop: "0.5rem !important",
    fontFamily: "Helvetica Neue !important",
    // display: selectedAccount ? "none" : "",
    // color:
    //     subheadingText === " My Profile" ||
    //         pathname === "/myprofile"
    //         ? color.themeColor
    //         : color.themeDarkGray,
})

export const LogoutWrapper = styled(Box)({
    display: "flex",
    flexDirection: "row",
    cursor: "pointer",
    marginBottom: "8px !important",
    justifyContent: "center",
})

export const LogoutContainer = styled(Box)({
    border: "1px solid #FFE6E6",
    padding: "0.5rem !important",
    height: "3rem",
    width: 232,
    backgroundColor: "#FFF7F7",
    display: "flex",
    borderRadius: "8px !important",
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "center",
})

export const LogoutHeading = styled(Typography)({
    fontSize: "1rem !important",
    fontWeight: "500 !important",
    marginLeft: "1rem !important",
    color: "#FF5A5A",
    fontFamily: "Helvetica Neue !important",
})

export const TermsOfService = styled(Box)({
    display: "flex",
    borderRadius: 2,
    flexDirection: "row",
    marginBottom: "5px !important",
})


export const TermsOfServiceHeading = styled(Box)({
    fontSize: "0.875rem !important",
    color: "#AEAEAE",
    fontFamily: "Helvetica Neue !important",
    textDecoration: "underline",
    cursor: "pointer",
    margin: "0 auto !important",
})

export const DataProcessing = styled(Typography)({
    fontSize: "0.875rem !important",
    color: "#AEAEAE",
    fontFamily: "Helvetica Neue !important",
    textDecoration: "underline",
    cursor: "pointer",
    margin: "0 auto !important",
})