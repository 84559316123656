import { Box, Typography, useMediaQuery } from '@mui/material'
import React, { useCallback, useState } from 'react'
import { useEffect } from 'react'
import { useLocation, useNavigate, useSearchParams } from 'react-router-dom'

import Stepper from '../../components/Stepper/Stepper'
import { useCreatePasswordMutation } from '../../services/query'
import showPwdImg from '../../assets/show_password.svg';
import hidePwdImg from '../../assets/hide_password.svg';
import { Strings } from '../../utils/Strings'
import CommonButton from '../../components/CommonButton/CommonButton'
import CommonInput from '../../components/CommonInput/CommonInput'
import madConnectLogo from "../../assets/madconnectdark.png";
import AuthSuccessModal from './AuthSuccessModal'



const OAuthCompleteProfile = () => {

    const [step, setStep] = useState(1)
    const [searchParams, setSearchParams] = useSearchParams();
    const userData = JSON.parse(localStorage.getItem('USER'))
    const token = JSON.parse(localStorage.getItem('TOKEN'))
    const urltoken = searchParams.get('t')
    const userid = searchParams.get('u')

    const [newPassword, setNewPassword] = useState("");
    const [confirmNewPassword, setConfirmNewPassword] = useState("");
    const [pwdError, setPwdError] = useState(false);
    const [misMatchedPassword, setMisMatchedPassword] = useState(false);
    const [isRevealNewPwd, setIsRevealNewPwd] = useState(false);
    const [isRevealConfirmNewPwd, setIsRevealConfirmNewPwd] = useState(false);
    const [passErrorMsg, setPassErrorMsg] = useState('')
    const [signupSucess, setSignupSucess] = useState(false);
    const [userType, setUserType] = useState()

    const navigate = useNavigate();
    const clientid = searchParams.get('clientId')

    const [createNewPass, { data, error, isLoading }] = useCreatePasswordMutation()
    const validatePassword = () => {
        if (!newPassword.match(/^(?=.*?[A-Z])(?=.*?[a-z])(?=.*[!@#$&*])(?=.*?[0-9]).{8,}$/)) {
            setPwdError(true);
            return true;
        } else {
            setPwdError(false);
        }
        return false;
    };

    const completePassword = useCallback((event) => {
        event.preventDefault();
        if (validatePassword()) {
            return;
        }
        if (newPassword !== confirmNewPassword) {
            setMisMatchedPassword(true);
            return;
        }
        createNewPass({
            token: urltoken,
            userId: userid,
            password: newPassword,
            oauth: true
        })
        // setStep(2)
    }, [useCreatePasswordMutation, validatePassword, token, newPassword, userData, confirmNewPassword,])

    useEffect(() => {
        if (data !== undefined) {
            if (data.statusCode === 200) {
                setSignupSucess(true)
                setTimeout(() => {
                    navigate("/oauth/authorize?clientId=" + clientid)
                }, 3000);
            } else {
                setPassErrorMsg(data?.statusMessage)
            }
        }
        if (error !== undefined) {
            console.log("error", error);
        }
    }, [data, error]);

    const maxHeight = useMediaQuery('(max-height:515px)');

    return (
        <Box display='flex' justifyContent='center' bgcolor="#C4C4C4" alignItems="center" height={maxHeight ? "" : "100vh"}>
            <Box display="block" >
                <Box textAlign="center" padding="20px">
                    <img style={{ width: "auto", height: "4rem", justifyContent: 'center' }} alt={"MADCONNECT LOGO"} src={madConnectLogo}></img>
                </Box>
                <Stepper step={step} userType={userType}>
                    <>
                        {signupSucess ?
                            <AuthSuccessModal />
                            :
                            <div className='' >
                                <div className='' style={{ background: "#C4C4C4" }}>
                                    <div className='create-password-container' style={{ background: "white", borderRadius: 8, boxShadow: "rgba(0, 0, 0, 0.06) 0px 8px 20px" }}>
                                        <Box sx={{ display: "flex", textAlign: "center", justifyContent: "center" }}>
                                            <Typography variant='subtitle2' sx={{ color: "red", textAlign: "center", height: "1.25rem" }}>{passErrorMsg}</Typography>
                                        </Box>
                                        <p className='text-center mb-2' style={{ fontSize: "1.4rem", fontWeight: 500, fontFamily: 'Helvetica Neue' }}>{Strings.createPassword}</p>
                                        <p className='text-center mb-4 sub-text-color' style={{ fontSize: "0.9rem", fontWeight: 500, fontFamily: 'Helvetica Neue' }}>Please set a password below to continue</p>
                                        <div className=''>
                                            <CommonInput height={"2.75rem"} backgroundColor={'white'} placeholder={Strings.newPassword}
                                                inputType={isRevealNewPwd ? "text" : "password"} value={newPassword} onChange={(event) => {
                                                    setPwdError(false); setNewPassword(event.target.value); setPassErrorMsg('')
                                                }}
                                                endAdornment={<img title={isRevealNewPwd ? "Hide password" : "Show password"} src={isRevealNewPwd ? hidePwdImg : showPwdImg}
                                                    onClick={() => setIsRevealNewPwd(prevState => !prevState)} style={{ width: 18, height: 18, cursor: "pointer" }} />}></CommonInput>

                                            {pwdError ? <div style={{ width: "290px" }}> <span className='form-error'>{Strings.password_error}{Strings.password_rule}</span></div> : <></>}
                                        </div>
                                        <div className='mt-3'>
                                            <CommonInput height={"2.75rem"} backgroundColor={'white'} placeholder={Strings.confirmNewPassword}
                                                inputType={isRevealConfirmNewPwd ? "text" : "password"} value={confirmNewPassword} onChange={(event) => {
                                                    setMisMatchedPassword(false); setConfirmNewPassword(event.target.value); setPassErrorMsg('')
                                                }} onKeyDown={(event) => {
                                                    if (event.key === 'Enter') { completePassword(event); setPassErrorMsg('') }
                                                }}
                                                endAdornment={<img title={isRevealConfirmNewPwd ? "Hide password" : "Show password"}
                                                    src={isRevealConfirmNewPwd ? hidePwdImg : showPwdImg} onClick={() => setIsRevealConfirmNewPwd(prevState => !prevState)}
                                                    style={{ width: 18, height: 18, cursor: "pointer" }} />}></CommonInput>

                                            {misMatchedPassword ? <div style={{ width: "290px" }}><span className='form-error'>{Strings.confirm_password_error}</span></div> : <></>}
                                        </div>
                                        <div className='mt-3'></div>
                                        <div className='mt-2 mb-4'>
                                            <CommonButton width={`100%`} height={"2.75rem"} textStyle={{ fontSize: '1.125rem', fontWeight: '500' }} onClick={(event) => {
                                                completePassword(event);
                                            }} isLoading={isLoading} title={Strings.next} color={'#3843AC'}></CommonButton>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        }
                    </>

                </Stepper>
            </Box>
        </Box>

    )
}

export default OAuthCompleteProfile