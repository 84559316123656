import React from 'react'
import { Box, useMediaQuery } from '@mui/material'
import { LazyLoadImage } from 'react-lazy-load-image-component';
import 'react-lazy-load-image-component/src/effects/blur.css';

import aboutMadconnectImg from '../../assets/AbotMadconnect.webp'
import { AboutSuperContainer, ConnectDescription, ConnectSubHeading, ConnectContainer, ConnectMainContainer, Heading } from './AboutMad.styles';
import { getFontSize } from '../../utils/utils';


const AbouMadconnect = () => {
    
    const matches = {
        lg: useMediaQuery('(min-width: 1400px)'),
        md: useMediaQuery('(min-width: 1280px)'),
        sm: useMediaQuery('(min-width: 1200px)'),
        xs: useMediaQuery('(min-width: 1060px)'),
        xss: useMediaQuery('(min-width: 810px)'),
      };

    return (
        <AboutSuperContainer isConnect={true} >
            <ConnectMainContainer matchesxss={matches.xss}>
                <Box>
                    <LazyLoadImage
                        alt={'ABOUT MADCONNECT'}
                        // effect="blur"
                        width={'100%'}
                        height={'100%'}
                        src={aboutMadconnectImg} />
                </Box>
                <ConnectContainer matchesxss={matches.xss}>
                    <Heading  isConnect={true} fontSize={getFontSize(matches , { lg: '1.2rem', md: '1.1rem', sm: '1rem', xs: '1rem', xss: '1rem' })} >
                        ABOUT MADCONNECT
                   </Heading>   
                   <ConnectSubHeading fontSize={getFontSize(matches ,{ lg: '2.5rem', md: '2.2rem', sm: '1.9rem', xs: '1.7rem', xss: '1.7rem' })} >
                    Solving for Platform Connectivity
                   </ConnectSubHeading>
                   <ConnectDescription  isDescription1={true} fontSize={getFontSize(matches ,{ lg: '1rem', md: '1rem', sm: '1rem', xs: '1rem', xss: '1rem' })}  >
                   MadConnect is a connector hub that powers bi-directional communication across the AdTech and MarTech ecosystems. It enables seamless integration between platforms, unlocking diverse use cases such as activation, measurement, enrichment, data collaboration, suppression, and analytics. By connecting to MadConnect once, platforms gain instant access to a vast network of clouds, CDPs, SSPs, DSPs, clean rooms, data providers, media management platforms, and more.
                   </ConnectDescription>
                   <ConnectDescription fontSize={getFontSize(matches, { lg: '1rem', md: '1rem', sm: '1rem', xs: '1rem', xss: '1rem' })}>
                   MadConnect eliminates the complexities of platform integration at scale, allowing engineering teams to focus on innovation instead of the time-consuming task of building and maintaining API integrations.
                   </ConnectDescription>
                    <ConnectDescription fontSize={getFontSize(matches, {lg : '1rem' ,md: '1rem' , sm : '1rem' , xs : '01rem' , xss: '1rem'})} />
                </ConnectContainer>
            </ConnectMainContainer>
        </AboutSuperContainer>

    )
}

export default AbouMadconnect